import React from 'react'
import _, { forEach } from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import { ROUTES } from '../navigation/route-constants'
import Trailer from './Trailer'
import VersionSelector from './VersionSelector'
import ShowtimeSchedule from '../showtime/ShowtimeSchedule'
import MovieInfo from './MovieInfo'
import Pictures from './Pictures'
import Poster from './Poster'

export default class Movie extends React.Component {

   


    createLabels() {
        const { movie, showtimeTypes,} = this.props
       
        let shows = []
        

        if (movie.showtimesByOrgId  ) {
            _.forOwn(movie.showtimesByOrgId, (showtimes, orgId) => {
              
                let tt = showtimes.map((i) => i.type !== null ? i.type : '')
             
              
              
                   function groupBy( tt, property) {
                    return tt.reduce(function (memo, x) {
                        if (!memo[x[property]]) {
                            memo[x[property]] = [];
                        }
                        memo[x[property]].push(x);
                        return memo;
                    }, {});
                }

                //group by name
                const grouped = tt && groupBy(tt, "name");
                const keys = Object.keys(grouped);
                var output = [];

                //loop keys
                keys.forEach(key => {
                    //merge using reduce
                    const out = grouped[key].reduce((acc, current) => {
                        return {
                            id: current.id,
                            name: current.name,
                            
                        }
                    });
                    output.push(out);
                });
               
              
               
                const index = showtimeTypes ? _.findIndex(showtimeTypes, (showType) => { return output[0].id == showType.id }) : null;
                const indx = showtimeTypes ? _.findIndex(showtimeTypes, (showType) => { return output[1] && output[1].id == showType.id }) : null;
                const ind = showtimeTypes ? _.findIndex(showtimeTypes, (showType) => { return output[2] && output[2].id == showType.id }) : null;
                const idx = showtimeTypes ? _.findIndex(showtimeTypes, (showType) => { return output[3] && output[3].id == showType.id }) : null;
                const inx = showtimeTypes ? _.findIndex(showtimeTypes, (showType) => { return output[4] && output[4].id == showType.id }) : null;

               // console.log(index, indx,ind,idx,inx)
                shows.push(<div key={tt.id} style={{ marginLeft: '20%', }}>
                    {index !== -1 &&
                        <span style={{ marginRight: '20px' }}>
                            <span style={{
                                border: index >= 0 ? '1px solid black' : '', width: '18px', height: '18px', justifyContent: 'center', background: '#fbe0bd', color: 'black', fontSize: '12px',
                                fontWeight: 600, alignItems: 'center', paddingLeft: '4px', paddingRight: '2px', borderRadius: '2px', boxShadow: '0 2px 6px 0 rgb(0 0 0 / 23%)'
                            }}> {index > -1 ? index + 1 : ''} </span> <span style={{ marginLeft: '5px' }}> {output[0].name} </span>  </span>}

                    {indx !== -1 &&
                        <span style={{ marginRight: '20px' }}>
                            <span style={{
                                border: indx >= 0 ? '1px solid black' : '', width: '18px', height: '18px', justifyContent: 'center', background: '#fbe0bd', color: 'black', fontSize: '12px',
                                fontWeight: 600, alignItems: 'center', paddingLeft: '4px', paddingRight: '2px', borderRadius: '2px', boxShadow: '0 2px 6px 0 rgb(0 0 0 / 23%)'
                            }} > {indx > -1 ? indx + 1 : ''} </span><span style={{ marginLeft: '5px' }}>  {output[1] && output[1].name}</span>   </span>}

                    {ind !== -1 &&
                        <span style={{ marginRight: '20px' }}>
                            <span style={{
                                border: ind > 0 ? '1px solid black' : '', width: '18px', height: '18px', justifyContent: 'center', background: '#fbe0bd', color: 'black', fontSize: '12px',
                                fontWeight: 600, alignItems: 'center', paddingLeft: '4px', paddingRight: '2px', borderRadius: '2px', boxShadow: '0 2px 6px 0 rgb(0 0 0 / 23%)'
                            }}> {ind > -1 ? ind + 1 : ''} </span> <span style={{ marginLeft: '5px' }}>  {output[2] && output[2].name} </span> </span>}

                    { idx !== -1 &&
                    <span style={{ marginRight: '20px' }}>
                    <span style={{
                        border: idx > 0 ? '1px solid black' : '', width: '18px', height: '18px', justifyContent: 'center', background: '#fbe0bd', color: 'black', fontSize: '12px',
                            fontWeight: 600, alignItems: 'center', paddingLeft: '4px', paddingRight: '2px', borderRadius: '2px', boxShadow: '0 2px 6px 0 rgb(0 0 0 / 23%)'
                            }}> {idx > -1 ? idx + 1 : ''} </span>  <span style={{ marginLeft: '5px' }}>  {output[3] && output[3].name} </span> </span>}

                    {inx !== -1 &&
                    <span style={{ marginRight: '20px' }}>
                    <span style={{
                        border: inx > 0 ? '1px solid black' : '', width: '18px', height: '18px', justifyContent: 'center', background: '#fbe0bd', color: 'black', fontSize: '12px',
                            fontWeight: 600, alignItems: 'center', paddingLeft: '4px', paddingRight: '2px', borderRadius: '2px', boxShadow: '0 2px 6px 0 rgb(0 0 0 / 23%)'
                            }}> {inx > -1 ? inx + 1 : ''} </span>  <span style={{ marginLeft: '5px' }}> {output[4] && output[4].name} </span>  </span>}
                     
                </div>)
            })

        }
        return shows
      
    }

    generateShowtimeSchedules() {
        const { movie, showtimeClicked, appLocale, organizers, showExpired, filterIds, movies, output, brand} = this.props;
        
        let showtimeSchedules = [];
       
            if  (movie.showtimesByOrgId)  {

            _.forOwn(movie.showtimesByOrgId, (showtimes, orgId) => {
                if (showtimes.length > 0) {
                    const isMultiBio = organizers.length > 1;
                  
                    const orgName = _.find(organizers, (org) => { return org.id == orgId });

                    showtimeSchedules.push(
                        <div key={orgId} className="organizer">
                            {isMultiBio && orgName ? <h3>{orgName.name}</h3> : null}
                         
                            <ShowtimeSchedule movie={{ ...movie, validShowtimes: showtimes }}
                                showtimeClicked={showtimeClicked}
                                appLocale={appLocale}
                                showExpired={showExpired}
                                filterIds={filterIds}
                                output={this.output}
                             
                            />

                           
            
                        </div>);
                        }
            });
        }
      

       
        return showtimeSchedules;
           
       
    }


  

    render() {
      
        const { movie, movieClicked, appLocale, iframeId } = this.props;
        
    
      
        const trailer = movie.trailer ? <Trailer trailerUrl={movie.trailer} trailerPoster={movie.trailerPoster} iframeId={iframeId} /> : null;
        const allVersions = movie.versions.length > 1 ? <VersionSelector movie={movie} movieClicked={movieClicked} /> : null;
        const poster = movie.posters ? <Poster movie={movie} /> : null;
        const pictures = movie.pictures ? <Pictures pictureUrls={movie.pictures} /> : null;
        const movieNameLocale = movie.names ? (movie.names[appLocale] ? movie.names[appLocale] : movie.names['da']) : movie.name;
      
        return (
            <div className="Movie" >
                {trailer}
                {allVersions}
                <div className="schedules">{this.generateShowtimeSchedules()}</div>
                <div style={{ marginBottom:'10px' }}>  {movie.versions.length > 1 ? this.createLabels() : ''} </div>
              

                <div className="info-container" >
                  
                    <h1>{movieNameLocale}</h1>
                    {poster}
                    <MovieInfo appLocale={appLocale} movie={movie} />
                </div>
                {/*{pictures}*/}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
   // console.log(state, 'state')
    return {
        showtimeTypes: state.movielist.showtimeTypes
    }
}

//connect(mapStateToProps)(ShowtimeType)