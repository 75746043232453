import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import CONSTANTS from '../movielist-constants'
import LOCALE from '../MovieListLocale'
import WeekProgramListItem from './WeekProgramListItem'

export default function WeekProgramList({ posterBanners, appLocale, movieClicked, showtimeClicked, showLocationOnShowtime,
    appHeightChanged, filterIds, sortBy, movies, showtimes, showBackInTime, showAsBase, showExpired, currentStartDate, moreInfoText  }) {

    const sortMovies = (movies) => {
       
        let sortedMovies = [];
        _.forEach(movies, (movie) => {
            movie.validShowtimes = _.filter(showtimes, (showtime) => {

                if (showtime.showInfo.enabled !== true) return false;
                if ((showtime.movieId == movie.id || (showAsBase && showtime.movieBaseId > 0 && showtime.movieBaseId == movie.baseId))) {
                    const datetime = moment(showtime.dateTime);
                    const isInFuture = datetime > moment() || showBackInTime;

                    return (sortBy == CONSTANTS.sort.alphabet ||
                        sortBy == CONSTANTS.sort.time && showExpired ||
                        sortBy == CONSTANTS.sort.time && isInFuture);

                }
            });

           
           
            const currentEndDate = currentStartDate.clone().add(6, 'days');
            const hasShowtimesInSelectedWeek = _.some(movie.validShowtimes, (showtime) =>
                moment(showtime.dateTime).isBetween(currentStartDate, currentEndDate, 'day', '[]'));

            if (hasShowtimesInSelectedWeek) {
                movie.validShowtimes = _.sortBy(movie.validShowtimes, ['dateTime']);
                sortedMovies.push(movie);
            }
        });

        sortedMovies.sort((a, b) => moment(a.validShowtimes[0].dateTime) - moment(b.validShowtimes[0].dateTime));
        return sortedMovies;

       
    }
   

    //console.log(showTimesOfMovie, 'from weekprogramlist')
    const generateMovieListItems = (movies) => {
        let sortedMovies = sortMovies(movies);

        return _.map(sortedMovies, (movie) =>
          //  showTimesOfMovie=movie,
            <WeekProgramListItem
                key={movie.id}
                appLocale={appLocale}
                showAsBase={showAsBase}
                movie={movie}
                posterBanners={posterBanners}
                movieClicked={movieClicked}
                showtimeClicked={showtimeClicked}
                showLocationOnShowtime={showLocationOnShowtime}
                appHeightChanged={appHeightChanged}
                showExpired={showExpired}
                showBackInTime={showBackInTime}
                filterIds={filterIds}
                currentStartDate={currentStartDate}
                moreInfoText={moreInfoText}
            />);
    }

    const movieListItems = generateMovieListItems(movies);
    const noMoviesText = LOCALE[appLocale].noMoviesThisWeekText;

    return (
        <div className="MovieList">
            {movieListItems.length > 0 ? movieListItems : <h3 className="no-events">{noMoviesText}</h3>}
        </div>)

}