import './payment.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import * as orderActions from '../../../actions/order-actions'
import * as customerActions from '../../../actions/customer-actions'
import * as gaActions from '../../../actions/ga-actions'
import { appHeightChanged } from '../../../actions/app-actions'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../orderflow-constants'
import CreditCards from './CreditCards'
import LOCALE from './PaymentLocale'

class PaymentContainer extends React.Component {
    constructor(props) {
        super(props);
        localStorage.removeItem('beforeDibsState')
        this.state = {
            errors: {
                creditCard: '',
                disableBack: ''
            },
        }

        const { configuration, showtime, history } = props;
        if (!configuration.giftCardsEnabled && !configuration.payWithDibs)
            history.replace(`${ROUTES.ORDERFLOW.REEPAY}/${showtime.movieId}/${showtime.id}${history.location.search}`);

        this.giftCardPaymentClicked = this.giftCardPaymentClicked.bind(this);
        this.navBack = this.navBack.bind(this);
        this.navNext = this.navNext.bind(this);
    }

    componentDidMount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId);
    }

    giftCardPaymentClicked() {
        const { showtime, organizerId, history } = this.props;
        history.push(`${ROUTES.ORDERFLOW.GIFTCARDS}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
    }

    navBack() {
        const { history, disableBack, appLocale } = this.props;
        const { errors } = this.state;
        if (disableBack) this.setState({ errors: { ...errors, disableBack: LOCALE[appLocale].errorBack } });
        else history.goBack();
    }

    // IF DIBS
    navNext(e) {
        const { organizerId, actions, transactionId, customer, showtime, totalPrice, history } = this.props;

        if (this.paymentIsValid(customer) === true) {
            actions.preBook(transactionId, customer).then((result) => {
                if (!result.error) {
                    if (totalPrice === 0) {
                        actions.book(transactionId, 'Free', totalPrice)
                            .then((result) => { if (!result.error) history.push(`${ROUTES.ORDERFLOW.RECEIPT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`) });
                    } else history.push(`${ROUTES.ORDERFLOW.DIBS}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
                }
            });
        }
    }

    paymentIsValid(payment) {
        const { totalPrice, appLocale, giftCardAmount } = this.props;
        if (totalPrice === 0) return true;
        else {
            const errors = {
                ...errors,
                creditCard: !payment.creditCard && (!giftCardAmount > 0 || giftCardAmount > 0 && giftCardAmount < totalPrice) ? LOCALE[appLocale].errorCreditCard : ''
            }

            this.setState({ errors });
            return errors.creditCard == '';
        }
    }
    //---

    render() {
        const { orderType, totalPrice, configuration, customer, actions, appLocale, disableBack, organizerId, history, showtime, creditCards } = this.props;
        const { errors } = this.state;

        const chooseCreditCard = totalPrice > 0 && (orderType === CONSTANTS.orderTypes.buy || orderType === CONSTANTS.orderTypes.buyReservation) && configuration.payWithDibs ?
            <CreditCards
                creditCards={creditCards}
                appLocale={appLocale}
                totalPrice={totalPrice}
                customer={customer}
                actions={actions}
                errors={errors}
            /> : null;

        return (
            <div className="PaymentContainer">
                <div className="content-container">
                    <h1>{LOCALE[appLocale].paymentMethod}</h1>
                    <div>{configuration.giftCardsEnabled ? <input type="button" className="giftcard" value={LOCALE[appLocale].giftCard} onClick={this.giftCardPaymentClicked} /> : null}</div>

                    <div>{configuration.giftCardsEnabled && !configuration.payWithDibs ? <input type="button" className="giftcard" value={LOCALE[appLocale].payWithReepay}
                        onClick={() => history.push(`${ROUTES.ORDERFLOW.REEPAY}/${showtime.movieId}/${showtime.id}?org=${organizerId}`)} /> : null}</div>
                    
                    {chooseCreditCard}
                </div>

                <p className="error" dangerouslySetInnerHTML={{ __html: errors.disableBack }}></p>
                <div className={configuration.bottomBarAllwaysVisible ? 'nav-buttons fixed' : 'nav-buttons'}>
                    <input type="button" className={disableBack ? 'dim back gray' : 'back gray'} onClick={this.navBack} value={LOCALE[appLocale].back} />
                    {configuration.payWithDibs ? <input type="button" id="buy" className="next" onClick={this.navNext} value={LOCALE[appLocale].nextBuy} /> : null}
                </div>
            </div >

        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        orderType: state.order.orderType,
        configuration: state.organizer.configuration,
        creditCards: state.organizer.creditcards || [],
        customer: state.customer,
        transactionId: state.order.selected.transactionId,
        receipt: state.receipt,
        totalPrice: state.order.totalPrice,
        clubCardId: state.user.clubCardId,
        appLocale: state.app.locale,
        disableBack: state.order.selected.giftCardAmount > 0,
        giftCardAmount: state.order.selected.giftCardAmount,
        iframeId: state.app.iframeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, orderActions, customerActions, gaActions, { appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer)