import React from 'react';
import orderflowApi from '../api/orderflow-api'
import Error from './overlays/Error'

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        const errorTrace = {
            message: error.message,
            stack: error.stack,
            componentStack: errorInfo.componentStack
        }

        const body = {
            client: {
                id: 1,
                name: 'OrderFlow.WebApp',
                url: window.location.href
            },
            logLevel: 'error',
            entry: {
                origin: 'Frontend',
                method: 'React: ErrorBoundary',
                message: JSON.stringify(errorTrace),
            }
        }

        orderflowApi.postLog(body);
    }

    render() {
        if (this.state.hasError) {
            const error = { message: 'Der skete en fejl.' };
            return <Error error={error} />;
        }

        return this.props.children;
    }
}