export function isValidPhone(number, minLength) {
    const regex = new RegExp(`^\\d{${minLength},}$`);
    return regex.test(number);
}

export function isValidEmail(email) {
    const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}

export function isValidPassword(password) {
    const regex = /^[a-zA-Z0-9_.-]*$/;
    //return regex.test(password) && password !== '' ;
    return password !== '';
}

export function hasDecimals(number) {
    if (!isNaN(number)) return number % 1 != 0;
    else return false;
}

export function withTwoDecimals(number) { return number.toFixed(2).replace('.', ',') }