import {
    FETCH_COMMERCIALS_REQUEST,
    FETCH_COMMERCIALS_SUCCESS,
    FETCH_COMMERCIALS_FAILED
} from '../actions/cms-actions'

export default function cms(
    state = {
        isFetching: false,
        commercials: [],
        error: null
    }, action) {
    switch (action.type) {
        case FETCH_COMMERCIALS_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case FETCH_COMMERCIALS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.suceededAt,
                commercials: action.commercials,
                error: null
            }
        case FETCH_COMMERCIALS_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state
    }
}