import '../css/movielists.scss'
import './weekprogram.scss'

import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { getParams } from '../../../utilities/location-utilities'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../movielist-constants'
import { appHeightChanged } from '../../../actions/app-actions'
import { getMovieList, selectedMovieChanged, changeSelectedShowtime } from '../../../actions/movielist-actions'
import Calendar from '../../datepicker/Calendar'
import WeekProgramList from './WeekProgramList'

class WeekProgramContainer extends React.Component {
    constructor() {
        super();
        this.state = {
            showCalendar: false,
            currentStartDate: moment()
        };

        this.movieClicked = this.movieClicked.bind(this);
        this.showtimeClicked = this.showtimeClicked.bind(this);
        this.toggleCalendar = this.toggleCalendar.bind(this);
        this.changeStartDate = this.changeStartDate.bind(this);
    }

    componentDidMount() {
        const { actions, movies, organizerIds, iframeId } = this.props;
        if (movies.length == 0) actions.getMovieList(organizerIds);

        if (window.parent != window) actions.appHeightChanged(iframeId, false, false, true);
        else window.scrollTo(0, sessionStorage.getItem("scroll-position"));
    }

    componentWillUnmount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, false, true, false);
        else sessionStorage.setItem("scroll-position", window.scrollY);
    }

    toggleCalendar() {
        const showCalendar = this.state.showCalendar ? false : true;
        this.setState({ showCalendar });
    }

    changeStartDate(date) {
        this.setState({ currentStartDate: moment(date) });
    }

    movieClicked(id) {
        const { organizerIds, actions, history, movies } = this.props;
        const movie = _.find(movies, (movie) => { return movie.id == id });
        actions.selectedMovieChanged(movie.id, history.location.pathname).then(() => history.push(`${ROUTES.MOVIE}/${id}?org=${organizerIds}`));
    }




    showtimeClicked(organizerId, showtimeId) {
        const { openOrderInNewWindow, actions, history, showtimes } = this.props;
        const showtime = _.find(showtimes, (showtime) => { return showtime.id == showtimeId && showtime.organizerId == organizerId });
        if (showtime) {
            actions.changeSelectedShowtime(showtime, history.location.pathname).then(() => {
                const orderUrl = `${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${showtime.organizerId}`;
                if (openOrderInNewWindow) window.open(orderUrl, '_blank', 'width=1170,height=800,resizable=yes,scrollbars=yes');
                else history.push(orderUrl);
            })
        }
    }

    render() {
        const { currentStartDate, showCalendar } = this.state;
        const { appLocale, movies, showAsBase, showtimes, posterBanners, showExpired, datesWithShowtimes, moreInfoText,
            showLocationOnShowtime, showBackInTime, actions, organizerIds, sortBy, filterIds, listType, calendarText } = this.props;
       
        return (
            <div className={`MovieListContainer ${listType}`}>
                <div className="DatePicker show-as-week-buttons">
                    <div className="day" onClick={this.toggleCalendar}><span className="icon-calendar"></span> Flere datoer</div>

                    <Calendar
                        appLocale={appLocale}
                        initialDate={currentStartDate}
                        selectedDate={currentStartDate}
                        datesWithShowtimes={datesWithShowtimes}
                        showCalendar={showCalendar}
                        toggleCalendar={this.toggleCalendar}
                        changeDate={this.changeStartDate}
                        calendarText={calendarText}
                    />
                </div>

                <WeekProgramList appLocale={appLocale}
                    showBackInTime={showBackInTime}
                    movies={movies}
                    showAsBase={showAsBase}
                    showtimes={showtimes}
                    sortBy={sortBy}
                    movieClicked={this.movieClicked}
                    showtimeClicked={this.showtimeClicked}
                    posterBanners={posterBanners}
                    currentStartDate={currentStartDate}
                    showLocationOnShowtime={showLocationOnShowtime}
                    appHeightChanged={actions.appHeightChanged}
                    organizerIds={organizerIds}
                    showExpired={showExpired}
                    filterIds={filterIds}
                    listType={listType}
                    moreInfoText={moreInfoText}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const config = state.organizer.configuration;
    const listConfig = config.movielists;
    const movies = listConfig.week.base ? extractMoviesAsBases(state.movielist.movies) : state.movielist.movies;

    function extractMoviesAsBases(movies) {
        const moviesBases = state.movielist.movieBases;
        const moviesAsBase = [];
        _.each(movies, (movie) => {
            if (movie.baseId) {
                const baseMovie = _.find(moviesBases, (base) => { return base.id == movie.baseId; });
                const alreadyInList = _.some(moviesAsBase, ['baseId', movie.baseId]);
                if (baseMovie && !alreadyInList) moviesAsBase.push({ ...movie, name: baseMovie.name, posters: baseMovie.posters });
            } else moviesAsBase.push(movie);
        });
        return moviesAsBase;
    }

    return {
        organizerIds: state.movielist.organizerIds || getParams().org,
        movies,
        sortBy: listConfig.week.sort,
        showAsBase: listConfig.week.base,
        showtimes: state.movielist.showtimes,
        posterBanners: config.posterBanners,
        appLocale: state.app.locale,
        openOrderInNewWindow: config.openOrderInNewWindow,
        showLocationOnShowtime: config.showLocationOnShowtime,
        showBackInTime: config.daysBack > 0,
        iframeId: state.app.iframeId,
        showExpired: config.movielists.date.showExpired,
        filterIds: state.movielist.filterIds,
        listType: CONSTANTS.listTypes.week,
        calendarText: config.calendarText,
        datesWithShowtimes: state.movielist.datesWithShowtimes,
        moreInfoText: _.find(state.organizer.configuration.texts, (text) => { return text.id == 'uge-program-filminfo' })
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { getMovieList, selectedMovieChanged, changeSelectedShowtime, appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WeekProgramContainer)