export default {
    da: {
        loading: 'Indlæser...',
        notFound: 'Siden findes ikke.',
        unspecified: 'Der skete en fejl.',
        close: 'Luk',
        errors: {
            7: 'Transaktionen er udløbet. Dit køb er ikke gået igennem. Hvis der er reserveret penge på din konto, frigives de indenfor 1 time.',
            16: 'Du har allerede en anden aktiv bestilling i et andet faneblad, færdiggør den der eller start forfra.',
            17: 'Det ønskede antal pladser er desværre ikke ledige til denne forestilling.',
            29: 'Vi kunne ikke kontakte betalingstjenesten. Prøv igen senere.',
            30: 'Du har været for længe om at indtaste dine oplysninger.',
            35: 'Din session er udløbet, da du har været for længe om at gennemføre din bestilling.',
            40: 'Det er ikke muligt at aktivere reservationen, da den er købt eller afbestilt.',
            46: 'Den angivne bruger har ikke noget medlemskab eller det er udløbet.',
            472: 'Det er ikke muligt at reservere/købe til dette arrangement. Tjek at forestillingen ikke allerede er begyndt eller overstået.',
            474: 'Køb og reservering er ikke aktiveret på denne forestilling.',
            475: 'Køb er ikke aktiveret på denne forestilling.',
            476: 'Reservering er ikke aktiveret på denne forestilling.',
            999: 'Denne arrangør er ikke opsat.',
            10001: 'Arrangøren kan ikke kontaktes i øjeblikket. Prøv igen senere eller ring til arrangøren.',
            10004: 'Det er ikke muligt, at kontakte billet-udbyderens billet system. Prøv igen lidt senere.'
        }
    },

    en: {
        loading: 'Loading...',
        notFound: 'Page not found.',
        unspecified: 'An error occured.',
        close: 'close',
        errors: {
            7: 'The transaction has expired. Your purchase did not succeed. If the amount is reserved on your account, you will have them refunded within 1 hour.',
            16: 'You already have an active order in another tab, complete is there or start over.',
            17: 'The requested amount of seats unfortunately are not available for this showtime.',
            29: 'We could not contact the payment service. Try again later.',
            30: 'You have been too slow filling in your information.',
            35: 'Your session has expired, you have been too slow filling out your order.',
            40: 'It is not possble to activate the reservation, it has already been purchased or deleted.',
            46: 'This user does not have a membership or it has expired.',
            472: 'It is not possible to reserve/purchase tickets for this showtime. Check that the show has not already begun or is in the past.',
            474: 'Purchasing and reserving is not activated on this showtime.',
            475: 'Purchasing is not activated on this showtime.',
            476: 'Reserving is not activated on this showtime.',
            999: 'This organizer is not configured.',
            10001: 'The organizer can not be contacted right now. Try again later or call directly to the organizer.',
            10004: 'It is not possible to reach the ticket system. Try again later.'
        }
    },

    fo: {
        loading: 'Innlesur...',
        notFound: 'Síðan finnist ikki.',
        unspecified: 'Her er ein feilur íkomin',
        close: 'Lat aftur',
        errors: {
            7: 'Keypi er útgingi. Títt keyp fór ikki ígøgnum. Um peningur er afturhildin á tíni konto, verður tað leysgivi innan 1 tíma.',
            16: 'Tú hevur longu eina aktiva bílegging í øðrum teigi, enda hana ella byrja umaftur.',
            17: 'Tað eru ikki nokk tøk pláss til hesa sýningina.',
            29: 'Vit hava avbjóðingar við samband við gjaldsveitaran. Royn aftur seinni.',
            30: 'Tú hevur nýtt ov langa tíð til at skriva tínar upplýsingar.',
            35: 'Títt keep er ógilda, tað hevur tiki ov langa tíð at gjøgnumføra tína bílegging.',
            40: 'Tað er ikki møguligt at gilda bíleggingina, tí hon er keypt ella strika.',
            46: 'Hesin brúkarin hevur ikki nakran limaskap ella er hann útgingin.',
            472: 'Tað er ikki møguligt at umbiða/keypa til hesa sýningina. Vinarliga kanna eftir at sýningin ikki er byrja ella er liðug.',
            474: 'Keyp og bíleggingar eru ikki møguligar til hesa sýning.',
            475: 'Keyp er ikki møguligt til hesa sýning.',
            476: 'Bílegging er ikki møguligt til hesa sýning.',
            999: 'Hesin fyriskiparin er ikki uppsettur.',
            10001: 'Fyriskiparin er ikki tøkur gjøgnum skipanini júst nú. Royn aftur seinni ella ring beinleiðis til fyriskiparan.',
            10004: 'Tað er ikki møguleiki at fáa fatur í atgongumerkja skipanini. Royn aftur seinni.'
        }
    }

     //new: {
     //   loading: '...',
     //   notFound: '',
     //   unspecified: '',
     //   close: '',
     //   errors: {
     //       7: '',
     //       16: '',
     //       17: '',
     //       29: '',
     //       30: '',
     //       35: '',
     //       40: '',
     //       46: '',
     //       472: '',
     //       474: '',
     //       475: '',
     //       476: '',
     //       999: '',
     //       10001: '',
     //       10004: ''
     //   }
    //}
}