import _ from 'lodash'
import { getFutureMovies, getMovieList } from './movielist-actions'
import { fetchOrganizer } from '../api/ticketgate-api'

export default {
    getOrganizer,
    getMultiOrgFutureMovies
}

export const FETCH_ORGANIZER_REQUEST = 'FETCH_ORGANIZER_REQUEST'
export const FETCH_ORGANIZER_SUCCESS = 'FETCH_ORGANIZER_SUCCESS'
export const FETCH_ORGANIZER_FAILED = 'FETCH_ORGANIZER_FAILED'

export function getOrganizer(organizerIds, siteType, customerEmail) {
    const isMultipleOrgs = _.includes(organizerIds, ',');
    const id = isMultipleOrgs ? _.split(organizerIds, ',')[0] : organizerIds;

    return dispatch => {
        dispatch({
            type: FETCH_ORGANIZER_REQUEST,
            requestedAt: Date.now(),
            id
        });

        return fetchOrganizer(id, siteType, customerEmail).then(
            response => dispatch({
                type: FETCH_ORGANIZER_SUCCESS,
                succeededAt: Date.now(),
                id: response.id,
                multibioIds: organizerIds,
                name: response.name,
                futureTemplateId: response.futureTemplateId,
                configuration: response.configuration,
                creditcards: response.creditcards
            }), error => dispatch({
                type: FETCH_ORGANIZER_FAILED,
                failedAt: Date.now(),
                error
            })
        );
    }
}

export function getMultiOrgFutureMovies(organizerIds) {
    return dispatch => {
        const orgIds = _.split(organizerIds, ',');
        const promises = [];
        _.forEach(orgIds, (id) => {
            promises.push(new Promise((resolve, reject) => {
                resolve(fetchOrganizer(id));
            }));
        });

        Promise.all(promises).then(result => {
            let templateIds;
            _.forEach(result, (organizer) => {
                templateIds ? templateIds += ',' + organizer.futureTemplateId : templateIds = organizer.futureTemplateId;
            });
            return dispatch(getFutureMovies(templateIds, organizerIds));
        });
    }
}