import React from 'react'
import _ from 'lodash'

import { useLocation } from "react-router-dom";


export default function BookReciept() {
    const location = useLocation();



    return (
         <>
            {location.state.receiptHtml && <div dangerouslySetInnerHTML={{ __html: location.state.receiptHtml  }} />}
         </>
        )
    
}

