import React from 'react'
import _ from 'lodash'
import LOCALE from './TransactionsLocale'

export default class TransactionReceipt extends React.Component {

    render() {
        const { purchaseReceipt, toggleReceipt, fadeIn, appLocale } = this.props;

        return (
            <div className={fadeIn ? 'TransactionReceipt overlay fade-in' : 'TransactionReceipt overlay fade-out'}>
                <div className="receipt-container">
                    <input type="button" className="close" value={LOCALE[appLocale].close} onClick={toggleReceipt} />
                    <div className="purchaseReceipt" dangerouslySetInnerHTML={{ __html: purchaseReceipt }} />
                </div>
            </div>
        )
    }
}