import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { getParams } from '../utilities/location-utilities'
import { ROUTES } from './navigation/route-constants'
//import { SITE_TYPES } from '../../../api/api-constants'
import xml2js from '../lib/xml-to-js'
import { useHistory, Link } from "react-router-dom";
//import ReceiptContainerMulti from '../receipt/ReceiptContainerMulti'
import CONSTANTS from './api-constants'




export default function ReepayLandingPage() {
    const querystrings = getParams();
    //  console.log(querystrings, 'querystrings')
    const search = window.location.search;
    // const search = window.location.search;
    const paramss = new URLSearchParams(search);

    const org = paramss.get('org')
    let history = useHistory();
    const [bookError, setBookError] = useState('')
    const [errorCode, setErrorCode] = useState('')
    const [errorNr, setErrorNr] = useState('')
    const [receiptHtml, setReceiptHtml] = useState('')

    let receiptHeight = document.querySelector('#backgroundTable')
    var iframe = document.querySelector('iframe');
    //iframe.height && iframe.height = receiptHeight.height;


    useEffect(() => {

        const ref = querystrings.id
        const buyCode = querystrings.invoice
        const amount = querystrings.amount

        const transId = querystrings.transactionID
        const accept = querystrings.accept
        const link = sessionStorage.getItem('link')

        // console.log(buyCode, transId, amount, querystrings)
        //  console.log(ref, buyCode, amount, transId)
        if (buyCode && amount && transId && accept) {
            fetch(`${CONSTANTS.fetchUrl}/${CONSTANTS.ticketgatePath}/Book?TransactionID=${transId}&Reference=&Amount=${amount}&BuyCode=${buyCode}`, {
                method: 'GET',
                headers: {
                    'UserName': 'web',
                    'Password': 'web',
                    'OriginNo': '10',
                },
            })
                .then(res => res.text())
                .then(result => {

                    const json = xml2js.parseString(result)
                    const data = json.message
                    //console.log(data, 'data')

                    // data.answer.
                    if (data.answer._niceMessage) {
                        setBookError(data.answer._niceMessage)
                        setErrorCode(data.answer._code)
                        setErrorNr(data.answer._error)
                    }

                    if (link) {
                        window.location.href = `${link}?orderid=${querystrings.invoice}`
                    }


                    else {
                        if (data.transaction.reciept) {
                            document.getElementsByTagName("html")[0].innerHTML = data.transaction.reciept
                            setReceiptHtml(data.transaction.reciept)
                        }
                        else if (data.answer._niceMessage)
                            setBookError('Kvittering kunne ikke generes')
                    }



                })

        }
        else fetch(`${CONSTANTS.fetchUrl}/${CONSTANTS.ticketgatePath}/CancelTransaction?TransactionID=${transId}`, {
            method: 'GET',
            headers: {
                'UserName': 'web',
                'Password': 'web',
                'OriginNo': '10',

            },
        })
            .then(res => res.text())
            .then((result) =>
                result && history.push(`${ROUTES.PROGRAM}?org=${org}`)
            )

    }, [])




    useEffect(() => {
        if (window.parent != window) {
            var iframeId = querystrings.iframeid;
            const app = document.querySelector('.App');
            var newPageHeight = Math.max(app.scrollHeight, app.offsetHeight, app.clientHeight);
            const receipt = document.getElementsByTagName("body")[0];
            newPageHeight = receipt ? Math.max(newPageHeight, receipt.scrollHeight, receipt.offsetHeight, receipt.clientHeight) : newPageHeight;

            console.log('[CHILD] AppHeightChanged! Posting to parent: ');
            console.log({ iframeId: iframeId, newHeight: newPageHeight });

            window.parent.postMessage({
                iframeId: iframeId,
                iframeHeight: newPageHeight,
                scrollToTop: false
            }, '*');
        }
    }, [receiptHtml])







    //  console.log(bookError)
    return (

        <>

            <div className={bookError ? 'Error overlay fade-in' : 'Error overlay fade-out'}>
                <div className="overlay-content">
                    <p dangerouslySetInnerHTML={{ __html: bookError }}></p>
                    {/*{buttons || error.buttons || (error.close ? closeButton : null)}*/}
                    <div className="code"> Error code: ({errorCode}, {errorNr})</div>
                </div>
            </div>
        </>

    )
}
