import React, { useEffect, useState } from 'react'
import _, { last } from 'lodash'
import LOCALE from '../MovieListLocale'
import ShowtimeSchedule2 from '../../showtime/ShowtimeSchedule2'
import ShowtimeScheduleWeek from '../../showtime/ShowtimeScheduleWeek'
import ShowtimeTypeList from '../../showtime/ShowtimeTypeList';
import Showtime2 from '../../showtime/Showtime2'
import moment from 'moment'
import ShowtimeType from '../../showtime/ShowtimeType'
import { getParams } from '../../../utilities/location-utilities'

import Poster from '../../movie/Poster'


export default function WeekProgramListItem({
    movie, appLocale, showAsBase, filterIds, showExpired, showtimeClicked, movieClicked,
    moreInfoText, take, weekNumber, nextWeek, firstWeek, lastWeek, previousWeek, setDiffer2, nextValidWeek, currentStartDate, lastShowtimeDay
}) {
      
    const [dimNextArrow, setDimNextArrow] = useState(false)
    const [dimBackArrow, setDimBackArrow] = useState(false)
    const showtimeTypes = [];
    const querystrings = getParams();
    let base = querystrings.base && querystrings.base.toLowerCase()
    const showtimesByDate = _.groupBy(movie.validShowtimes, (showtime) => {
        if (base === 'true' ? showtime.movieId === movie.id && showtime.showInfo.enabled && showtime.type && !_.find(showtimeTypes, showtime.type)
            : showtime.showInfo.enabled && showtime.type && !_.find(showtimeTypes, showtime.type)) //needs if condition on queryparams
            showtimeTypes.push(showtime.type);

        return base === 'true' ? showtime.movieId === movie.id && moment(showtime.dateTime).format('YYYY-MM-DD') : moment(showtime.dateTime).format('YYYY-MM-DD'); // needs if condition on queryparams
    });
   

    let startDate = currentStartDate;
    if (!startDate && movie.validShowtimes.length > 0) startDate = moment(movie.validShowtimes[0].dateTime);
    

    const poster = movie.posters ?
        <div className="poster-container" data-movie-id={movie.id} onClick={onMovieClick}>
            <Poster movie={movie} /></div> : null;

    const movieNameLocale = movie.names ? (movie.names[appLocale] ? movie.names[appLocale] : movie.names['da']) : movie.name;
    const infoText = moreInfoText ? moreInfoText[appLocale] : LOCALE[appLocale].movieInfo;
    
  
    function onMovieClick(e) {
        e.preventDefault();
        const movieId = e.currentTarget.dataset.movieId || e.target.id;
        if (e.target.tagName == 'IMG' || e.target.tagName == 'H1' || e.target.tagName == 'INPUT' || _.includes(e.target.classList, 'poster-banner'))
            movieClicked(movieId);

    }

    let shoss = [{}]
    // lave MAX - showtimesOnDate.length gang tom div
    const generateShowtimesOnDate = (showtimesOnDate, MAX) => {
        let s = showtimesOnDate && showtimesOnDate.length
       // let tt = MAX - s

        //console.log(showtimesOnDate && showtimesOnDate.length)
               
        return showtimesOnDate ? _.map(showtimesOnDate, (showtime) => {
            //const isInFuture = moment(showtime.dateTime) > moment();
            //   console.log(showtime, 'showtime')
            if (showtime.showInfo.enabled === true) {
                shoss.push(showtime)
                return <Showtime2 key={showtime.id}
                    showtime={showtime}
                    text={moment(showtime.dateTime).format('LT')}
                    showtimeClicked={showtimeClicked}
                    showExpired={showExpired}
                />;
               
                return shoss

            }
           
        })
            :
           Array.from({ length: MAX })
          .map((_, index) => (
                    <div className="Showtime hidden_Showtime" key={index} style={{  marginTop: '3px' }}></div>
                    )
                    )                       
           // return <div className="Showtime" style={{ backgroundColor: '#327365', marginTop: '3px' }}>fff</div>          
            
    }

    const generateNoShowtimeDiv = (MAX, showtimesOnDate) => {
        //console.log(MAX, 'max')
        let s = showtimesOnDate && showtimesOnDate.length
        //console.log(s,'s')
        let tt = MAX - s
        
        return   Array.from({ length: tt })
            .map((_, index) => (
                <div className="Showtime  hidden_Showtime" key={index} style={{  marginTop: '3px' }}></div>
            )
            )
          
       
    }

   
            const generateWeekSchedule = (startDateOrg) => {
                startDate = startDateOrg.clone(); // we don't want to mutate it
              
                const weekScheduleItems = [];
                //console.log(showtimesByDate, 'showtimes by date')
               // console.log(movie.validShowtimes, 'movie validshowtimes')

                let showtimesOnDateMAX = 0
                for (var i = 0; i < take; i++) {
                    const x = showtimesByDate[startDate.format('YYYY-MM-DD')] /*_.map(showtimesByDate, (shows) => { return shows[startDate.format('YYYY-MM-DD')] })*/
                    if (x != null && x.length > showtimesOnDateMAX) showtimesOnDateMAX = x.length
                    startDate.add(1, 'day')
                }
                
                startDate = startDateOrg.clone(); // we don't want to mutate it

               // console.log(showtimesOnDateMAX, 'MAX showtimes')

                for (var i = 0; i < take; i++) {
                    const date = moment(startDate);
                    //  console.log(date, 'date')
                    const formattedDate = _.capitalize(date.format('ddd D/M'))
                    const today = date.isSame(moment(), 'day') ? LOCALE[appLocale].today : ''
                    const tomorrow = date.isSame(moment().add(1, 'day'), 'day') ? 'I morgen' : ''
                    const showtimesOnDate = showtimesByDate[startDate.format('YYYY-MM-DD')] /*_.map(showtimesByDate, (shows) => { return shows[startDate.format('YYYY-MM-DD')] })*/

                  //  console.log(showtimesOnDate, 'showtimes on date')

                    weekScheduleItems.push(

                        <div key={startDate} className="showtimes-on-date">

                            <h4 className="date" style={{ fontFamily:'oswald' }}>{today || tomorrow || formattedDate}</h4>
                            <div>{ generateShowtimesOnDate(showtimesOnDate, showtimesOnDateMAX)  }  </div>
                           
                            <div> {generateNoShowtimeDiv(showtimesOnDateMAX, showtimesOnDate)} </div>

                        </div>

                    )

                    startDate.add(1, 'day');
                }

               return weekScheduleItems                  
               
    }
   
    

    let showtypesperfilm = []
    let multishowtypes = []
        showtimeTypes.map(showtime => {
            if (showtime.movieId === movie.id) {
               
            return    showtypesperfilm.push(showtime.type)
            }
        })

    if (showtypesperfilm && showtypesperfilm.length > 1) multishowtypes.push(showtypesperfilm[0])
   

    let identicalShowtypes = []
    let obj
    let uniqObj = {}
    for (let i in showtypesperfilm) {
        obj = showtypesperfilm[i]['id']
        uniqObj[obj] = showtypesperfilm[i]
    }
    for (let i in uniqObj) {
        identicalShowtypes.push(uniqObj[i])
    }

   
    var widths = [0, 431, 850];

   // console.log(moment().day(), 'uge day-----------')
    const currentEndDate = currentStartDate.clone().add((take - 1), 'days');
    //console.log(currentEndDate, 'end date')
    let bool1 = moment(currentEndDate)
        .isSameOrBefore(lastShowtimeDay);  // true
    
    let ff = movie.validShowtimes
    let fg = ff[ff.length - 1]
  

    let lastShowtime = moment(fg.dateTime).format('YYYY-MM-DD')
 
    let datejump = []
   _.map(movie.validShowtimes, showtime => {
       
        let check = moment(showtime.dateTime).format('YYYY-MM-DD') >= currentStartDate.clone().add((take), 'days').format('YYYY-MM-DD')
        if (check === true) {
            datejump.push( showtime.dateTime)

            return showtime.dateTime
        }
        
   })


    let dateBack = []
    

        _.map(movie.validShowtimes, showtime => {
                    
            let check = moment(showtime.dateTime).format('YYYY-MM-DD') < currentStartDate.clone().format('YYYY-MM-DD')
            if (check === true ) {
            
                dateBack.push(showtime.dateTime.slice(0, 10))

                return showtime.dateTime
            }

        });

  
       let firstShowtime = moment(movie.validShowtimes[0].dateTime).format('YYYY-MM-DD')


    let str =  currentStartDate.format('YYYY-MM-DD') 
  
 
    var date1 = moment(str);
    var date2 = moment(datejump[0]);
    var date3 = moment(lastShowtime)
    var date4 =  moment(dateBack[dateBack.length - 1])
  
    var differ = date2.diff(date1, 'days')
    var forrigeDiffer = date4.diff(date1, 'days') 
   
  
    var diff = date1.diff(date3, 'days')

    /*-------------FINDING OUT if there are showtimes in currentWeek------*/
    let tw = 0
    useEffect(() => {
       
        _.map(movie.validShowtimes, showtime => {
          
            if ((moment(showtime.dateTime).isBetween(currentStartDate, currentEndDate)) && tw == 0) {
                tw = 1
            }
           
        })

    })

    
    /*-------------FINDING OUT WHEN TO DISABLE NEXT ARROW------*/
    let nextWeekStrD = currentStartDate.clone().add(take, 'days');
    let nextWeekEndD = currentEndDate.clone().add(take,'days')


    let nw = 0
    useEffect(() => {    
         _.map(movie.validShowtimes, showtime => {

             if ((moment(showtime.dateTime).isBetween(nextWeekStrD, nextWeekEndD)  || (moment(showtime.dateTime).isAfter(nextWeekEndD) && tw == 1)) && nw == 0)
        {
            nw = 1
        }
             //}
         })
        if (nw == 0) setDimNextArrow(true)
        else setDimNextArrow(false)
       
    })
    /*----------FINDING OUT WHEN TO DISABLE BACK ARROW----------*/
    let lastWeekStrD = currentStartDate.clone().subtract(take, 'days')
    let lastWeekEndD = currentEndDate.clone().subtract(take, 'days')
   

    let lw = 0
    useEffect(() => {
        let td = currentStartDate
        let tm = moment().add(1, 'days')
        let isCrntDisT = td.isSame(tm, 'day')
     
            _.map(movie.validShowtimes, showtime => {
                
                if ((moment(showtime.dateTime).isBetween(lastWeekStrD, lastWeekEndD) ||
                    (moment(showtime.dateTime).isBefore(currentStartDate) && isCrntDisT) || (moment(showtime.dateTime).isSame(lastWeekEndD,'day'))
                    || (moment(showtime.dateTime).isBefore(lastWeekStrD) && tw == 1)) && lw == 0) {
                    lw = 1
                }
                
            })
        if (lw == 0) setDimBackArrow(true)
        else setDimBackArrow(false)
      
    })

    
    return (
        <>

            <div  className="MovieListItem">
                {poster}
            <div className="movie-info">
                    <h1 data-movie-id={movie.id} onClick={onMovieClick}>{showAsBase ? movie.name : movieNameLocale}</h1>
                    
                   
                    <div className="ShowtimeSchedule2">
                     
                       
                        <div className="weekNumberContainer" >
                           {/* <p style={{ fontSize:'12px'}}>Uge {weekNumber}</p>*/}
                        </div>
                            
                        <div className="schedule">
                            <div className="ShowtimeScheduleWeek"
                                style={{
                                    /*justifyContent: shoss.length <= 1 && window.innerWidth > widths[1] ? 'center' : 'space-between',*/
                                    paddingTop: /*shoss.length !== 1 ||*/ window.innerWidth < widths[1] ? '40px' :''
                                }} >


                                {generateWeekSchedule(currentStartDate)}
                                {/*{window.innerWidth < 385 && (<br></br> )}*/}
                            {/*</div>*/}

                            <div >

                               
                                {shoss.length <= 1 ?
                                    lastShowtime < str ?

                                        <div id={movie.id} className="previousShowBtn" onClick={() => lastWeek(diff)}>
                                            <span style={{ marginLeft: window.innerWidth < widths[1] ? '0px' : '7px', marginTop: window.innerWidth < widths[1] ? '10px' : '5px' }}> <svg xmlns="http://www.w3.org/2000/svg"
                                                width={window.innerWidth < widths[1] ? '20' : '40'} height={window.innerWidth < widths[1] ? '20' : '40'} fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                            </svg></span>
                                            <p>SIDSTE VISNING</p>
                                        </div>
                                        :
                                        firstShowtime < str ?
                                            <div className="leftRightShowtimes" >
                                                <div id={movie.id} className="previousShowBtn" onClick={() => nextValidWeek(forrigeDiffer)} >
                                                    <span style={{ marginLeft: window.innerWidth < widths[1] ? '0px' : '7px', marginTop: window.innerWidth < widths[1] ? '10px' : '5px' }}> <svg xmlns="http://www.w3.org/2000/svg"
                                                        width={window.innerWidth < widths[1] ? '20' : '40'} height={window.innerWidth < widths[1] ? '20' : '40'} fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                    </svg></span>
                                                    <p>FORRIGE VISNING</p>
                                                </div>
                                                <div id={movie.id} className="nextShowBtn" onClick={() => firstWeek(differ)}>
                                                        <p>NÆSTE VISNING</p>
                                                        <span className="ty" style={{ marginLeft: window.innerWidth < widths[1] ? '0px' : '7px', marginTop: window.innerWidth < widths[1] ? '10px' : '5px' }}>
                                                            <svg className="ty" xmlns="http://www.w3.org/2000/svg"
                                                        width={window.innerWidth < widths[1] ? '20' : '40'} height={window.innerWidth < widths[1] ? '20' : '40'} fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                    </span>
                                                </div>

                                            </div>

                                            :
                                            <div id={movie.id}   className="nextShowBtn" onClick={() => firstWeek(differ)}>
                                             <p>NÆSTE VISNING</p>
                                                <span style={{ marginLeft: window.innerWidth < widths[1] ? '0px' : '7px', marginTop: window.innerWidth < widths[1] ? '10px' : '5px' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                            width={window.innerWidth < widths[1] ? '20' : '40'} height={window.innerWidth < widths[1] ? '20' : '40'} fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                                      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                </span>
                                         </div>

                                    : ''}
                                </div>
                            </div>
                           
                           
                            <section className="moviesArrows">
                                <div className={dimBackArrow === false ? 'week-nav ' : 'week-nav disabled'} id='leftarrow' onClick={previousWeek}>
                                    <svg className="moviesLeftArr"
                                       style={{ position: window.innerWidth < widths[1] ? 'absolute' : '', top: window.innerWidth < widths[1] ? '10px' : '', right: '43%' }}
                                        opacity={moment(currentStartDate).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY') && window.innerWidth < widths[1] || (dimBackArrow === true && window.innerWidth < widths[1]) ? '0.3' : ''}
                                        width={window.innerWidth < widths[1] ? '11' : '16'} marginbottom={window.innerWidth < widths[1] ? '5px' : '0'}
                                        viewBox="0 0 15 29" fill="#161717" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M1.70707 14.5L14.8535 1.35359L14.1464 0.646484L0.292855 14.5L14.1464 28.3536L14.8535 27.6465L1.70707 14.5Z" fill="#161717" />
                                         </svg>
                                </div>
                                <div className={dimNextArrow === false ? 'week-nav ' : 'week-nav disabled'} onClick={nextWeek} >
                                    <svg className="moviesRightArr"
                                        style={{ position: window.innerWidth < widths[1] ? 'absolute' : '', top: window.innerWidth < widths[1] ? '10px' : '', right: '40%' }}
                                        opacity={dimNextArrow === true  && window.innerWidth < widths[1] ? '0.3' : ''} width={window.innerWidth < widths[1] ? '11' : '16'}
                                        marginbottom={window.innerWidth < widths[1] ? '5px' : '0'} viewBox="0 0 16 29" fill="#161717"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.7929 14.5L0.646484 1.35359L1.35359 0.646484L15.2071 14.5L1.35359 28.3536L0.646484 27.6465L13.7929 14.5Z" fill="#161717" />
                                    </svg>

                                </div>
                              
                           </section>
                          
                        </div>

                        <section className="showtimeTypeContainer" style={{ marginTop: window.innerWidth < widths[1]  ? '-6px' : '' }}>

                            {
                                window.location.href.includes('film-info') ?
                                    ((!filterIds || filterIds.length > 1) /*&& showtimeTypes.length > 0*/ && (movie.versions && movie.versions.length <= 0 || movie.versions.length === 1) ? <ShowtimeTypeList types={showtimeTypes} /> : null)
                                    :
                                    (!filterIds || filterIds.length > 1) /*&& showtimeTypes.length > 0 */? <ShowtimeTypeList types={showtimeTypes} /> : null
                            }

                           

                          
                        </section>

                        <input type="button" className="movieinfo-btn" data-movie-id={movie.id} onClick={onMovieClick} value='Filminfo' style={{ fontSize: '15px', fontFamily: 'oswald', fontWeight:'normal' }} />

                        

                    </div>

               
            </div>

        </div>
           
           

            </>
    )
}