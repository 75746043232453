import _ from 'lodash'
import moment from 'moment'

export const TRACK_CHECKOUT_STEP = 'TRACK_CHECKOUT_STEP';
export const TRACK_PURCHASE = 'TRACK_PURCHASE';
export const TRACK_RESERVATION = 'TRACK_RESERVATION';
export const TRACK_DELETE_RESERVATION = 'TRACK_DELETE_RESERVATION';

export const gaTrackCheckoutStep = (step) => {
    return (dispatch, getState) => {
        if (getState().organizer.configuration.googleAnalytics.gtmId) {
            const movie = getState().movielist.selectedMovie;
            const showtime = getState().movielist.selectedShowtime;
            const selected = getState().order.selected;
            const datetimeNow = moment();
            const showtimeDay = moment(showtime.dateTime);
            
            const products = [];
            const averageTicketPrice = (selected.totalTicketPrice + selected.totalFees) / selected.totalTicketQuantity;
            _.each(selected.tickets, (ticket) => products.push(ticketsAsProduct(movie, averageTicketPrice, ticket, showtime)));
            
            const event = 'checkout';
            const dataLayer = {
                'transaction_step': step,
                'event': event,
                'ecommerce': {
                    'checkout': {
                        'actionField': { 'step': step, 'option': '' },
                        'products': products,
                        'sameday_buy': showtimeDay.isSame(datetimeNow, 'day'),
                        'day_of_the_week': datetimeNow.format('ddd')
                    },
                }                
            };

            window.dataLayer.push(dataLayer);

            return dispatch({
                type: TRACK_CHECKOUT_STEP,
                event,
                step,
                dataLayer
            });
        }
    }
}

export const gaTrackPurchase = () => {
    return (dispatch, getState) => {
        if (getState().organizer.configuration.googleAnalytics.gtmId) {
            const movie = getState().movielist.selectedMovie;
            const showtime = getState().movielist.selectedShowtime;
            const selected = getState().order.selected;
            const availableExtras = getState().order.availableExtraPurchases;
            const receipt = getState().receipt;
            const datetimeNow = moment();
            const showtimeDay = moment(showtime.dateTime);

            const products = [];
            const averageTicketPrice = (selected.totalTicketPrice + selected.totalFees) / selected.totalTicketQuantity;
            _.each(selected.tickets, (ticket) => products.push(ticketsAsProduct(movie, averageTicketPrice, ticket, showtime)));

            if (selected.extraPurchases.length > 0) {
                _.each(selected.extraPurchases, (extra) => {
                    const available = _.find(availableExtras, (item) => item.id == extra.id);
                    const price = available ? available.price : '';
                    products.push({
                        'name': movie.name,
                        'id': movie.id,
                        'price': price,
                        'brand': '',
                        'category': extra.name,
                        'variant': showtime.dateTime,
                        'quantity': extra.quantity
                    });
                })
            }

            const event = 'transactionComplete';
            const dataLayer = {
                'ecommerce': {
                    'purchase': {
                        'actionField': {
                            'id': receipt.transactionId,
                            'affiliation': 'Ebillet Orderflow',
                            'revenue': receipt.prices.total,
                            'tax': receipt.prices.total * 0.25,
                            'shipping': 0,
                            'coupon': ''
                        },
                        'products': products,
                        'sameday_buy': showtimeDay.isSame(datetimeNow, 'day'),
                        'day_of_the_week': datetimeNow.format('ddd')
                    }
                },
                'event': event,
                'JS - Null referrer on thank you page': null
            };

            window.dataLayer.push(dataLayer);

            return dispatch({
                type: TRACK_PURCHASE,
                event,
                dataLayer
            });
        }
    }
}

export const gaTrackReservation = () => {
    return (dispatch, getState) => {
        if (getState().organizer.configuration.googleAnalytics.gtmId) {
            const movie = getState().movielist.selectedMovie;
            const selected = getState().order.selected;
            const datetimeNow = moment();
            const showtimeDay = moment(getState().movielist.selectedShowtime.dateTime);

            const event = 'ticket_reservation';
            const dataLayer = eventAsDataLayer(movie, selected, event, showtimeDay, datetimeNow);
            window.dataLayer.push(dataLayer);

            return dispatch({
                type: TRACK_RESERVATION,
                event,
                dataLayer
            });
        }
    }
}

export const gaDeleteReservation = () => {
    return (dispatch, getState) => {
        if (getState().organizer.configuration.googleAnalytics.gtmId) {
            const movie = getState().movielist.selectedMovie;
            const selected = getState().order.selected;
            const datetimeNow = moment();
            const showtimeDay = moment(getState().movielist.selectedShowtime.dateTime);

            const event = 'delete_reservation';
            const dataLayer = eventAsDataLayer(movie, selected, event, showtimeDay, datetimeNow);
            window.dataLayer.push(dataLayer);

            return dispatch({
                type: TRACK_DELETE_RESERVATION,
                event,
                dataLayer
            });
        }
    }
}

function eventAsDataLayer(movie, selected, event, showtimeDay, datetimeNow) {
    return {
        'movie_name': movie.name,
        'expected_revenue': selected.totalTicketPrice + selected.totalFees,
        'quantity': selected.totalTicketQuantity,
        'event': event,
        'sameday_buy': showtimeDay.isSame(datetimeNow, 'day'),
        'day_of_the_week': datetimeNow.format('ddd')
    };
}

function ticketsAsProduct(movie, averageTicketPrice, ticket, showtime) {
    return {
        'name': movie.name,
        'id': movie.id,
        'price': averageTicketPrice,
        'brand': '',
        'category': ticket.name,
        'variant': showtime.dateTime,
        'quantity': ticket.quantity,
    };
}
