export const ROUTES = {
    TICKETMASTER_ALL_EVENTS: '/ticketmaster-alle-events',
    TICKETMASTER_EVENT: '/ticketmaster-event',

    BILLETTEN_ALL_EVENTS: '/billetten-alle-events',
    BILLETTEN_EVENT: '/billetten-event',

    MULTI_ALL_MOVIES: '/multibio-alle-film',
    MULTI_FUTURE_MOVIES: '/multibio-kommende-film',
    MULTI_PROGRAM: '/multibio-program',
    MULTI_MOVIE: '/multibio-film-info',

    HOME: '/',
    COMMERCIALS: '/reklamer',
    PAGES: '/pages',
    MOVIE: '/film-info',

    PROGRAM_AND_ALL_FUTURE_MOVIES: '/program-og-fremtidige-film',

    ALL_MOVIES: '/alle-film',
    ALL_FUTURE_MOVIES: '/alle-fremtidige-film',
    PROGRAM: '/program',

    // WEEK PROGRAM ROUTES
    WEEK_PROGRAM: '/uge-program',
    WEEK_PROGRAM_2: '/uge-program2',
   

    FUTURE_MOVIES: '/kommende-film',
    SPECIAL_EVENTS: '/arrangementer',
    CIRCUS: '/cirkus',


    // EBILLETS NEW WEBSITE ROUTES
    EBILLET_PROGRAM: '/ebillet-program',
    ALL_EBILLET_MOVIES: '/ebillet-alle-film',
    EBILLET_MOVIE_DETAILS:'/film-details',

    // new routes which allows klub tickets 
    FILMCLUB: '/filmklub',
    MULTI_TICKETS: '/multi-billetter',
    FILMCLUB_CUSTOMER: '/filmklub-Kunde',
    FILMCLUB_REEPAY: '/reepayMulti',
    FILMCLUB_RECEIPT:'/filmklub-kvittering',
    FILMCLUB_REEPAY_LANDING: '/reepay-multi-Landing',
     //new route for alta belating for multi pay
    ALTA_PAY_PAYMENT_MULTI: '/altapay-betaling-multi',

    USER_TRANSACTIONS: '/mine-billetter',
    NEWSLETTERS: '/nyhedsbreve',
   
    BOOK_KVITTERING: '/book-kvittering',
    REEPAY_LANDING: '/reepay-book-landing',

    

    ORDERFLOW: {

        TICKETS: '/billetter',     
        EXTRA_PURCHASES: '/tilkøb',
        CUSTOMER: '/kunde-oplysninger',
        BIOKLUB_DK: '/biografklub-danamark',
        PAYMENT: '/betaling',
        GIFTCARDS: '/gavekort',
        DIBS: '/dibs',
        REEPAY: '/reepay',
        RECEIPT: '/kvittering',
        RESERVATION: '/reservation',

        RECEIPTQ: '/kvit',
        CANCELORBUY: '/resq',

        //new route for alta belating
       ALTA_PAY_PAYMENT:'/altapay-betaling'

    },

    NOT_FOUND: '/findes-ikke'
}