export default {
    da: {
        price: 'Pris: ',
        including: 'inkl. gebyr',
        back: '❮  Tilbage',
        buyTickets: 'Billetter',
        soldOut: 'Udsolgt',
        cancelled: 'Aflyst',
    },

    en: {
        price: 'Price: ',
        including: 'incl. fees',
        back: '❮  Back',
        buyTickets: 'Tickets',
        soldOut: 'Sold out',
        cancelled: 'Cancelled',
    },

    fo: {
        price: 'Prísur: ',
        including: 'írokna avgjøld',
        back: '❮  Aftur',
        buyTickets: 'Atgongumerki',
        soldOut: 'Útselt',
        cancelled: 'Avlýst',
    }

    //new: {
        //price: ': ',
        //including: '',
        //back: '❮  ',
        //buyTickets: '',
        //soldOut: '',
        //cancelled: '',
    //}
}