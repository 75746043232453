import OrderFlowLocale from '../OrderFlowLocale'

export default {
    da: {
        ...OrderFlowLocale.da,
        yourOrder: 'Din Bestilling',
        extraPurchase: 'tilkøb',
        totalPrice: 'Pris i alt',
        tickets: 'Billetter',
        fees: 'Gebyr',
        giftCard: 'Gavekort',
        clubCard: 'Klubkort'
    },

    en: {
        ...OrderFlowLocale.en,
        yourOrder: 'Your Order',
        extraPurchase: 'extras',
        totalPrice: 'Total price',
        tickets: 'Tickets',
        fees: 'Fees',
        giftCard: 'Gift card',
        clubCard: 'Club card'
    },

    fo: {
        ...OrderFlowLocale.fo,
        yourOrder: 'Tín Bílegging',
        extraPurchase: 'tilkeyp',
        totalPrice: 'Samla prísur',
        tickets: 'Atgongumerki',
        fees: 'Ómaksgjald',
        giftCard: 'Gávukort',
        clubCard: 'Limakort'
    }

    //new: {
    //    ...OrderFlowLocale.new,
    //    yourOrder: '',
    //    extraPurchase: '',
    //    totalPrice: '',
    //    tickets: '',
    //    fees: '',
    //    giftCard: '',
    //    clubCard: ''
    //}
}