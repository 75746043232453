import '../styles/general.scss'
import '../styles/icomoon.scss'
import '../styles/print.scss'

import './showtime/showtime.scss'

import React from 'react';
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Switch, Route, withRouter } from 'react-router-dom'
import { getParams } from '../utilities/location-utilities'
import { ROUTES } from './navigation/route-constants'
import { APP_MODES } from '../api/api-constants'
import { appHeightChanged, initFromParentSite } from '../actions/app-actions'

import Program_AllFutureMovies from './movielists/combinations/Program_AllFutureMovies'

import Multibio_ProgramContainer from './movielists/multibio/Multibio_ProgramContainer'
import Multibio_AllContainer from './movielists/multibio/Multibio_AllContainer'
import Multibio_FutureMoviesContainer from './movielists/multibio/futuremovies/Multibio_FutureMoviesContainer'

import ProgramListContainer from './movielists/program/ProgramListContainer'
import AllMoviesListContainer from './movielists/allmovies/AllMoviesListContainer'
import FutureMoviesListContainer from './movielists/futuremovies/FutureMoviesListContainer'
import SpecialEventsContainer from './movielists/specialevents/SpecialEventsContainer'

import TicketMasterListContainer from './external/ticketmaster/TicketMasterListContainer'
import TicketMasterDetailsContainer from './external/ticketmaster/TicketMasterDetailsContainer'
import BillettenListContainer from './external/billetten/BillettenListContainer'
import BillettenDetailsContainer from './external/billetten/BillettenDetailsContainer'

// NEW WEEK PROGRAM ROUTE
import WeekProgramListContainer from './movielists/weekprogram/WeekProgramListContainer'
import WeekProgramListContainer2 from './movielists/weekprogram2/WeekProgramListContainer2'

import CircusListContainer from './circus/CircusListContainer'
import MovieContainer from './movie/MovieContainer'
import OrderFlowContainer from './orderflow/OrderFlowContainer'
import Spinner from './overlays/Spinner'
import Error from './overlays/Error'
import NotFound from './overlays/NotFound'
import CommercialsContainer from './commercials/CommercialsContainer'
import TransactionsContainer from './user/transactions/TransactionsContainer'
import NewslettersContainer from './user/newsletters/NewslettersContainer'

import FilmClubContainer from './movielists/filmclub/FilmClubContainer'
import FilmClubOrderContainer from './orderflow/order/FilmClubOrderContainer'
import FilmClubCustomerContainer from './orderflow/customer/FilmClubCustomerContainer';
import ReepayContainerMulti from './orderflow/payment/ReepayContainerMulti'
import ReceiptContainerMulti from './orderflow/receipt/ReceiptContainerMulti'
import ReepayMultiLandingPage from './orderflow/payment/ReepayMultiLandingPage'
import AltaPaymentMulti from './orderflow/payment/AltaPaymentContainerMulti'


// new book routes
import ReepayLandingPage from './ReepayLandingPage'
import BookReciept from './orderflow/receipt/BookReciept'

// EBILLET WEBSITE ROUTES
//import  DataContextProvider  from './ebillet/DataContext'
import EbilletProgram from './ebillet/EbilletProgram'
import AllEbilletMovies from './ebillet/AllMovies'
//import EbilletMovieContainer from './ebillet/EbilletMovieContainer'


class App extends React.Component {
   

    constructor(props) {
        super(props);
        const queryParameters = getParams();
        this.state = {
            app: this.initializeApp(props.mode),
            iframeId: queryParameters.iframeid,
          //  ebilletPath: window.location.pathname === 'ebillet-program' ? true : false
          
        }
        this.checkIfAppHeightChanged = this.checkIfAppHeightChanged.bind(this);
    }

    componentDidMount() {
        // Sometimes the onLoad isn't triggered on <App (fx in TransactionContainer) so we put an extra height check after mount.
        if (window.parent != window) {
            const { iframeId } = this.state;
            const { actions } = this.props;
            const queryParameters = getParams();
            actions.appHeightChanged(iframeId);
            actions.initFromParentSite(queryParameters.origin); //TODO put iframeId here and maybe also first app height change instead of using onLoad?
        }
        else window.scrollTo(0, 0);
    }

    //This is for handling dynamic height when inside an iframe
    checkIfAppHeightChanged(e) {
        if (window.parent != window) {
            const { iframeId } = this.state;
            const { actions } = this.props;
            actions.appHeightChanged(iframeId);
        }
    }

    ticketMasterAllEventsRoute = <Route path={ROUTES.TICKETMASTER_ALL_EVENTS} component={TicketMasterListContainer} />;
    ticketMasterEventRoute = <Route path={ROUTES.TICKETMASTER_EVENT + '/:eventId'} component={TicketMasterDetailsContainer} />;
    billettenAllEventsRoute = <Route path={ROUTES.BILLETTEN_ALL_EVENTS} component={BillettenListContainer} />;
    billettenEventRoute = <Route path={ROUTES.BILLETTEN_EVENT + '/:eventId'} component={BillettenDetailsContainer} />;

    multiAllMoviesRoute = <Route exact path={ROUTES.MULTI_ALL_MOVIES} component={Multibio_AllContainer} />;
    multiProgramRoute = <Route exact path={ROUTES.MULTI_PROGRAM + '/:date?'} component={Multibio_ProgramContainer} />;
    multiFutureMoviesRoute = <Route exact path={ROUTES.MULTI_FUTURE_MOVIES} component={Multibio_FutureMoviesContainer} />;

    program_AllFutureMoviesRoute = <Route path={ROUTES.PROGRAM_AND_ALL_FUTURE_MOVIES} component={Program_AllFutureMovies} />;

    allMoviesRoute = <Route path={ROUTES.ALL_MOVIES} component={AllMoviesListContainer} />;
    programRoute = <Route path={ROUTES.PROGRAM + '/:date?'} component={ProgramListContainer} />;

    weekProgramRoute = <Route path={ROUTES.WEEK_PROGRAM} component={WeekProgramListContainer} />;

    // new week program route
    weekProgramRoute2 = <Route path={ROUTES.WEEK_PROGRAM_2} component={WeekProgramListContainer2} />;
   


    filmClubRoute = <Route path={ROUTES.FILMCLUB} component={FilmClubContainer} />
   // filmClubOrderFlowRoute = <Route path={'/:page/'} component={FilmClubOrderFlowContainer} />
    filmClubOrderContainer = <Route path={ROUTES.MULTI_TICKETS + '/:eventId'} component={FilmClubOrderContainer} />
    altaPayMulti = <Route path={ROUTES.ALTA_PAY_PAYMENT_MULTI + '/:eventId'} component={AltaPaymentMulti}/>
    FilmClubCustomerContainerRoute = <Route path={ROUTES.FILMCLUB_CUSTOMER + '/:eventId'} component={FilmClubCustomerContainer} />
    FilmClubReepayContainerRoute = <Route path={ROUTES.FILMCLUB_REEPAY + '/:eventId'} component={ReepayContainerMulti} />
    FilmClubReceiptContainerRoute = <Route path={ROUTES.FILMCLUB_RECEIPT } component={ReceiptContainerMulti} />
    FilmClubReepayLandingPage = <Route path={ROUTES.FILMCLUB_REEPAY_LANDING} component={ReepayMultiLandingPage} />


    //EBILLET WEBSITE ROUTES
   
    ebilletProgramRoute =  <Route path={ROUTES.EBILLET_PROGRAM} component={EbilletProgram} />        
   // ebilletAllMoviesRoute=   <Route path={ROUTES.ALL_EBILLET_MOVIES} component={AllEbilletMovies} />   
  //  ebilletMovieContainer= <DataContextProvider>  <Route path={ROUTES.EBILLET_MOVIE_DETAILS} component={EbilletMovieContainer} />  </DataContextProvider>
       
       
    //EBILLET WEBSITE ROUTES ABOVE


    speicalEventsRoute = <Route path={ROUTES.SPECIAL_EVENTS + '/:currentMonth?'} component={SpecialEventsContainer} />;
    futureMoviesRoute = <Route path={ROUTES.FUTURE_MOVIES} component={FutureMoviesListContainer} />;
    circusRoute = <Route exact path={ROUTES.CIRCUS} component={CircusListContainer} />;

    movieRoute = <Route path={ROUTES.MOVIE + '/:movieId'} component={MovieContainer} />;

    reepayLandingRoute = <Route path={ROUTES.REEPAY_LANDING} component={ReepayLandingPage} />;
    bookReceiptRoute = <Route path={ROUTES.BOOK_KVITTERING}  component={BookReciept} />;

    orderFlowRoute = <Route path={'/:page/:movieId/:showtimeId'} component={OrderFlowContainer} />;
    reservationRoute = <Route path={'/:page/:reservationId'} component={OrderFlowContainer} />;
    qRoute = <Route path={'/:page/:reservationId'} component={OrderFlowContainer} />

     
    commercialsRoute = <Route exact path={ROUTES.COMMERCIALS} component={CommercialsContainer} />;

    userTransactionsRoute = <Route path={ROUTES.USER_TRANSACTIONS} component={TransactionsContainer} />;
    userNewslettersRoute = <Route path={ROUTES.NEWSLETTERS} component={NewslettersContainer} />;

    notFoundRoute = window.location.pathname === '/ebillet-film' || window.location.pathname === '/ebillet-program' || window.location.pathname === '/reepay-book-landing'
        || window.location.pathname === '/book-kvittering' || window.location.pathname === '/film-details'  ? '' : < Route render={() => !this.props.isFecthing ? <NotFound /> : null} />;
   

    initializeApp(mode) {
        switch (mode) {
            case APP_MODES.PROGRAM:
                return <Switch>
                    {this.ticketMasterAllEventsRoute}
                    {this.ticketMasterEventRoute}
                    {this.billettenAllEventsRoute}
                    {this.billettenEventRoute}

                    {this.multiAllMoviesRoute}
                    {this.multiProgramRoute}
                    {this.multiFutureMoviesRoute}
                    {this.multiMovieRoute}

                    {this.filmClubRoute}
                    {this.filmClubOrderFlowRoute}
                    {this.FilmClubCustomerContainerRoute}
                    {this.FilmClubReepayContainerRoute}
                    {this.FilmClubReceiptContainerRoute}
                    {this.FilmClubReepayLandingPage}
                    {this.filmClubOrderContainer}
                    {this.altaPayMulti}

                     {/*new book route*/}
                    {this.bookReceiptRoute}
                    {this.reepayLandingRoute}



                    {this.commercialsRoute}

                    {this.program_AllFutureMoviesRoute}

                    {this.programRoute}

                    {/* week program routes */}
                     
                    {this.weekProgramRoute}
                    {this.weekProgramRoute2}


                    {this.speicalEventsRoute}
                    {this.allMoviesRoute}
                    {this.futureMoviesRoute}
                    {this.circusRoute}

                    {this.movieRoute}
                    {this.orderFlowRoute}
                    {this.reservationRoute}
                    {this.qRoute}
                   
                    {this.userTransactionsRoute}
                    {this.userNewslettersRoute}
                    {this.notFoundRoute}

                    {/* EBILLET WEBSITE ROUTES */}
                    {this.ebilletProgramRoute}
                    {this.ebilletAllMoviesRoute}
                    {this.ebilletMovieContainer}

                </Switch>
            case APP_MODES.MOVIE:
                return <Switch>
                    {this.movieRoute}
                    {this.orderFlowRoute}
                    {this.reservationRoute}
                    {this.qRoute}
                    {this.notFoundRoute}
                    {this.ebilletProgramRoute}
                    {this.ebilletMovieContainer}

                    { /* {this.filmClubOrderFlowRoute}
                    {this.FilmClubCustomerContainerRoute} */}

                 
                </Switch>
            case APP_MODES.ORDERFLOW:
                return <Switch>
                    {this.orderFlowRoute}
                    {this.reservationRoute}
                    {this.qRoute}

                    {this.notFoundRoute}

                </Switch>
            default:
                {
                    const error = { message: 'Siden kunne ikke initialiseres', debug: 'DEBUG: App.js could not determine app mode' };
                    return /*<Error error={error} />*/
                }
        }
    }

    render() {
        const { mode } = this.props;
        const { app }  = this.state;
       
        return (
            <div className="App" onLoad={this.checkIfAppHeightChanged}>
                <Spinner />
                <div className={'content-wrapper ' + mode}>
                    {app}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isFecthing: state.organizer.isFetching || state.movielist.isFetching || state.order.isFetching || state.customer.isFetching || state.receipt.isFetching || false,
        mode: state.organizer.configuration.appMode,
        pageHeight: state.app.height
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { appHeightChanged, initFromParentSite }), dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))