import { combineReducers } from 'redux'
import organizer from './organizer-reducer'
import order from './order-reducer'
import customer from './customer-reducer'
import receipt from './receipt-reducer'
import user from './user-reducer'
import cms from './cms-reducer'
import googleAnalytics from './ga-reducer'
import app from './app-reducer'
import movielist from './movielist-reducer'
import ticketmaster from './ticketmaster-reducer'
import external from './external-reducer'
import error from './error-reducer'

export default combineReducers({
    organizer,
    order,
    customer,
    receipt,
    user,
    cms,
    googleAnalytics,
    app,
    movielist,
    ticketmaster,
    external,
    error
})