import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import LOCALE from '../movielists/MovieListLocale'
import CircusListItem from './CircusListItem'

export default class CircusList extends React.Component {

    generateListItems(events) {
        const { organizer, posterBanners, appLocale, showtimeClicked, columns, appHeightChanged } = this.props;
        let sortedEvents = this.sortEvents(events);
        return _.map(sortedEvents, (event) => {
            return <CircusListItem key={event.id}
                appLocale={appLocale}
                columns={columns}
                organizer={organizer}
                event={event}
                showtimes={event.validShowtimes}
                posterBanners={posterBanners}
                showtimeClicked={showtimeClicked}
                appHeightChanged={appHeightChanged}
            />;
        });
    }

    sortEvents(events) {
        const { showtimes } = this.props;

        let sortedEvents = [];
        _.forEach(events, (event) => {
            event.validShowtimes = _.filter(showtimes, (showtime) => {
                if (showtime.movieId == event.id && showtime.showInfo.enabled === true) {
                    return moment(showtime.dateTime) > moment(); //isInFuture;
                }
            });

            if (event.validShowtimes.length > 0) {
                event.validShowtimes = _.sortBy(event.validShowtimes, ['dateTime']);
                sortedEvents.push(event);
            }
        });

        return sortedEvents;
    }

    render() {
        const { events, appLocale } = this.props;
        const listItems = this.generateListItems(events);

        return (
            <div className="CircusList MovieList">
                {listItems.length > 0 ? listItems : <h3 className="no-events">{LOCALE[appLocale].noMoviesText}</h3>}
            </div>)
    }
}