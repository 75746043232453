import React from 'react'
import _ from 'lodash'
import LOCALE from '../MovieListLocale'
import { setItemClasses } from '../shared'
import FutureMoviesListItem from './FutureMovieListItem'

export default class FutureMoviesList extends React.Component {
    generateMovieListItems(movies) {
        const { posterBanners, appLocale, movieClicked, columns, organizerIds, showtimes } = this.props;
        let movieItems = [];
        let counter = 0;

        _.forEach(movies, (movie, i) => {
            let rowClasses = setItemClasses(counter, columns);
            const hasShowtimes = _.some(showtimes, (showtime) => {
                return showtime.movieId == movie.id
            });

            movieItems.push(<FutureMoviesListItem key={movie.id.toString() + i}
                appLocale={appLocale}
                rowClasses={rowClasses}
                columns={columns}
                organizerIds={organizerIds}
                movie={movie}
                posterBanners={posterBanners}
                movieClicked={movieClicked}
                hasShowtimes={hasShowtimes}
            />);

            counter++;
        });

        return movieItems;
    }

    render() {
        const { movies, appLocale } = this.props;
        const movieListItems = this.generateMovieListItems(movies);

        return (
            <div className="MovieList">
                {movieListItems.length > 0 ? movieListItems : <h3 className="no-events">{LOCALE[appLocale].noMoviesText}</h3>}
            </div>)
    }
}