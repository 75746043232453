import _ from 'lodash'
import api from '../api/ticketmaster-api'

export function getEventList(promoterCodes) {
    return (dispatch, getState) => {
        dispatch(eventListRequest());
        const config = getState().organizer.configuration.ticketmaster;
        return api.fetchEvents(promoterCodes)
            .then(result => {
                const events = _.map(result.events, (event) => extractEvent(event, config));
                return dispatch(eventListSuccess(events))
            }, error => dispatch(eventListFailed(error)));
    }
}

export const EVENTLIST_REQUEST = 'EVENTLIST_REQUEST'
const eventListRequest = () => {
    return { type: EVENTLIST_REQUEST }
}

export const EVENTLIST_SUCCESS = 'EVENTLIST_SUCCESS'
const eventListSuccess = (events) => {
    return {
        type: EVENTLIST_SUCCESS,
        events: _.sortBy(events, 'dateTime')
    }
}

export const EVENTLIST_FAILED = 'EVENTLIST_FAILED'
const eventListFailed = (error) => {
    return {
        type: EVENTLIST_FAILED,
        error: {
            ...error,
            message: 'Der var ingen data at hente. Prøv igen senere.'
        }
    }
}

export function getEvent(id) {
    return (dispatch, getState) => {
        let selectedEvent = _.find(getState().ticketmaster.events, (event) => { return id == event.id });
        if (selectedEvent && selectedEvent.description) {
            return new Promise((resolve) => {
                return resolve(dispatch(selectedEventChanged(selectedEvent)));
            });
        } else return api.fetchEvent(id)
            .then(event => {
                if (event) {
                    const config = getState().organizer.configuration.ticketmaster;
                    selectedEvent = extractEvent(event, config);

                    return api.fetchEventImg(id)
                        .then(result => {
                            let image = _.find(result.images, (image) => { return image.ratio == '16_9' && image.width == 2048 });
                            if (!image) image = _.find(result.images, (image) => { return image.ratio == '16_9' && image.width == 1136 });
                            if (image.url) selectedEvent.posters.large = image.url;

                            return dispatch(selectedEventChanged(selectedEvent));
                        });
                } else return dispatch(eventListFailed());
            }, error => dispatch(eventListFailed(error)));
    }
}

export function changeSelectedEvent(id) {
    return (dispatch, getState) => {
        const event = _.find(getState().ticketmaster.events, (event) => { return event.id == id });
        return dispatch(selectedEventChanged(event));
    }
}

export const SELECTED_EVENT_CHANGED = 'SELECTED_EVENT_CHANGED'
export const selectedEventChanged = (selectedEvent) => {
    return {
        type: SELECTED_EVENT_CHANGED,
        selectedEvent: selectedEvent
    }
}

function extractEvent(event, config) {
    return {
        id: event.id,
        name: event.name,
        description: event.description,
        posters: event.images ? {
            standard: event.images.standard ? event.images.standard.url : null,
            large: event.images.large ? event.images.large.url : null
        } : {},
        dateTime: event.event_date ? event.event_date.value : null,
        ticketsUrl: replaceInUrl(event.url, config),
        price: event.price_ranges ? extractPrice(event.price_ranges) + ' ' + event.currency : null,
        venue: event.venue ? {
            name: event.venue.name,
            address: `${event.venue.location.address.address}, ${event.venue.location.address.postal_code} ${event.venue.location.address.city}`
        } : null,
        isSoldOut: event.properties ? event.properties.sold_out : null,
        isCancelled: event.properties ? event.properties.cancelled : null
    }
}

function replaceInUrl(url, config) {
    url = url.replace(/camefrom=(.*)&/, `camefrom=${config.cameFrom}&`);
    url += `&brand=${config.brand}`;
    return url;
}

function extractPrice(price) {
    const from = price.including_ticket_fees.min;
    const to = price.including_ticket_fees.max;

    if (from === to) return from;
    else if (from && to) return from + ' - ' + to;
    else if (from && !to) return from;
    else if (!from && to) return to;
    else return null;
}