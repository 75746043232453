import '../css/movielists.scss'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { getParams } from '../../../utilities/location-utilities'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../movielist-constants'
import { appHeightChanged } from '../../../actions/app-actions'
import { getMovieList, changeDate, selectedMovieChanged, changeSelectedShowtime } from '../../../actions/movielist-actions'
import DatePickerContainer from '../../datepicker/DatePickerContainer'
import ProgramList from './ProgramList'
import SpecialEventsContainer from '../specialevents/SpecialEventsContainer'
import { manipulateTitles, setContainerClasses } from '../shared'

class ProgramContainer extends React.Component {
    constructor() {
        super();
        this.changeDate = this.changeDate.bind(this);
        this.movieClicked = this.movieClicked.bind(this);
        this.showtimeClicked = this.showtimeClicked.bind(this);
    }

    componentDidMount() {
        const { actions, movies, organizerIds, iframeId } = this.props;
        if (movies.length == 0) actions.getMovieList(organizerIds);

        if (window.parent != window) actions.appHeightChanged(iframeId, false, false, true);
        else window.scrollTo(0, sessionStorage.getItem("scroll-position"));

        this.manipulateTitles()
    }

    componentWillUnmount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, false, true, false);
        else sessionStorage.setItem("scroll-position", window.scrollY);
    }

    componentDidUpdate(prevProps) {
        this.manipulateTitles()
    }

    manipulateTitles() {
        const { columns, listType } = this.props
        if (columns != CONSTANTS.columns.one) {
            const movielist = document.querySelector(`.MovieListContainer.${listType}`)
            manipulateTitles(movielist, columns);
        }
    }

    changeDate(date) {
        const { history, organizerIds, actions } = this.props;
        actions.changeDate(date);
        history.push(`${window.location.pathname}?org=${organizerIds}&date=${date}`);
    }

    movieClicked(id) {
        const { organizerIds, actions, history, movies } = this.props;
        const movie = _.find(movies, (movie) => { return movie.id == id });
        actions.selectedMovieChanged(movie.id, history.location.pathname).then(() => history.push(`${ROUTES.MOVIE}/${id}?org=${organizerIds}`));
    }

    showtimeClicked(organizerId, showtimeId) {
        const { openOrderInNewWindow, actions, history, showtimes } = this.props;
        const showtime = _.find(showtimes, (showtime) => { return showtime.id == showtimeId && showtime.organizerId == organizerId });
        if (showtime) {
            actions.changeSelectedShowtime(showtime, history.location.pathname).then(() => {
                const orderUrl = `${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${showtime.organizerId}`;
                if (openOrderInNewWindow) window.open(orderUrl, '_blank', 'width=1170,height=800,resizable=yes,scrollbars=yes');
                else history.push(orderUrl);
            })
        }
    }

    render() {
        const { appLocale, pageHeader, movies, showAsBase, showtimes, posterBanners, selectedDate, columns, showExpired,
            showLocationOnShowtime, specialMoviesEnabledOnProgram, showBackInTime, actions, organizerIds, sortBy, filterIds, eventGroups } = this.props;
     // console.log(showtimes, 'showtimes')
      //  showtimes.map(show => console.log(show.dateTime))

       // console.log(movies, 'grps')
        const header = pageHeader ? <div className="page-header" dangerouslySetInnerHTML={{ __html: pageHeader }}></div> : null;
        let classes = setContainerClasses(CONSTANTS.listTypes.date, columns);

        return (
            <div className={classes}>
                <div className="flex-container">
                    {header}
                    <DatePickerContainer hasListHeader={pageHeader != null}
                        isAllMovies={false}
                        changeDate={this.changeDate}
                        isOneColumn={columns == CONSTANTS.columns.one}
                    />
                </div>

                <ProgramList appLocale={appLocale}
                    showBackInTime={showBackInTime}
                    columns={columns}
                    movies={movies}
                    showAsBase={showAsBase}
                    showtimes={showtimes}
                    sortBy={sortBy}
                    movieClicked={this.movieClicked}
                    showtimeClicked={this.showtimeClicked}
                    posterBanners={posterBanners}
                    selectedDate={selectedDate}
                    showLocationOnShowtime={showLocationOnShowtime}
                    appHeightChanged={actions.appHeightChanged}
                    organizerIds={organizerIds}
                    showExpired={showExpired}
                    filterIds={filterIds}
                />

                {specialMoviesEnabledOnProgram ? <SpecialEventsContainer /> : null}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    //console.log(state, 'state')
   // console.log(state.movielist, 'state movielist')
    const config = state.organizer.configuration;
    const listConfig = config.movielists;
    const selectedDate = state.movielist.selectedDate;
    const movies = listConfig.date.base ? extractMoviesAsBases(state.movielist.movies) : state.movielist.movies;
   // const eventGroups = state.eventGroups

    let dateForHeader = extractHeaderDate(selectedDate);
    const pageHeader = _.replace(config.pageHeaders.date, 'SELECTED_DATE', _.capitalize(dateForHeader));

    function extractHeaderDate(selectedDate) {
        const date = moment(selectedDate);
        if (date.isSame(moment(), 'day')) return 'i dag ';
        else if (date.isSame(moment().add(1, 'days'), 'day')) return 'i morgen ';
        else return date.format('dddd DD/MM');

    }

    function extractMoviesAsBases(movies) {
        const moviesBases = state.movielist.movieBases;
        const moviesAsBase = [];
        _.each(movies, (movie) => {
            if (movie.baseId) {
                const baseMovie = _.find(moviesBases, (base) => { return base.id == movie.baseId; });
                const alreadyInList = _.some(moviesAsBase, ['baseId', movie.baseId]);
                if (baseMovie && !alreadyInList) moviesAsBase.push({ ...movie, name: baseMovie.name, posters: baseMovie.posters });
            } else moviesAsBase.push(movie);
        });
        return moviesAsBase;
    }

    return {
        organizerIds: state.movielist.organizerIds || getParams().org,
        pageHeader,
        columns: listConfig.date.columns,
        movies,
        sortBy: listConfig.date.sort,
        showAsBase: listConfig.date.base,
        showtimes: state.movielist.showtimes,
        posterBanners: config.posterBanners,
        appLocale: state.app.locale,
        openOrderInNewWindow: config.openOrderInNewWindow,
        selectedDate,
        initialDate: state.movielist.initialDate,
        showLocationOnShowtime: config.showLocationOnShowtime,
        specialMoviesEnabledOnProgram: config.movielists.special.enabledOnProgram,
        showBackInTime: config.daysBack > 0,
        iframeId: state.app.iframeId,
        showExpired: config.movielists.date.showExpired,
        filterIds: state.movielist.filterIds,
        listType: CONSTANTS.listTypes.date,
        eventGroups: state.eventGroups
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { getMovieList, selectedMovieChanged, changeDate, changeSelectedShowtime, appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgramContainer)