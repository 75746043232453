import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../movielist-constants'
import LOCALE from '../MovieListLocale'
import MovieInfo from '../../movie/MovieInfo'
import Showtime from '../../showtime/Showtime'
import Poster from '../../movie/Poster'
import { isInsideShowPeriod } from '../shared'
import ShowtimeTypeList from '../../showtime/ShowtimeTypeList'

export default class Multibio_MovieListItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maxDateCount: props.organizers.length > 1 ? 1 : 2,
            showMovieInfo: false
        }
        this.movieClicked = this.movieClicked.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showMovieInfo != this.state.showMovieInfo) {
            const { appHeightChanged } = this.props;
            setTimeout(() => { appHeightChanged() }, 500); //Workaround for the duration it takes the DOM to rerender with the expanded element so we get the right height...
        }
    }

    movieClicked(e) {
        const { showMovieInfo } = this.state;
        const { columns, movieClicked } = this.props;
        const dataset = e.target.dataset;
        if (e.target.tagName != 'A') e.preventDefault();

        if (columns == CONSTANTS.columns.one && dataset.toggleMovieInfo) {
            if (showMovieInfo == false) this.setState({ showMovieInfo: true });
            else this.setState({ showMovieInfo: false });
        } else {
            const movieId = e.currentTarget.id || dataset.movieId;
            if (e.target.tagName == 'IMG' || e.target.tagName == 'H1' || e.target.tagName == 'INPUT' || _.includes(e.target.classList, 'poster-banner'))
                movieClicked(movieId);
        }
    }

    generateShowtimesPerOrganizer() {
        const { maxDateCount } = this.state;
        const { organizers, movie, appLocale, columns } = this.props;
        const showtimesPerOrganizer = [];

        _.each(organizers, (organizer) => {
            const showtimesByDate = _.groupBy(movie.validShowtimes, (showtime) => {
                return showtime.organizerId == organizer.id ? moment(showtime.dateTime).format('ddd DD/MM') : -1;
            });
            const showtimesPerDate = this.generateShowtimesPerDate(showtimesByDate);
            const moreButton = columns !== CONSTANTS.columns.one && organizers.length == 1 && _.keys(showtimesByDate).length > maxDateCount ?
                <div className="read-more"><input type="button" className="more-showtimes" data-movie-id={movie.id} value={LOCALE[appLocale].moreShowtimes} /></div> : null;

            showtimesPerOrganizer.push(<div key={organizer.id} className="organizer">
                {organizers.length > 1 ? <h3>{organizer.name}</h3> : null}
                {showtimesPerDate}
                {moreButton}
            </div>);
        });

        return showtimesPerOrganizer;
    }

    generateShowtimesPerDate(showtimesByDate) {
        const { maxDateCount } = this.state;
        const { showLocationOnShowtime, showtimeClicked, listType, columns, showAsBase, showExpired } = this.props;
        const showtimesPerDate = [];
        let counter = 0;

        _.forOwn(showtimesByDate, (showtimes, dateKey) => {
            if (dateKey != -1) {
                if (counter < maxDateCount) {
                    showtimesPerDate.push(<div key={dateKey} className="showtimes">
                        {listType === CONSTANTS.listTypes.all ? <div className="date">{_.capitalize(dateKey)}</div> : null}

                        {_.map(showtimes, (showtime) => {
                            const dateTime = moment(showtime.dateTime);
                            return <div key={showtime.id} className="row">
                                <div className="showtime-row">
                                    {columns == CONSTANTS.columns.one || showLocationOnShowtime ? <div className="location">{showtime.locationName}</div> : ''}
                                    <Showtime key={showtime.id}
                                        showtime={showtime}
                                        text={columns != CONSTANTS.columns.one && showLocationOnShowtime ? `${dateTime.format('LT')} ${showtime.locationName}` : dateTime.format('LT')}
                                        showtimeClicked={showtimeClicked}
                                        showExpired={showExpired}
                                    />
                                    {isInsideShowPeriod && columns == CONSTANTS.columns.one ? <div className="type">
                                        <div>{showtime.type ? showtime.type.name : ''} {showAsBase ? showtime.movieBaseShowName : null}</div>
                                    </div> : null}
                                </div>
                                {columns == CONSTANTS.columns.one ? <div className="info">{showtime.info}</div> : null}
                            </div>
                        })}
                    </div>);
                    counter++;
                } else return false;
            }
        })
        return showtimesPerDate;
    }

    render() {
        const { showMovieInfo } = this.state;
        const { movie, rowClasses, appLocale, showAsBase, columns, organizerIds } = this.props;
        const showtimesPerOrganizer = this.generateShowtimesPerOrganizer();

        const poster = movie.posters ?
            <a className="poster-container" href={`${window.location.origin}${ROUTES.MOVIE}/${movie.id}?org=${organizerIds}`}>
                <Poster movie={movie} /></a> : null;

        const movieNameLocale = movie.names ? (movie.names[appLocale] ? movie.names[appLocale] : movie.names['da']) : movie.name;

        return (
            <div className={rowClasses} id={movie.id} onClick={this.movieClicked}>
                {poster}
                <div className="movie-info">
                    <a href={`${window.location.origin}${ROUTES.MOVIE}/${movie.id}?org=${organizerIds}`}><h1>{showAsBase ? movie.name : movieNameLocale}</h1></a>
                    <div className="flex-container">{showtimesPerOrganizer}</div>
                    {columns === CONSTANTS.columns.one ? <div className="read-more">
                        <input type="button" className="more-showtimes" data-toggle-movie-info={true} value={LOCALE[appLocale].movieInfo} />
                        <input type="button" className="more-showtimes" data-movie-id={movie.id} value={LOCALE[appLocale].allShowtimes} />
                        <MovieInfo addClass={showMovieInfo ? 'slide-down' : 'slide-up'} movie={movie} showTitle={false} appLocale={appLocale} />
                    </div> : null}
                </div>
            </div>
        )
    }
}