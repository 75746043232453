import './customer.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import * as orderActions from '../../../actions/order-actions'
import * as customerActions from '../../../actions/customer-actions'
import * as gaActions from '../../../actions/ga-actions'
import * as validators from '../../../utilities/validation-utilities'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../orderflow-constants'
import Customer from './Customer'
import LOCALE from './CustomerLocale'
import { ORGANIZER_TYPES } from '../../../api/api-constants';
import { appHeightChanged } from '../../../actions/app-actions';
import { useLocation } from 'react-router-dom'
import local from '../summary/SummaryLocale'
import xml2js from '../../../lib/xml-to-js'

class FilmClubCustomerContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {
                name: '',
                phone: '',
                email: '',
                repeatEmail: '',
                password: '',
                conditionsAccepted: '',
                zipCode: '',
                value: this.props.location.state
            },
            showPriceInfo: '',
           
           
        }
       
       // props.actions.gaTrackCheckoutStep(2);
        this.navBack = this.navBack.bind(this);
        this.navNext = this.navNext.bind(this);
        this.togglePriceInfo = this.togglePriceInfo.bind(this);
    }

    componentDidMount() {
       
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);
    }

    componentDidUpdate = (prevProps) => {


    }
    navBack() {
        const { customer, history, actions, clubCardId } = this.props;
        let transId = this.props.location.state.transactionId
        if (customer.rememberMe === true && !clubCardId) actions.updateCustomerLocalStorage();


        //history.goBack();
        fetch(`${CONSTANTS.fetchUrl}/${CONSTANTS.ticketgatePath}/CancelTransaction_Multi?TransactionID=${transId}`, {
            method: 'GET',
            headers: {
                'UserName': 'web',
                'Password': 'web',
                'OriginNo': '10',
            },
        })
            .then(res => {
                return res.text()
            })
            .then(result => {
                //const json = xml2js.parseString(result)
              //  console.log('cancel')
                //console.log(json)
                history.goBack();

            })
    }

    navNext(e) {
        const id = e.target.id;
        const eventId = window.location.href.split('filmklub-Kunde/')[1].split('?')[0]
        const search = window.location.search;
        const paramss = new URLSearchParams(search);
        const org = paramss.get('org')

        const { organizerId, history,  customer } = this.props;
       
        let transId = this.props.location.state.transactionId
      
        //  if (customer.rememberMe === true && !clubCardId) actions.updateCustomerLocalStorage();

        if (this.customerIsValid(id) === true) {


            fetch(`${CONSTANTS.fetchUrl}/${CONSTANTS.ticketgatePath}/PreBook_Multi?TransactionID=${transId}&UserID=&Phone=${customer.phone}&Name=${customer.name}&Password=${customer.password}&EMail=${customer.email}&CardHolder=0&CreditCardCode=DK&QuickUser=0&Newsletter=&nSendReceipt=1&Zipcode=&Note=`, {
                method: 'GET',
                headers: {
                    'UserName': 'web',
                    'Password': 'web',
                    'OriginNo': '10',
                },
            })
                .then(res => res.text())
                .then(result => {
                    const json = xml2js.parseString(result)
                    const data = json.message
                    let parameters = data && data.dibs.parameters
                  //  console.log(data && data)
                    const merchant = data && data.dibs.parameters.merchant
                   


                    if (merchant.startsWith('altapay')) {

                        history.push({
                            pathname: `/altapay-betaling-multi/${eventId}?org=${organizerId}`,
                            state: this.props.location.state,
                            preBook_parameters: parameters
                        })

                    }
                    else
                        history.push({
                        pathname: `/reepayMulti/${eventId}?org=${organizerId}`,
                        state: {
                            naam: 'mero',
                            transactionId: this.props.location.state.transactionId,
                            preBook_parameters: parameters
                        }
                    })


                })

        }
    }
    

    customerIsValid() {
        //const isReserve = orderType === CONSTANTS.orderTypes.reserve
        const { customer, configuration, appLocale, organizerType } = this.props;
       // console.log(customer)
        const nameIsValid = customer.name !== '' ,
            phoneIsValid = validators.isValidPhone(customer.phone, configuration.phoneMinLength),
            emailIsValid = validators.isValidEmail(customer.email),
            repeatEmailIsValid = configuration.repeatEmail ? validators.isValidEmail(customer.repeatEmail) && customer.email === customer.repeatEmail : true,
            passwordIsValid = !configuration.customerPasswordEnabled ? true : validators.isValidPassword(customer.password),
            conditionsAccepted = customer.conditionsAccepted === true , //only check for buy
            zipCodeIsValid = organizerType == ORGANIZER_TYPES.CIRCUS ? customer.zipCode !== '' : true; //only for circus

        const errors = {
            name: !nameIsValid ? LOCALE[appLocale].errorName : '',
            phone: !phoneIsValid ? LOCALE[appLocale].errorPhone.replace('INSERT_NUMBER', configuration.phoneMinLength) : '',
            email: !emailIsValid ? LOCALE[appLocale].errorEmail : '',
            repeatEmail: !repeatEmailIsValid ? LOCALE[appLocale].errorRepeatEmail : '',
            password: !passwordIsValid ? LOCALE[appLocale].errorPassword : '',
            conditionsAccepted:  !conditionsAccepted ? LOCALE[appLocale].errorConditionsAccepted : '',
            zipCode: !zipCodeIsValid ? LOCALE[appLocale].errorZipCode : ''
        }


        this.setState({ errors });
        return nameIsValid && phoneIsValid && emailIsValid && repeatEmailIsValid && passwordIsValid && conditionsAccepted && zipCodeIsValid;
    }

    togglePriceInfo() {
        const { showPriceInfo } = this.state;
        if (showPriceInfo === false) this.setState({ showPriceInfo: true });
        else this.setState({ showPriceInfo: false });
       
    }

   


    render() {
        const { orderType, configuration, customer, actions, clubCardId, appLocale, organizerType, receipt } = this.props;
        const { errors, showPriceInfo, } = this.state;
        const params = '/:eventId/';
        let location = useLocation

      
        const icon = showPriceInfo ? <span className="icon-chevrons-up"></span> : <span className="icon-chevrons-down"></span>;
      
       
        const gdprText = <div className="gdpr-text" dangerouslySetInnerHTML={{ __html: configuration.gdprTexts.buy }}></div> 
        

        return (
            <>
                <section style={{ textAlign: 'center', height: '300px', backgroundColor: '#2e2e2e', color: 'white', padding: '90px' }}>
                    <h1>{this.props.location.state.eventName}</h1>
                </section>
                <div className="CustomerContainer" style={{padding:'20px'}}>
                    <Customer appLocale={appLocale}
                    customer={customer}
                    actions={actions}
                    configuration={configuration}
                    errors={errors}
                    isReserve={orderType === CONSTANTS.orderTypes.reserve}
                    clubCardId={clubCardId}
                    organizerType={organizerType}
                /> 

                {gdprText}

               
                </div>
                <br></br>
           
                <div className="nav-buttons">
                    <div className="back-buttons">
                        <input type="button" className="back gray" onClick={this.navBack} value={LOCALE[appLocale].back}/>
                    </div>
                    <div className="next-buttons">
                        <input type="button" id="buy" className="next green fade-in" onClick={this.navNext} value={LOCALE[appLocale].buy} />
                    </div>
                </div>

                <div className="Summary fixed-nav">
                    <h3 onClick={this.togglePriceInfo}>
                        {icon} {local[appLocale].yourOrder}
                    </h3>
                    <div className="summary-info">
                       
                        <div className="order">
                            <span>{this.props.location.state.ticketCount > 0 && this.props.location.state.ticketCount + ' x ' + this.props.location.state.ticketType}</span>
                            <span>{this.props.location.state.ticketCount > 0 && this.props.location.state.ticketCount1 > 0 && ' , '}</span>
                            <span>{this.props.location.state.ticketCount1 > 0 && this.props.location.state.ticketCount1 + ' x ' + this.props.location.state.ticketType1}</span>
                            <span>{this.props.location.state.ticketCount1 > 0 && this.props.location.state.ticketCount2 > 0 && ' , '}</span>
                            <span>{this.props.location.state.ticketCount2 > 0 && this.props.location.state.ticketCount2 + ' x ' + this.props.location.state.ticketType2}</span>
                            <span>{this.props.location.state.ticketCount2 > 0 && this.props.location.state.ticketCount3 > 0 && ' , '}</span>
                            <span>{this.props.location.state.ticketCount3 > 0 && this.props.location.state.ticketCount3 + ' x ' + this.props.location.state.ticketType3}</span>
                            <span>{this.props.location.state.ticketCount3 > 0 && this.props.location.state.ticketCount4 > 0 && ' , '}</span>
                            <span>{this.props.location.state.ticketCount4 > 0 && this.props.location.state.ticketCount4 + ' x ' + this.props.location.state.ticketType4}</span>
                            <span>{this.props.location.state.ticketCount4 > 0 && this.props.location.state.ticketCount5 > 0 && ' , '}</span>
                            <span>{this.props.location.state.ticketCount5 > 0 && this.props.location.state.ticketCount5 + ' x ' + this.props.location.state.ticketType5}</span>
                        </div>
                        <div className="total-price">{local[appLocale].totalPrice}  {this.props.location.state.totalTicketPrice} Kr.</div>
                    </div>
                    {showPriceInfo ?
                        <div className="PriceInfo">
                            <p> {local[appLocale].tickets}  <span>{this.props.location.state.ticketPrice} kr.</span></p>
                            <p> {local[appLocale].fees}     <span>{this.props.location.state.gebyr} kr.</span></p>
                            <hr />
                            <p>{local[appLocale].totalPrice}  <span>{this.props.location.state.totalTicketPrice} Kr.</span></p>
                        </div>
                        : ''
                    }

                </div>
            
              
                </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  
    return {
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        orderType: state.order.orderType,
        configuration: state.organizer.configuration,
        customer: state.customer,
        transactionId: state.order.selected.transactionId,
        receipt: state.receipt,
        clubCardId: state.user.clubCardId,
        totalPrice: state.receipt.transactionId > 0 ? state.receipt.prices.total : state.order.totalPrice ,
        appLocale: state.app.locale,
        organizerType: state.organizer.configuration.type,
        iframeId: state.app.iframeId,
       
       
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, customerActions,  gaActions, { appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilmClubCustomerContainer)