export default {
    // Safari, in Private Browsing Mode, looks like it supports localStorage but all calls to setItem
    // throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
    // to avoid the entire page breaking, without having to do a check at each usage of Storage.
    localStorageIsSupported: (() => {
        var isSupported = false;
        try {
            window.localStorage.setItem('localStorage', 1);
            window.localStorage.removeItem('localStorage');
            isSupported = true;
        } catch (e) {
            isSupported = false;
        }
        return function () { return isSupported; };
    })(),

    getItem(key) {
        if (this.localStorageIsSupported()) return JSON.parse(window.localStorage.getItem(key));
    },

    setItem(key, object) {
        if (this.localStorageIsSupported()) window.localStorage.setItem(key, JSON.stringify(object));
    },

    removeItem(key) {
        if (this.localStorageIsSupported()) window.localStorage.removeItem(key);
    }
}