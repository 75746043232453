import React from 'react'
import _ from 'lodash'

export default class QuantitySelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            quantity: props.quantity
        };
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
    }

    debounceMultipleClicks = _.debounce((quantity) => {
        const { quantityChanged } = this.props;
        const { id } = this.state;
        quantityChanged(id, quantity);
    }, 400)

    canIncrement(quantity) {
        const { extra, canIncrementTotalPoints } = this.props;
        if (extra.points) return canIncrementTotalPoints(extra.points, quantity) && this.checkMaxQuantity(quantity);
        else return this.checkMaxQuantity(quantity);
    }

    increment() {
        let { quantity } = this.state;
        const newQuantity = quantity + 1;
        if (this.canIncrement(newQuantity)) {
            this.setState({ quantity: newQuantity });
            this.debounceMultipleClicks(newQuantity);
        }
    }

    decrement() {
        let { quantity } = this.state;
        if (quantity > 0) {
            this.setState({ quantity: --quantity });
            this.debounceMultipleClicks(quantity);
        }
    }

    checkMaxQuantity(quantity) {
        const { maxQuantity } = this.props;
        return maxQuantity === 0 || maxQuantity > 0 && quantity < maxQuantity;
    }

    render() {
        const { clubCard, extra, isFetching } = this.props;
        const { quantity } = this.state;
        const decrementClasses = !isFetching && quantity > 0 ? 'decrement' : 'decrement deactivated';
        let incrementClasses = !isFetching && this.checkMaxQuantity(quantity) ? 'increment' : 'increment deactivated';
        if (clubCard && extra.points > clubCard.remainingPoints) incrementClasses = 'increment deactivated';

        return (
            <div className="QuantitySelector">
                <div className={decrementClasses} onClick={this.decrement}><span className="icon-circle-minus"></span></div>
                <div className="quantity">{quantity}</div>
                <div className={incrementClasses} onClick={this.increment}><span className="icon-circle-plus"></span></div>
            </div>
        )
    }
}