import api from '../api/cms-api'

export const FETCH_COMMERCIALS_REQUEST = 'FETCH_COMMERCIALS_REQUEST'
export const FETCH_COMMERCIALS_SUCCESS = 'FETCH_COMMERCIALS_SUCCESS'
export const FETCH_COMMERCIALS_FAILED = 'FETCH_COMMERCIALS_FAILED'

export default {
    getCommercials
}

function getCommercials(organizerId) {
    return dispatch => {
        dispatch({
            type: FETCH_COMMERCIALS_REQUEST,
            requestedAt: Date.now()
        });

        return api.fetchCommercials(organizerId).then(
            commercials => dispatch({
                type: FETCH_COMMERCIALS_SUCCESS,
                succeededAt: Date.now(),
                commercials: commercials
            }),
            error => dispatch({
                type: FETCH_COMMERCIALS_FAILED,
                failedAt: Date.now(),
                error
            })
        );
    }
}