export default {
    da: {
        longDateTimeFormat: 'dddd [d.] LL [kl.] LT',
        cinema: 'Biograf',
        circus: 'Cirkus',
        museum: 'Sted',
        age: 'Censur',
        duration: 'Varighed',
        durationTo: 'til ca.'
    },

    en: {
        longDateTimeFormat: 'dddd LL [at] LT',
        cinema: 'Cinema',
        circus: 'Circus',
        museum: 'Place',
        age: 'Censoring',
        duration: 'Duration',
        durationTo: 'to approx.'
    },

    fo: {
        longDateTimeFormat: 'dddd LL [d.] LT',
        cinema: 'Biografur',
        circus: 'Sirkus',
        age: 'Aldursmark',
        duration: 'Longd',
        durationTo: 'til uml.'
    }

    //new: {
    //    longDateTimeFormat: 'dddd LL [INSERT HERE] LT',
    //    cinema: '',
    //    circus: '',
    //    age: '',
    //    duration: '',
    //    durationTo: ''
    //}
}