import _ from 'lodash'
import moment from 'moment'
import CONSTANTS from './movielist-constants'

export function setContainerClasses(listType, columns, isMultiBio) {
    let classes = `MovieListContainer ${listType}`;
    if (isMultiBio) classes += ' multibio';
    switch (columns) {
        case CONSTANTS.columns.one:
            return classes += ' one-column';
        case CONSTANTS.columns.two:
            return classes += ' two-columns';
        case CONSTANTS.columns.five:
            return classes += ' five-columns';
        case CONSTANTS.columns.six:
            return classes += ' six-columns';
        default: return classes;
    }
}

export function setItemClasses(counter, columns) {
    let classes = 'MovieListItem';

    switch (columns) {
        case CONSTANTS.columns.six:
            return calculateRowPlacement(counter, classes += ' six-columns');
        case CONSTANTS.columns.five:
            return calculateRowPlacement(counter, classes += ' five-columns');
        case CONSTANTS.columns.two:
            return calculateRowPlacement(counter, classes += ' two-columns');
        case CONSTANTS.columns.one:
            return calculateRowPlacement(counter, classes += ' one-column');
    }

    function calculateRowPlacement(counter, rowClasses) {
        if (counter > 1) {
            for (let i = 2; i <= 7; i++) {
                if (counter % i == 0) rowClasses += ' first-in-row' + i;
            }
        }
        return rowClasses;
    }
}

export function isInsideShowPeriod(showtime) {
    if (showtime.showInfo.enabled === true) {
        const from = moment(showtime.showInfo.start);
        const to = moment(showtime.showInfo.end);
        return moment().isBetween(from, to);
    } else return false;
}

// Aligns Showtimes for matrix layouts...
export function manipulateTitles(movielist, columns) {
    const titles = movielist.querySelectorAll('h1')
    let counter = 0
    while (counter < titles.length) {
        let tallestTitle = 55 // approx. min for 1 row

        const row = _.take(_.drop(titles, counter), columns)
        row.forEach((title) => {
            const titleHeight = Math.max(title.scrollHeight, title.offsetHeight, title.clientHeight)
            if (titleHeight > tallestTitle)
                tallestTitle = titleHeight
        })

        row.forEach((title) => title.style.height = tallestTitle + "px")
        counter += columns
    }
}