import './filmclub.scss'

import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { getParams } from '../../../utilities/location-utilities'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../movielist-constants'
import { appHeightChanged } from '../../../actions/app-actions'
import { getMovieList, changeDate, selectedMovieChanged, changeSelectedShowtime } from '../../../actions/movielist-actions'
import DatePickerContainer from '../../datepicker/DatePickerContainer'

import Poster from '../../movie/Poster'

import { manipulateTitles, setContainerClasses } from '../shared'

import LOCALE from '../MovieListLocale'



class FilmClubContainer extends React.Component {
    constructor() {
        super();
        this.changeDate = this.changeDate.bind(this);
        this.movieClicked = this.movieClicked.bind(this);
        this.showtimeClicked = this.showtimeClicked.bind(this);
    }

    componentDidMount() {
        const { actions, movies, organizerIds, iframeId } = this.props;
        actions.getMovieList(organizerIds);

        if (window.parent != window) actions.appHeightChanged(iframeId, false, false, true);
        else window.scrollTo(0, sessionStorage.getItem("scroll-position"));

        // this.manipulateTitles()

    }

    componentWillUnmount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, false, true, false);
        else sessionStorage.setItem("scroll-position", window.scrollY);
    }

    // componentDidUpdate(prevProps) {
    // this.manipulateTitles()
    //   }

    //manipulateTitles() {
    //    const { columns, listType } = this.props
    //    if (columns != CONSTANTS.columns.one) {
    //        const movielist = document.querySelector(`.MovieListContainer.${listType}`)
    //        manipulateTitles(movielist, columns);
    //    }
    //}

    changeDate(date) {
        const { history, organizerIds, actions } = this.props;
        actions.changeDate(date);
        history.push(`${window.location.pathname}?org=${organizerIds}&date=${date}`);
    }

    movieClicked(id) {
        const { organizerIds, actions, history, movies } = this.props;
        const movie = _.find(movies, (movie) => { return movie.id == id });
        actions.selectedMovieChanged(movie.id, history.location.pathname).then(() => history.push(`${ROUTES.MOVIE}/${id}?org=${organizerIds}`));
    }

    showtimeClicked(organizerId, showtimeId) {
        const { openOrderInNewWindow, actions, history, showtimes } = this.props;
        const showtime = _.find(showtimes, (showtime) => { return showtime.id == showtimeId && showtime.organizerId == organizerId });
        if (showtime) {
            actions.changeSelectedShowtime(showtime, history.location.pathname).then(() => {
                const orderUrl = `${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${showtime.organizerId}`;
                if (openOrderInNewWindow) window.open(orderUrl, '_blank', 'width=1170,height=800,resizable=yes,scrollbars=yes');
                else history.push(orderUrl);
            })
        }
    }




    generateEventGroups(eventShows) {

        const { movies, showtimes, columns } = this.props
      //  console.log(eventShows, 'eventshows')
     //   console.log(movies, 'movies')
        return <div className="MovieList ">

            {_.map(eventShows.sort((a, b) => a.dateTime > b.dateTime ? 1 : -1), (shows, index) => {

             //   console.log(shows.dateTime)
                return _.map(movies, (movie) => {
                    //console.log(movie)
                    let dd = moment(shows.dateTime).format('yyyy-MM-DD');
                    const date = moment(dd);
                    if (shows.movieId === movie.id)

                        return <div className="MovieListItem five-columns" key={index} >

                            {/*<Poster movie={movie} />*/}
                            <img src={movie.posters.large} />
                            <div className="eventFilmsItems-movieInfo" >
                                <h3 style={{ height: '40px' }}>{movie.name}</h3>
                                <p style={{ color: '#747474', letterSpacing: '0.6px', lineHeight: '1.17' }}> {_.capitalize(date.format('dddd'))}
                                    <span style={{ color: '#982f2f', fontWeight: '600' }}> {date.format('DD.MM.YY')}</span>  kl.{moment(shows.dateTime).format('LT')}  </p>



                            </div>
                        </div>
                })
            })}


        </div>



    }


    render() {
        const { appLocale, pageHeader, movies, showAsBase, showtimes, posterBanners, selectedDate, columns, showExpired,
            showLocationOnShowtime, specialMoviesEnabledOnProgram, showBackInTime, actions, organizerIds, sortBy, filterIds } = this.props;

        //  console.log(movies)
          // console.log(showtimes, 'showtimes')
        let arr = []
        showtimes && showtimes.map((i) => {
           // console.log(i.eventGroup)
            if (i.eventGroup) {
                arr.push(i)
              //  console.log(i, 'i')
            }
            return arr
        });



        let eventArr = [];
        arr && arr.filter((i) => eventArr.push(i.eventGroupNo))
        //console.log(eventArr, 'event arr')
        function FilmDuplicates(eventArr) {
            const uniqueElements = new Set(eventArr);

            eventArr.filter((item) => {
                if (uniqueElements.has(item)) {

                } else {
                    return item;
                }
            });

            return [...new Set(uniqueElements)];
        }
        const duplicateFilm = FilmDuplicates(eventArr);
        //console.log(duplicateFilm, 'dps film')
        let result = []
        for (let index = 0; index < duplicateFilm.length; index++) {
            result.push({
                eventGroupId: duplicateFilm[index],

                shows: _.filter(showtimes, (shows) => {
                    return shows.eventGroupNo == duplicateFilm[index]
                }),
                eventName: _.filter(showtimes, (shows) => {
                    return shows.eventGroupNo == duplicateFilm[index]
                })[0].eventGroup.name
            })
        }
        const search = window.location.search;
        const paramss = new URLSearchParams(search);
        const eventGroupNo = paramss.get('eventgroup')


        let classes = setContainerClasses(CONSTANTS.listTypes.date, columns);

     //   console.log(result)


        return (
            <div className='MovieListContainer five-columns'>
                
                {/*<div className="flex-container">*/}

                {/*    <DatePickerContainer hasListHeader={pageHeader != null}*/}
                {/*        isAllMovies={false}*/}
                {/*        changeDate={this.changeDate}*/}
                {/*        isOneColumn={columns == CONSTANTS.columns.one}*/}
                {/*    />*/}
                {/*</div>*/}


                {result && result.map((item, index) => (
                    <section key={index} className="filmklub-container"
                        style={eventGroupNo ? { display: eventGroupNo && item.eventName.toLowerCase() === eventGroupNo && eventGroupNo.toLowerCase() || item.eventName.toLowerCase().includes(eventGroupNo && eventGroupNo.toLowerCase()) ? 'flex' : 'none' } : {}}
                    >
                        <div className="filmklub-header">
                            <h2> "{item.eventName}" </h2>

                            <a href={`${window.location.origin}${ROUTES.MULTI_TICKETS}/${item.eventGroupId}?org=${organizerIds}`}>
                                <button className="filmklub-billet-btn" >{LOCALE[appLocale].buyTickets}</button>
                            </a>

                        </div>

                        {this.generateEventGroups(item.shows)}
                    </section>
                )
                )}


            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    //  console.log(state, 'state')
    const config = state.organizer.configuration;
    const listConfig = config.movielists;
    const selectedDate = state.movielist.selectedDate;
    const movies = listConfig.date.base ? extractMoviesAsBases(state.movielist.movies) : state.movielist.movies;

    let dateForHeader = extractHeaderDate(selectedDate);
    const pageHeader = _.replace(config.pageHeaders.date, 'SELECTED_DATE', _.capitalize(dateForHeader));

    function extractHeaderDate(selectedDate) {
        const date = moment(selectedDate);
        if (date.isSame(moment(), 'day')) return 'i dag ';
        else if (date.isSame(moment().add(1, 'days'), 'day')) return 'i morgen ';
        else return date.format('dddd DD/MM');

    }

    function extractMoviesAsBases(movies) {
        const moviesBases = state.movielist.movieBases;
        const moviesAsBase = [];
        _.each(movies, (movie) => {
            if (movie.baseId) {
                const baseMovie = _.find(moviesBases, (base) => { return base.id == movie.baseId; });
                const alreadyInList = _.some(moviesAsBase, ['baseId', movie.baseId]);
                if (baseMovie && !alreadyInList) moviesAsBase.push({ ...movie, name: baseMovie.name, posters: baseMovie.posters });
            } else moviesAsBase.push(movie);
        });
        return moviesAsBase;
    }

    return {
        organizerIds: state.movielist.organizerIds || getParams().org,
        pageHeader,
        columns: listConfig.date.columns,
        movies,
        sortBy: listConfig.date.sort,
        showAsBase: listConfig.date.base,
        showtimes: state.movielist.showtimes,
        posterBanners: config.posterBanners,
        appLocale: state.app.locale,
        openOrderInNewWindow: config.openOrderInNewWindow,
        selectedDate,
        initialDate: state.movielist.initialDate,
        showLocationOnShowtime: config.showLocationOnShowtime,
        specialMoviesEnabledOnProgram: config.movielists.special.enabledOnProgram,
        showBackInTime: config.daysBack > 0,
        iframeId: state.app.iframeId,
        showExpired: config.movielists.date.showExpired,
        filterIds: state.movielist.filterIds,
        listType: CONSTANTS.listTypes.date
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { getMovieList, selectedMovieChanged, changeDate, changeSelectedShowtime, appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilmClubContainer)