import React, { useState, useEffect} from 'react'
import _ from 'lodash'
import "./custom.slider.scss";

export default function AlertContainer({ selected, organizerId }) {

    const [modalShown, setModalShown] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
  
    const seatsArr = selected.seats
   
    let datas = [
        {
            seatAlertImage: 'Magenta-Seat-icon.png',
            seatAlertInfo: 'magentaa',

            _no:5

        },
        {
            seatAlertImage: 'Fork-Seat-icon.png',
            seatAlertInfo: 'forkaaa',

            _no:4
        }
    ]
    const selSeatsInfo = selected.seatsInfo && selected.seatsInfo.length ? selected.seatsInfo : ''
   

    useEffect(() => {
        
        selSeatsInfo.length && (selSeatsInfo[0] && selSeatsInfo[0].seatAlertImage || selSeatsInfo[1] && selSeatsInfo[1].seatAlertImage) ? setModalShown(true) : setModalShown(false)
      
      
    }, [selected])

    function closeModal() {
        setModalShown(false)
    }

   

    const slideNext = () => {
      
        setActiveIndex((val) => {
            if (val >= seatsArr.length - 1) {
                return 0;
            } else {
                return val + 1;
            }
        });
    };

    const slidePrev = () => {
        setActiveIndex((val) => {
            if (val <= 0) {
                return seatsArr.length - 1;
            } else {
                return val - 1;
            }
        });
    };


    function calculateSeats(nr) {
        let seatsPerNo = []
        seatsArr.filter(sa => {
            if (sa.areano === nr) {

                return seatsPerNo.push(sa.seat)
            }
        })
        return seatsPerNo
    }


    function closeModal() {
        setModalShown(false)
    }

    let selectedSeatArr = []

    selected.seats && selected.seats.map(seat => {

        selSeatsInfo && selSeatsInfo.map(sir => {

            if (seat.areano === sir._no && sir.seatAlertImage) {
                selectedSeatArr.push(seat.seat)

            }
        })
    })

    // calculation of the length of seatsInfo and if seatAlertImage is not null
    let alert_images = []
    let alert_infos = []
    selSeatsInfo.length && selSeatsInfo.map(s => {
        if (s.seatAlertImage !== null)
            alert_images.push(s.seatAlertImage);
        if (s.seatAlertInfo !== null)
            alert_infos.push(s.seatAlertInfo)
    })

   
    return modalShown ? (
        <div className='modal-backdrop'>
            <div className='modal-content-alert'>

                {selSeatsInfo.length > 1 && alert_images.length > 1 && alert_infos.length > 1 ?
                    <>
                        <div className="container__slider"  >

                            {selSeatsInfo && selSeatsInfo.map((data, index) => {
                                let seatsPerModel = calculateSeats(data._no)

                                return <div className={"slider__item slider__item-active-" + (activeIndex + 1)} key={index}>
                                      <div className='modal-container slider__item slider__item-active- +
                                            (activeIndex + 1)' key={index}>
                                        <p className='alert-text'> Særlige bemærkninger om sæde
                                            <div key={index} style={{ display: 'flex' }}>
                                                {seatsPerModel.map(spm => {
                                                    return <p className='seat-numbers'>{spm}</p>
                                                })}  </div>   </p>
                                        <img height='279px' width='394px' style={{ border: '1px solid  #b8b8b8' }}
                                            src={`https://flow.ebillet.dk/images/organizer_${organizerId}/${data.seatAlertImage}`} alt='alrt image' />
                                        <p className='alert-info' dangerouslySetInnerHTML={{ __html: data.seatAlertInfo }}>  </p>
                                        <input type='button' style={{ fontSize: '16px', fontFamily: 'Oswald' }}
                                            onClick={closeModal} value='Acceptér' className='accept-btn' />

                                    </div>
                                </div>
                            })}



                        </div>
                        <button style={{ display: activeIndex === 1 ? 'none' : '' }} className="img_slide_right" onClick={(e) => { e.preventDefault(); slideNext(); }}> ❯ </button>
                        <button style={{ display: activeIndex === 0 ? 'none' : '' }} className="img_slide_left" onClick={(e) => { e.preventDefault(); slidePrev(); }}> ❮ </button>
                    </>
                    : <div className='modal-container' >
                        <p className='alert-text'> Særlige bemærkninger om sæde
                            <div style={{ display: 'flex' }}>
                                {selectedSeatArr && selectedSeatArr.length > 1 ?
                                    selectedSeatArr.map((seat, index) => {
                                        return <p className='seat-numbers' key={index}>{seat}</p>
                                    })
                                    : <p className='seat-numbers'>{selectedSeatArr && selectedSeatArr[0]}</p>
                                }
                            </div>
                        </p>
                        <img height='279px' width='397px' style={{ border: '1px solid  #b8b8b8' }}
                            src={`https://flow.ebillet.dk/images/organizer_${organizerId}/${alert_images
                                }`}
                            alt='alrt image' />
                        <p className='alert-info' dangerouslySetInnerHTML={{ __html: alert_infos && alert_infos }}>
                        </p>
                        <input type='button' style={{ fontSize: '16px', fontFamily: 'Oswald' }}
                            onClick={closeModal} value='Acceptér' className='accept-btn' />

                    </div>

                }
            </div>
        </div>
    )
        : null


}