import React, { useEffect, useState } from 'react'
import './styles/program.scss'
import DatePickerContainer from '../datepicker/DatePickerContainer'
//import CONSTANTS from '../movielist-constants'
import xml2js from '../../lib/xml-to-js';
import DayPicker from 'react-day-picker';
import moment from 'moment'
import downarrow from './images/down-arrow-backup-2-svgrepo-com (1).svg'
import check from "./images/check-mark.svg";
import checkIcon from "./images/check-mark.svg";
import Region from './Region';


export default function Cinema() {

   

    // choose biograf functions
    const [isCinemaOpen, setIsCinemaOpen] = useState(false);
    const [films, setFilms] = useState([{}]);
    const [chosenCinema, setChosenCinema] = useState('Vælge biograf');
    // const [Dd, setDd] = useState({data.map(item.id)});
    const [check, setCheck] = useState(false);

    // console.log(Dd)
    function handleArrrow() {
        setIsCinemaOpen((current) => !current);
    }



    useEffect(() => {
        fetch('https://jsonplaceholder.typicode.com/users')
            .then(res => res.json())
            .then(result => setFilms(result))
    }, []);

    const [toggle, setToggle] = useState(true)

    function handleCheck(e, item) {

        films.map(i => {
            if (i.id === e.id) {
                let p = item.target
                let img = document.createElement('img')
                img.src = toggle ? checkIcon : ''
                p.append(img)
                // console.log('click')
                item.target.parentElement.style.color = toggle ? '#fff' : '#999'
                setToggle(!toggle)
                setIsOpen(false)


            }
            setChosenCinema(e.name)

        })


    }

    return (
       
           

        <div style={{  backgroundColor:'#3b3b3b',  }}>
                    <p className="region-title">Vælge biograf</p>
                    <div className='chooseCinema'>
                        <div
                            style={{
                                position: "relative",
                                padding: "5px 8px",
                                boxSizing: "border-box",
                                height: "31px",
                            }}  >
                            <p style={{ margin: 0 }}>{chosenCinema}</p>
                            <span className="one"> 1</span>
                            <span
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    height: "31px",
                                    right: "35px",
                                    borderLeft: "0.1px solid grey",
                                }}
                            ></span>
                            <button
                                onClick={handleArrrow}
                                style={{
                                    position: "absolute",
                                    bottom: "6px",
                                    right: "-4px",
                                    border: "none",
                                    outline: "none",
                                    background: "transparent",
                                }}
                            >
                                <p className={isCinemaOpen ? "rotated" : ""}>
                                    <img src={downarrow} alt="arrows" />
                                </p>
                            </button>
                        </div>
                    </div>
                    {isCinemaOpen &&
                        <div className="scroll" style={{
                            borderLeft: "1px solid #757575",
                            borderRight: "1px solid #757575",
                            borderBottom: "1px solid #757575",
                            color: '#999',
                            backgroundColor: "#3a3a3a",
                            height: "244px",
                            overflowX: 'auto',
                            position: 'relative',
                            zIndex: 100
                        }}>
                            {films && films.map((item) => (

                                <div onClick={(e) => handleCheck(item, e)}
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        height: "31px",
                                        padding: "8px",
                                        marginRight: '2px',
                                        borderRight: '1px solid #757575'

                                    }}
                                >
                                    <p style={{ margin: 0, color: item.name === chosenCinema ? '#fff' : '#999' }}>{item.name}</p>
                                    <p
                                        className="checkCheckbox"
                                        style={{ position: "relative", top: "-14px" }}

                                    >
                                        {item.name === chosenCinema ?
                                            <img
                                                style={{ position: "absolute", top: "2px", left: "3px" }}
                                                src={checkIcon} alt=""
                                            />
                                            : ''}
                                    </p>
                                </div>
                            ))}

                        </div>
                    }

                </div>

        

           

        
        )
}