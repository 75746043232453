import './customer.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import * as orderActions from '../../../actions/order-actions'
import * as customerActions from '../../../actions/customer-actions'
import * as gaActions from '../../../actions/ga-actions'
import * as validators from '../../../utilities/validation-utilities'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../orderflow-constants'
import Customer from './Customer'
import LOCALE from './CustomerLocale'
import { ORGANIZER_TYPES } from '../../../api/api-constants';
import { appHeightChanged } from '../../../actions/app-actions'
import Spinner from '../../overlays/Spinner'




class CustomerContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
            value: "1212",
            loading:false,
            errors: {
                name: '',
                phone: '',
                email: '',
                repeatEmail: '',
                password: '',
                conditionsAccepted: '',
                zipCode: '',
               
            },
        }

        props.actions.gaTrackCheckoutStep(2);
        this.navBack = this.navBack.bind(this);
        this.navNext = this.navNext.bind(this);
       
       
        this.changeState.bind(this);
    }
    changeState = (newValue) => {
        this.setState({ value: newValue });
    };
  

    componentDidMount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);
    }

    navBack() {
        const { customer, history, actions, clubCardId } = this.props;
        if (customer.rememberMe === true && !clubCardId) actions.updateCustomerLocalStorage();
        history.goBack();
    }

   
    navNext(e) {
        const id = e.target.id;
        const { value, loading } = this.state
        const { origin, iframeId } = this.props
        const license = sessionStorage.getItem('license')

      // const origin = undefined
        const { organizerId, actions, transactionId, customer, showtime, clubCardId, totalPrice, history, orderType, organizer_merchantID, someVar } = this.props;
         // console.log(organizer_merchantID, 'merchant id')
        //console.log(orderType)
        if (customer.rememberMe === true && !clubCardId) actions.updateCustomerLocalStorage();

        
                
        if (this.customerIsValid(id) === true) {
            if (id === CONSTANTS.orderTypes.reserve) {

                actions.reserve(transactionId, customer)
                    .then((result) => { if (!result.error) { history.push(`${ROUTES.ORDERFLOW.RECEIPT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`); } });

            }
            else if (totalPrice === 0) {
                this.setState({ loading: true })
                actions.preBook(transactionId, customer).then((result) => {
                    if (!result.error) {
                        const preBook = result.dibs.parameters;
                        //const license = sessionStorage.getItem('license')

                        let callback = (window.location.origin.startsWith(`http://localhost`) ? `http://dev.ebillet.dk` : window.location.origin) + `/systemnative/ticketgate.asmx/Kvittering?org=${organizerId}&amount=${preBook.amount}&iframeid=${iframeId}&transactionID=${transactionId}&id=&invoice=${preBook.orderid}&customer=${customer.phone}`
                        let acceptUrl = callback + `&accept=true&LicensePlate=${license}`;
                        //console.log(acceptUrl, 'accept url')
                        window.location.href = acceptUrl
                        //actions.book(transactionId, 'Free', totalPrice)
                        //    .then((result) => {
                        //        if (!result.error)

                        //            history.push(`${ROUTES.ORDERFLOW.RECEIPT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`)
                        //    });
                    }
                });
            }
            else if (orderType == CONSTANTS.orderTypes.buyReservation) {

                if (organizer_merchantID.startsWith('altapay')) 
                    history.push(
                        `${ROUTES.ORDERFLOW.ALTA_PAY_PAYMENT}/${showtime.movieId}/${showtime.id}?org=${organizerId}${!origin ? '&origin=' + window.location.origin : '&origin=' + origin}`

                    );
                

                else history.push(`${ROUTES.ORDERFLOW.REEPAY}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);

            }
            // if there is merchant id and ordertype is buy then redirect to ALTA PAYAMENT route
            else if (organizer_merchantID.startsWith('altapay')) history.push(
                `${ROUTES.ORDERFLOW.ALTA_PAY_PAYMENT}/${showtime.movieId}/${showtime.id}?org=${organizerId}${!origin ? '&origin=' + window.location.origin  :  '&origin=' + origin  }`
               
            );

                

            else history.push(`${ROUTES.ORDERFLOW.PAYMENT}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
        }
    }
              
    

    customerIsValid(orderType) {
        const isReserve = orderType === CONSTANTS.orderTypes.reserve
        const { customer, configuration, appLocale, organizerType } = this.props;
       // console.log(customer)
        const nameIsValid = customer.name !== '' ,
            phoneIsValid = validators.isValidPhone(customer.phone, configuration.phoneMinLength),
            emailIsValid = validators.isValidEmail(customer.email),
            repeatEmailIsValid = configuration.repeatEmail ? validators.isValidEmail(customer.repeatEmail) && customer.email === customer.repeatEmail : true,
            passwordIsValid = !configuration.customerPasswordEnabled ? true : validators.isValidPassword(customer.password),
            conditionsAccepted = !isReserve ? customer.conditionsAccepted === true : true, //only check for buy
            zipCodeIsValid = (organizerType == ORGANIZER_TYPES.CIRCUS || organizerType == ORGANIZER_TYPES.MUSEUM) ? customer.zipCode !== '' : true; //only for circus

        const errors = {
            name: !nameIsValid ? LOCALE[appLocale].errorName : '',
            phone: !phoneIsValid ? LOCALE[appLocale].errorPhone.replace('INSERT_NUMBER', configuration.phoneMinLength) : '',
            email: !emailIsValid ? LOCALE[appLocale].errorEmail : '',
            repeatEmail: !repeatEmailIsValid ? LOCALE[appLocale].errorRepeatEmail : '',
            password: !passwordIsValid ? LOCALE[appLocale].errorPassword : '',
            conditionsAccepted: !isReserve && !conditionsAccepted ? LOCALE[appLocale].errorConditionsAccepted : '',
            zipCode: !zipCodeIsValid ? LOCALE[appLocale].errorZipCode : ''
        }


        this.setState({ errors });
        return nameIsValid && phoneIsValid && emailIsValid && repeatEmailIsValid && passwordIsValid && conditionsAccepted && zipCodeIsValid;
    }

    render() {
        const { orderType, configuration, customer, actions, clubCardId, appLocale, organizerType, receipt, organizer_merchantID, origin, totalPrice } = this.props;
        const { errors, someVar, numberplate, value, loading } = this.state;
       // console.log(orderType, 'order type')
       // console.log(organizer_merchantID, 'organizer_merchantID')
       // console.log(CONSTANTS)
        
     

        const nextButton = orderType === CONSTANTS.orderTypes.buy || orderType === CONSTANTS.orderTypes.buyReservation ?
            <input type="button" id="buy" className="next" onClick={this.navNext} value={LOCALE[appLocale].next} /> :
            <input type="button" id="reserve" className="next" onClick={this.navNext} value={LOCALE[appLocale].nextReserve} />;

        const gdprText = orderType !== CONSTANTS.orderTypes.reserve ? <div className="gdpr-text" dangerouslySetInnerHTML={{ __html: configuration.gdprTexts.buy }}></div> :
            <div className="gdpr-text" dangerouslySetInnerHTML={{ __html: configuration.gdprTexts.reserve }}></div>;

        return (

            <div className="CustomerContainer">
              
                <Customer appLocale={appLocale}
                    customer={customer}
                    actions={actions}
                    configuration={configuration}
                    errors={errors}
                    isReserve={orderType === CONSTANTS.orderTypes.reserve}
                    clubCardId={clubCardId}
                    organizerType={organizerType}  changeState={this.changeState}
                />

                {gdprText}

                <div className={configuration.bottomBarAllwaysVisible ? 'nav-buttons fixed' : 'nav-buttons'}>
                    {!receipt.transactionId ? <input type="button" className="back gray" onClick={this.navBack} value={LOCALE[appLocale].back} /> : null}
                    {nextButton}
                </div>
                {loading === true ? <div className='Error overlay fade-in'>
                    < h1 className='overlay-content' > Vent venligst</h1>
                </div>
                    : ''}
                
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
   //console.log(state)
    return {
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        orderType: state.order.orderType,
        configuration: state.organizer.configuration,
        customer: state.customer,
        transactionId: state.order.selected.transactionId,
        receipt: state.receipt,
        clubCardId: state.user.clubCardId,
        totalPrice: state.receipt.transactionId > 0 ? state.receipt.prices.total : state.order.totalPrice ,
        appLocale: state.app.locale,
        organizerType: state.organizer.configuration.type,
        iframeId: state.app.iframeId,
        organizer_merchantID: state.movielist.organizers[0].merchantID,
        origin: state.app.origin 
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, orderActions, customerActions, gaActions, { appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerContainer)