import {
    CLUBCARD_LOGIN_REQUEST,
    CLUBCARD_LOGIN_SUCCESS,
    CLUBCARD_LOGIN_FAILED,
    TRANSACTIONS_REQUEST,
    TRANSACTIONS_SUCCESS,
    TRANSACTIONS_FAILED,
    NEWSLETTERS_REQUEST,
    NEWSLETTERS_SUCCESS,
    NEWSLETTERS_FAILED
} from '../actions/user-actions'

export default function user(
    state = {
        isFetching: false,
        clubCardId: 0,
        transactions: [],
        newsletters: [],
        error: null
    }, action) {
    switch (action.type) {
        case CLUBCARD_LOGIN_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case CLUBCARD_LOGIN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastSucceeded: action.succeededAt,
                clubCardId: action.clubCardId,
                error: null
            }
        case CLUBCARD_LOGIN_FAILED:
            return {
                ...state,
                isFetching: false,
                lastFailed: action.failedAt,
                error: action.error
            }
        case TRANSACTIONS_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case TRANSACTIONS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastSucceeded: action.succeededAt,
                transactions: action.transactions,
                error: null
            }
        case TRANSACTIONS_FAILED:
            return {
                ...state,
                isFetching: false,
                lastFailed: action.failedAt,
                error: action.error
            }
        case NEWSLETTERS_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case NEWSLETTERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastSucceeded: action.succeededAt,
                newsletters: action.newsletters,
                customerEmail: action.customerEmail,
                error: null
            }
        case NEWSLETTERS_FAILED:
            return {
                ...state,
                isFetching: false,
                lastFailed: action.failedAt,
                error: action.error
            }
        default:
            return state
    }
}