import './newsletters.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import * as userActions from '../../../actions/user-actions'
import LOCALE from './NewslettersLocale'
import { getParams } from '../../../utilities/location-utilities'
import * as validators from '../../../utilities/validation-utilities'
import Error from '../../overlays/Error'
import Newsletter from './Newsletter'

class NewslettersContainer extends React.Component {
    constructor() {
        super();
        this.state = {
            organizerId: 0,
            customerEmail: '',
            invalidInput: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.newsletterChanged = this.newsletterChanged.bind(this);
    }

    componentDidMount() {
        const queryParameters = getParams(window.location.href);
        const organizerId = queryParameters.org;
        const customerEmail = queryParameters.email;
        this.setState({ organizerId, customerEmail });

        if (organizerId && customerEmail && validators.isValidEmail(customerEmail)) {
            this.props.actions.getNewsletters(organizerId, customerEmail);
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const { organizerId } = this.state;
        const customerEmail = this.email.value;

        if (validators.isValidEmail(customerEmail)) {
            this.setState({ customerEmail });
            this.props.actions.getNewsletters(organizerId, customerEmail);
        }
        else this.setState({ invalidInput: true });
    }

    newsletterChanged(id, checked) {
        const { actions } = this.props;
        actions.newsletterChanged(id, checked);
    }

    render() {
        const { customerEmail, invalidInput } = this.state;
        const { newsletters, appLocale, error } = this.props;

        return (
            <div className="NewslettersContainer">
                <h1>{LOCALE[appLocale].pageHeader}</h1>
                <p>{LOCALE[appLocale].info} <strong>{customerEmail}</strong></p>

                {!customerEmail ? <form onSubmit={this.handleSubmit}>
                    <label>{LOCALE[appLocale].email}</label><input className="textbox" type="text" defaultValue={customerEmail} ref={(email) => this.email = email} />
                    <input type="submit" className="next" value={LOCALE[appLocale].find} />
                    <div className="error">{invalidInput ? LOCALE[appLocale].invalidInput : ''}</div>

                </form> : null}

                {customerEmail ? _.map(newsletters, (newsletter) => {
                    return <Newsletter key={newsletter.id} newsletter={newsletter} newsletterChanged={this.newsletterChanged} appLocale={appLocale} />
                }) : null}
                {error ? <Error error={error} /> : null}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {
        newsletters: state.user.newsletters,
        appLocale: state.app.locale,
        error: state.user.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, userActions), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewslettersContainer)