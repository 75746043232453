import {
    RECEIPT_RESET,
    RECEIPT_SUCCESS,
    GET_RECEIPT_REQUEST,
    GET_RECEIPT_FAILED,
    DELETE_RESERVATION_REQUEST,
    DELETE_RESERVATION_SUCCESS,
    DELETE_RESERVATION_FAILED,
    ACTIVATE_RESERVATION_REQUEST,
    ACTIVATE_RESERVATION_SUCCESS,
    ACTIVATE_RESERVATION_FAILED
} from '../actions/receipt-actions'

const initialState = {
    isFetching: false,
    completed: false,
    activated: false,
    deleted: false,
    id: 0,
    transactionId: 0,
    orderId: 0,
    organizerId: 0,
    movieId: 0,
    movies: [],
    showtimeId: 0,
    customer: {},
    cinema: {},
    tickets: {},
    seats: [],
    seatTexts: [],
    hasNumberedSeats: true,
    prices: {},
    pickupInfo: '',
    error: null
}

export default function receipt(
    state = initialState, action) {
    switch (action.type) {
        case RECEIPT_RESET:
            return initialState;
        case RECEIPT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.suceededAt,
                completed: true,
                id: action.receipt.id,
                transactionId: action.receipt.transactionId,
                orderId: action.receipt.orderId,
                organizerId: action.receipt.organizerId,
                movieId: action.receipt.movieId,
                showtimeId: action.receipt.showtimeId,
                customer: action.receipt.customer,
                cinema: action.receipt.cinema,
                tickets: action.receipt.tickets,
                seats: action.receipt.seats,
                seatTexts: action.receipt.seatTexts,
                hasNumberedSeats: action.receipt.hasNumberedSeats,
                prices: action.receipt.prices,
                pickupInfo: action.receipt.pickupInfo,
                error: null,
            }
        case GET_RECEIPT_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case GET_RECEIPT_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case DELETE_RESERVATION_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case DELETE_RESERVATION_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.suceededAt,
                deleted: action.result,
                error: null
            }
        case DELETE_RESERVATION_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case ACTIVATE_RESERVATION_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case ACTIVATE_RESERVATION_SUCCESS:
            return {
                ...state,
                isFetching: false,
                activated: true,
                lastUpdated: action.suceededAt,
                error: null
            }
        case ACTIVATE_RESERVATION_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        default:
            return state
    }
}