import localStorageUtils from '../utilities/localstorage-utilities'
import {
    RESERVE_REQUEST,
    RESERVE_SUCCESS,
    RESERVE_FAILED,
    PRE_BOOK_REQUEST,
    PRE_BOOK_SUCCESS,
    PRE_BOOK_FAILED,
    BOOK_REQUEST,
    BOOK_SUCCESS,
    BOOK_FAILED,
    CUSTOMER_CHANGED,
    SMS_REMINDER_CHANGED,
    NEWSLETTERS_CHANGED,
    CREDIT_CARD_CHANGED,
    CONDITIONS_ACCEPTED_CHANGED,
    REMEMBER_ME_CHANGED,
    UPDATE_CUSTOMER_LOCAL_STORAGE
} from '../actions/customer-actions'

export default function customer(
    state = {
        isFetching: false,
        name: '',
        phone: '',
        email: '',
        repeatEmail: '',
        password: '',
        rememberMe: false,
        creditCard: '',
        conditionsAccepted: false,
        dibs: { parameters: {} },       
        checkoutComplete: false,
        newsletters: [],
        zipCode: '',
        note:'',
        error: null
        }, action) {
    switch (action.type) {
        case RESERVE_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case RESERVE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.succeededAt,
                error: null
            }
        case RESERVE_FAILED:
            return {
                ...state,
                isFetching: false,
                lastFailed: action.failedAt,
                error: action.error
            }
        case PRE_BOOK_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case PRE_BOOK_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.succeededAt,
                dibs: action.dibs,
                error: null
            }
        case PRE_BOOK_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        
        case BOOK_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case BOOK_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.succeededAt,
                checkoutComplete: true,
                error: null
            }
        case BOOK_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case CUSTOMER_CHANGED: {
            return {
                ...state,
                name: action.customer.name,
                phone: action.customer.phone,
                email: action.customer.email,
                repeatEmail: action.customer.repeatEmail,
                password: action.customer.password,
                zipCode: action.customer.zipCode,
                note: action.customer.note
            }
        }
        case SMS_REMINDER_CHANGED:
            return {
                ...state,
                smsReminder: action.smsReminder
            }
        case NEWSLETTERS_CHANGED:
            return {
                ...state,
                newsletters: action.newsletters
            }
        case CREDIT_CARD_CHANGED:
            return {
                ...state,
                creditCard: action.creditCard
            }
        case CONDITIONS_ACCEPTED_CHANGED:
            return {
                ...state,
                conditionsAccepted: action.conditionsAccepted
            }
        case REMEMBER_ME_CHANGED:
            if (action.rememberMe) setCustomerInLocalStorage(state);
            else localStorageUtils.removeItem('customer');
            return {
                ...state,
                rememberMe: action.rememberMe
            }
        case UPDATE_CUSTOMER_LOCAL_STORAGE:
            setCustomerInLocalStorage(state);
        default:
            return state
    }
}

function setCustomerInLocalStorage(state) {
    localStorageUtils.setItem('customer', {
        name: state.name,
        phone: state.phone,
        email: state.email,
        repeatEmail: state.repeatEmail,
        password: state.password,
        newsletters: [],
        smsReminder: state.smsReminder,
        conditionsAccepted: state.conditionsAccepted,
        rememberMe: true,
        dibs: { parameters: {} },
        zipCode: state.zipCode,
        note: state.note
    });
}