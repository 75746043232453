import React, { Fragment } from 'react'
import LOCALE from '../OrderLocale'

const SeatColors = ({ showReservedAsRed, appLocale, seatSelectionInfo }) => {
    const selected = _.find(seatSelectionInfo, (text) => { return text.id == 'Seat_Info_Selected' });
    const free = _.find(seatSelectionInfo, (text) => { return text.id == 'Seat_Info_Free' });
    const reserved = _.find(seatSelectionInfo, (text) => { return text.id == 'Seat_Info_Reserved' });
    const sold = _.find(seatSelectionInfo, (text) => { return text.id == 'Seat_Info_Sold' });

    return <div className="SeatColors">
        {selected ?
            selected[appLocale] ? <Fragment><span className="blue"></span> {selected[appLocale]}</Fragment> : ''
            : <Fragment><span className="blue"></span> {LOCALE[appLocale].seatBlue}</Fragment>}

        {free ?
            free[appLocale] ? <Fragment><span className="green"></span> {free[appLocale]}</Fragment> : ''
            : <Fragment><span className="green"></span> {LOCALE[appLocale].seatGreen}</Fragment>}

        {!showReservedAsRed ?
            reserved ?
                reserved[appLocale] ? <Fragment><span className="white"></span> reserved[appLocale]</Fragment> : ''
                : <Fragment><span className="white"></span> {LOCALE[appLocale].seatWhite}</Fragment>
            : null}

        {sold ?
            sold[appLocale] ? <Fragment><span className="red"></span> {sold[appLocale]}</Fragment> : ''
            : <Fragment><span className="red"></span> {LOCALE[appLocale].seatRed}</Fragment>}
    </div>
}

export default SeatColors