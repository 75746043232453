import React from 'react'
import _ from 'lodash'
import LOCALE from '../MovieListLocale'
import ShowtimeSchedule from '../../showtime/ShowtimeSchedule'
import Poster from '../../movie/Poster'

export default function WeekProgramListItem({ movie, appLocale, showAsBase, filterIds, showExpired, showtimeClicked, movieClicked, currentStartDate, moreInfoText }) {
    
    const onMovieClick = (e) => {
        e.preventDefault();
        const movieId = e.currentTarget.dataset.movieId;
       
        if (e.target.tagName == 'IMG' || e.target.tagName == 'H1' || e.target.tagName == 'INPUT' || _.includes(e.target.classList, 'poster-banner'))
            movieClicked(movieId);
    }

    const poster = movie.posters ?
        <div className="poster-container" data-movie-id={movie.id} onClick={onMovieClick}>
            <Poster movie={movie} /></div> : null;

    const movieNameLocale = movie.names ? (movie.names[appLocale] ? movie.names[appLocale] : movie.names['da']) : movie.name;
    const infoText = moreInfoText ? moreInfoText[appLocale] : LOCALE[appLocale].movieInfo;
   // console.log(movie, 'weekpro list')
    return (
        <div className="MovieListItem">
            {poster}
            
            <div className="movie-info">
                <h1 data-movie-id={movie.id} onClick={onMovieClick}>{showAsBase ? movie.name : movieNameLocale}</h1>

                <ShowtimeSchedule movie={movie}
                    showtimeClicked={showtimeClicked}
                    appLocale={appLocale}
                    showExpired={showExpired}
                    filterIds={filterIds}
                    currentStartDate={currentStartDate}
                />

                <input type="button" className="movie-info-btn" data-movie-id={movie.id} onClick={onMovieClick} value={infoText} />
            </div>
        </div>
    )
}