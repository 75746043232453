import React from 'react'
import { hasDecimals, withTwoDecimals } from '../../../utilities/validation-utilities'
import PriceInfo from './PriceInfo'
import SelectedTickets from './SelectedTickets'
import LOCALE from './SummaryLocale'

export default class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showPriceInfo: props.summaryExpanded };

        this.togglePriceInfo = this.togglePriceInfo.bind(this);
    }

    togglePriceInfo() {
        const { showPriceInfo } = this.state;
        if (showPriceInfo === false) this.setState({ showPriceInfo: true });
        else this.setState({ showPriceInfo: false });

        //iframe resize
        if (window.parent != window) {
            const { actions } = this.props;
            setTimeout(() => { actions.appHeightChanged() }, 500); //Workaround for the duration it takes the DOM to rerender with the expanded element so we get the right height...
        }
    }

    render() {
        const { tickets, ticketPrice, extraPurchasePrice, totalFees, totalPrice, showFees, includeExtras, selected, bottomBarAllwaysVisible, appLocale } = this.props;
        const { showPriceInfo } = this.state;
        const icon = showPriceInfo ? <span className="icon-chevrons-up"></span> : <span className="icon-chevrons-down"></span>;

        const showWithDecimals = hasDecimals(ticketPrice) || hasDecimals(totalFees) || hasDecimals(extraPurchasePrice) || hasDecimals(selected.giftCardAmount) || hasDecimals(totalPrice);
        const totalPriceAsText = showWithDecimals ? withTwoDecimals(totalPrice) : totalPrice;

        return (
            <div className={bottomBarAllwaysVisible ? 'Summary fixed-nav' : 'Summary'}>
                <h3 onClick={this.togglePriceInfo}>{icon} {LOCALE[appLocale].yourOrder}</h3>
                <div className="summary-info">
                    <div className="order">
                        <SelectedTickets tickets={tickets} />
                        {selected.extraPurchases.length > 0 ? `, ${LOCALE[appLocale].extraPurchase}` : ''}
                    </div>
                    <div className="total-price">{LOCALE[appLocale].totalPrice}: {totalPriceAsText} {LOCALE[appLocale].currency}</div>
                </div>
                <PriceInfo onClick={this.togglePriceInfo}
                    appLocale={appLocale}
                    showPriceInfo={showPriceInfo}
                    ticketPrice={ticketPrice}
                    extraPurchasePrice={extraPurchasePrice}
                    totalFees={totalFees}
                    totalPrice={totalPrice}
                    showFees={showFees}
                    includeExtras={includeExtras}
                    selected={selected}
                    showWithDecimals={showWithDecimals}
                />
            </div>
        )
    }
}