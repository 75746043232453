import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import LOCALE from '../datepicker/DatePickerLocale'
import Showtime from './Showtime'

export default function ShowtimeScheduleWeek({ showtimesByDate, take, appLocale, showExpired, showtimeClicked, movie, currentStartDate }) {
 //   console.log(showtimesByDate, 'showtimes by date')

    const generateShowtimesOnDate = (showtimesOnDate) => {
        return _.map(showtimesOnDate, (showtime) => {
            const isInFuture = moment(showtime.dateTime) > moment();
           
            if (showtime.showInfo.enabled === true && (showExpired || isInFuture)) {
                return <Showtime key={showtime.id}
                    showtime={showtime}
                    text={moment(showtime.dateTime).format('LT')}
                    showtimeClicked={showtimeClicked}
                    showExpired={showExpired}
                />;
            }
        });
    }

    const generateWeekSchedule = (startDate) => {
        startDate = startDate.clone(); // we don't want to mutate it
        const weekScheduleItems = [];

        for (var i = 0; i < take; i++) {
            const date = moment(startDate);
            const formattedDate = _.capitalize(date.format('ddd D/M'))
            const today = date.isSame(moment(), 'day') ? LOCALE[appLocale].today : ''
            const tomorrow = date.isSame(moment().add(1, 'day'), 'day') ? LOCALE[appLocale].tomorrow : ''
            //const showsPerDate = _.find(showtimesByDate, (shows) => { return shows.id === movie.id })
         //   console.log(showsPerDate, 'shows per dATE')
            // const showtimesOnDate = showsPerDate[startDate.format('YYYY-MM-DD')];
            const showtimesOnDate = showtimesByDate[startDate.format('YYYY-MM-DD')]

            //console.log(showtimesByDate)
           // console.log(showtimesOnDate, 'showtimes on date')
            weekScheduleItems.push(<div key={startDate} className="showtimes-on-date">
                <h4 className="date">{today || tomorrow || formattedDate}</h4>
                {showtimesOnDate ? generateShowtimesOnDate(showtimesOnDate) : null}
            </div>)

            startDate.add(1, 'day');
        }

        return weekScheduleItems;
    }
   // console.log(moment().week())
    return (
        <div className="ShowtimeScheduleWeek">
            {generateWeekSchedule(currentStartDate)}
        </div>
    )
}