import React, { useEffect, useState } from 'react'
import _, { last } from 'lodash'
import LOCALE from '../MovieListLocale'
import ShowtimeSchedule2 from '../../showtime/ShowtimeSchedule2'
import ShowtimeScheduleWeek from '../../showtime/ShowtimeScheduleWeek'
import ShowtimeTypeList from '../../showtime/ShowtimeTypeList';
import Showtime2 from '../../showtime/Showtime2'
import moment from 'moment'
import ShowtimeType from '../../showtime/ShowtimeType'
import { getParams } from '../../../utilities/location-utilities'
import './weekprogram2.scss';
import '../../showtime/showtime.scss'
import Poster from '../../movie/Poster'
import { ROUTES } from '../../navigation/route-constants'


export default function WeekProgramListItemExtended({
    movie, appLocale, showAsBase, filterIds, showExpired, showtimeClicked, movieClicked,
    moreInfoText, take, weekNumber, nextWeek, firstWeek, lastWeek, previousWeek, setDiffer2, nextValidWeek, currentStartDate, lastShowtimeDay
}) {
      
   
  

    function onMovieClick(e) {
        e.preventDefault();
        const movieId = e.currentTarget.dataset.movieId || e.target.id;
        //if (e.target.tagName == 'IMG' || e.target.tagName == 'H1' || e.target.tagName == 'INPUT' || _.includes(e.target.classList, 'poster-banner'))
            movieClicked(movieId);

    }

    const poster = movie.posters ?
        <div className="poster-container" data-movie-id={movie.id} onClick={onMovieClick} >
            <Poster movie={movie} /></div> : null;

    const premierDate = movie.validShowtimes[0].dateTime.slice(0,10)

    
    return (
        <>

            <div className='showing_soon' >
                {poster}

                <div className='showing_desc' >
                    <p className='showing_title'>{movie.name}</p>
                    <p className='showing_prem'>Premiere: {moment(premierDate).format('DD.MM.YYYY')}</p>
                    <button className="movieinfo-btn showing_btn Showtime" data-movie-id={movie.id} onClick={onMovieClick}
                        value='Billetter ' style={{ fontSize: '15px', fontFamily: 'oswald', fontWeight: 'normal', border: 'none', textAlign:'center' }} >
                        Billetter  </button>
                
                </div>
            </div>
           
           

            </>
    )
}