import React, { useEffect } from 'react'
import _ from 'lodash'
import * as validators from '../../../utilities/validation-utilities'
import LOCALE from './CustomerLocale'
import { ORGANIZER_TYPES } from '../../../api/api-constants';
import { useParams } from "react-router-dom";


export default class Customer extends React.Component {
    

    constructor(props) {
        super(props);
        const customer = props.customer;
        this.state = {
            isValid: {
                name: customer.name !== '',
                phone: validators.isValidPhone(customer.phone, props.configuration.phoneMinLength),
                email: validators.isValidEmail(customer.email),
                repeatEmail: validators.isValidEmail(customer.repeatEmail) && customer.email === customer.repeatEmail,
                password: validators.isValidPassword(customer.password),
                conditionsAccepted: customer.conditionsAccepted === true,
                zipCode: customer.zipCode !== ''
            },
            name: customer.name,
            phone:  customer.phone,
            email:   customer.email,
            repeatEmail: customer.repeatEmail,
            password:   customer.password,
            smsReminder: customer.smsReminder == null ? props.configuration.smsReminder.checkedByDefault : customer.smsReminder,
            rememberMe: customer.rememberMe,
            conditionsAccepted: customer.conditionsAccepted,
            zipCode: customer.zipCode,
            note: customer.note

        };
       
        // Need this in customer store for reserve and book
        if (customer.smsReminder == null) props.actions.smsReminderChanged(props.configuration.smsReminder.checkedByDefault);
        if (customer.newsletters.length == 0 && props.configuration.newsletters.length > 0) props.actions.newsletterChanged(props.configuration.newsletters);
        //--

        this.nameChanged = this.nameChanged.bind(this);
        this.phoneChanged = this.phoneChanged.bind(this);
        this.emailChanged = this.emailChanged.bind(this);
        this.repeatEmailChanged = this.repeatEmailChanged.bind(this);
        this.passwordChanged = this.passwordChanged.bind(this);
        this.newsletterChanged = this.newsletterChanged.bind(this);
        this.smsReminderChanged = this.smsReminderChanged.bind(this);
        this.rememberMeChanged = this.rememberMeChanged.bind(this);
        this.customerChanged = this.customerChanged.bind(this);
        this.conditionsAcceptedChanged = this.conditionsAcceptedChanged.bind(this);
        this.zipCodeChanged = this.zipCodeChanged.bind(this);
        this.noteChanged = this.noteChanged.bind(this);
      
        this.handleChange.bind(this);
    }
   
    componentDidMount() {
        const { clubCardId, customer, configuration } = this.props;
       // console.log(sessionStorage.getItem('code'))
        if (sessionStorage.getItem('name')) {
        this.setState({
            isValid: {
                name: sessionStorage.getItem('name') !== null,
                phone: validators.isValidPhone(sessionStorage.getItem('phone') ? sessionStorage.getItem('phone') : customer.phone, configuration.phoneMinLength),
                email: validators.isValidEmail(sessionStorage.getItem('email') ? sessionStorage.getItem('email') : customer.email),
                password: validators.isValidPassword((sessionStorage.getItem('code') !== null) ? sessionStorage.getItem('code') : customer.password)

            },


            name: sessionStorage.getItem('name')   !== null  ? sessionStorage.getItem('name') : customer.name,
            phone: sessionStorage.getItem('phone') !== null  ? sessionStorage.getItem('phone') : customer.phone,
            email: sessionStorage.getItem('email') !== null  ? sessionStorage.getItem('email') : customer.email,
            password: sessionStorage.getItem('code') !== null  ? sessionStorage.getItem('code') : customer.password
        })
        
            this.customerChanged();
         }
    }

   
    componentDidUpdate(prevProps) {
        const { clubCardId, customer, configuration } = this.props;
        if (clubCardId && prevProps.customer != customer) {
            
            this.setState({
                isValid: {
                    name: customer.name !== ''  ,
                    phone: validators.isValidPhone(customer.phone, configuration.phoneMinLength),
                    email: validators.isValidEmail(customer.email),
                    repeatEmail: validators.isValidEmail(customer.repeatEmail) && customer.email === customer.repeatEmail,
                    conditionsAccepted: customer.conditionsAccepted === true,
                    zipCode: customer.zipCode !== ''
                },
                name : customer.name,
                phone: customer.phone,
                email:  customer.email,
                repeatEmail: customer.repeatEmail,
                conditionsAccepted: customer.conditionsAccepted,
                zipCode: customer.zipCode,
                note: customer.note
            });
        }
      
    }

    customerChanged() {
        const customer = this.state;
        this.props.actions.customerChanged({
            name: customer.name || sessionStorage.getItem('name'),
            phone: customer.phone || sessionStorage.getItem('phone'),
            email: customer.email || sessionStorage.getItem('email'),
            repeatEmail: customer.repeatEmail || sessionStorage.getItem('email'),
            password: customer.password || sessionStorage.getItem('code'),
            zipCode: customer.zipCode,
            note: customer.note
        });
    }

    nameChanged(e) {
        
        const name = e.target.value ;

        const isValid = name !== '' ;
        this.setState({ name, isValid: { ...this.state.isValid, name: isValid } });
    }

    phoneChanged(e) {
        const { configuration } = this.props;
      
        let phone = e.target.value;
        phone = this.numbersOnly(phone);

        const isValid = validators.isValidPhone(phone, configuration.phoneMinLength);
        this.setState({ phone, isValid: { ...this.state.isValid, phone: isValid } });
    }

    numbersOnly(input) {
        const nonNumericRegex = /[^0-9]+/g;
        input = input.replace(nonNumericRegex, '');
        return input;
    }

    emailChanged(e) {
        const email = e.target.value;
        const isValid = validators.isValidEmail(email);
        const repeatEmail = this.state.repeatEmail;
        const repeatEmailIsValid = validators.isValidEmail(repeatEmail) && email === repeatEmail;
        this.setState({ email, isValid: { ...this.state.isValid, email: isValid, repeatEmail: repeatEmailIsValid } });
    }

    repeatEmailChanged(e) {
        const repeatEmail = e.target.value;
        const isValid = validators.isValidEmail(repeatEmail) && this.state.email === repeatEmail;
        this.setState({ repeatEmail, isValid: { ...this.state.isValid, repeatEmail: isValid } });
    }

    passwordChanged(e) {
        const password = e.target.value;
        const isValid = validators.isValidPassword(password);
        this.setState({ password, isValid: { ...this.state.isValid, password: isValid } });
    }

    newsletterChanged(e) {
        const id = e.target.id;
        const checked = e.target.checked;
        const { customer, configuration, actions } = this.props;
        const newsletters = customer.newsletters.length > 0 ? customer.newsletters : configuration.newsletters;
        const letter = _.find(newsletters, (letter) => { return letter.id == id });
        letter.checked = checked;
        actions.newsletterChanged(newsletters);
    }

    smsReminderChanged(e) {
        const checked = e.target.checked;
        this.setState({ smsReminder: checked });
        this.props.actions.smsReminderChanged(checked);
    }

    rememberMeChanged(e) {
        const checked = e.target.checked;
        this.setState({ rememberMe: checked });
        this.props.actions.rememberMeChanged(checked);
    }

    conditionsAcceptedChanged(e) {
        const checked = e.target.checked;
        const isValid = checked === true;
        this.setState({ conditionsAccepted: checked, isValid: { ...this.state.isValid, conditionsAccepted: isValid } });
        this.props.actions.conditionsAcceptedChanged(checked);
    }

    zipCodeChanged(e) {
        let zipCode = e.target.value;
        zipCode = this.numbersOnly(zipCode);
        const isValid = zipCode !== '';
        this.setState({ zipCode, isValid: { ...this.state.isValid, zipCode: isValid }  });
    }

    noteChanged(e) {
        const note = e.target.value;
        this.setState({ note });
    }

    generateNewsletters(newsletters) {
        const { customer } = this.props;
        return _.map(newsletters, (newsletter) => {
            if (newsletter.enabled) {
                const remembered = _.find(customer.newsletters, (letter) => { return newsletter.id == letter.id });
                return <label key={newsletter.id}><input type="checkbox" id={newsletter.id} checked={newsletter.checked} onChange={this.newsletterChanged} /> {newsletter.name}</label>
            }
        });
    }
   
    generatePasswordInput() {
       
        const codeis = sessionStorage.getItem('code')
        const { configuration, isReserve, errors, clubCardId, appLocale } = this.props;
        const { password, isValid } = this.state;

        return configuration.customerPasswordEnabled && !clubCardId ? <div className="textbox">
            <label>{LOCALE[appLocale].password}</label><input type="password" value={password} onChange={this.passwordChanged} disabled={codeis ? "disabled" : ''} onBlur={this.customerChanged} />
            <span className={isValid.password ? 'required valid icon-check' : 'required icon-required'}></span>
            <div className="helper-text">{isReserve ? LOCALE[appLocale].passwordReserveText : LOCALE[appLocale].passwordBuyText}</div>
            
            <div className="error">{!isValid.password ? errors.password : ''}</div>
         

            
        </div> : null;
    }

    generateCircusInputs() {
        const { appLocale, errors } = this.props;
        const { isValid, zipCode, note } = this.state;

        return <div>
            <div className="textbox">
                <label>{LOCALE[appLocale].zipCode}</label><input type="text" value={zipCode} onChange={this.zipCodeChanged} onBlur={this.customerChanged} />
                <span className={isValid.zipCode ? 'required valid icon-check' : 'required icon-required'}></span>
                <div className="error">{!isValid.zipCode ? errors.zipCode : ''}</div>
            </div>
            <div className="textbox">
                <label>{LOCALE[appLocale].note}</label><input type="text" value={note} onChange={this.noteChanged} onBlur={this.customerChanged} />
            </div>
        </div>
    }

    generateMuseumInputs() {
        const { appLocale, errors } = this.props;
        const { isValid, zipCode, note } = this.state;

        return <div>
                  <div className="textbox">
                     <label>{LOCALE[appLocale].zipCode}</label><input type="text" value={zipCode} onChange={this.zipCodeChanged} onBlur={this.customerChanged} />
                     <span className={isValid.zipCode ? 'required valid icon-check' : 'required icon-required'}></span>
                     <div className="error">{!isValid.zipCode ? errors.zipCode : ''}</div>
                  </div>
                   <div className="textbox" hidden>
                       <label>{LOCALE[appLocale].note}</label><input type="text" value={note} onChange={this.noteChanged} onBlur={this.customerChanged} />
                   </div>
                </div>
    }
    handleChange = (e) => {
        let value = e.target.value
        sessionStorage.setItem('license', e.target.value.toString().toUpperCase())
       
        this.props.changeState(value.toString());
    };
  

    render() {
        const { configuration, isReserve, errors, clubCardId, appLocale, organizerType} = this.props;
        const { name, phone, email, repeatEmail, isValid, smsReminder, rememberMe, conditionsAccepted } = this.state;
      
        const phoneis = sessionStorage.getItem('phone');
        const nameis = sessionStorage.getItem('name');
        const emailis = sessionStorage.getItem('email');
        const texts = configuration.texts
        const parkingText = texts && texts.filter(text => { return text.id === 'Parking_Text' })
       
        const parking_text = parkingText.length > 0 ? appLocale == 'en' ? parkingText[0].en : parkingText[0].da : ''
       
        const passwordTextBox = this.generatePasswordInput();
        const newsletterCheckboxes = configuration.newsletters.length > 0 && !clubCardId ? this.generateNewsletters(configuration.newsletters) : null;
        const smsReminderCheckbox = configuration.smsReminder.enabled && isReserve ? <label id="sms"><input type="checkbox" checked={smsReminder} onChange={this.smsReminderChanged} /> {LOCALE[appLocale].smsReminder} </label> : null;
        const newsletterGdpr = newsletterCheckboxes && configuration.gdprTexts.newsletters && !clubCardId ? <div className="gdpr-text" dangerouslySetInnerHTML={{ __html: configuration.gdprTexts.newsletters }}></div> : null;
        const rememberMeCheckbox = !clubCardId ? <label id="remember-me"><input type="checkbox" checked={rememberMe} onChange={this.rememberMeChanged} /> {LOCALE[appLocale].rememberMe} </label> : null;

        const circusTextBoxes = organizerType == ORGANIZER_TYPES.CIRCUS ? this.generateCircusInputs() : (organizerType == ORGANIZER_TYPES.MUSEUM ? this.generateMuseumInputs() : null);

      
        return (
            <div className="Customer content-container" >
                <h1>{LOCALE[appLocale].customerInfo}</h1>
               
               
                <div className="textbox">
                    <label>{LOCALE[appLocale].name}</label>
                    <input type="text" style={{ color: nameis ? 'darkgrey' : '' }} value={name} disabled={nameis ? "disabled" : ''} onChange={this.nameChanged} onBlur={this.customerChanged} />
                    <span className={isValid.name ? 'required valid icon-check' : 'required icon-required'}></span>
                    <div className="error">{!isValid.name ? errors.name : ''}</div>
                    

                    
                </div>
                <div className="textbox">
                    <label>{LOCALE[appLocale].phone}</label><input type="tel" style={{ color: phoneis ? 'darkgrey' : '' }} disabled={phoneis ? "disabled" : ''} value={ phone} onChange={this.phoneChanged} onBlur={this.customerChanged} />
                    <span className={isValid.phone  ? 'required valid  icon-check' : 'required icon-required'}></span>  
                    <div className="error">{!isValid.phone ? errors.phone : ''}</div>
                  
                </div>
                <div className="textbox">
                    <label>{LOCALE[appLocale].email}</label><input type="email" style={{ color: emailis ? 'darkgrey' : '' }} disabled={emailis ? "disabled" : ''} value={ email} onChange={this.emailChanged} onBlur={this.customerChanged} />
                    <span className={isValid.email  ? 'required valid  icon-check' : 'required icon-required'}></span>
                        <div className="error">{!isValid.email ? errors.email : ''}</div>
                    
                </div>
                {configuration.repeatEmail ? <div className="textbox" >
                    <label>{LOCALE[appLocale].repeatEmail}</label> <input type="email" value={repeatEmail} onChange={this.repeatEmailChanged} onBlur={this.customerChanged} />
                    <span className={isValid.repeatEmail ? 'required valid icon-check' : 'required icon-required'}></span>
                    
                    <div className="error">{!isValid.repeatEmail ? errors.repeatEmail : ''}</div>
                </div> : null}
                {passwordTextBox}
                {circusTextBoxes}
              
                <div className="checkboxes">
                    <div>
                        {rememberMeCheckbox}
                        {smsReminderCheckbox}
                        {newsletterCheckboxes}
                    </div>
                    {newsletterGdpr}
                    {parkingText.length > 0 ? <section className='parkingContainer'>
                        <div style={{}}>
                            <p style={{ width: '120px' }}>{LOCALE[appLocale].parking}: </p>
                            <p dangerouslySetInnerHTML={{ __html: parking_text }}></p>
                        </div>
                        <div className='inputContainer'>

                            <div className='input_border'>
                                <div className='logos'>
                                    <svg style={{ marginLeft: '8px', marginTop: '-3px' }} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.90272 0L6.11197 0.644009H6.78913L6.2413 1.04203L6.45055 1.68604L5.90272 1.28802L5.3549 1.68604L5.56415 1.04203L5.01632 0.644009H5.69347L5.90272 0Z" fill="#FBE84D" />
                                        <path d="M8.54335 0.621338L8.7526 1.26535H9.42975L8.88192 1.66337L9.09117 2.30738L8.54335 1.90936L7.99552 2.30738L8.20477 1.66337L7.65694 1.26535H8.3341L8.54335 0.621338Z" fill="#FBE84D" />
                                        <path d="M8.54335 9.16492L8.7526 9.80893H9.42975L8.88192 10.2069L9.09117 10.851L8.54335 10.4529L7.99552 10.851L8.20477 10.2069L7.65694 9.80893H8.3341L8.54335 9.16492Z" fill="#FBE84D" />
                                        <path d="M3.2621 0.621338L3.47135 1.26535H4.1485L3.60067 1.66337L3.80992 2.30738L3.2621 1.90936L2.71427 2.30738L2.92352 1.66337L2.37569 1.26535H3.05285L3.2621 0.621338Z" fill="#FBE84D" />
                                        <path d="M3.2621 9.16492L3.47135 9.80893H4.1485L3.60067 10.2069L3.80992 10.851L3.2621 10.4529L2.71427 10.851L2.92352 10.2069L2.37569 9.80893H3.05285L3.2621 9.16492Z" fill="#FBE84D" />
                                        <path d="M10.4066 2.48535L10.6159 3.12936H11.293L10.7452 3.52738L10.9545 4.17139L10.4066 3.77337L9.8588 4.17139L10.0681 3.52738L9.52023 3.12936H10.1974L10.4066 2.48535Z" fill="#FBE84D" />
                                        <path d="M1.39882 2.48535L1.60807 3.12936H2.28522L1.73739 3.52738L1.94664 4.17139L1.39882 3.77337L0.850989 4.17139L1.06024 3.52738L0.512413 3.12936H1.18956L1.39882 2.48535Z" fill="#FBE84D" />
                                        <path d="M10.2523 7.30078L10.4616 7.94479H11.1387L10.5909 8.34281L10.8002 8.98682L10.2523 8.5888L9.7045 8.98682L9.91376 8.34281L9.36593 7.94479H10.0431L10.2523 7.30078Z" fill="#FBE84D" />
                                        <path d="M1.55311 7.30078L1.76236 7.94479H2.43952L1.89169 8.34281L2.10094 8.98682L1.55311 8.5888L1.00529 8.98682L1.21454 8.34281L0.66671 7.94479H1.34386L1.55311 7.30078Z" fill="#FBE84D" />
                                        <path d="M5.90272 10.0968L6.11197 10.7408H6.78913L6.2413 11.1388L6.45055 11.7828L5.90272 11.3848L5.3549 11.7828L5.56415 11.1388L5.01632 10.7408H5.69347L5.90272 10.0968Z" fill="#FBE84D" />
                                        <path d="M0.932019 4.97083L1.14127 5.61483H1.81842L1.2706 6.01285L1.47985 6.65686L0.932019 6.25884L0.384192 6.65686L0.593443 6.01285L0.0456163 5.61483H0.722768L0.932019 4.97083Z" fill="#FBE84D" />
                                        <path d="M10.8734 4.97083L11.0827 5.61483H11.7598L11.212 6.01285L11.4213 6.65686L10.8734 6.25884L10.3256 6.65686L10.5348 6.01285L9.98702 5.61483H10.6642L10.8734 4.97083Z" fill="#FBE84D" />
                                    </svg>

                                    <span className='input_dk'>
                                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.385318 0.62307H3.21122C4.61347 0.62307 5.60361 0.944195 6.18164 1.58645C6.77037 2.2287 7.06473 3.22954 7.06473 4.58897V9.37375C7.06473 10.8081 6.77572 11.8785 6.19769 12.585C5.63037 13.2808 4.68305 13.6287 3.35573 13.6287H0.385318V0.62307ZM3.22728 12.3281C3.85883 12.3281 4.31376 12.205 4.59206 11.9588C4.88108 11.7126 5.0577 11.3808 5.12192 10.9633C5.19685 10.5352 5.23432 9.8929 5.23432 9.03656V4.81376C5.23432 4.07517 5.18615 3.5132 5.08981 3.12785C5.00418 2.7318 4.81685 2.44278 4.52784 2.26081C4.23883 2.06814 3.78925 1.9718 3.17911 1.9718H2.19968V12.3281H3.22728ZM8.69194 0.62307H10.5063V6.75657L13.4928 0.62307H15.1787L12.6097 6.35516L15.5961 13.6287H13.8299L11.3412 7.52727L10.5063 9.03656V13.6287H8.69194V0.62307Z" fill="white" />
                                        </svg>
                                    </span>
                                </div>
                                <input type='text' id='numberPlate' style={{ textTransform: 'uppercase' }} onChange={this.handleChange}  />
                            </div>

                        </div>

                    </section> : ''}
                    {!isReserve ? <div className="conditions">
                        <label>
                            <input type="checkbox" defaultChecked={conditionsAccepted} onChange={this.conditionsAcceptedChanged} />
                            <span dangerouslySetInnerHTML={{ __html: LOCALE[appLocale].acceptConditions }}></span>
                        </label>
                      
                        <div className="error">{!isValid.conditionsAccepted ? errors.conditionsAccepted : ''}</div>
                    </div> : null}
                </div>
            </div>
        )
    }
}