import React from 'react'
import _ from 'lodash'
import LOCALE from '../../MovieListLocale'
import { setItemClasses } from '../../shared'
import Multibio_FutureMoviesListItem from './Multibio_FutureMovieListItem'

export default class Multibio_FutureMoviesList extends React.Component {

    generateMovieListItems(movies) {
        const { organizers, posterBanners, appLocale, movieClicked, columns, appHeightChanged, organizerIds } = this.props;
        let movieItems = [];
        let counter = 0;

        _.forEach(movies, (movie) => {
            let rowClasses = setItemClasses(counter, columns);

            movieItems.push(<Multibio_FutureMoviesListItem key={movie.id}
                appLocale={appLocale}
                rowClasses={rowClasses}
                columns={columns}
                organizerIds={organizerIds}
                organizers={organizers}
                movie={movie}
                posterBanners={posterBanners}
                movieClicked={movieClicked}
                appHeightChanged={appHeightChanged}
            />);

            counter++;
        });

        return movieItems;
    }

    render() {
        const { movies, appLocale, organizers } = this.props;
        const movieListItems = this.generateMovieListItems(movies);

        return (
            <div className={organizers.length > 1 ? 'MovieList multibio' : 'MovieList'}>
                {movieListItems.length > 0 ? movieListItems : <h3 className="no-events">{LOCALE[appLocale].noMoviesText}</h3>}
            </div>)
    }
}