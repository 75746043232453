import React from 'react'
import _ from 'lodash'
import ExtraPurchaseItem from './ExtraPurchaseItem'

export default class ExtraPurchaseList extends React.Component {

    constructor(props) {
        super(props);
        this.state = { selectedPoints: props.selectedClubCardPoints || 0 }
        this.canIncrementTotalPoints = this.canIncrementTotalPoints.bind(this);
    }

    canIncrementTotalPoints(incrementBy) {
        const { selectedPoints } = this.state;
        const { clubCard } = this.props;
        const newTotal = selectedPoints + incrementBy;
        const canIncrement = newTotal <= clubCard.points;
        if (canIncrement) this.setState({ selectedPoints: newTotal });
        return canIncrement;
    }

    generateExtraItems() {
        const { isFetching, availableExtraPurchases, selectedExtraPurchases, quantityChanged, clubCard, appLocale } = this.props;
        return _.map(availableExtraPurchases, (extra, i) => {
            const selectedExtra = _.find(selectedExtraPurchases, (selected) => { return selected.id === extra.id; });
            return <ExtraPurchaseItem key={i} appLocale={appLocale}
                extra={extra}
                quantity={selectedExtra ? selectedExtra.quantity : 0}
                quantityChanged={quantityChanged}
                isFetching={isFetching}
                clubCard={clubCard}
                canIncrementTotalPoints={this.canIncrementTotalPoints}
            />
        }, this);
    }

    render() {
        const extraPurchases = this.generateExtraItems();

        return (
            <div className="ExtraPurchaseList">                
                {extraPurchases}
            </div>
        )
    }
}