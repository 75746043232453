import React from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import Commercial from './CommercialListItem'

class CommercialsList extends React.Component {
    constructor() {
        super();
        this.commercialClicked = this.commercialClicked.bind(this);
    }

    commercialClicked(navigateTo) {
        const { organizerId, history } = this.props;
        if (navigateTo.startsWith('http')) window.open(navigateTo, '_blank');
        else history.push(`${navigateTo}?org=${organizerId}`);
    }

    generateCommercialItems() {
        const { commercials } = this.props;
        return _.map(commercials, function (commercial, i) {
            return <Commercial key={i}
                commercial={commercial}
                commercialClicked={this.commercialClicked}
            />
        }.bind(this));
    }

    render() {
        const items = this.props.commercials ? this.generateCommercialItems() : null;

        return (
            <div className="CommercialsList">
                {items}
            </div>
        )
    }
}

export default withRouter(CommercialsList)