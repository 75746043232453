import OrderFlowLocale from '../OrderFlowLocale'

export default {
    da: {
        ...OrderFlowLocale.da,
        bioKlubDk: 'Biografklub Danmark',
        yourTickets: 'Dine billetter',
        instructions: 'Er du medlem af Biografklub Danmark, kan du indtaste din kuponkode her, og spare halvdelen af billetprisen.',
        description: 'Kuponnummeret finder du på din Biografklub Danmark kupon under stregkoden.',
        info: 'Du får mulighed for at indtaste flere kuponnumre, når du har tastet det første og klikket indløs.'
    },

    en: {
        ...OrderFlowLocale.en,
        bioKlubDk: 'Cinema club: "Biografklub Danmark"',
        yourTickets: 'Your tickets',
        instructions: 'If you are a member of the cinema club "Biografklub Danmark" you can enter your coupon code here, and save half of the ticket price.',
        description: 'The coupon number is located on your "Biografklub Danmark" coupon underneath the bar code.',
        info: 'You can add more voucher numbers when you have typed the first one and clicked submit.'
    },

    fo: {
        ...OrderFlowLocale.fo,
        bioKlubDk: 'Biografklub Danmark',
        yourTickets: 'Tíni atgongumerki',
        instructions: ' "Er tú limur í Biografklub Danmark, kann tú skriva tína kotu her, og tú sparir helvtina av atgongumerkja prísinum.',
        description: 'Tína kotu finnur tú á tínum Biografklub Danmark limakorti undir striku kotuni.',
        info: 'Tað er møguleiki at innloysa fleiri nummur, eftir at tú hevur innskriva tað fyrsta og trýst á innloys.'
    }

    // new: {
    //    ...OrderFlowLocale.new,
    //    bioKlubDk: ': "Biografklub Danmark"',
    //    yourTickets: '',
    //    instructions: ' "Biografklub Danmark" ',
    //    description: '',
    //    info: ''
    //}
}