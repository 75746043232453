import React from 'react'
import LOCALE from './NewslettersLocale'

export default class Newsletter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: props.newsletter.isSubscribed,
            triggerEffect: false
        }

        this.newsletterChanged = this.newsletterChanged.bind(this);
    }

    newsletterChanged(e) {
        const id = e.target.id;
        const checked = e.target.checked;
        const { newsletterChanged } = this.props;
        this.setState({ isChecked: checked });
        newsletterChanged(parseInt(id), checked);
    }

    render() {
        const { isChecked, triggerEffect } = this.state;
        const { newsletter, appLocale } = this.props;

        return (
            <label key={newsletter.id} className="newsletter" onClick={() => this.setState({ triggerEffect: true })}
                onAnimationEnd={() => this.setState({ triggerEffect: false })}>
                <input type="checkbox" id={newsletter.id} checked={isChecked} onChange={this.newsletterChanged} />
                {newsletter.name}
                {isChecked ? <span className={triggerEffect ? 'subscribed fade-in-out' : 'subscribed'}> {LOCALE[appLocale].subscribed}</span> :
                    <span className={triggerEffect ? 'unsubscribed fade-in-out' : 'unsubscribed'}> {LOCALE[appLocale].unsubscribed}</span>}
            </label>
        )
    }
}

