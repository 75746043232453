export default {
    da: {
        pageHeader: 'Dine Nyhedsbreve',
        info: 'Tilmeld eller afmeld ',
        email: 'Email',
        find: 'Søg',
        invalidInput: 'Ugyldig email',
        subscribed: 'Tilmeldt',
        unsubscribed: 'Frameldt',
    },

    en: {
        pageHeader: 'Your Newsletters',
        info: 'Subscribe or unsubscribe ',
        email: 'Email',
        find: 'Search',
        invalidInput: 'Invalid email'
    },

    fo: {
        pageHeader: 'Tíni tíðindabrøv',
        info: ' Tilmelda ella avmelda',
        email: 'teldupostur',
        find: 'leita',
        invalidInput: 'Skeivur teldupostur'
    }

    //new: {
    //  pageHeader: '',
    //  info: ' ',
    //  email: '',
    //  find: '',
    //  invalidInput: ''
    //}
}