import React from 'react'
import { connect } from 'react-redux'
import LOCALE from './OverlayLocales'

class Spinner extends React.Component {
    render() {
        const { appLocale } = this.props;
        return (
            <div className="NotFound overlay fade-in" >
                <div className="overlay-content">
                    <h3>{LOCALE[appLocale].notFound}</h3>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        appLocale: state.app.locale
    }
}

export default connect(mapStateToProps)(Spinner)