import React, {useState, useEffect} from 'react'
import _ from 'lodash'
import moment from 'moment'
import CONSTANTS from '../movielist-constants'
import LOCALE from '../MovieListLocale'
import WeekProgramListItem2 from './WeekProgramListItem2'
import WeekProgramListItem2Extended from './WeekProgramListItem2Extnd'


export default function WeekProgramList({ posterBanners, appLocale, movieClicked, showtimeClicked, showLocationOnShowtime,  lastShowtimeDay,
    appHeightChanged, filterIds, sortBy, movies, showtimes, showBackInTime, showAsBase, showExpired, moreInfoText, nextWeek, previousWeek, anyInFuture, anyInPast }) {

    const search = window.location.search;
    const paramss = new URLSearchParams(search);
    const days = paramss.get('days') ;
  //  console.log(days)
    days && sessionStorage.setItem('days', days)
    const savedDays = sessionStorage.getItem('days')
  //  console.log(savedDays)
    const [weekNumber, setWeekNumber] = useState(moment().week());
    const [take, setTake] = useState(7);
    const [currentStartDate, setCurrentStartDate] = useState(moment())
    const [differ2, setDiffer2] = useState(0);
    const tilDate = (days || savedDays) && moment().add(days || savedDays, 'days')
    //console.log(currentStartDate, 'start date')
   // console.log(days && tilDate.format('YYYY-MM-DD'), 'til date')
    let d = "2024-04-29T15:15:00+02:00"
    //console.log(d.slice(0,10))
  
    //console.log(moment(d.slice(0,10)).isAfter(moment(tilDate.format('YYYY-MM-DD'))), 'is after?')
    //const scheduleWidth = document.querySelector('.ShowtimeScheduleWeek');
    useEffect(() => {
      //  if (window.innerWidth < 421) setTake(7)

    })
    //console.log(window.innerWidth)
   // const schedule = document.querySelector('.ShowtimeScheduleWeek');
    const showtimeTypes = [];
        let startDate = currentStartDate;
    if (!startDate && movie.validShowtimes.length > 0) startDate = moment(movie.validShowtimes[0].dateTime);

 


    function nextWeek() {
        
        setCurrentStartDate(currentStartDate.add(take, 'days'))
        setWeekNumber(weekNumber + 1)
        if (currentStartDate < moment()) { setCurrentStartDate(moment()) }

    }


    function previousWeek() {
        setCurrentStartDate(currentStartDate.subtract(take, 'days'))
        if (currentStartDate < moment()) { setCurrentStartDate(moment()) }
        setWeekNumber(weekNumber - 1)

    }
  
  
    function firstWeek(differ) {
        //console.log(take, 'take')
       // console.log(differ, 'differrrrrr')
        if (take < 7) {
           // console.log('yes')
            setCurrentStartDate(currentStartDate.add(differ, 'days'))
        }
        else {
            var ugedag = moment().day();
            var ugedag_film = currentStartDate.clone().add((differ), 'days').day();
            //  console.log(ugedag, ugedag_film, 'ugee')

            var hop = differ + (ugedag - ugedag_film);
           // console.log(hop, 'hop')

            // if ugedag_film is SMALLER than ugedag than add (hop - take) days otherwise add hop days


            hop > take ? setCurrentStartDate(currentStartDate.add(ugedag_film < ugedag ? hop - take : hop, 'days')) :
                setCurrentStartDate(currentStartDate.add((take), 'days'))
        }

            if (currentStartDate < moment()) { setCurrentStartDate(moment()) }
            setWeekNumber(weekNumber + 1)

       
    }

    useEffect(() => {
        if (currentStartDate < moment()) { setCurrentStartDate(moment()) }

    },[])

    function nextValidWeek(forrigeDiffer) {
      // console.log(forrigeDiffer, 'forrige differ')
     
        var ugedag = moment().day();
        var ugedag_film = currentStartDate.clone().add((forrigeDiffer), 'days').day();
      //  console.log(ugedag, ugedag_film)
        var hop = forrigeDiffer + (ugedag - ugedag_film)
       // console.log(hop, 'hop')
        if (take < 7) {
            setCurrentStartDate(currentStartDate.add(forrigeDiffer , 'days'))
        }
        else {
            setCurrentStartDate(currentStartDate.add(ugedag_film > ugedag || ugedag_film === ugedag ? hop : hop - take, 'days'))
        }

        if (currentStartDate < moment()) { setCurrentStartDate(moment()) }
        setWeekNumber(weekNumber - 1)


    }
 //   console.log(take, 'take')
    function lastWeek(diff) {
   //  console.log(diff, 'diff')

        if (take < 7) {
           
            setCurrentStartDate(currentStartDate.subtract(diff, 'days'))
        }
        else {
            var ugedag = moment().day();
            var ugedag_film = currentStartDate.clone().add((diff), 'days').day();
           // console.log(ugedag, ugedag_film)
            var hop = diff + (ugedag - ugedag_film)
           // console.log(hop, 'hop')
            if (diff > take) {
                setCurrentStartDate(currentStartDate.subtract(ugedag_film > ugedag ? hop + take : hop, 'days'))
            }
            else if (diff < 0) {
                setCurrentStartDate(currentStartDate.add(ugedag_film > ugedag ? hop - take : hop, 'days'))

            }
            else setCurrentStartDate(currentStartDate.subtract(take, 'days'))
        }
        

        if (currentStartDate < moment()) { setCurrentStartDate(moment()) }
           setWeekNumber(weekNumber - 1)

    }
   
    
    const sortMovies = (movies) => {

        let sortedMovies = [];
        _.forEach(movies, (movie) => {
            movie.validShowtimes = _.filter(showtimes, (showtime) => {

                if (showtime.showInfo.enabled !== true) return false;
                if ((showtime.movieId == movie.id || (showAsBase && showtime.movieBaseId > 0 && showtime.movieBaseId == movie.baseId))) {
                    const datetime = moment(showtime.dateTime);
                   
                    const isInFuture = datetime > moment() || showBackInTime;

                    return (sortBy == CONSTANTS.sort.alphabet ||
                        sortBy == CONSTANTS.sort.time /*&& showExpired*/ ||
                        sortBy == CONSTANTS.sort.time && isInFuture);

                }
            });
            //const currentEndDate = currentStartDate.clone().add(6, 'days');
            const hasShowtimesInSelectedWeek = _.some(movie.validShowtimes, (showtime) =>
                showtime
                //moment(showtime.dateTime).isBetween(currentStartDate, currentEndDate, 'day', '[]')
           
            );
            
           if (hasShowtimesInSelectedWeek) {
            movie.validShowtimes = _.sortBy(movie.validShowtimes, ['dateTime'])

            sortedMovies.push(movie);
            }
        });

        sortedMovies.sort((a, b) => moment(a.validShowtimes[0].dateTime) - moment(b.validShowtimes[0].dateTime));
        return sortedMovies;
       
    }
    

   
    const generateShowtimeByDate = (movies) => {
        let sortedMovies = sortMovies(movies);
    
        return _.map(sortedMovies, (movie) => {
           return      _.groupBy(movie.validShowtimes, (showtime) => {
                if (showtime.showInfo.enabled && showtime.type && !_.find(showtimeTypes, showtime.type))
                    showtimeTypes.push(showtime);

                return moment(showtime.dateTime).format('YYYY-MM-DD');
            });
          
        }
    
        )
    }
    const showtimesByDate = generateShowtimeByDate(movies)
    const disabled = 'week-nav disabled';
    const prevWeekClasses = anyInPast(currentStartDate)  ? 'week-nav' : disabled;
    const nextWeekClasses = anyInFuture(currentStartDate) ? 'week-nav' : disabled;
    const generateMovieListItems = (movies) => {
        let sortedMovies = sortMovies(movies);


        return _.map(sortedMovies, (movie) =>
            //  showTimesOfMovie=movie,
            <WeekProgramListItem2
                key={movie.id}
                appLocale={appLocale}
                showAsBase={showAsBase}
                movie={movie}
                posterBanners={posterBanners}
                movieClicked={movieClicked}
                showtimeClicked={showtimeClicked}
                showLocationOnShowtime={showLocationOnShowtime}
                appHeightChanged={appHeightChanged}
                showExpired={showExpired}
                showBackInTime={showBackInTime}
                filterIds={filterIds}
                currentStartDate={currentStartDate}
                moreInfoText={moreInfoText}
                nextWeek={nextWeek}
                previousWeek={previousWeek}
                anyInPast={anyInPast}
                anyInFuture={anyInFuture}
                nextWeekClasses={nextWeekClasses}
                prevWeekClasses={prevWeekClasses}

                take={take}
                setDiffer2={setDiffer2}
                lastWeek={diff => lastWeek(diff)}
                firstWeek={differ => firstWeek(differ)}
                nextValidWeek={forrigeDiffer => nextValidWeek(forrigeDiffer)}
                lastShowtimeDay={lastShowtimeDay}
                weekNumber={weekNumber}
            />

        );
        // return diffs

    }
  
    const generateMovieListItemsModified = (movies) => {
        let sortedMovies = sortMovies(movies);

        
     
        return _.map(sortedMovies, (movie) => {
            let d = movie.validShowtimes[0].dateTime
            let firstMovieDate = moment(d.slice(0, 10));
            //console.log(moment(d.slice(0, 10)).isSameOrBefore(moment(tilDate.format('YYYY-MM-DD'))), 'is same or before?')
            if (firstMovieDate.isBefore(moment(tilDate.format('YYYY-MM-DD')))) {
             return    <WeekProgramListItem2
                key={movie.id}
                appLocale={appLocale}
                showAsBase={showAsBase}
                movie={movie}
                posterBanners={posterBanners}
                movieClicked={movieClicked}
                showtimeClicked={showtimeClicked}
                showLocationOnShowtime={showLocationOnShowtime}
                appHeightChanged={appHeightChanged}
                showExpired={showExpired}
                showBackInTime={showBackInTime}
                filterIds={filterIds}
                currentStartDate={currentStartDate}
                moreInfoText={moreInfoText}
                nextWeek={nextWeek}
                previousWeek={previousWeek}
                anyInPast={anyInPast}
                anyInFuture={anyInFuture}
                nextWeekClasses={nextWeekClasses}
                prevWeekClasses={prevWeekClasses}

                take={take}
                setDiffer2={setDiffer2}
                lastWeek={diff => lastWeek(diff)}              
                firstWeek={differ => firstWeek(differ)}
                nextValidWeek={forrigeDiffer => nextValidWeek(forrigeDiffer)}
                lastShowtimeDay={lastShowtimeDay}
                weekNumber={weekNumber}
            />
            }
           
        }
            

        );
       // return diffs

    }

    const generateMovieListItemsExtended = (movies) => {
        let sortedMovies = sortMovies(movies);
       // console.log(sortedMovies, 'sorted movies')
          
        return _.map(sortedMovies, (movie) => {
            let d = movie.validShowtimes[0].dateTime
            let firstMovieDate = moment(d.slice(0, 10));
            //console.log(moment(d.slice(0, 10)).isSameOrBefore(moment(tilDate.format('YYYY-MM-DD'))), 'is same or before?')
            if ((days || savedDays) && (days > 0 || savedDays > 0) && firstMovieDate.isSameOrAfter(moment(tilDate.format('YYYY-MM-DD')))) {
                return <WeekProgramListItem2Extended
                    key={movie.id}
                    appLocale={appLocale}
                    showAsBase={showAsBase}
                    movie={movie}
                    movieClicked={movieClicked}
                    showtimeClicked={showtimeClicked}
                />
            }
        }
            )
    }
    
    const noMoviesText = LOCALE[appLocale].noMoviesThisWeekText;
    //console.log(currentStartDate)

    function anyInPast(currentStartDate) {
     //   console.log(currentStartDate)
     //   console.log(showtimesByDate, 'showtimes from anyinpast')
        return _.some(showtimesByDate, (shows) => {
           
            return _.some(shows, (showtimes, dateKey) => moment(dateKey).isBefore(currentStartDate, 'day'));
        })
    }

 
    function anyInFuture(currentStartDate) {
       // console.log(showtimesByDate, 'showtimes from anyinfuture')
        const nextStartDate = currentStartDate.clone().add(take, 'days');
       return _.some(showtimesByDate, (shows) => {

            return _.some(shows, (showtimes, dateKey) => moment(dateKey).isSameOrAfter(nextStartDate, 'day'))
            
        })
       
    }

    anyInPast(currentStartDate);
    anyInFuture(currentStartDate)
    var widths = [0, 430, 850];
    useEffect(() => {        
        window.addEventListener('resize', reSize);
       
        //console.log(window.innerWidth)
    })
  
    function reSize() {      
       determineTakeFromScreenWidth()     
    }

   
    
    function determineTakeFromScreenWidth() {
        const schedule = document.querySelector('.ShowtimeScheduleWeek');
       
        const scheduleWidth = Math.round(Math.min(schedule.scrollWidth, schedule.offsetWidth, schedule.clientWidth));
    //    console.log(scheduleWidth, 'schdl width')
        const showtimeItem = document.querySelector('.showtimes-on-date');    
        const showtimeItemWidth = Math.round(Math.max(showtimeItem.scrollWidth, showtimeItem.offsetWidth, showtimeItem.clientWidth));  
        // console.log(Math.floor(scheduleWidth / showtimeItemWidth))
        (scheduleWidth < 879 && window.innerWidth > 431) || window.innerWidth < 380 ? setTake(Math.floor(scheduleWidth / showtimeItemWidth)) : setTake(7)

        //if (scheduleWidth < 879 && window.innerWidth > 420 ) {
        //    setTake(Math.floor(scheduleWidth / showtimeItemWidth))
        //}
        //else if (window.innerWidth < 385) {
        //    setTake(Math.floor(scheduleWidth / showtimeItemWidth))
        //}
        //else {
        //    setTake(7)
        //}
       
         return Math.floor(scheduleWidth / showtimeItemWidth);
    }


    const movieListItems = (days || savedDays) && (days > 0 || savedDays > 0) ? generateMovieListItemsModified(movies) : generateMovieListItems(movies);
    const movieListItemsExtended = generateMovieListItemsExtended(movies)
  
    

    return (
        <div className="MovieList">
           
            {movieListItems.length > 0 ? movieListItems : <h3 className="no-events">{noMoviesText}</h3>}
            {movieListItemsExtended.length > 0 && (days || savedDays) && (days > 0 || savedDays > 0) ?
                <div style={{width:'100%'}}>

                    <p className='showSoon' >Vises Snart</p>
                    <div className="MovieListItemExtended" style={{ display: 'flex',  flexWrap: 'wrap' }}>{movieListItemsExtended}</div>
                </div>
                : ''}
           
        
        </div>)

}