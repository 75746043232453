import React, { useEffect, useState } from 'react'
import './styles/program.scss'
import _ from 'lodash'
import DatePickerContainer from '../datepicker/DatePickerContainer'
//import WeekDayPicker from '../datePicker/WeekDayPicker'
import CONSTANTS from '../movielists/movielist-constants'
import WeekDayPicker from '../datepicker/WeekDayPicker'
import xml2js from '../../lib/xml-to-js';
import DayPicker from 'react-day-picker';
import moment from 'moment'
import sortIcon from './images/sort-icon.png'

import downarrow from './images/down-arrow-backup-2-svgrepo-com (1).svg'
//import check from "./images/check-mark.svg";
//import checkIcon from "./images/check-mark.svg";
import Region from './Region';
import Cinema from './Cinema';
import Sorting from './Sorting';
import LOCALES from '../overlays/OverlayLocales'


export default function Program() {

    // choose dag and fetch functions
    const [selected, setSelected] = useState(0);
    const [moredDatesClicked, setMoreDatesClicked] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false)
    const [allMovies, setAllMovies] = useState([{}])
    const [allEvents, setAllEvents] = useState([{}])
    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'))
    const [movies, setMovies] = useState([{}])
    const [showtimes, setShowtimes] = useState([{}])
    const [showAsWeekButton, setShowAsWeekButton] = useState(true)


    // const [showBtn, setShowBtn] = useState(false);

    const lists = [
        { id: 0, title: "I dag" },
        { id: 1, title: "I morgen" },
        { id: 2, title: "Ons. 4/12" },
        { id: 3, title: "Tors. 5/12" },
        { id: 4, title: "Fre. 6/12" },

    ]

    const handleColor = (row) => {
        setSelected(row.id)
    }
 
    //console.log(sortID, 'sort id')

    // getMovies call
    useEffect(() => {
    const baseUrl = window.location.href.indexOf('localhost') != -1 ? 'https://localhost:44390/' : window.location.protocol + '//' + window.location.host + '/api/';
        const querystring = 'movies?organizerIds=162'
        fetch(baseUrl + querystring)
            .then(res => {
                if (res.ok) return res.json()
            })
            .then(result => {
                console.log(result, 'result')
                result && setMovies(result.movies)
                result && setShowtimes(result.showtimes)
            })
    }, [])
    showtimes.map((show) => {
        if (show.movieId === 28495) console.log(show)
    })
    // dates functionality
    function extractHeaderDate(selectedDate) {
        const date = moment(selectedDate);
        if (date.isSame(moment(), 'day')) return 'i dag ';
        else if (date.isSame(moment().add(1, 'days'), 'day')) return 'i morgen ';
        else return date.format('dddd DD/MM');

    }
    let dateForHeader = extractHeaderDate(selectedDate);
    const header = dateForHeader ? <div className="page-header" dangerouslySetInnerHTML={{ __html: dateForHeader }}></div> : null;


    const datesWithShowtimes = []
    _.forEach(showtimes,(showtime) => {
     // console.log(showtime.showInfo)
        if (showtime.showInfo && showtime.showInfo.enabled == true) {
           // console.log(showtime)
            const date = moment(showtime.dateTime).format('YYYY-MM-DD')
            return datesWithShowtimes.push(date)
            if (!_.includes(datesWithShowtimes, date)) datesWithShowtimes.push(date);
        }
    });
   // console.log(datesWithShowtimes, 'times')
    let dd = []
    datesWithShowtimes.filter((i) => dd.push(i))
    function dateDupli(dd) {
        const uniqEl = new Set(dd)
        dd.filter((item) => {
            if (uniqEl.has(item)) {

            }
            else return item
        }
        )
        return [...new Set(uniqEl)]
    }
   // console.log(dateDupli(dd))
    const showDates = dateDupli(dd)

    //let sortedMovies = [];
    //let sortBy 

    //useEffect(() => {
    //    let selectedDate = moment().format('YYYY-MM-DD')
    //    _.forEach(movies, (movie) => {
    //        movie.validShowtimes = _.filter(showtimes, (showtime) => {
    //            if ((showtime.movieId == movie.id ||  showtime.movieBaseId > 0 && showtime.movieBaseId == movie.baseId)) {
    //                const datetime = moment(showtime.dateTime);
    //                const isInFuture = datetime > moment() ;

    //                //if (showtime.showInfo.enabled !== true) return false;

    //                const formattedDate = datetime.format('YYYY-MM-DD');
    //                const isOnDate = formattedDate === selectedDate;
    //               // if (this.noMoviesText == null && isOnDate) this.noMoviesText = LOCALE[appLocale].noMoreMoviesTodayText;

    //               // return (sortBy == CONSTANTS.sort.alphabet || sortBy == CONSTANTS.sort.time && showExpired || sortBy == CONSTANTS.sort.time && isInFuture) && isOnDate;
    //            }
    //        });

    //        if (movie.validShowtimes.length > 0) {
    //            movie.validShowtimes = _.sortBy(movie.validShowtimes, ['dateTime']);
    //            sortedMovies.push(movie);
    //        }
    //        return sortBy
    //    });
    //   // if (sortBy === sort.alphabet) sortedMovies = _.sortBy(sortedMovies, ['name']);
    //  //  else if (sortBy === sort.time)
    //        sortedMovies.sort((a, b) => { return moment(a.validShowtimes[0].dateTime) - moment(b.validShowtimes[0].dateTime); });

    //    return sortedMovies;
    //})
    //console.log(sortedMovies, 'SORTED movies')

    //useEffect(() => {
    //    fetch('https://flow.ebillet.dk/systemnative/export.asmx/GetEventsMulti?nStartDate=0&nStartTime=0&nEndDate=1&nEndTime=0&nSystemNo=3&strOrgNos=0&nWebMovieNo=0&REMOTE_HOST=127.0.0.2')
    //        .then(res => res.text())
    //        .then(result => {
    //            const json = xml2js.parseString(result)
    //            const data = json.eventList

    //            setAllMovies(data.movies.movie)
    //            setAllEvents(data.events.event)
    //            console.log(data)

    //        })

    //}, [])
    const date = moment(showtimes.dateTime).format('YYYY-MM-DD');
   // console.log(date)

    function handleCalendarClick() {
        setMoreDatesClicked(true)
        setSelected(5)
        setShowCalendar(true)
    }


    function toggleCalendar() {
        setShowCalendar(false)
    }

   // console.log(allMovies, 'all movies')
   // if (date.isSame(moment(), 'day'))
    let dates = allEvents.map((event) => event.dateTime)
  //  console.log(dates, 'dates')
    //console.log(moment(dates).isSame(moment(), 'day'))

    //allMovies.map((movies) => movies.push({
    //    mostSold: findMostSold(),
    //    mostProgrammed: findMostProgrammed()
    //})
    //)


    // sorting functions
    const [isOpen, setIsOpen] = useState(false);
    const [sortedBy, setSortedBy] = useState('Alfabetisk sorteret');
    const [sortID, setSortID] = useState('0')


    function handleArrrow() {
        setIsOpen((current) => !current);
    }

    function handleSort(e) {
        setSortID(e.target.id)
        setSortedBy(e.target.innerHTML)
        setIsOpen(false)
    }


    // FINDING REPEATED EVENTS having same movieNo
    let eventArr = [];
    allEvents && allEvents.map((event) => eventArr.push( event._movieNo));

   // console.log(eventArr, 'event e')
    function EventDuplicates(eventArr) {
        const uniqueElements = new Set(eventArr);

        eventArr.filter((item) => {
            if (uniqueElements.has(item)) {

            } else {
                return item;
            }
        });

        return [...new Set(uniqueElements)];
    }
    const duplicateEvents = EventDuplicates(eventArr);
   // console.log(duplicateEvents, 'duplicate events')

    var result = allEvents.filter(o1 => allMovies.filter(o2 => o1._movieNo === o2._no))
   // console.log(result, 'result')

  let res=  allEvents.filter((event) =>
        duplicateEvents.filter((elm) => {
            if(event._movieNo === elm) return event 
        })
    )
    //console.log(res)
    let ff = [];
    for(let i = 0; i < duplicateEvents.length; i++) {
        ff.push({
            id: i,
            no: duplicateEvents[i],
            name: allMovies.filter((m) =>  m._no === duplicateEvents[i]),
            seat: allEvents.find((e) => e._movieNo === duplicateEvents[i] )

        })
    }
   // console.log(showtimes, 'showtimes')
    let moviesPerDag = [{}]

    {
        movies.map((movie) => (

            showtimes.map((shows) => {
                const date = moment(shows.dateTime).format('DD-MM-YYYY');

                if (movie.id === shows.movieId && date === moment().format('DD-MM-YYYY')) {
                    moviesPerDag.push(movie)
                }
            }

            ))

        )
    }
   // console.log(moviesPerDag, 'movies per dag')
    function getUniqueArrayBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }
    const filteredMovies = getUniqueArrayBy(moviesPerDag, 'id')
  //  console.log(filteredMovies, 'filtered')

    let columns;

    const filteredShowtimes = getUniqueArrayBy(datesWithShowtimes, 'index')
    //console.log(filteredShowtimes)

    return (
    <>
            
               
           
        
        <main className='ebilletProgram'>
                    <div className='chooseFromContainer mblMenu' style={{ justifyContent: 'space-between', backgroundColor: '#3b3b3b'}}>
                        <div >
                    <p className="choose-title" style={{ color: 'white', marginRight: '10px' }}> Vælg dag</p>

                    {lists.map((list, index) => (
                        <button
                           
                            key={index}
                            onClick={() => handleColor(list)}
                            style={{
                                border: '1px solid #757575', padding: '5px 20px', borderRadius: '2px',
                                backgroundColor:
                                    list.id === selected 
                                        ? "white"
                                        : "inherit",
                                color:
                                    list.id === selected ? "#323232" : "#d2d2d2",
                                backgroundImage: 'none',
                                marginRight:'8px'
                            }}
                        >
                            {list.title}
                        </button>
                    ))}
                    <button onClick={handleCalendarClick} style={{
                        backgroundColor: moredDatesClicked ? 'white' : 'transparent', backgroundImage: 'none',
                        border: '1px solid #757575', padding: '5px 10px', borderRadius: '2px', color: moredDatesClicked ? "#323232" : '#d2d2d2'
                    }}>Flere datoer</button>

                    {
                        moredDatesClicked &&
                        <div className={showCalendar? 'Calendar slide-down' : 'Calendar slide-up'}>
                            <span className="icon-close" onClick={toggleCalendar}></span>
                            <DayPicker 
                                //initialMonth={firstDate}
                               // fromMonth={firstDate}
                                //toMonth={lastDate}
                              //  months={LOCALE[appLocale].months} //This is only the display name of the from-to months, unfortunately not which months to include in the calendar. This can't seem to be done with this lib.
                              //  weekdaysLong={LOCALE[appLocale].weekdays}
                              //  weekdaysShort={LOCALE[appLocale].weekdaysShort}
                                firstDayOfWeek={1}
                                enableOutsideDays={false}
                                selectedDays={moment().toDate()}
                             //   disabledDays={this.disabledDays}
                             //   onDayClick={this.handleDayClick}
                                fixedWeeks
                            />
                         
                        </div>
                    }

                </div>

                        <div style={{display:'flex'}}>
                            <Region />
                            <Cinema />

                        </div>
              
             

            </div>

                <section className='programFilmContainer'>
                    <WeekDayPicker
                        appLocale={'da'}
                        datesWithShowtimes={filteredShowtimes}
                        selectedDate={selectedDate}
                        //changeDate={this.changeDate}
                        isAllMovies={true}
                    />
                   
                    {/*<DatePickerContainer*/}
                    {/*    showAsWeekButtons={showAsWeekButton}*/}
                    {/*   // hasListHeader={dateForHeader != null}*/}
                    {/*    isAllMovies={true}*/}
                    {/*   // changeDate={this.changeDate}*/}
                    {/*    //isOneColumn={columns == CONSTANTS.columns.one}*/}
                    {/*/>*/}

                <div className='program-header'>
                            <p style={{ fontSize: '30px' }}> Program</p>
                            <div className='sorting' >

                                <img src={sortIcon} alt="" />
                                <div className=''>
                                    <div className='sortingContainer' >
                                        <div style={{                                           
                                            padding: "5px 8px",
                                            boxSizing: "border-box",
                                            height: "31px",
                                        }}>
                                            <p style={{ margin: 0, }}>{sortedBy}</p>
                                            <span className='line'></span>
                                            <button
                                                onClick={handleArrrow}
                                                style={{
                                                    position: "absolute",
                                                    bottom: "7px",
                                                    right: "-35px",
                                                    border: "none",
                                                    outline: "none",
                                                    background: "transparent",
                                                }}
                                            >
                                                <p className={isOpen ? "rotated" : ""}>
                                                    <img src={downarrow} alt="arrows" />
                                                </p>
                                            </button>
                                        </div>

                                        {isOpen &&
                                            <div className="sortingDiv">
                                                <p onClick={handleSort} id="0" style={{ height: '15px', marginTop: '5px', }}>Alfabetisk sorteret</p>
                                                <p onClick={handleSort} id="1" style={{ height: '15px' }}>Mest solgte</p>
                                                <p onClick={handleSort} id="2" style={{ height: '15px' }}>Mest programlagte</p>
                                                <p onClick={handleSort} id="3" style={{ height: '15px', color: '#d2d2d2' }}>Nyeste</p>
                                            </div>

                                        }
                                    </div>

                                </div>
                            </div>
                </div>

                      
                        <div className='all-film-program'>
                            {filteredMovies.length > 1 ? filteredMovies.sort((a, b) => (
                                sortID === '0' ? a.name > b.name ? 1 : -1 : a.id > b.id ? 1 : -1 
                           )).map((item, index) => (
                                item.id &&
                                <div key={item.id} style={{ width: '228px', height: '469px', position: 'relative', backgroundImage: 'linear-gradient(to top, #d4d4d4, #eee)' }}>
                                    <img src={item.posters.large} alt="" height='351px' width='228px' />
                                    <div style={{ padding: '5px 12px' }}>
                                        <p style={{ fontSize: '22px', color: '#000', margin: '0', height: '60px' }} className='ellipsis-twoline'>{item.name}</p>
                                        <button style={{ border: 'none', position: 'absolute', bottom: '8px', fontWeight: 'bold', color: 'white', width: '204px', height: '31px', borderRadius: '2px', backgroundImage: ' linear-gradient(to top, #950200, #9e0200)' }}> Billetter</button>
                                    </div>
                                </div>
                           )) : <div className={`Spinner overlay ${allMovies ? ' fade-in' : ' fade-out'}`}>
                                <p className="overlay-content">Indlæser..........</p>
                            </div>}
                        </div>

                {/*<div className='all-film-program' >*/}
                {/*            {allMovies.length > 1 ? allMovies.sort((a, b) => (*/}
                {/*                sortID === '0' ? a.name > b.name ? 1 : -1 : a.id > b.id ? 1 : -1*/}
                {/*            )).map((item,index) => (*/}
                {/*        <div key={index} style={{ width: '228px', height: '469px', position: 'relative', backgroundImage: 'linear-gradient(to top, #d4d4d4, #eee)' }}>*/}
                {/*            <img src={'https://poster.ebillet.dk/' + `${item.largePath}`} alt="" height='351px' width='228px' />*/}
                {/*            <div style={{ padding: '5px 12px' }}>*/}
                {/*                <p style={{ fontSize: '22px', color: '#000', margin: '0',height:'60px' }} className='ellipsis-twoline'>{item.name}</p>*/}
                {/*                <button style={{ border: 'none', position: 'absolute', bottom: '8px', fontWeight: 'bold', color: 'white', width: '204px', height: '31px', borderRadius: '2px', backgroundImage: ' linear-gradient(to top, #950200, #9e0200)' }}> Billetter{item.id}</button>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )) : (<h1>loading..........</h1>)*/}
                {/*    }*/}
                {/*</div>*/}
            </section>

                </main>
            
               

        
            </>
        )
}