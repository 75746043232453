import './vouchers.scss'
import React from 'react'
import _ from 'lodash'

const VoucherErrors = ({ errors }) => (
    errors ?
        <div className="VoucherErrors">
            {_.map(errors, (error, i) => {
                return <div key={i}>{error}</div>
            })}
        </div> : null
)

export default VoucherErrors