import _ from 'lodash'
import { VOUCHER_TYPES } from '../api-constants'

export default function extractFromTransaction(transaction, timeLeft, transactionId) {
    const ticketInfo = transaction.tickets;
    const totalTicketPrice = typeof ticketInfo._price === 'string' ? parseFloat(ticketInfo._price.replace(',', '.')) : ticketInfo._price;
    const { selectedTickets, totalTicketQuantity } = extractTickets(ticketInfo);

    const concessions = transaction.concessions;
    const totalExtraPurchasesPrice = typeof concessions._price === 'string' ? parseFloat(concessions._price.replace(',', '.')) : concessions._price;

    const seats = extractSeats(transaction.seats);
    const seatTexts = extractSeatTexts(transaction.seatText);
    const seatsInfo = extractSeatInfos(transaction.seatsInfo);

    const extraPurchases = extractExtraPurchases(concessions, transaction.vouchers);
    const prices = transaction.amounts ? extractPrices(transaction.amounts) : {};

    const redeemBarCodes = transaction.redeemedBarcodes;
    const giftCards = redeemBarCodes && redeemBarCodes.giftCards ? extractGiftCards(redeemBarCodes.giftCards) : '';
    const coupons = redeemBarCodes && redeemBarCodes.cupons && redeemBarCodes.cupons.item ? extractVouchers(redeemBarCodes.cupons) : '';
    const ifOnlyOne = giftCards ? giftCards : coupons;
    const vouchers = redeemBarCodes ? {
        active: coupons && giftCards ? _.concat(coupons, giftCards) : ifOnlyOne,
        errors: redeemBarCodes.errors ? Array.isArray(redeemBarCodes.errors.error) ? redeemBarCodes.errors.error : [redeemBarCodes.errors.error] : null
    } : { active: '', errors: '' }
        
    const showtime = transaction.showDetails ? {
        movieId: transaction.showDetails.movieID,
        showtimeId: transaction.showDetails._no,
        cinema: {
            id: transaction.showDetails.cinemaID,
            locationName: transaction.showDetails.auditoriumName,
            name: transaction.showDetails.cinemaName,
            address: transaction.showDetails.cinemaAddress
        },
        hasNumberedSeats: transaction.showDetails._numbered,
        movieName: transaction.showDetails.movieName
    } : null;

    return {
        transactionId, //for ActiveReservation
        id: transaction._no, //Used for reservationId
        organizerId: transaction._orgNo,
        ...showtime,
        timeLeft,

        tickets: selectedTickets,
        totalTicketQuantity,
        totalTicketPrice,
        seats,
        seatTexts,
        seatsInfo,
        giftCards,
        extraPurchases,
        totalExtraPurchasesPrice,
        vouchers,

        customer: transaction.customer ? {
            name: transaction.customer.name,
            phone: transaction.customer.phone,
            email: transaction.customer.eMail,
            password: transaction.customer.password
        } : null,

        prices,
        pickupInfo: transaction._type === "Reservation" ? transaction.collect.info : '',
        orderId: transaction._type === "Sale" ? (transaction.customer.cardHolder ? transaction.customer.cardHolder.card : transaction.orderId) : 0, //if the user is logged in we put the card number as orderId for the receipt page
    }
}

function extractTickets(ticketInfo) {
    const tickets = ticketInfo && ticketInfo.ticket ? Array.isArray(ticketInfo.ticket) ? ticketInfo.ticket : [ticketInfo.ticket] : [];
    let totalTicketQuantity = 0;
    const selectedTickets = _.map(tickets, (ticket) => {
        totalTicketQuantity += ticket._count;

        return {
            id: ticket._ticketNo,
            name: ticket._name,
            quantity: ticket._count
        };
    })
    return { selectedTickets, totalTicketQuantity };
}

export function extractSeatTexts(seatTexts) {
    let seats = seatTexts && seatTexts.line ? seatTexts.line : [];
    return Array.isArray(seats) ? seats : [seats];
}

export function extractSeatInfos(seatsInfo) {
    let si = seatsInfo && seatsInfo.seatInfo ? seatsInfo.seatInfo : [];
    return Array.isArray(si) ? si : [si];
}

export function extractSeats(seatsInfo) {
    let seats = seatsInfo && seatsInfo.seat ? seatsInfo.seat : [];
    seats = Array.isArray(seats) ? seats : [seats];
    return _.map(seats, function (seat) {
        return {
            location: seatsInfo._location,
            seat: seat._name,
            row: seat._row,
            area: seat._area,
            areano: seat._areaNo
        };
    })
}

function extractExtraPurchases(concessions, bonusVouchers) {
    const extras = concessions && concessions.item ? Array.isArray(concessions.item) ? concessions.item : [concessions.item] : [];
    const clubPurchases = bonusVouchers && bonusVouchers.item ? ((Array.isArray(bonusVouchers.item) ? bonusVouchers.item : [bonusVouchers.item])) : [];

    let extraPurchases = _.map(extras, (item) => {
        return {
            id: item._no,
            quantity: item._count
        };
    });

    if (clubPurchases.length > 0) {
        _.forEach(clubPurchases, (item) => {
            extraPurchases.push({
                id: item._no,
                points: item._points,
                quantity: item._count
            });
        });
    }

    return extraPurchases;
}

function extractVouchers(vouchers) {
    const items = vouchers && vouchers.item ? Array.isArray(vouchers.item) ? vouchers.item : [vouchers.item] : [];
    return _.map(items, (voucher) => {
        return {
            id: voucher._no,
            type: VOUCHER_TYPES.TICKETS,
            name: voucher._name,
            barCode: voucher._barCode
        };
    })
}

function extractGiftCards(giftCards) {
    const items = giftCards && giftCards.item ? Array.isArray(giftCards.item) ? giftCards.item : [giftCards.item] : [];
    return _.map(items, (gift) => {
        return {
            id: gift._no,
            type: VOUCHER_TYPES.GIFTCARD,
            name: gift._name,
            barCode: gift._barCode,
            amount: gift._redeemAmount / 100, //convert from "ører" til "kroner"
            remainingAmount: gift._restAmount / 100 //convert from "ører" til "kroner"
        };
    })
}

function extractPrices(amounts) {
    let tickets = amounts.tickets;
    tickets = typeof tickets === 'string' ? parseFloat(tickets.replace(',', '.')) : tickets;

    let extras = amounts.concessions;
    extras = typeof extras === 'string' ? parseFloat(extras.replace(',', '.')) : extras;

    let fees = amounts.fees._total;
    fees = typeof fees === 'string' ? parseFloat(fees.replace(',', '.')) : fees;

    let total = amounts._total;
    total = typeof total === 'string' ? parseFloat(total.replace(',', '.')) : total;

    return { tickets, fees, extras, total };
}


function extractVoucherErrors(errors) {
    return _.map(errors, (error) => {
        return {
            code: error.barcode,
            message: error.shortError
        }
    });
}