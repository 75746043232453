export default {
    fetchEvents,
    fetchEvent,
    fetchEventImg
}

//International Discovery Api;
export const baseUrl = 'https://app.ticketmaster.eu/mfxapi/v2';
const apiKey = 'ZyseZ7zPA0qMzTKYYG3Y6r3cHyvsjar5';
const shared = '&domain=denmark&lang=da-dk';

//US Discovery Api
export const US_baseUrl = 'https://app.ticketmaster.com/discovery/v2/events/legacy/MFX-DK-';
const US_apiKey = 'rCAZin3R6zCNGuVp7pMnGeUvnbydlRGz';


async function fetchEvents(promoterCodes) {
    return await fetch(`${baseUrl}/events?apikey=${apiKey}&venue_ids=${promoterCodes}&start=0&rows=250${shared}`)
        .then(response => {
            if (response.ok) return response.json();
            else throw response
        });
}

async function fetchEvent(id) {
    return await fetch(`${baseUrl}/events/${id}?apikey=${apiKey}&${shared}`)
        .then(response => {
            if (response.ok) return response.json();
            else throw response
        });
}

async function fetchEventImg(id) {
    return await fetch(`${US_baseUrl}${id}?locale=da-dk&apikey=${US_apiKey}`)
        .then(response => {
            if (response.ok) return response.json();
            else throw response
        });
}