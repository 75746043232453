import '../external.scss'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import moment from 'moment'
import LOCALE from '../ExternalLocale'
import { ROUTES } from '../../navigation/route-constants'
import { getEventList, changeSelectedEvent } from '../../../actions/billetten-actions'
import { appHeightChanged } from '../../../actions/app-actions'

class BillettenDetailsContainer extends React.Component {
    constructor() {
        super();
        this.showtimeClicked = this.showtimeClicked.bind(this);
        this.navBack = this.navBack.bind(this);
    }

    componentDidMount() {
        const { events, actions, iframeId, organizerId, eventId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);

        if (events.length == 0) actions.getEventList(organizerId).then(() => actions.changeSelectedEvent(eventId));
        else actions.changeSelectedEvent(eventId);
    }

    showtimeClicked() {
        const { event } = this.props;
        window.open(event.ticketsUrl, '_blank', 'width=1170,height=800,resizable=yes,scrollbars=yes')
    }

    navBack() {
        const { organizerId, history } = this.props;
        history.push(`${ROUTES.BILLETTEN_ALL_EVENTS}?org=${organizerId}`);
    }

    getSoldOutOrCancelledText() {
        const { event, appLocale } = this.props;
        if (event.isSoldOut) return <span className="cancelled"> - {LOCALE[appLocale].soldOut}</span>;
        else if (event.isCancelled) return <span className="cancelled"> - {LOCALE[appLocale].cancelled}</span>;
    }

    render() {
        const { event, pageHeader, appLocale } = this.props;
        const header = pageHeader ? <div className="page-header" dangerouslySetInnerHTML={{ __html: pageHeader }}></div> : null;
        const description = event.description ? <p dangerouslySetInnerHTML={{ __html: event.description }} /> : null
        const price = event.price ? <h4>{event.price} {LOCALE[appLocale].including}</h4> : null;
        const venue = event.venue ? <div><strong>{event.venue.name}</strong><br />{event.venue.address}</div> : null;
        const soldOutOrCancelled = this.getSoldOutOrCancelledText();

        return (
            <div className="ExternalEvent">
                {header}
                <div className="top">
                    <div className="Poster"><img src={event.posters.large} /></div>
                    <div className="info">
                        <h1>
                            {event.name}
                            {soldOutOrCancelled}
                        </h1>
                        <h3>{event.dateTime ? _.capitalize(moment(event.dateTime).format('dddd [d.] DD/MM [kl.] LT')) : null}</h3>
                        {price}
                    </div>
                </div>
                <div className="description">
                    {description}
                    {venue}
                </div>
                <div className="nav-buttons">
                    <button className="back gray" onClick={this.navBack}>{LOCALE[appLocale].back}</button>
                    {!event.isSoldOut && !event.isCancelled ? <button className="next" onClick={this.showtimeClicked}>{LOCALE[appLocale].buyTickets}</button> : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        organizerId: state.organizer.id,
        events: state.external.events,
        event: state.external.selectedEvent || { posters: {} },
        eventId: ownProps.match.params.eventId,
        pageHeader: state.organizer.configuration.pageHeaders.event,
        appLocale: state.app.locale,
        iframeId: state.app.iframeId
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { getEventList, changeSelectedEvent, appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillettenDetailsContainer)