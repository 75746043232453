import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import CONSTANTS from '../../orderflow/orderflow-constants'
import Poster from '../../movie/Poster'
import LOCALE from './TransactionsLocale'
import { ORGANIZER_TYPES } from '../../../api/api-constants';

const Transaction = ({ transaction = {}, rowClasses, getReceipt, appLocale, organizerType }) => {
    const dateTime = moment(transaction.showtime.dateTime);
    const tickets = transaction.ticketCount == 1 ? LOCALE[appLocale].ticket : LOCALE[appLocale].tickets;

    return (
        <div id={transaction.id} className={rowClasses} onClick={getReceipt}>
            {transaction.movie.posters ? <Poster movie={transaction.movie} /> : null}
            <div className="movie-info">
                <h1 id={transaction.id}>{transaction.movie.name}</h1>
                <div>{_.capitalize(dateTime.format(LOCALE[appLocale].shortDateTimeFormat))}, {transaction.showtime.locationName}</div>

                {organizerType == ORGANIZER_TYPES.CIRCUS ?
                    <p>
                        {transaction.location.name} <br />
                        {transaction.location.address} <br />
                        {transaction.location.city}
                    </p> :
                    <p>
                        {transaction.organizer.name} <br />
                        {transaction.organizer.address} <br />
                        {transaction.organizer.city}
                    </p>
                }
                <p>{transaction.orderType == CONSTANTS.orderTypes.buy ? LOCALE[appLocale].paid : LOCALE[appLocale].reserved} {transaction.ticketCount} {tickets}</p>
                {transaction.removed == false ? <div className="read-more"><input type="button" className="next" id={transaction.id} value={`${_.capitalize(transaction.orderType == CONSTANTS.orderTypes.buy ? tickets : LOCALE[appLocale].reservation)}`} /></div> : <div className="read-more">{LOCALE[appLocale].deleted}</div>}
            </div>
        </div>
    )
}

export default Transaction