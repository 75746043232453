import React from 'react'
import _ from 'lodash'
import { ROUTES } from '../../navigation/route-constants'
import LOCALE from '../MovieListLocale'
import CONSTANTS from '../movielist-constants'
import MovieInfo from '../../movie/MovieInfo'
import Poster from '../../movie/Poster'

export default class FutureMoviesListItem extends React.Component {
    constructor(props) {
        super(props);
        this.movieClicked = this.movieClicked.bind(this);
    }

    movieClicked(e) {
        const { movieClicked } = this.props;
        const dataset = e.target.dataset;
        if (e.target.tagName != 'A') e.preventDefault();

        const movieId = e.currentTarget.id || dataset.movieId;
        if (e.target.tagName == 'IMG' || e.target.tagName == 'H1' || e.target.tagName == 'INPUT' || _.includes(e.target.classList, 'poster-banner'))
            movieClicked(movieId);
    }

    render() {
        const { movie, rowClasses, appLocale, organizerIds, columns, hasShowtimes } = this.props;
        const poster = movie.posters ?
            <a className="poster-container" href={`${window.location.origin}${ROUTES.MOVIE}/${movie.id}?org=${organizerIds}`}>
                <Poster movie={movie} /></a> : null;

        const movieNameLocale = movie.names ? (movie.names[appLocale] ? movie.names[appLocale] : movie.names['da']) : movie.name;

        return (
            <div className={rowClasses} id={movie.id} onClick={this.movieClicked}>
                {poster}
                <div className="movie-info">
                    <a href={`${window.location.origin}${ROUTES.MOVIE}/${movie.id}?org=${organizerIds}`}><h1>{movieNameLocale}</h1></a>
                    <div className="future-info">
                        <MovieInfo movie={movie} showTitle={false} appLocale={appLocale} />
                        <div className="read-more future"><input type="button" className="next" data-movie-id={movie.id} value={hasShowtimes ? LOCALE[appLocale].buyTickets : (columns == CONSTANTS.columns.one ? LOCALE[appLocale].moreInfo : LOCALE[appLocale].readMore)} /></div>
                    </div>
                </div>
            </div>
        )
    }
}