import '../css/movielists.scss'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../movielist-constants'
import { appHeightChanged } from '../../../actions/app-actions'
import { getMovieList, selectedMovieChanged, changeSelectedShowtime } from '../../../actions/movielist-actions'
import Multibio_MovieList from './Multibio_MovieList'
import { setContainerClasses } from '../shared'

class Multibio_AllContainer extends React.Component {
    constructor() {
        super();

        this.movieClicked = this.movieClicked.bind(this);
        this.showtimeClicked = this.showtimeClicked.bind(this);
    }

    componentDidMount() {
        const { actions, movies, organizerIds, iframeId } = this.props;
        if (movies.length == 0) actions.getMovieList(organizerIds);

        if (window.parent != window) actions.appHeightChanged(iframeId, false, false, true);
        else window.scrollTo(0, sessionStorage.getItem("scroll-position"));
    }

    componentWillUnmount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, false, true, false);
        else sessionStorage.setItem("scroll-position", window.scrollY);
    }

    movieClicked(id) {
        const { organizerIds, actions, history, movies } = this.props;
        const movie = _.find(movies, (movie) => { return movie.id == id });
        actions.selectedMovieChanged(movie.id, history.location.pathname).then(() => history.push(`${ROUTES.MOVIE}/${id}?org=${organizerIds}`));
    }

    showtimeClicked(organizerId, showtimeId) {
        const { openOrderInNewWindow, actions, history, showtimes } = this.props;
        const showtime = _.find(showtimes, (showtime) => { return showtime.id == showtimeId && showtime.organizerId == organizerId });
        if (showtime) {
            actions.changeSelectedShowtime(showtime, history.location.pathname).then(() => {
                const orderUrl = `${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${showtime.organizerId}`;
                if (openOrderInNewWindow) window.open(orderUrl, '_blank', 'width=1170,height=800,resizable=yes,scrollbars=yes');
                else history.push(orderUrl);
            })
        }
    }

    render() {
        const { appLocale, pageHeader, organizers, movies, showAsBase, showtimes, posterBanners, selectedDate, columns,
            listType, showLocationOnShowtime, showBackInTime, actions, organizerIds, sortBy, showExpired } = this.props;

        const header = pageHeader ? <div className="page-header full-width" dangerouslySetInnerHTML={{ __html: pageHeader }}></div> : null;
        let classes = setContainerClasses(listType, columns, true);

        return (
            <div className={classes}>
                <div className="flex-container">{header}</div>
                <Multibio_MovieList appLocale={appLocale}
                    listType={listType}
                    showBackInTime={showBackInTime}
                    columns={columns}
                    organizers={organizers}
                    movies={movies}
                    showAsBase={showAsBase}
                    showtimes={showtimes}
                    sortBy={sortBy}
                    movieClicked={this.movieClicked}
                    showtimeClicked={this.showtimeClicked}
                    isAllMovies={true}
                    posterBanners={posterBanners}
                    selectedDate={selectedDate}
                    showLocationOnShowtime={showLocationOnShowtime}
                    appHeightChanged={actions.appHeightChanged}
                    organizerIds={organizerIds}
                    showExpired={showExpired}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const config = state.organizer.configuration;
    const listConfig = config.movielists;
    const selectedDate = state.movielist.selectedDate;
    const url = ownProps.match.url;

    let movies = listConfig.all.base ? extractMoviesAsBases(state.movielist.movies) : state.movielist.movies;
    let showAsBase = listConfig.all.base;
    let pageHeader = config.pageHeaders.allMovies;
    let columns = listConfig.all.columns;
    let sortBy = listConfig.all.sort;
    let listType = CONSTANTS.listTypes.all

    if (url == ROUTES.MULTI_ALL_MOVIES) columns = CONSTANTS.columns.one;

    function extractMoviesAsBases(movies) {
        const moviesBases = state.movielist.movieBases;
        const moviesAsBase = [];
        _.each(movies, (movie) => {
            if (movie.baseId) {
                const baseMovie = _.find(moviesBases, (base) => { return base.id == movie.baseId; });
                const alreadyInList = _.some(moviesAsBase, ['baseId', movie.baseId]);
                if (baseMovie && !alreadyInList) moviesAsBase.push({ ...movie, name: baseMovie.name, posters: baseMovie.posters });
            } else moviesAsBase.push(movie);
        });
        return moviesAsBase;
    }

    return {
        organizerIds: state.organizer.multibioIds,
        organizers: state.movielist.organizers,
        pageHeader,
        columns,
        sortBy,
        movies,
        showAsBase,
        listType,
        showtimes: state.movielist.showtimes,
        posterBanners: config.posterBanners,
        appLocale: state.app.locale,
        openOrderInNewWindow: config.openOrderInNewWindow,
        selectedDate,
        initialDate: state.movielist.initialDate,
        showLocationOnShowtime: config.showLocationOnShowtime,
        specialMoviesEnabledOnProgram: config.movielists.special.enabledOnProgram,
        showBackInTime: config.daysBack > 0,
        iframeId: state.app.iframeId,
        showExpired: config.movielists.all.showExpired
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { getMovieList, selectedMovieChanged, changeSelectedShowtime, appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Multibio_AllContainer)