import './order.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import moment from 'moment'
import CONSTANTS from '../orderflow-constants'
import { ROUTES } from '../../navigation/route-constants'
import { APP_MODES } from '../../../api/api-constants'
import * as orderActions from '../../../actions/order-actions'
import * as gaActions from '../../../actions/ga-actions'
import { appHeightChanged } from '../../../actions/app-actions'
import AvailableTickets from './ticketselection/AvailableTickets'
import SeatSelection from './seatselection/SeatSelection'
import LOCALE from './OrderLocale'
import Info from '../../overlays/Info'

import AlertContainer from './seatselection/AlertContainer'


class OrderContainer extends React.Component {
    constructor(props) {
        super(props);
      
        this.state = {
            error: '',
            canReserveSelected: true,
            
            canBuySelected: true,
            showExtraPurchasesReset: false,
            showTicketWarning: false,
            ticketWarningConfirmed: false
        };
        props.actions.calculateTotalPrice();
        this.ticketQuantityChanged = this.ticketQuantityChanged.bind(this);
        this.submitDiscountCode = this.submitDiscountCode.bind(this);
        this.navBack = this.navBack.bind(this)
        this.navNext = this.navNext.bind(this);
        this.setError = this.setError.bind(this);
       
      
    }

    componentDidMount() {
        const { actions, iframeId, showExtraPurchasesReset} = this.props;
       
        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);

        if (showExtraPurchasesReset) this.setState({ showExtraPurchasesReset: nextProps.showExtraPurchasesReset });
    }

    componentDidUpdate(prevProps) {
        const { order, actions, iframeId } = this.props;
        if (window.parent != window && order.availableTickets.length > 3)
            actions.appHeightChanged(iframeId, true);
     
    }

    ticketQuantityChanged(id, quantity) {
      
        this.setError('');
        const { actions, showtime, selected } = this.props;

     
        let selectedTickets = [...selected.tickets];
        const existingTicket = _.find(selectedTickets, (ticket) => { return id === ticket.id; });
        if (existingTicket) {
            if (quantity > 0) existingTicket.quantity = quantity;
            else _.remove(selectedTickets, (ticket) => { return ticket.id === existingTicket.id });
        } else selectedTickets.push({ id, quantity });

        this.checkAndUpdateReserveAndBuy();
        actions.ticketQuantityChanged(showtime.id, selected.transactionId, selectedTickets, selected.vouchers);
      
    }

    submitDiscountCode(discountCode) {
        const { selected, showtime, clubCardId, actions } = this.props;
        actions.getAvailableTicketsAndExtraPurchases(showtime.id, clubCardId, discountCode, selected.transactionId);
    }

  
    

    setError(message) {
        this.setState({ error: message });
    }

    navBack(e) {
        const { organizerId, history } = this.props;
        const page = e.target.dataset.navTo;
        history.push(`${page}?org=${organizerId}`);
    }

    navNext(e,anyFalse) {
        const { organizerId, showtime, order, selected, actions, history, appLocale, bioKlubDkAvailable, warnIfOnlyThisInfo } = this.props;
        const { canReserveSelected, canBuySelected, ticketWarningConfirmed } = this.state;
     
        let availableTicket = selected.tickets.length == 1 ? _.find(order.availableTickets, (available) => { return selected.tickets[0].id == available.id; }) : {};
        if (!ticketWarningConfirmed && warnIfOnlyThisInfo && availableTicket.warnIfOnlyThis) this.setState({ showTicketWarning: true });
        else if (selected.tickets.length > 0) {
            const orderType = e.target.id;

            if (orderType === CONSTANTS.orderTypes.reserve && (!this.canReserveNow() || !canReserveSelected || !anyFalse))
                order.clubCard ? this.setError(LOCALE[appLocale].noReserveClubCard) : this.setError(LOCALE[appLocale].noReserve);
            else if (orderType === CONSTANTS.orderTypes.buy && (!this.canBuyNow() || !canBuySelected))
                this.setError(LOCALE[appLocale].noBuy);
            else {
                actions.gaTrackCheckoutStep(1);
                actions.orderTypeChanged(orderType);
                if (e.target.dataset.buyBioKlubDk && bioKlubDkAvailable) history.push(`${ROUTES.ORDERFLOW.BIOKLUB_DK}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
                else history.push(`${ROUTES.ORDERFLOW.EXTRA_PURCHASES}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
            }
        } else this.setError(LOCALE[appLocale].chooseTickets);
    }

    canReserveNow() {
        const { showtime } = this.props;
        const datetimeNow = moment();
        const isInsidePeriod = datetimeNow >= moment(showtime.reserveInfo.start) && datetimeNow <= moment(showtime.reserveInfo.end);
        return showtime.reserveInfo.enabled && isInsidePeriod;
    }

    canBuyNow() {
        const { showtime } = this.props;
        const datetimeNow = moment();
        const isInsidePeriod = datetimeNow >= moment(showtime.buyInfo.start) && datetimeNow <= moment(showtime.buyInfo.end);
        return showtime.buyInfo.enabled && isInsidePeriod;
    }

    checkAndUpdateReserveAndBuy() {
        const { order, selected } = this.props;
        
        const canReserveTotalQuantity = order.selected.totalTicketQuantity <= order.reserveMax;
        const canBuyTotalQuantity = order.selected.totalTicketQuantity <= order.buyMax;
        let canReserveTicketQuantity = true, canBuyTicketQuantity = true;
     
     
        _.some(order.selected.tickets, function (selectedTicket) {
            const availableTicket = _.find(order.availableTickets, (available) => { return selectedTicket.id == available.id; });
            if (availableTicket) {
                canReserveTicketQuantity = availableTicket.canReserve !== false && selectedTicket.quantity <= availableTicket.reserveMax;
                canBuyTicketQuantity = availableTicket.canBuy !== false && selectedTicket.quantity <= availableTicket.buyMax;
            }
        });
      
        this.setState({
           // canReserveSelected: canReserveTotalQuantity && canReserveTicketQuantity && !order.clubCard && order.canReserve,
            canBuySelected: canBuyTotalQuantity && canBuyTicketQuantity
        });
       
    }

    generateReserveButton(selected) {
        const { bioDkActive, appLocale, configuration, canReserve, clubCardId, order } = this.props;
        const { canReserveSelected } = this.state;
        let slectedTckts = selected.tickets
        
        let anyfalse = true;

        _.forEach(order.availableTickets, (at) => {
            _.filter(slectedTckts, (st) => {
                
                if (at.name === st.name) {
                    if (anyfalse === true) anyfalse = at.canReserve;    // Sæt kun til false, da bare én false gør at man ikke kan reservere
                }
             
            })
            
        })
       
     
      
        let reserveClasses = canReserve && selected.tickets.length > 0 && canReserveSelected && anyfalse === true ? 'next green fade-in' : 'next green dim';
        if (configuration.openOrderInNewWindow) reserveClasses += ' no-top-margin';
        return !bioDkActive && !clubCardId && this.canReserveNow() ? <input type="button" id="reserve" className={reserveClasses} onClick={(e) => this.navNext(e,anyfalse)} value={LOCALE[appLocale].nextReserve} /> : null;
    }

    generateBuyButton(selected) {
        const { canBuySelected } = this.state;
        const { appLocale, configuration } = this.props;
        let buyClasses = selected.tickets.length > 0 && canBuySelected ? 'next green fade-in' : 'next green dim';
        if (configuration.openOrderInNewWindow) buyClasses += ' no-top-margin';
        return this.canBuyNow() ? <input type="button" id="buy" className={buyClasses} onClick={this.navNext} value={LOCALE[appLocale].nextBuy} /> : null;
    }

    generateBioKlubDkButton(selected) {
        const { canBuySelected } = this.state;
        const { appLocale, configuration } = this.props;
        let buyClasses = selected.tickets.length > 0 && canBuySelected ? 'next green fade-in' : 'next green dim';
        if (configuration.openOrderInNewWindow) buyClasses += ' no-top-margin';
        return this.canBuyNow() ? <input type="button" id="buy" data-buy-bio-klub-dk="buy-bio-klub-dk" className={buyClasses} onClick={this.navNext} value={LOCALE[appLocale].nextBuyBioKlubDk} /> : null;
    }

    generateBackButtons(configuration, showtime) {
        const { backTo, appLocale } = this.props;
        let backText = LOCALE[appLocale].backProgram;
        switch (backTo) {
            case ROUTES.PROGRAM:
                backText = LOCALE[appLocale].backProgram;
                break;
            case ROUTES.ALL_MOVIES:
                backText = LOCALE[appLocale].backAll;
                break;
            case ROUTES.FUTURE_MOVIES:
                backText = LOCALE[appLocale].backFuture;
                break;
        }

        const programButton = configuration.appMode !== APP_MODES.ORDERFLOW && configuration.appMode !== APP_MODES.MOVIE ?
            <input type="button" data-nav-to={`${backTo}`} className="back gray" onClick={this.navBack} value={backText} /> : null;

        const movieButton = configuration.appMode !== APP_MODES.ORDERFLOW ? <input type="button" data-nav-to={`${ROUTES.MOVIE}/${showtime.movieId}`} className="back gray" onClick={this.navBack} value={LOCALE[appLocale].backMovie} /> : null;

        return <div className="back-buttons">{programButton}{movieButton}</div>;
    }

    render() {
        const { showExtraPurchasesReset, showTicketWarning, modal_class } = this.state;
        const { organizerId, showtime, order, selected, configuration, actions, bioDkActive, initialFetchDone, appLocale, discountEnabled, discountActivated, bioKlubDkAvailable, flowInfo, seatSelectionInfo, unNumberedText, warnIfOnlyThisInfo } = this.props;
        const backButtons = !configuration.openOrderInNewWindow ? this.generateBackButtons(configuration, showtime) : null;
        const reserveButton = showtime ? this.generateReserveButton(selected) : null;
        const buyButton = showtime ? this.generateBuyButton(selected) : null;
        const buyBioKlubDkButton = showtime && bioKlubDkAvailable ? this.generateBioKlubDkButton(selected) : null;
        const disabled = !reserveButton && !buyButton ? <span className="buy-and-reserve-disabled">{LOCALE[appLocale].noOnlineOrder}</span> : null;
        const navButtonClasses = configuration.bottomBarAllwaysVisible ? 'nav-buttons fixed' : 'nav-buttons';
        
        // here are name, phone,emil, code and link from the URL parameters and we save them in session storage for later use.
        const search = window.location.search;
        const paramss = new URLSearchParams(search);
       // console.log(paramss.get('external'), 'external')
        paramss.get('name')  && sessionStorage.setItem('name',  paramss.get('name'))
        paramss.get('phone') && sessionStorage.setItem('phone', paramss.get('phone'))
        paramss.get('email') && sessionStorage.setItem('email', paramss.get('email'))
        paramss.get('code')  && sessionStorage.setItem('code',  paramss.get('code'))
        paramss.get('link')  && sessionStorage.setItem('link',  paramss.get('link'))
        
       
        const alertContainer = <AlertContainer selected={selected} organizerId={organizerId} />

        const seatSelection = showtime ? <SeatSelection appLocale={appLocale}
            showtime={showtime}
            seatsImage={order.seatsImage}
            selected={selected}
            organizerId={organizerId}
            selectedSeatsChanged={this.selectedSeatsChanged}
            moveSeats={actions.moveSeats}
            configuration={configuration}
            setError={this.setError}
            error={this.state.error}
            seatSelectionInfo={seatSelectionInfo}
            unNumberedText={unNumberedText}
        /> : null;

        let infoPopups = [];
        if (flowInfo) infoPopups.push(<Info key="info-1" canClose={true} text={`<strong>${flowInfo[appLocale]}</strong><br /><br />`} buttons={<input type="button" className="next" value="OK" />} />);
        if (showtime.info) infoPopups.push(<Info key="info-2" canClose={true} text={showtime.info} buttons={<input type="button" className="next" value="OK" />} />);
        if (showExtraPurchasesReset) infoPopups.push(<Info key="info-3" canClose={true} text={LOCALE[appLocale].extraPurchasesReset} buttons={<input type="button" className="next" onClick={() => this.setState({ showExtraPurchasesReset: false})} value="OK" />} />);
        if (showTicketWarning && warnIfOnlyThisInfo) infoPopups.push(<Info key="info-4" canClose={false} text={warnIfOnlyThisInfo[appLocale]} buttons={<input type="button" className="next" onClick={() => this.setState({ ticketWarningConfirmed: true, showTicketWarning: false })} value="OK" />} />);

        return (
            <div className="OrderContainer">
                {_.map(infoPopups, (info) => info)}

                <div className="table-container">
                    <AvailableTickets appLocale={appLocale}
                        order={order}
                        selected={selected}
                        ticketQuantityChanged={this.ticketQuantityChanged}
                        bioDkActive={bioDkActive}
                        freeSeats={showtime.freeSeats}
                        initialFetchDone={initialFetchDone}
                        discountEnabled={discountEnabled}
                        discountActivated={discountActivated}
                        submitDiscountCode={this.submitDiscountCode}
                        configuration={configuration}
                        appHeightChanged={actions.appHeightChanged}
                        locationId={showtime.locationId}
                    />
                    {alertContainer}
                  
                    {seatSelection}
                </div>
                <div className={configuration.openOrderInNewWindow ? 'nav-buttons window' : navButtonClasses}>
                    {backButtons}
                    <div className="next-buttons">
                        {buyButton}
                        {buyBioKlubDkButton}
                        {reserveButton}
                    </div>
                    {disabled}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
 
    return {
        
        currentPage: ownProps.currentPage,
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        order: state.order,
        selected: state.order.selected,
        configuration: state.organizer.configuration,
        bioKlubDkAvailable: state.order.bioKlubDk.available,
        bioDkActive: state.order.selected.vouchers.active != '',
        backTo: state.movielist.backTo,
        initialFetchDone: state.order.initialFetchDone,
        appLocale: state.app.locale,
        discountEnabled: state.order.discountEnabled,
        discountActivated: state.order.discountActivated,
        clubCardId: state.user.clubCardId,
        canReserve: state.order.canReserve,
        showExtraPurchasesReset: state.order.showExtraPurchasesReset,
        iframeId: state.app.iframeId,
        flowInfo: _.find(state.organizer.configuration.texts, (text) => { return text.id == 'Flow_Popup' }),
        unNumberedText: _.find(state.organizer.configuration.texts, (text) => { return text.id == 'flow-unnumbered-text' }),
        seatSelectionInfo: _.filter(state.organizer.configuration.texts, (text) => { return text.id == 'Seat_Info_Reserved' || text.id == 'Seat_Info_Sold' || text.id == 'Seat_Info_Free' || text.id == 'Seat_Info_Selected' }),
        warnIfOnlyThisInfo: _.find(state.organizer.configuration.texts, (text) => { return text.id == 'WarnIfOnlyThis_Text' })
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, orderActions, gaActions, { appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderContainer)