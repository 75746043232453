import './commercials.scss'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ROUTES } from '../navigation/route-constants'
import getCommercials from '../../actions/cms-actions'
import CommercialsList from './CommercialsList'

class CommercialsContainer extends React.Component {

    componentDidMount() {
        const { actions, organizerId, history } = this.props;
        actions.getCommercials(organizerId).then((result => {
            if (result.error || result.commercials.length == 0) history.replace(`${ROUTES.PROGRAM}?org=${organizerId}`);
        }));
    }

    render() {
        const { history, organizerId, commercials } = this.props;

        return (
            <div className="CommercialsContainer">
                <CommercialsList organizerId={organizerId} commercials={commercials} />
                <div className="nav-buttons">
                    <input type="button" className="next" onClick={() => history.push(`${ROUTES.PROGRAM}?org=${organizerId}`)} value="Dagens Program" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        organizerId: state.organizer.id,
        commercials: state.cms.commercials
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(getCommercials, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommercialsContainer))