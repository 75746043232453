export default {
    timeout: 3000,
    fetchUrl: extractEnvironmentFromUrl(),
    ticketgatePath: 'systemnative/ticketgate.asmx',

    soldOut: 'Denne forestilling er desværre udsolgt.',

    ticketType: {
        quantity: 'quantity',
        voucher: 'voucher'
    },

    pages: {
        order: 'order',
        customer: 'customer',
        dibs: 'dibs',
        receipt: 'receipt'
    },

    orderTypes: {
        reserve: 'reserve',
        buy: 'buy',
        buyReservation: 'buyReservation'
    }
}

function extractEnvironmentFromUrl() {
    const origin = window.location.origin;
    return origin.indexOf('localhost') !== -1 ? 'http://nosession.tickets.dk' : origin;
}