import React from 'react'
import _ from 'lodash'
import CONSTANTS from '../../orderflow-constants'
import QuantitySelector from './QuantitySelector'
import LOCALE from '../OrderLocale'

export default class Ticket extends React.Component {
    generatePrice(price) {
        if (price.from === price.to) return price.from;
        else if (price.from && price.to) return price.from + ' - ' + price.to;
        else if (price.from && !price.to) return price.from;
        else if (!price.from && price.to) return price.to;
        else return null;
    }

    generateTicketInteraction(availableTicket) {
        const { order, selectedTicket, bioDkActive, ticketQuantityChanged, canIncrementTotalQuantity, incrementTotalQuantity, decrementTotalQuantity, canIncrementTotalPoints, freeSeats } = this.props;
        switch (availableTicket.type) {
            case CONSTANTS.ticketType.quantity:
                return <QuantitySelector
                    availableTicket={availableTicket}
                    selectedTicket={selectedTicket}
                    order={order}
                    ticketQuantityChanged={ticketQuantityChanged}
                    canIncrementTotalQuantity={canIncrementTotalQuantity}
                    incrementTotalQuantity={incrementTotalQuantity}
                    decrementTotalQuantity={decrementTotalQuantity}
                    bioDkActive={bioDkActive}
                    canIncrementTotalPoints={canIncrementTotalPoints}
                    freeSeats={freeSeats}
                />
        }
    }

    render() {
        const { availableTicket, appLocale } = this.props;
        let price = availableTicket.price ? this.generatePrice(availableTicket.price) + ` ${LOCALE[appLocale].currency}` : null;
        price = availableTicket.points ? availableTicket.points + ` ${LOCALE[appLocale].points}` : null;
        const ticketInteraction = this.generateTicketInteraction(availableTicket);

        return (
            <div className="Ticket">
                <h3>{availableTicket.name}</h3>
                <div className="price">{price}</div>
                {ticketInteraction}
            </div>
        )
    }
}