import React from 'react'
import CONSTANTS from '../movielist-constants'
import AllMoviesListContainer from '../allmovies/AllMoviesListContainer'
import ProgramListContainer from '../program/ProgramListContainer'

export default class Program_AllFutureMovies extends React.Component {
    //NOTE! We need to pass down the props, so we get history from the router so the containers can navigate...

    render() {
        return (
            <div className="program-with-future">
                <ProgramListContainer {...this.props} />
                <AllMoviesListContainer {...this.props} listType={CONSTANTS.listTypes.allFuture} />
            </div>
        )
    }
}