import React from 'react'
import _ from 'lodash'
import ShowtimeType from './ShowtimeType'

export default class ShowtimeTypeList extends React.Component {

    render() {
        const { types } = this.props;
   //   console.log(types)
        return (
            <div className="ShowtimeTypeList" key={types}>
                
                {_.map(types, (type) =>  <ShowtimeType key={type.id} type={type} includeName={true} />)}
            </div>
        )
    }
}