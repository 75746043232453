import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { getParams } from '../../../utilities/location-utilities'
import { ROUTES } from '../../navigation/route-constants'
import { SITE_TYPES } from '../../../api/api-constants'
import xml2js from '../../../lib/xml-to-js'
import { useHistory, Link } from "react-router-dom";
import ReceiptContainerMulti from '../receipt/ReceiptContainerMulti'
import CONSTANTS from '../orderflow-constants'


export default function ReepayMultiLandingPage() {
    const querystrings = getParams();
    const search = window.location.search;

    const [eventId, setEventId] = useState('')
    const [datas, setDatas] = useState('')

    const paramss = new URLSearchParams(search);

    const org = paramss.get('org')
    let history = useHistory();


    useEffect(() => {
       
        const ref = querystrings.id
        const buyCode = querystrings.invoice
        const amount = querystrings.amount
       
        const transId = querystrings.transactionID
        const accept = querystrings.accept
        console.log(ref, buyCode, amount, transId)
        if (ref && buyCode && amount && transId && accept) {
            fetch(`${CONSTANTS.fetchUrl}/${CONSTANTS.ticketgatePath}/Book_Multi?TransactionID=${transId}&Reference=${ref}&Amount=${amount}&BuyCode=${buyCode}`, {
                method: 'GET',
                headers: {
                    'UserName': 'web',
                    'Password': 'web',
                    'OriginNo': '10',
                },
            })
                .then(res => res.text())
                .then(result => {
                   
                    const json = xml2js.parseString(result)
                    const data = json.message
                    setDatas(data)
                    data.transaction && setEventId(data.transaction.arrangements.arrangement[0]._eventGroupNo)
                   
                  

                    if (data) {
                        history.push({
                            pathname: '/filmklub-kvittering',
                            search: `?org=${org}`,
                            state: {
                                id: eventId,
                               
                                receiptHtml: data.transaction.reciept
                            }
                        })
                    }


                    
                })

        }
        else {
            fetch(`${CONSTANTS.fetchUrl}/${CONSTANTS.ticketgatePath}/CancelTransaction_Multi?TransactionID=${transId}`, {
                method: 'GET',
                headers: {
                    'UserName': 'web',
                    'Password': 'web',
                    'OriginNo': '10',
                },
            })
                .then(res => {
                    return res.text()
                })
                .then(result => {
                    //const json = xml2js.parseString(result)
                    //console.log(json)
                    history.push(`${ROUTES.FILMCLUB}?org=${org}`)

                })
           // history.push(`${ROUTES.FILMCLUB}/?org=${org}`);
        }
    },[])

    return (
        <>

            {/* <section style={{ textAlign: 'center', height: '300px', backgroundColor: '#2e2e2e', color: 'white', padding: '90px' }}>
               <h1>{sessionStorage.getItem('event_name')}</h1>
            </section>
             */}
        </>
    )
}

  