import _ from 'lodash'

export default {
    fetchEvents
}

const baseUrl = window.location.href.indexOf('localhost') != -1 ? 'https://localhost:44390/' : window.location.protocol + '//' + window.location.host + '/api/';
async function fetchEvents(organizerId) {
    return await fetch(`${baseUrl}billetten?organizerId=${organizerId}`, {
        headers: { 'Content-Type': 'text/xml;charset=UTF-8' },
    }).then(response => {
        if (response.ok) return response.json();
        else throw response
    }).then(events => extractEvents(events));
}


function extractEvents(events) {
    return _.map(events, (event) => {
        const show = event.shows ? event.shows[0] : {}; // Ser ud som om der altid kun er en per event (hvilket vi også kun understøtter i vores layout lige pt.)
        return {
            id: event.event_no,
            name: event.event_name,
            description: event.event_notes,
            posters: event.event_images ? {
                small: event.event_images.small,
                medium: event.event_images.medium,
                large: event.event_images.large,
            } : null,
            dateTime: show.show_time,
            ticketsUrl: show.show_link,
            price: show.prices ? extractPrice(show.prices) : null,
            venue: {
                name: show.location_name,
                address: `${show.location_street}, ${show.location_zip} ${show.location_city}`
            },
            isSoldOut: show.availability == 1
        }
    });
}

function extractPrice(prices) {
    const min = _.minBy(prices, (price) => price.min_price);
    const from = min ? min.min_price : null;
    const max = _.maxBy(prices, (price) => price.max_price);
    const to = max ? max.max_price : null;

    if (from === to) return from;
    else if (from && to) return from + ' - ' + to;
    else if (from && !to) return from;
    else if (!from && to) return to;
    else return null;
}