import './countdowntimer.scss'
import React from 'react'
import _ from 'lodash'
import { CountdownCircleTimer } from "react-countdown-circle-timer"

export default class CountdownTimer extends React.Component {

    countDownFormat(remainingTime) {
        if (remainingTime === 0) {
            const { handleTimeout } = this.props;
            if (handleTimeout) handleTimeout();
        }

        let minutes = Math.floor(remainingTime / 60);
        let seconds = remainingTime % 60;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        return `${minutes}:${seconds}`
    }

    getSeconds(timeLeft) {
        let a = _.split(timeLeft, ':');
        const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        return seconds;
    }

    render() {
        const { timeLeft, text } = this.props;
        const seconds = _.isNumber(timeLeft) ? timeLeft : this.getSeconds(timeLeft);

        return (
            <div className="CountdownTimer">
                <CountdownCircleTimer
                    isPlaying={true}
                    duration={seconds}
                    colors={[["#228B22", 0.8], ["#F7B801", 0.1], ["#FF0000", 0.1]]}
                    size={70}
                    strokeWidth={4}
                >
                    {({ remainingTime }) => this.countDownFormat(remainingTime)}
                </CountdownCircleTimer>
                <div className="text">{text}</div>
            </div >
        )
    }
}