import './vouchers.scss'
import React from 'react'
import _ from 'lodash'
import LOCALE from './VoucherLocale'
import { withTwoDecimals } from '../../../utilities/validation-utilities'
import { VOUCHER_TYPES } from '../../../api/api-constants';

export default class VoucherDisplay extends React.Component {
    constructor() {
        super();
        this.removeActiveVoucher = this.removeActiveVoucher.bind(this);
    }

    removeActiveVoucher(e) {
        const { vouchers, vouchersChanged } = this.props;
        const code = e.target.id;
        const updatedActiveVouchers = _.filter(vouchers.active, (voucher) => { return voucher.barCode != code });

        let updatedVouchers = '';
        _.map(updatedActiveVouchers, (voucher) => { return updatedVouchers += voucher.barCode + ','; });
        updatedVouchers = _.trimEnd(updatedVouchers, ',');
        vouchersChanged(updatedVouchers);
    }

    generateVouchers() {
        const { type, vouchers, tickets, showWithDecimals, appLocale } = this.props;
        const validVouchers = _.filter(vouchers.active, (voucher) => { return voucher.type == type });

        if (type == VOUCHER_TYPES.GIFTCARD) {
            return _.map(validVouchers, (giftcard, i) => {
                const giftCardAmount = showWithDecimals ? withTwoDecimals(giftcard.amount) : giftcard.amount;
                return <p key={i}>{giftcard.name}: {giftcard.barCode}
                    <span id={giftcard.barCode} className="clear icon-close" onClick={this.removeActiveVoucher}></span>
                    <strong>{LOCALE[appLocale].remaningAmount} {showWithDecimals ? withTwoDecimals(giftcard.remainingAmount) : giftcard.remainingAmount} {LOCALE[appLocale].currency}</strong>
                    <span>{giftCardAmount} {LOCALE[appLocale].currency}</span>
                </p>
            });
        } else if (type == VOUCHER_TYPES.TICKETS) {
            const ticketItems = _.map(tickets, (ticket, i) => {
                const voucher = _.find(validVouchers, (coupon) => { return coupon.id == ticket.id });
                if (!voucher) return <p key={'t' + i} className="ticket">{ticket.quantity} x {ticket.name}</p>
            });

            const couponItems = _.map(validVouchers, (voucher, i) => {
                return <p key={'v' + i}><label>1 x {voucher.name}: {voucher.barCode}</label><span id={voucher.barCode} className="clear icon-close" onClick={this.removeActiveVoucher}></span></p>
            });

            return _.concat(ticketItems, couponItems);
        }
    }

    render() {
        const { type } = this.props;

        return (
            <div className={`VoucherDisplay ${type}`}>
                <div className="active">{this.generateVouchers()}</div>
            </div>
        );
    }
} 