import '../css/movielists.scss'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { getParams } from '../../../utilities/location-utilities'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../movielist-constants'
import { appHeightChanged } from '../../../actions/app-actions'
import { getFutureMovies, selectedMovieChanged, changeSelectedShowtime } from '../../../actions/movielist-actions'
import MovieList from './FutureMoviesList'
import { setContainerClasses } from '../shared'

class FutureMovieListContainer extends React.Component {
    constructor() {
        super();
        this.movieClicked = this.movieClicked.bind(this);
    }

    componentDidMount() {
        const { actions, movies, templateIds, organizerIds, iframeId } = this.props;
        if (movies.length == 0) actions.getFutureMovies(templateIds, organizerIds);

        if (window.parent != window) actions.appHeightChanged(iframeId, false, false, true);
        else window.scrollTo(0, sessionStorage.getItem("scroll-position"));
    }

    componentWillUnmount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, false, true, false);
        else sessionStorage.setItem("scroll-position", window.scrollY);
    }

    movieClicked(id) {
        const { organizerIds, actions, history } = this.props;
        actions.selectedMovieChanged(id, history.location.pathname).then(() => history.push(`${ROUTES.MOVIE}/${id}?org=${organizerIds}`));
    }

    render() {
        const { appLocale, pageHeader, movies, posterBanners, columns, organizerIds, showtimes } = this.props;
        const header = pageHeader ? <div className="page-header" dangerouslySetInnerHTML={{ __html: pageHeader }}></div> : null;
        const classes = setContainerClasses(CONSTANTS.listTypes.future, columns);

        return (
            <div className={classes}>
                <div className="flex-container">{header}</div>
                <MovieList appLocale={appLocale}
                    columns={columns}
                    movies={movies}
                    movieClicked={this.movieClicked}
                    posterBanners={posterBanners}
                    organizerIds={organizerIds}
                    showtimes={showtimes}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const config = state.organizer.configuration;
    const listConfig = config.movielists;
    const queryParams = getParams();

    return {
        templateIds: queryParams.templateid || state.organizer.futureTemplateId,
        organizerIds: state.movielist.organizerIds || queryParams.org,
        pageHeader: config.pageHeaders.futureMovies,
        columns: listConfig.future.columns,
        movies: state.movielist.futureMovies,
        showtimes: state.movielist.showtimes,
        posterBanners: config.posterBanners,
        appLocale: state.app.locale,
        openOrderInNewWindow: config.openOrderInNewWindow,
        iframeId: state.app.iframeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { getFutureMovies, selectedMovieChanged, changeSelectedShowtime, appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FutureMovieListContainer)