import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateQueue, startOrder } from '../../actions/order-actions'
import CountdownTimer from '../countdowntimer/CountdownTimer'

class Queue extends React.Component {
    poll = null;
    interval = 30000; // 30 seconds

    constructor(props) {
        super(props);
        this.handlePoll = this.handlePoll.bind(this);
        this.poll = setInterval(this.handlePoll, this.interval);
        this.state = {
            show: props.show,
            queue: props.queue
        };
    }

    componentWillUnmount() {
        clearInterval(this.poll);
    }

    handlePoll() {
        const { queue, actions } = this.props;
        actions.updateQueue(queue.id).then((result) => {
            if (result.queue) this.setState({ queue: result.queue });
            else {
                clearInterval(this.poll);
                console.log('handlepoll clear');
            }
        });
    }

    render() {
        const { show, queue } = this.state;

        return (
            <div className={`Queue overlay ${show ? ' fade-in' : ' fade-out'}`}>
                <div className="overlay-content">
                    <h1>{queue.yourPlace}</h1>
                    <p>
                        {queue.totalQueue}<br />
                    </p>

                    <label>Næste opdatering:</label>
                    <CountdownTimer key={Math.random()} timeLeft={30} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        show: ownProps.show || true,
        appLocale: state.app.locale
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ updateQueue, startOrder }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Queue)