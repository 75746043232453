import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import ShowtimeScheduleWeek from './ShowtimeScheduleWeek'
import ShowtimeTypeList from './ShowtimeTypeList';


export default class ShowtimeSchedule extends React.Component {

    constructor(props) {
        super(props);
        //props.func({ take: this.state.take })

        const { currentStartDate, movie, take } = this.props;
        const schedule = document.querySelector('.ShowtimeScheduleWeek');

        //const showtimeTypes = [];
        //const showtimesByDate = _.groupBy(movie.validShowtimes, (showtime) => {
        //    if (showtime.showInfo.enabled && showtime.type && !_.find(showtimeTypes, showtime.type))
        //        showtimeTypes.push(showtime.type);

        //    return moment(showtime.dateTime).format('YYYY-MM-DD');
        //});

        //let startDate = currentStartDate;
        //if (!startDate && movie.validShowtimes.length > 0) startDate = moment(movie.validShowtimes[0].dateTime);
       
        //this.state = {
        //    showtimesByDate,
        //    currentStartDate: startDate || moment(),
        //    showtimeTypes,
        //    weekNo: moment().week(),
        //    take: 7 //days in schedule
        //}

        // this.nextWeek = this.nextWeek.bind(this);
       // this.previousWeek = this.previousWeek.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
        this.onMovieClick = this.onMovieClick.bind(this)

    }

    componentDidMount() {
        const schedule = document.querySelector('.ShowtimeScheduleWeek');
       //console.log(Math.round(Math.min(schedule.scrollWidth, schedule.offsetWidth, schedule.clientWidth)))
        Math.round(Math.min(schedule.scrollWidth, schedule.offsetWidth, schedule.clientWidth)) > 575 && window.addEventListener('resize', this.handleWindowResize);
        Math.round(Math.min(schedule.scrollWidth, schedule.offsetWidth, schedule.clientWidth)) > 575 && this.setState({ take: this.determineTakeFromScreenWidth() });
    }

    componentWillUnmount() {
        const schedule = document.querySelector('.ShowtimeScheduleWeek');

        Math.round(Math.min(schedule.scrollWidth, schedule.offsetWidth, schedule.clientWidth)) > 575 && window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = _.debounce(() => {
        const schedule = document.querySelector('.ShowtimeScheduleWeek');

        Math.round(Math.min(schedule.scrollWidth, schedule.offsetWidth, schedule.clientWidth)) > 575 && this.setState({ take: this.determineTakeFromScreenWidth() });
    }, 200)

    determineTakeFromScreenWidth() {
        const schedule = document.querySelector('.ShowtimeScheduleWeek');
        const scheduleWidth = Math.round(Math.min(schedule.scrollWidth, schedule.offsetWidth, schedule.clientWidth));
        console.log(scheduleWidth, 'width')
        const showtimeItem = document.querySelector('.showtimes-on-date');
        //  console.log(showtimeItem)
        const showtimeItemWidth = Math.round(Math.max(showtimeItem.scrollWidth, showtimeItem.offsetWidth, showtimeItem.clientWidth));
        return Math.floor(scheduleWidth / showtimeItemWidth);
    }

    componentDidUpdate(prevProps) {
        const { movie, appLocale, currentStartDate } = this.props;
        //Update showtimes if another version of same movie is selected
        if (movie.id != prevProps.movie.id || appLocale != prevProps.appLocale)
            this.setState({ showtimesByDate: _.groupBy(movie.validShowtimes, (showtime) => moment(showtime.dateTime).format('YYYY-MM-DD')) });

        if (currentStartDate != prevProps.currentStartDate)
            this.setState({ currentStartDate });
    }

    //anyInFuture(currentStartDate) {
    //    const { showtimesByDate, take } = this.state;
    //    const nextStartDate = currentStartDate.clone().add(take, 'days');
    //    return _.some(showtimesByDate, (showtimes, dateKey) => moment(dateKey).isSameOrAfter(nextStartDate, 'day'));
    //}

    //anyInPast(currentStartDate) {
    //    const { showtimesByDate } = this.state;
    //    return _.some(showtimesByDate, (showtimes, dateKey) => moment(dateKey).isBefore(currentStartDate, 'day'));
    //}

    //nextWeek() {
    //    const { currentStartDate, take, weekNo } = this.state;
    //    this.setState({ currentStartDate: currentStartDate.add(take, 'days') });
    //    this.setState({ weekNo: weekNo + 1 })

    //}

    //previousWeek() {
    //    const { currentStartDate, take, weekNo } = this.state;
    //    this.setState({ currentStartDate: currentStartDate.subtract(take, 'days') });
    //    this.setState({ weekNo: weekNo - 1 })

    //}

    // onMovieClick (e)  {
    //     e.preventDefault();
    //     const { movieClicked } = this.props
    //    const movieId = e.currentTarget.dataset.movieId || e.target.id;

    //    if (e.target.tagName == 'IMG' || e.target.tagName == 'H1' || e.target.tagName == 'INPUT' || _.includes(e.target.classList, 'poster-banner'))
    //        movieClicked(movieId);
    //}


    render() {
        const { currentStartDate, showtimesByDate, take } = this.state;
        
        const { filterIds, showExpired, showtimeClicked, appLocale, movie, movieClicked, nextWeek, previousWeek, weekNo, showtimeTypes } = this.props;
        //console.log(take, 'take')
        //const infoText = moreInfoText ? moreInfoText[appLocale] : LOCALE[appLocale].movieInfo;

        //const disabled = 'week-nav disabled';
        //const prevWeekClasses = this.anyInPast(currentStartDate) ? 'week-nav'   : disabled;
        //const nextWeekClasses = this.anyInFuture(currentStartDate) ? 'week-nav' : disabled;

        return (
            <section className="showtimeTypeContainer">

                {
                    window.location.href.includes('film-info') ?
                        ((!filterIds || filterIds.length > 1) && showtimeTypes.length > 0 && (movie.versions && movie.versions.length <= 0 || movie.versions.length === 1) ? <ShowtimeTypeList types={showtimeTypes} /> : null)
                        :
                        (!filterIds || filterIds.length > 1) && showtimeTypes.length > 0 ? <ShowtimeTypeList types={showtimeTypes} /> : null
                }

                {/*<input type="button" className="movieinfo-btn" data-movie-id={movie.id} onClick={this.onMovieClick} value='Filminfo' />*/}


            </section>
        )
    }
}