import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import ShowtimeScheduleWeek from './ShowtimeScheduleWeek'
import ShowtimeTypeList from './ShowtimeTypeList';

export default class ShowtimeSchedule extends React.Component {

    constructor(props) {
        super(props);
        const { currentStartDate, movie } = this.props;
        const schedule = document.querySelector('.ShowtimeScheduleWeek');

        const showtimeTypes = [];
        const showtimesByDate = _.groupBy(movie.validShowtimes, (showtime) => {
            if (showtime.showInfo.enabled && showtime.type && !_.find(showtimeTypes, showtime.type))
                showtimeTypes.push(showtime.type);

            return moment(showtime.dateTime).format('YYYY-MM-DD');
        });

        let startDate = currentStartDate;
        if (!startDate && movie.validShowtimes.length > 0) startDate = moment(movie.validShowtimes[0].dateTime);

        this.state = {
            showtimesByDate,
            currentStartDate: startDate || moment(),
            showtimeTypes,
            weekNo: moment().week(),
            take: 7 //days in schedule
        }

        this.nextWeek = this.nextWeek.bind(this);
        this.previousWeek = this.previousWeek.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
    }

    componentDidMount() {
        const schedule = document.querySelector('.ShowtimeScheduleWeek');
       // console.log(Math.round(Math.min(schedule.scrollWidth, schedule.offsetWidth, schedule.clientWidth)))
        window.addEventListener('resize', this.handleWindowResize);
       this.setState({ take: this.determineTakeFromScreenWidth() });
    }

    componentWillUnmount() {
        const schedule = document.querySelector('.ShowtimeScheduleWeek');

         window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = _.debounce(() => {
        const schedule = document.querySelector('.ShowtimeScheduleWeek');

        this.setState({ take: this.determineTakeFromScreenWidth() });
    }, 200)

    determineTakeFromScreenWidth() {
        const schedule = document.querySelector('.ShowtimeScheduleWeek');
        const scheduleWidth = Math.round(Math.min(schedule.scrollWidth, schedule.offsetWidth, schedule.clientWidth));
      //  console.log(scheduleWidth, 'width')
        const showtimeItem = document.querySelector('.showtimes-on-date');
      //  console.log(showtimeItem)
        const showtimeItemWidth = Math.round(Math.max(showtimeItem.scrollWidth, showtimeItem.offsetWidth, showtimeItem.clientWidth));
        return Math.floor(scheduleWidth / showtimeItemWidth);
    }

    componentDidUpdate(prevProps) {
        const { movie, appLocale, currentStartDate } = this.props;
        //Update showtimes if another version of same movie is selected
        if (movie.id != prevProps.movie.id || appLocale != prevProps.appLocale)
            this.setState({ showtimesByDate: _.groupBy(movie.validShowtimes, (showtime) => moment(showtime.dateTime).format('YYYY-MM-DD')) });

        if (currentStartDate != prevProps.currentStartDate)
            this.setState({ currentStartDate });
    }

    anyInFuture(currentStartDate) {
        const { showtimesByDate, take } = this.state;
      //  console.log(showtimesByDate, 'shows by date')

        const nextStartDate = currentStartDate.clone().add(take, 'days');
        return _.some(showtimesByDate, (showtimes, dateKey) => moment(dateKey).isSameOrAfter(nextStartDate, 'day'));
    }

    anyInPast(currentStartDate) {
        const { showtimesByDate } = this.state;
        return _.some(showtimesByDate, (showtimes, dateKey) => moment(dateKey).isBefore(currentStartDate, 'day'));
    }

    nextWeek() {
        const { currentStartDate, take, weekNo } = this.state;
        this.setState({ currentStartDate: currentStartDate.add(take, 'days') });
        this.setState({ weekNo: weekNo + 1 })

    }

    previousWeek() {
        const { currentStartDate, take, weekNo } = this.state;
        this.setState({ currentStartDate: currentStartDate.subtract(take, 'days') });
        this.setState({ weekNo: weekNo - 1 })

    }


    render() {
        const { currentStartDate, showtimesByDate, showtimeTypes, take } = this.state;
     //  console.log(this.showtimetimesByDate, 'showtimes by date')
        const { filterIds, showExpired, showtimeClicked, appLocale, movie } = this.props;
     
        const disabled = 'week-nav disabled';
        const prevWeekClasses = this.anyInPast(currentStartDate) ? 'week-nav' : disabled;
        const nextWeekClasses = this.anyInFuture(currentStartDate) ? 'week-nav' : disabled;

        return (
            <div className="ShowtimeSchedule">
            
                <div className="schedule">
                    <div className={prevWeekClasses} onClick={this.previousWeek}>&#10094;</div>
                    <ShowtimeScheduleWeek
                        currentStartDate={currentStartDate}
                        showtimesByDate={showtimesByDate}
                        appLocale={appLocale}
                        showExpired={showExpired}
                        showtimeClicked={showtimeClicked}
                        take={take}
                    />
                    
                    <div className={nextWeekClasses} onClick={this.nextWeek}>&#10095;</div>
                </div>

                {
                    window.location.href.includes('film-info') ?
                        ((!filterIds || filterIds.length > 1) && showtimeTypes.length > 0 && (movie.versions && movie.versions.length <= 0 || movie.versions.length === 1) ? <ShowtimeTypeList types={showtimeTypes} /> : null)
                        :
                        (!filterIds || filterIds.length > 1) && showtimeTypes.length > 0 ? <ShowtimeTypeList types={showtimeTypes} /> : null
                }
               
                   
                
            </div>
        )
    }
}