export default {
    da: {
        next: 'Næste  ❯',
        nextReserve: 'Reserver  ❯',
        nextBuy: 'Køb  ❯',
        back: '❮  Tilbage',

        currency: 'kr.',
        points: 'point'
    },

    en: {
        next: 'Next  ❯',
        nextReserve: 'Reserve  ❯',
        nextBuy: 'Buy  ❯',
        back: '❮  Back',

        currency: 'DKK',
        points: 'points'
    },

    fo: {
        next: 'Næsta  ❯',
        nextReserve: 'Avlegg  ❯',
        nextBuy: 'Keyp  ❯',
        back: '❮  Aftur',

        currency: 'kr.',
        points: 'stig'
    }

    //new: {
    //    next: '  ❯',
    //    nextReserve: '  ❯',
    //    nextBuy: '  ❯',
    //    back: '❮  ',

    //    currency: '',
    //    points: ''
    //}
}