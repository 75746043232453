import './bioklubdk.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import * as orderActions from '../../../actions/order-actions'
import * as customerActions from '../../../actions/customer-actions'
import * as gaActions from '../../../actions/ga-actions'
import { ROUTES } from '../../navigation/route-constants'
import VoucherInput from '../vouchers/VoucherInput'
import VoucherDisplay from '../vouchers/VoucherDisplay'
import VoucherErrors from '../vouchers/VoucherErrors'
import { VOUCHER_TYPES } from '../../../api/api-constants'
import { appHeightChanged } from '../../../actions/app-actions'
import LOCALE from './BioKlubDkLocale'

class BioKlubDkContainer extends React.Component {
    constructor(props) {
        super(props);
        this.navBack = this.navBack.bind(this);
        this.navNext = this.navNext.bind(this);
    }

    componentDidMount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId);
    }

    navBack() {
        const { history } = this.props;
        history.goBack();
    }

    navNext() {
        const { organizerId, showtime, history } = this.props;
        history.push(`${ROUTES.ORDERFLOW.EXTRA_PURCHASES}/${showtime.movieId}/${showtime.id}?org=${organizerId}`);
    }

    render() {
        const { bioKlubDk, bottomBarAllwaysVisible, appLocale, vouchers, tickets, actions, voucherConstraints } = this.props;
        const image = bioKlubDk.image ? <img src={bioKlubDk.image} /> : null;

        return (
            <div className="BioKlubDkContainer">
                <div className="content-container">
                    <h1>{LOCALE[appLocale].bioKlubDk}</h1>
                    <p>{LOCALE[appLocale].instructions}</p>

                    {vouchers.active.length > 0 ? <div>
                        <p>{LOCALE[appLocale].yourTickets}:</p>
                        <VoucherDisplay type={VOUCHER_TYPES.TICKETS}
                            appLocale={appLocale}
                            tickets={tickets}
                            vouchers={vouchers}
                            vouchersChanged={actions.vouchersChanged}
                        /></div> : null}

                    <VoucherInput type={VOUCHER_TYPES.BIOKLUBDK}
                        appLocale={appLocale}
                        vouchers={vouchers}
                        voucherConstraints={voucherConstraints}
                        vouchersChanged={actions.vouchersChanged}
                        appHeightChanged={actions.appHeightChanged}
                        inputInfo={LOCALE[appLocale].info}
                    />
                    <VoucherErrors errors={vouchers.errors} />

                    {!image ? LOCALE[appLocale].description : image}
                </div>

                <div className={bottomBarAllwaysVisible ? 'nav-buttons fixed' : 'nav-buttons'}>
                    <input type="button" className="back gray" onClick={this.navBack} value={LOCALE[appLocale].back} />
                    <input type="button" className="next" onClick={this.navNext} value={LOCALE[appLocale].next} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        orderType: state.order.orderType,
        bottomBarAllwaysVisible: state.organizer.configuration.bottomBarAllwaysVisible,
        bioKlubDk: state.order.bioKlubDk,
        totalPrice: state.order.totalPrice,
        customer: state.customer,
        transactionId: state.order.selected.transactionId,
        appLocale: state.app.locale,
        vouchers: state.order.selected.vouchers,
        tickets: state.order.selected.tickets,
        voucherConstraints: state.order.voucherConstraints,
        iframeId: state.app.iframeId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, orderActions, customerActions, gaActions, { appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BioKlubDkContainer)