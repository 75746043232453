import _ from 'lodash'
import * as ticketgate from '../api/ticketgate-api'
import orderflow from '../api/orderflow-api'
import CONSTANTS from '../components/orderflow/orderflow-constants'
import * as orderActions from './order-actions'
import { customerChanged, creditCardChanged } from './customer-actions'
import { receiptSuccess } from './receipt-actions'

// CLUBCARD LOGIN
export function clubCardLogin(email, password) {
    return (dispatch, getState) => {
        dispatch(clubCardLoginRequest());

        return ticketgate.getUser(email, password)
            .then((result) => {
                const showtimeId = getState().movielist.selectedShowtime.id;     
                const transactionId = getState().order.selected.transactionId;
                if (!result.error) {
                    return dispatch(orderActions.getAvailableTicketsAndExtraPurchases(showtimeId, result.clubCardId, 0, transactionId))
                        .then((response) => {
                            if (response.clubCard == null || response.clubCard.id == 0) {
                                const appLocale = getState().app.locale;
                                const errorText = _.find(getState().organizer.configuration.texts, (text) => { return text.id == 'NotMemberInfo' }) || 'Du er ikke klubmedlem i denne biograf.';
                                return dispatch(clubCardLoginFailed({ message: errorText[appLocale] }));
                            }
                            else {
                                dispatch(clubCardLoginSuccess(result.clubCardId))
                                dispatch(customerChanged(result.customer))
                                dispatch(creditCardChanged(result.creditCard));
                                dispatch(orderActions.orderTypeChanged(CONSTANTS.orderTypes.buy));
                                return dispatch(orderActions.calculatePoints());
                            }
                        });
                } else return result;
            }, error => dispatch(clubCardLoginFailed(error)));
    }
}

export const CLUBCARD_LOGIN_REQUEST = 'CLUBCARD_LOGIN_REQUEST'
export const CLUBCARD_LOGIN_SUCCESS = 'CLUBCARD_LOGIN_SUCCESS'
export const CLUBCARD_LOGIN_FAILED = 'CLUBCARD_LOGIN_FAILED'

const clubCardLoginRequest = () => {
    return {
        type: CLUBCARD_LOGIN_REQUEST,
        requestedAt: Date.now(),
    }
}

const clubCardLoginSuccess = (clubCardId) => {
    return {
        type: CLUBCARD_LOGIN_SUCCESS,
        succeededAt: Date.now(),
        clubCardId
    }
}

const clubCardLoginFailed = (error) => {
    return {
        type: CLUBCARD_LOGIN_FAILED,
        failedAt: Date.now(),
        error: {
            ...error,
            message: error.message || 'Ugyldig email eller kode',
            close: true,
            show: false
        }
    }
}

// TRANSACTIONS
export function getUserTransactions(emailOrPhone, password) {
    return (dispatch) => {
        dispatch(transactionsRequest());
        return orderflow.fetchUserTransactions(emailOrPhone, password)
            .then((result) => {
                result.transactionId = result.id; // for the receipt...
                dispatch(receiptSuccess(result));
                return dispatch(transactionsSuccess(result))
            }, error => dispatch(transactionsFailed(error)));
    }
}

export const TRANSACTIONS_REQUEST = 'TRANSACTIONS_REQUEST'
export const TRANSACTIONS_SUCCESS = 'TRANSACTIONS_SUCCESS'
export const TRANSACTIONS_FAILED = 'TRANSACTIONS_FAILED'

const transactionsRequest = () => {
    return {
        type: TRANSACTIONS_REQUEST,
        requestedAt: Date.now(),
    }
}

const transactionsSuccess = (transactions) => {
    return {
        type: TRANSACTIONS_SUCCESS,
        succeededAt: Date.now(),
        transactions
    }
}

const transactionsFailed = (error) => {
    return {
        type: TRANSACTIONS_FAILED,
        failedAt: Date.now(),
        error: {
            ...error,
            message: 'Prøv igen senere.'
        }
    }
}

// NEWSLETTERS
export function getNewsletters(organizerId, customerEmail) {
    return (dispatch) => {
        dispatch(newslettersRequest());
        return orderflow.fetchNewsletters(organizerId, customerEmail)
            .then((result) => {
                return dispatch(newslettersSuccess(result, customerEmail))
            }, error => dispatch(newslettersFailed(error)));
    }
}

export const NEWSLETTERS_REQUEST = 'NEWSLETTERS_REQUEST'
export const NEWSLETTERS_SUCCESS = 'NEWSLETTERS_SUCCESS'
export const NEWSLETTERS_FAILED = 'NEWSLETTERS_FAILED'
export const NEWSLETTER_CHANGED = 'NEWSLETTER_CHANGED'

const newslettersRequest = () => {
    return {
        type: NEWSLETTERS_REQUEST,
        requestedAt: Date.now(),
    }
}

const newslettersSuccess = (newsletters, customerEmail) => {
    return {
        type: NEWSLETTERS_SUCCESS,
        succeededAt: Date.now(),
        newsletters,
        customerEmail
    }
}

const newslettersFailed = (error) => {
    return {
        type: NEWSLETTERS_FAILED,
        failedAt: Date.now(),
        error
    }
}

export function newsletterChanged(id, checked) {
    return (dispatch, getState) => {
        const customerEmail = getState().user.customerEmail;
        const newsletters = getState().user.newsletters;
        const newsletter = _.find(newsletters, ['id', id]);
        newsletter.isSubscribed = checked;

        orderflow.postNewsletter({
            id,
            isSubscribed: checked,
            customerEmail
        }).then(() => dispatch(newslettersSuccess(newsletters, customerEmail)));
    }
}