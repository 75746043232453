import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import { ROUTES } from '../navigation/route-constants'
import { isInsideShowPeriod } from '../movielists/shared'
import ShowtimeTypeIcon from '../showtime/ShowtimeType'

class Showtime extends React.Component {
    countDownFrom = 60;

    constructor(props) {
        super(props);
        const { showtime, showExpired, showBackInTime } = props;
        const { hidden, opensAt } = this.checkForTimerScenarios(showtime, showExpired, showBackInTime);

        this.state = {
            hidden,
            opensAt,
            minutes: null,
            seconds: null
        };

        this.showtimeClicked = this.showtimeClicked.bind(this);
        this.showtimeClicked_eventive = this.showtimeClicked_eventive.bind(this);
    }

    checkForTimerScenarios(showtime, showExpired, showBackInTime) {
        const dateTimeNow = moment();
        let opensAt = null;
        let hidden = !isInsideShowPeriod(showtime);
        //console.log(showExpired, 'show expired')
        if ((showExpired || showBackInTime) && showtime.showInfo.enabled) hidden = false;

        if (hidden) {
            opensAt = moment(showtime.showInfo.start);
            this.setShowTimer(showtime);
        } else {
            const buyEnabled = showtime.buyInfo.enabled;
            const buyStart = moment(showtime.buyInfo.start).add(55, 'seconds');

            const reserveEnabled = showtime.reserveInfo.enabled;
            const reserveStart = moment(showtime.reserveInfo.start).add(55, 'seconds');

            if (buyEnabled && !reserveEnabled)
                opensAt = buyStart;
            else if (reserveEnabled && !buyEnabled)
                opensAt = reserveStart;
            else if (buyEnabled && reserveEnabled)
                opensAt = moment.min(buyStart, reserveStart);

            if (opensAt != null) {
                const isInFuture = opensAt > dateTimeNow;
                const isNotDefault = !opensAt.isSame('0001-01-01', 'day');
                if (isInFuture && isNotDefault) this.setCountDown(opensAt);
                else opensAt = null;
            }
        }

        return { hidden, opensAt };
    }
  

    setShowTimer(showtime) {
        const dateTime = moment(showtime.showInfo.start);
        const diff = dateTime.diff(moment());

        this.timeout = setTimeout(() => {
            // Checking again to see if we are allowed to show now or if we need to start the next timer
            const { hidden, opensAt } = this.checkForTimerScenarios(showtime);

            this.setState({
                hidden,
                opensAt,
            });
        }, diff);
    }

    setCountDown(opensAt) {
        this.countDown = setInterval(() => {
            const dateTimeNow = moment();
            const diff = opensAt.diff(dateTimeNow);
            const duration = moment.duration(diff);

            if (duration.asMinutes() <= this.countDownFrom) {
                const { minutes, seconds } = this.state;

                if (minutes === 0 && seconds === 0) {
                    clearInterval(this.countDown);
                    this.setState({
                        opensAt: null,
                        minutes: null,
                        seconds: null
                    });
                }
                else {
                    this.setState({
                        minutes: duration.minutes(),
                        seconds: duration.seconds()
                    });
                }
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.countDown);
        clearTimeout(this.timer);
    }

    canReserveNow(dateTimeNow) {
        const { showtime } = this.props;
        const isInsidePeriod = dateTimeNow >= moment(showtime.reserveInfo.start) && dateTimeNow <= moment(showtime.reserveInfo.end);
        return showtime.reserveInfo.enabled && isInsidePeriod;
    }

    canBuyNow(dateTimeNow) {
        const { showtime } = this.props;
        const isInsidePeriod = dateTimeNow >= moment(showtime.buyInfo.start) && dateTimeNow <= moment(showtime.buyInfo.end);
        return showtime.buyInfo.enabled && isInsidePeriod;
    }

    showtimeClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        const target = e.currentTarget;
        if (target.className.indexOf('disabled') === -1) {
            const dateTimeNow = moment();
            const isInFuture = moment(target.dataset.dateTime) > dateTimeNow && (this.canReserveNow(dateTimeNow) || this.canBuyNow(dateTimeNow));
            if (!isInFuture) target.className += ' disabled';
            else {
                const showtimeId = target.id;
                const organizerId = target.dataset.organizerId;
                this.props.showtimeClicked(organizerId, showtimeId);
            }
        }
    }

    showtimeClicked_eventive(e) {
        //e.preventDefault();
        e.stopPropagation();
    }

    generateCountdown(opensAt, dateTimeNow) {
        const { minutes, seconds } = this.state;

        let countdown = null
        if (opensAt != null && opensAt > dateTimeNow) {
            countdown = minutes == null && seconds == null ? <div className="count-down">Aktiv d.{opensAt.format('DD/MM HH:mm')}</div> : null

            if (countdown == null)
                countdown = opensAt.isSame(dateTimeNow, 'day') ?
                    <div className="count-down">Aktiv om {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div> : null
        }
        return countdown
    }

    render() {
        const { hidden, opensAt } = this.state;
        const { showtime, text, showExpired, showBackInTime, filterIds, withLocation } = this.props;      
        const dateTimeNow = moment();     
        const isInFuture = moment(showtime.dateTime) > dateTimeNow;
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const origin = params.get('origin')
       //  console.log(origin)
        // if( showtime.showInfo.start.format() )
        let theYear = moment(showtime.showInfo.start).format('YYYY')
       // console.log(moment(showtime.showInfo.end))
        let countdown = this.generateCountdown(opensAt, dateTimeNow);
        let classes = isInFuture && !countdown  ? 'Showtime' : 'Showtime disabled';
        classes += withLocation ? ' with-location' : '';
        if (countdown == null) countdown = !showExpired && !showBackInTime && !isInFuture ? <div className="count-down">Lukket</div> : null;
      
        const type = (!filterIds || filterIds.length > 1) && showtime.type && !hidden  ? <ShowtimeTypeIcon type={showtime.type} includeName={false} /> : null;
        if (countdown) classes += ' has-count-down';

        if (showtime.id / 1000 > 999)
        {
            return hidden ? <span id={showtime.id} className="Showtime disabled full-width">Tid vises d.{opensAt.format('DD/MM HH:mm')} </span> :
                <a href={`https://buster.dk/billet/?ticketID=${showtime.eventiveID}`}
                    className={classes}
                    onClick={this.showtimeClicked_eventive}
                    target="_blank">
                    {text}
                </a>
        //        <a href={`https://cphdox.dk/da/billet/?ticketID=${showtime.eventiveID}`}
        //            className={classes}
        //            onClick={this.showtimeClicked_eventive}
        //            target="_blank">
        //            {text}
        //        </a>
        }
        else
        {
            return hidden ? theYear === '0001' ? <span className="Showtime disabled full-width" > {moment(showtime.showInfo.end).format('HH:mm')}</span>
              :  < div id={showtime.id} style={{ fontSize: '13px', display: 'flex', flexDirection: 'column', paddingTop: '-2px' }
        } className = "Showtime disabled full-width" >
                <span style={{ lineHeight: '10px',marginRight:0}}>Tid vises d.{opensAt.format('DD/MM')}</span>
                <span style={{ fontSize: '16px' }}>{opensAt.format('HH:mm')}</span></div>
                :
                <a href={`${window.location.origin}${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${showtime.organizerId}${origin!==null?'&origin='+origin:''}`}
                    id={showtime.id}
                    className={classes}
                    data-date-time={showtime.dateTime}
                    data-organizer-id={showtime.organizerId}
                    onClick={this.showtimeClicked}>
                    {text} {type}
                    {countdown}
                </a>
        }
    }
}


const mapStateToProps = (state, ownProps) => {
   //console.log(state)
    return {
        filterIds: state.movielist.filterIds
    }
}

export default connect(mapStateToProps)(Showtime)