import React, { useState } from 'react'
import _, { forEach } from 'lodash';
import './modal.scss'
import "./custom.slider.scss";


export default function Modal({ children, shown, close, seat_img, organizerId, extractedSeats, seatsInfo, alrtImg, button_text, bgColor, seatsInfoArr, seatsArr}) {
   
    const [activeIndex, setActiveIndex] = useState(0);

   
    const slideNext = () => {
       
        setActiveIndex((val) => {
            if (val >= seatsInfoArr.length - 1) {
                return 0;
            } else {
                return val + 1;
            }
        });
    };

    const slidePrev = () => {
        setActiveIndex((val) => {
            if (val <= 0) {
                return seatsInfoArr.length - 1;
            } else {
                return val - 1;
            }
        });
    };


    function calculateSeats(nr) {       
        let seatsPerNo = []
            seatsArr.filter(sa => {
                if (sa.areano === nr) {
                  
                 return  seatsPerNo.push(sa.seat)
                }
            })            
        return seatsPerNo
    }
    const selSeatsInfo = seatsInfoArr && seatsInfoArr.length ? seatsInfoArr : ''
    
    let real_images = []
    let real_infos = []

    selSeatsInfo.length && selSeatsInfo.map(s => {
        if (s.seatImageReal !== null)
            real_images.push(s.seatImageReal);
        if (s.seatInfo !== null)
            real_infos.push(s.seatInfo)
    })
    let selectedSeatArr = []
   
    seatsArr && seatsArr.map(seat => {

        selSeatsInfo && selSeatsInfo.map(sir => {

            if (seat.areano === sir._no && sir.seatImageReal) {
                selectedSeatArr.push(seat.seat)

            }
        })
    })
    return shown ? (
        <div
            className="modal-backdrop"
            onClick={() => {
                // close modal when outside of modal is clicked
                alrtImg === false &&   close();
            }}
        >
            <div
                className="modal-content" style={{ backgroundColor:bgColor }}
                onClick={e => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                }}
            >
                {/* <button onClick={close}>Close</button> */}
                {children}

                 {/*there must be multiple modal-container div to slide from*/}
                {seat_img && extractedSeats ? <>
                    {seatsInfoArr.length > 1 && real_images.length > 1 && real_infos.length > 1 ?

                       
                     <>
                            <div
                                className="container__slider"  >
                            {seatsInfoArr && seatsInfoArr.map((seat, index) => {
                                let seatsPerModel = calculateSeats(seat._no)
                                
                                return <div className={"slider__item slider__item-active-" + (activeIndex + 1)}  key={index}   >
                                           <div className='modal-container slider__item slider__item-active- + (activeIndex + 1)' key={index}>
                                              <p className='title'>Sædetyper</p>
                                              <img height='279px' width='397px' src={`https://flow.ebillet.dk/images/organizer_${organizerId}/${seat.seatImageReal}`} />
                                        <div style={{ display: 'flex', paddingLeft: '28px' }}>
                                                 <p style={{ fontWeight: 'bold', marginTop: '3px', fontSize: '16px', marginTop: '5px' }}> Sæde </p>
                                                  <div  style={{ marginTop: '2px', display: 'flex' }}>
                                                     {seatsPerModel.map( spm => {
                                                          return <p className='seat-numbers'>{spm}</p>
                                                          })
                                                        }
                                                  </div>
                                               </div>
                                        <p className='description' dangerouslySetInnerHTML={{ __html: seat.seatInfo }}></p>
                                        <div style={{ textAlign:'center' }}>
                                    <button  onClick={() => {
                                        // close modal when outside of modal is clicked
                                        close();
                                            }} style={{ fontSize: '16px', fontFamily: 'oswald', letterSpacing: '0.3px' }} className='next close-btn'> Luk </button> </div>
                                    </div>
                                    </div>

                            })
                                            }
                                                                
                        </div>
                            <button style={{ display: activeIndex === 1 ? 'none' : '' }} className="img_slide_right" onClick={(e) => { e.preventDefault(); slideNext(); }}> ❯ </button>
                            <button style={{ display: activeIndex === 0 ? 'none' : '' }} className="img_slide_left" onClick={(e) => { e.preventDefault(); slidePrev(); }}> ❮ </button>
                        </>
                        :
                        <div className='modal-container'>
                            <p className='title'>Sædetyper</p>
                            <img height='279px' width='397px' src={`https://flow.ebillet.dk/images/organizer_${organizerId}/${seat_img}`} />
                            <div style={{ display: 'flex', paddingLeft: '28px' }}>
                                <p style={{ fontWeight: 'bold', marginTop: '3px', fontSize: '16px', marginTop: '5px' }}> Sæde </p>
                                <div style={{ marginTop: '2px', display: 'flex' }}>
                                    {selectedSeatArr && selectedSeatArr.length > 1 ?
                                        selectedSeatArr.map((seat, index) => {
                                            return <p className='seat-numbers' key={index}>{seat}</p>
                                        })
                                        : <p className='seat-numbers'>{extractedSeats && extractedSeats[0]}</p>
                                    }
                                </div>
                            </div>
                            <p className='description' dangerouslySetInnerHTML={{ __html: seatsInfo }}></p>
                            <div style={{ textAlign: 'center' }}>
                            <button onClick={() => {
                                // close modal when outside of modal is clicked
                                close();
                                }} style={{ marginRight: '30px', marginBottom: '30px', fontSize: '16px', fontFamily: 'oswald', letterSpacing: '0.3px' }} className='next close-btn'>Luk </button> </div>
                        </div>
                    }
                </>
                : ''}
            </div>
        </div>
    ) : null;
}



