import React, { useState, useEffect} from 'react'
import _ from 'lodash'
import LOCALE from '../OrderLocale'
import Modal from './Modal'

export default function SeatModal({ seat_img, organizerId, extractedSeats, seatsInfo, seatsInfoArr, seatsArr}) {
    const [modalShown, toggleModal] = useState(false);

   

    return (
        <div>
            <Modal shown={modalShown} seatsArr={seatsArr} seat_img={seat_img} seatsInfoArr={seatsInfoArr} organizerId={organizerId} extractedSeats={extractedSeats} seatsInfo={seatsInfo} alrtImg={false}
                close={() => {
                    toggleModal(false);
                }} >
          </Modal>
            <div className='seatModal-container'>
                <button onClick={() => {
                    toggleModal(!modalShown)
                }} className='seatModal-container_btn'>Se sædetyper</button>
            </div>
            </div>
        )
}