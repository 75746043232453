import React from 'react'
import { Player, ControlBar } from 'video-react';
import 'video-react/dist/video-react.css';

export default class Trailer extends React.Component {

    constructor() {
        super();
        this.state = { isPlaying: false }
        this.trailerClicked = this.trailerClicked.bind(this);
        this.resizeIframe = this.resizeIframe.bind(this);
    }

    //This is for handling dynamic height when inside an iframe
    resizeIframe(e) {
        const { iframeId } = this.props;
        if (window.parent != window) {
            const app = document.querySelector('.App');
            const pageHeight = Math.max(app.scrollHeight, app.offsetHeight, app.clientHeight) + 300;

            console.log('Trailer clicked!');
            console.log('iframeId: ' + iframeId);
            console.log('setting height: ' + pageHeight);

            window.parent.postMessage({
                iframeId: iframeId,
                iframeHeight: pageHeight
            }, '*');
        }
    }

    trailerClicked(e) {
        const toggledPlay = this.state.isPlaying ? false : true;
        this.setState({ isPlaying: toggledPlay });

        this.player.play();
        setTimeout(() => { this.resizeIframe() }, 500); //Workaround for the duration it takes the DOM to rerender with the expanded element so we get the right height...
    }

    render() {
        const { trailerUrl, trailerPoster } = this.props;
        const { isPlaying } = this.state;

        return (
            <div className={isPlaying ? 'Trailer is-playing' : 'Trailer'}>
                <div className="trailer-container">
                    {trailerUrl ?
                        <Player className="player"
                            src={trailerUrl}
                            poster={trailerPoster}
                            preload="metadata"
                            ref={player => { this.player = player; }}
                        >
                            <ControlBar>
                                <img className="logo" src="/images/logo.png" />
                            </ControlBar>
                        </Player> : null}

                    {trailerUrl && !isPlaying ? <div className="trailer-overlay" onClick={this.trailerClicked}><span className="icon-play"></span></div> : null}
                </div>
            </div>)
    }
}