import React from 'react'
import moment from 'moment'
import DayPicker from 'react-day-picker';
import _ from 'lodash'
import LOCALE from './DatePickerLocale'

export default class Calendar extends React.Component {

    constructor() {
        super();
        this.handleDayClick = this.handleDayClick.bind(this);
        this.disabledDays = this.disabledDays.bind(this);
    }

    handleDayClick(datetime, _ref) {
        const { toggleCalendar, changeDate } = this.props;
        const disabled = _ref.disabled;
        if (!disabled) {
            toggleCalendar();
            const date = moment(datetime).format('YYYY-MM-DD');
            changeDate(date);
        }
    }

    disabledDays(dateTime) {
        const { datesWithShowtimes } = this.props;
        const day = moment(dateTime).format('YYYY-MM-DD');
        const dayHasShowtime = _.indexOf(datesWithShowtimes, day) != -1;
        if (!dayHasShowtime) return day;
    }

    render() {
        const { datesWithShowtimes, selectedDate, showCalendar, appLocale, toggleCalendar, calendarText } = this.props;
        const lastDate = moment(_.last(datesWithShowtimes)).toDate();
        const firstDate = moment(_.first(datesWithShowtimes)).toDate();
        const selected = selectedDate ? moment(selectedDate).toDate() : null;

        return (
            <div className={showCalendar ? 'Calendar slide-down' : 'Calendar slide-up'}>
                <span className="icon-close" onClick={toggleCalendar}></span>
                <DayPicker locale={appLocale}
                    initialMonth={firstDate}
                    fromMonth={firstDate}
                    toMonth={lastDate}
                    months={LOCALE[appLocale].months} //This is only the display name of the from-to months, unfortunately not which months to include in the calendar. This can't seem to be done with this lib.
                    weekdaysLong={LOCALE[appLocale].weekdays}
                    weekdaysShort={LOCALE[appLocale].weekdaysShort}
                    firstDayOfWeek={1}
                    enableOutsideDays={false}
                    selectedDays={selected}
                    disabledDays={this.disabledDays}
                    onDayClick={this.handleDayClick}
                    fixedWeeks
                />
                {calendarText ? <p className="calendar-text" dangerouslySetInnerHTML={{ __html: calendarText }}></p> : null}
            </div>
        );
    }
}