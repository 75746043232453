import React from 'react'
import _ from 'lodash'

export default class QuantitySelector extends React.Component {
    constructor(props) {
        super(props);
        const { selectedTicket, availableTicket, order } = props;
       
        const quantity = selectedTicket ? selectedTicket.quantity : availableTicket.defaultQuantity === 0 ? '0' : order.selected.tickets[0] ? order.selected.tickets[0].quantity : '0' ;

        this.state = {
            quantity };
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
    }

    debounceMultipleClicks = _.debounce((quantity) => {
        const { ticketQuantityChanged, availableTicket } = this.props;
        ticketQuantityChanged(availableTicket.id, quantity);
    }, 400)

    canIncrement(quantity) {
        const { freeSeats, availableTicket, canIncrementTotalQuantity, canIncrementTotalPoints, order } = this.props;
        const newQuantity = quantity + 1;
        const totalTicketQuantity = order.selected.totalTicketQuantity;
        const canIncrement = (freeSeats > 1 || freeSeats == 1 && quantity == 0 && totalTicketQuantity == 0) && (newQuantity <= Math.max(availableTicket.buyMax, availableTicket.reserveMax)) && canIncrementTotalQuantity();
        if (availableTicket.points) return canIncrementTotalPoints(availableTicket.points, newQuantity) && canIncrement;
        else return canIncrement;
    }

    increment() {
        const { incrementTotalQuantity } = this.props;
        let { quantity } = this.state;

        if (this.canIncrement(quantity)) {
            this.setState({ quantity: ++quantity });
            incrementTotalQuantity();
            this.debounceMultipleClicks(quantity);
        }
    }

    decrement() {
        const { decrementTotalQuantity } = this.props;
        let { quantity } = this.state;
        if (quantity > 0) {
            this.setState({ quantity: --quantity });
            decrementTotalQuantity();
            this.debounceMultipleClicks(quantity);
        }
    }

    render() {
        const { availableTicket, order, canIncrementTotalQuantity, freeSeats } = this.props;
        const { quantity } = this.state;

        const decrementClasses = !order.isFetching && quantity > 0 ? 'decrement' : 'decrement deactivated';
        let incrementClasses = !order.isFetching && (freeSeats > 1 || freeSeats == 1 && quantity == 0 && order.selected.totalTicketQuantity == 0) && canIncrementTotalQuantity() ? 'increment' : 'increment deactivated';
        if (order.clubCard && availableTicket.points > order.clubCard.remainingPoints) incrementClasses = 'increment deactivated';

        return (
            <div className="QuantitySelector">
                <div className={decrementClasses} onClick={this.decrement}><span className="icon-circle-minus"></span></div>
                <div className="quantity">{quantity}</div>
                <div className={incrementClasses} onClick={this.increment}><span className="icon-circle-plus"></span></div>
            </div>
        )
    }
}