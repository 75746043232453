import React from 'react'
import _ from 'lodash'
import LOCALE from './PaymentLocale'

export default class CreditCardOption extends React.Component {
    constructor() {
        super();
        this.creditCardChanged = this.creditCardChanged.bind(this);
    }
    
    creditCardChanged(e) {
        const creditCard = e.target.value;
        this.props.creditCardChanged(creditCard);
    }

    render() {
        const { creditCard, isChecked, appLocale } = this.props;
        const international = creditCard.isInternational ? `${LOCALE[appLocale].creditCardForeign} ` : null;

        return (
            <label className="CreditCardOption" onClick={this.creditCardChanged}>
                <input name="creditcards" type="radio" defaultChecked={isChecked} value={creditCard.type} />
                <img src={creditCard.image} />
                <span>{international}{creditCard.name}</span>
            </label>
        )
    }
}