import './order.scss'
import './multi.scss'
import React, { useEffect, useState } from 'react'
import local from '../summary/SummaryLocale'
import _ from 'lodash'


export default function SummaryMulti({ togglePriceInfo, icon, ticketPrice, ticketCount, ticketCount1, ticketCount2, ticketCount3, ticketCount4, ticketCount5,
    ticketType, ticketType1, ticketType2, ticketType3, ticketType4, ticketType5, gebyr, priceInfo, totalTicketPrice, appLocale, ticketName, ticketTypeLength, oneTicketType }) {
 //   console.log(oneTicketType)
    return (
        <>
      
            <div className="Summary fixed-nav">
                <h3 onClick={togglePriceInfo}>
                    {icon}  {local[appLocale].yourOrder}
                </h3>
                <div className="summary-info">
                  {ticketTypeLength ? 
                    <div className="order"> 
                        <span>{ticketCount  > 0 && ticketCount + ' x ' + ticketType}</span>
                        <span>{ticketCount  > 0 && ticketCount1 > 0 && ' , '}</span>
                        <span>{ticketCount1 > 0 && ticketCount1 + ' x ' + ticketType1}</span>
                        <span>{ticketCount1 > 0 && ticketCount2 > 0 && ' , '}</span>
                        <span>{ticketCount2 > 0 && ticketCount2 + ' x ' + ticketType2}</span>
                        <span>{ticketCount2 > 0 && ticketCount3 > 0 && ' , '}</span>
                        <span>{ticketCount3 > 0 && ticketCount3 + ' x ' + ticketType3}</span>
                        <span>{ticketCount3 > 0 && ticketCount4 > 0 && ' , '}</span>
                        <span>{ticketCount4 > 0 && ticketCount4 + ' x ' + ticketType4}</span>
                        <span>{ticketCount4 > 0 && ticketCount5 > 0 && ' , '}</span>
                        <span>{ticketCount5 > 0 && ticketCount5 + ' x ' + ticketType5}</span>
                      </div>
                        :
                        <div className="order"><span>{ ticketCount + ' x ' + oneTicketType}</span> </div>
                        }
                    <div className="total-price">{local[appLocale].totalPrice} {totalTicketPrice} Kr.</div>
                    </div>
                {priceInfo ?
                    <div className="PriceInfo">
                        <p> {local[appLocale].tickets} <span>{ticketPrice } kr.</span></p>
                        <p> {local[appLocale].fees}    <span>{gebyr} kr.</span></p>
                        <hr />
                        <p>{local[appLocale].totalPrice}  <span>{totalTicketPrice} Kr.</span></p>
                    </div>
                    : ''
}
             
            </div>

            </>
        )
}