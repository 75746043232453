import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import LOCALE from './DatePickerLocale'

export default class WeekDayPicker extends React.Component {
    maxDays = 5;

    extractWeekDays() {
        const { selectedDate, changeDate, isAllMovies, datesWithShowtimes } = this.props;
        const weekDays = [];
        const short = window.outerWidth < 800;

        for (var i = 2; i < _.take(datesWithShowtimes, this.maxDays).length; i++) {
            const weekDay = moment().add(i, 'days');
            if (weekDays.length <= this.maxDays && _.includes(datesWithShowtimes, weekDay.format('YYYY-MM-DD'))) {
                weekDays.push(<div key={i}
                    id={weekDay.format('YYYY-MM-DD')}
                    className={!isAllMovies && weekDay.isSame(moment(selectedDate), 'day') ? 'selected day' : 'day'}
                    onClick={changeDate}>{_.capitalize(short ? weekDay.format('ddd DD/MM') : weekDay.format('dddd DD/MM'))}</div>);
            }
        }
        return weekDays;
    }

    render() {
        const { appLocale, selectedDate, changeDate, isAllMovies, datesWithShowtimes } = this.props;
        const weekDays = this.extractWeekDays();
        const today = moment();
        const tomorrow = moment().add(1, 'days');

        return (
            <div className="WeekDayPicker">
                {_.includes(datesWithShowtimes, today.format('YYYY-MM-DD')) ? <div id={today.format('YYYY-MM-DD')}
                    className={!isAllMovies && today.isSame(moment(selectedDate), 'day') ? 'selected day' : 'day'}
                    onClick={changeDate}>
                    {LOCALE[appLocale].today}
                </div> : null}

                {_.includes(datesWithShowtimes, tomorrow.format('YYYY-MM-DD')) ? <div id={tomorrow.format('YYYY-MM-DD')}
                    className={!isAllMovies && tomorrow.isSame(moment(selectedDate), 'day') ? 'selected day' : 'day'}
                    onClick={changeDate}>{LOCALE[appLocale].tomorrow}</div> : null}

                {weekDays}
            </div>
        );
    }
}