import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import LOCALE from './MovieLocale'

export default class MovieInfo extends React.Component {
    render() {
        const { movie, appLocale, addClass } = this.props;
      
        const info = movie.info && !_.isObject(movie.info) ? <p className="info" dangerouslySetInnerHTML={{ __html: movie.info.replace(/(?:\r\n|\r|\n)/g, '<br />') }} /> : null;
        const descriptionLocale = movie.descriptions ? (movie.descriptions[appLocale] ? movie.descriptions[appLocale] : movie.descriptions['da']) : movie.description;
        const description = descriptionLocale ? <p key={movie.id} className="description" dangerouslySetInnerHTML={{ __html: descriptionLocale.replace(/(?:\r\n|\r|\n)/g, '<br />') }} /> : null;

        const movieNameLocale = movie.names ? (movie.names[appLocale] ? movie.names[appLocale] : movie.names['da']) : movie.name;
        const originalTitle = movieNameLocale && movie.originalName && movieNameLocale.substring(0, movie.originalName.length) !== movie.originalName ?
            <p className="original-title"><span>{LOCALE[appLocale].originalTitle}</span><label>{movie.originalName}</label></p> : null;

        const isDefault = moment(movie.openingDate).isSame(moment('1600-01-01'), 'date');
        const premiere = movie.openingDate && !isDefault ? <p className="premiere"><span>{LOCALE[appLocale].premiere} </span><label>{moment(movie.openingDate).format('LL')}</label></p> : null;
        const length = movie.length ? <p><span>{LOCALE[appLocale].length} </span><label>{movie.length}</label></p> : null;
        const age = movie.ageCensoring ? <p><span>{LOCALE[appLocale].age} </span><label> {movie.ageCensoring}</label></p> : null;
        const genre = movie.genre ? <p><span>{LOCALE[appLocale].genre} </span><label>{movie.genre}</label></p> : null;
        const actors = movie.actors && movie.actors.length > 0 ?
            <p><span>{movie.actors.length > 1 ? LOCALE[appLocale].actors : LOCALE[appLocale].actor} </span>
                <label>{_.map(movie.actors, (actor) => actor).join(', ')}</label></p> : null;

        const directors = movie.directors && movie.directors.length > 0  ?
            <p><span>{movie.directors.length > 1 ? LOCALE[appLocale].directors : LOCALE[appLocale].director} </span>
                <label>{_.map(movie.directors, (director) => director).join(', ')}</label></p> : null;

        const scriptWriters = movie.scriptwriters && movie.scriptwriters[0].length > 0 ?
            <p> <span>{movie.scriptwriters[0].length ? LOCALE[appLocale].scriptwriter : ''}</span>
                <label>{_.map(movie.scriptwriters, (scriptWriter) => scriptWriter).join(', ')}</label></p> : null;
       

        return (
            <div className={addClass ? `MovieInfo ${addClass}` : 'MovieInfo'}>
                {premiere}
                {info}
                {description}
                {originalTitle}
                {actors}
                {scriptWriters} 
                {directors}
                {genre}
                {length}
                
                {age}
            </div>)
    }
}