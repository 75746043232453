import React from 'react'
import _ from 'lodash'
import Info from '../../overlays/Info'
import LOCALE from './ReceiptLocale'

export default class DeleteReservation extends React.Component {

    constructor() {
        super()
        this.deleteConfirmResult = this.deleteConfirmResult.bind(this);
    }

    deleteConfirmResult(e) {
        if (e.target.id === 'delete') {
            const { receipt, deleteReservation, gaDeleteReservation } = this.props;
            deleteReservation(receipt.id, receipt.customer.phone, receipt.customer.password).then(() => gaDeleteReservation());
        }
    }

    render() {
        const { receipt, appLocale } = this.props;

        return (
            <div className="Reservation">
                {!receipt.deleted ?
                    <Info text={LOCALE[appLocale].deleteText} buttons={<div>
                        <input type="button" className="red" id="delete" value={LOCALE[appLocale].confirm} onClick={this.deleteConfirmResult} />
                    </div>} /> : <Info text={LOCALE[appLocale].deletedText} />}
            </div>
        )
    }
}