import './topbar.scss'
import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import Poster from '../../movie/Poster'
import { ORGANIZER_TYPES } from '../../../api/api-constants'
import CONSTANTS from '../../movielists/movielist-constants'
import LOCALE from './TopBarLocale'
import ShowtimeType from '../../showtime/ShowtimeType'
import CountdownTimer from '../../countdowntimer/CountdownTimer'
import LanguagePicker from '../../languagepicker/LanguagePicker';

class TopBar extends React.Component {

    generateDuration() {
        const { movie, showtime, appLocale } = this.props;
        const from = moment(showtime.dateTime);
        const duration = movie.type == CONSTANTS.types.special ? moment.duration(movie.length) : moment.duration(showtime.duration);
        const to = moment(from).add(duration);
        return <div className="info duration">
            <label>{LOCALE[appLocale].duration}:</label><div className="label">{from.format('HH:mm')} {LOCALE[appLocale].durationTo} {to.format('HH:mm')}</div>
        </div>
    }

    getTextForOrganizerType(type, appLocale) {
        switch (type) {
            case ORGANIZER_TYPES.CINEMA:
                return LOCALE[appLocale].cinema
            case ORGANIZER_TYPES.CIRCUS:
                return LOCALE[appLocale].circus
            case ORGANIZER_TYPES.MUSEUM:
                return LOCALE[appLocale].museum
            default: LOCALE[appLocale].cinema;
        }
    }

    render() {
        const { organizer, movie, showtime, clubCardEnabled, pageHeaderEnabled, appLocale, showTimer, timeLeft, handleTimeout, timerReloadText, timerText, timeReload, availableLocales } = this.props;
        const poster = movie.posters ? <Poster movie={movie} /> : null;
        const duration = showtime.duration ? this.generateDuration() : null;
        const age = movie.ageCensoring ? <div className="info age"><label>{LOCALE[appLocale].age}:</label><div className="label">{movie.ageCensoring}</div> </div> : null;
        const dateTime = moment(showtime.dateTime);

        let topBarClasses = 'TopBar';
        if (clubCardEnabled) topBarClasses += ' club-enabled'
        if (pageHeaderEnabled) topBarClasses += ' header-enabled';

        const movieNameLocale = movie.names[appLocale] ? movie.names[appLocale] : movie.names['da'];
        const typeName = showtime.type ? <ShowtimeType type={showtime.type} includeName={true} /> : null;

        return (
            <div className={topBarClasses}>
                {availableLocales.length > 1 ? <LanguagePicker /> : null}
                {poster}
                <div className="TicketInfo">
                    <h1>{movieNameLocale}</h1>
                    <h2>{_.capitalize(dateTime.format(LOCALE[appLocale].longDateTimeFormat))}</h2>
                    <div className="info"><label>{this.getTextForOrganizerType(organizer.configuration.type, appLocale)}:</label><div className="label">{`${organizer.name}, ${showtime.locationName}`}</div></div>
                    {age}
                    {duration}

                    {typeName}
                    {showTimer && timeLeft ? <CountdownTimer key={timeReload ? Math.random() : 1}
                        timeLeft={timeLeft} handleTimeout={handleTimeout} text={timeReload ? timerReloadText[appLocale] : timerText[appLocale]} /> : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        showtimeTypes: state.movielist.showtimeTypes,
        timeLeft: state.order.timeLeft,
        timeReload: state.order.timeReload,
        timerText: _.find(state.organizer.configuration.texts, (text) => { return text.id == 'CountDownText' }),
        timerReloadText: _.find(state.organizer.configuration.texts, (text) => { return text.id == 'CountDownReloadText' }),

        //needed to trigger the topbar to change..When reload is true the timeLeft from ticketgate is often the same..This is better than having each tick in the redux store and compare...
        triggerUpdate: state.order.selected.tickets,
        triggerUpdate2: state.user.clubCardId,
        triggerUpdate3: state.order.selected.extraPurchases
    }
}

export default connect(mapStateToProps)(TopBar)