import React from 'react'
import { connect } from 'react-redux'
import LOCALE from './OverlayLocales'

class Error extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: props.show
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show != this.props.show)
            this.setState({ show: this.props.show });
    }

    render() {
        let { error, buttons, isInWindow, appLocale } = this.props;
        const { show } = this.state;
        let message = error.message || LOCALE[appLocale].errors[error.code] || LOCALE[appLocale].errors[error.type] || LOCALE[appLocale].unspecified;
        const closeButton = <input type="button" onClick={() => this.setState({ show: false })} value={LOCALE[appLocale].close} />;

        switch (error.code) {
            case 17:
                if (error.type == 10001) message = LOCALE[appLocale].errors[error.type];
                if (error.type == 10004) message = LOCALE[appLocale].errors[error.type];
                break;
            case 29:
                if (error.type == 475) message = LOCALE[appLocale].errors[error.type];
                break;
            case 30:
                if (error.type == 32) message = LOCALE[appLocale].errors[error.code];
                break;
            case 35:
                if (error.type == 476) message = LOCALE[appLocale].errors[error.type];
                break;
            case 666:
                buttons = isInWindow ? null : closeButton;
                break;
        }

        if (error.serverMessage) message = error.serverMessage;

        return (
            <div className={show ? 'Error overlay fade-in' : 'Error overlay fade-out'}>
                <div className="overlay-content">
                    <p dangerouslySetInnerHTML={{ __html: message }}></p>
                    {buttons || error.buttons || (error.close ? closeButton : null)}
                    <div className="code"> Error code: ({error.code}, {error.type})</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        show: state.error.show && (state.error.code != null || state.error.type != null || state.error.message != null) || ownProps.error,
        error: ownProps.error || state.error,
        isInWindow: state.organizer.configuration.openOrderInNewWindow,
        appLocale: state.app.locale
    }
}

export default connect(mapStateToProps)(Error)