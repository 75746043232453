import React from 'react'
import _ from 'lodash'
import CreditCardOption from './CreditCardOption'

export default class Payment extends React.Component {
    
    generateOptions(creditCard, creditCardChanged) {
        const { creditCards, appLocale } = this.props;
        return creditCards.map((card, i) => {
            return <CreditCardOption key={i}
                appLocale={appLocale}
                creditCard={card}
                isChecked={creditCard === card.type}
                creditCardChanged={creditCardChanged}
            />
        }, this);
    }

    render() {
        const { customer, actions, errors } = this.props;
        const creditCardOptions = this.generateOptions(customer.creditCard, actions.creditCardChanged);
        const creditCardError = !customer.creditCard ? <div className="error">{errors.creditCard}</div> : null;

        return (
            <div className="Payment">
                <div className="creditcards">{creditCardOptions}</div>
                {creditCardError}
            </div>
        )
    }
}