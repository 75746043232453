import * as ticketgate from '../api/ticketgate-api'
import CONSTANTS from '../components/orderflow/orderflow-constants'
import { setTransactionId, orderTypeChanged } from './order-actions'
import api from '../api/orderflow-api'

export const RECEIPT_RESET = 'RECEIPT_RESET'
export const receiptReset = () => {
    return {
        type: RECEIPT_RESET
    }
}

export const RECEIPT_SUCCESS = 'RECEIPT_SUCCESS'
export const receiptSuccess = (receipt) => {
    return {
        type: RECEIPT_SUCCESS,
        suceededAt: Date.now(),
        receipt
    }
}

export const DELETE_RESERVATION_REQUEST = 'DELETE_RESERVATION_REQUEST'
export const DELETE_RESERVATION_SUCCESS = 'DELETE_RESERVATION_SUCCESS'
export const DELETE_RESERVATION_FAILED = 'DELETE_RESERVATION_FAILED'

export function deleteReservation(reservationId, phone, password) {
    return dispatch => {
        dispatch(deleteReservationRequest());
        return ticketgate.cancelReservation(reservationId, phone, password)
            .then(result => dispatch(deleteReservationSuccess(result)), error => dispatch(deleteReservationFailed(error)));
    };
}

const deleteReservationRequest = () => {
    return {
        type: DELETE_RESERVATION_REQUEST,
        requestedAt: Date.now()
    }
}

const deleteReservationSuccess = (result) => {
    return {
        type: DELETE_RESERVATION_SUCCESS,
        suceededAt: Date.now(),
        result
    }
}

const deleteReservationFailed = (error) => {
    return {
        type: DELETE_RESERVATION_FAILED,
        failedAt: Date.now(),
        error: {
            ...error,
            message: 'Din reservation kunne ikke slettes.'
        }
    }
}

export const ACTIVATE_RESERVATION_REQUEST = 'ACTIVATE_RESERVATION_REQUEST'
export const ACTIVATE_RESERVATION_SUCCESS = 'ACTIVATE_RESERVATION_SUCCESS'
export const ACTIVATE_RESERVATION_FAILED = 'ACTIVATE_RESERVATION_FAILED'

export function activateReservation(reservationId, HPKey) {
    return dispatch => {
        dispatch(activateReservationRequest());
        return ticketgate.activateReservation(reservationId, HPKey).then(receipt => {
            dispatch(activateReservationSuccess());
            dispatch(setTransactionId(receipt.transactionId)); //needed to set the TransactionId in order reducer
            receipt.id = reservationId;
            dispatch(orderTypeChanged(CONSTANTS.orderTypes.buyReservation));
            return dispatch(receiptSuccess(receipt));
        }, error => dispatch(activateReservationFailed(error)));
    };
}

const activateReservationRequest = () => {
    return {
        type: ACTIVATE_RESERVATION_REQUEST,
        requestedAt: Date.now()
    }
}

const activateReservationSuccess = (transaction) => {
    return {
        type: ACTIVATE_RESERVATION_SUCCESS,
        suceededAt: Date.now()
    }
}

const activateReservationFailed = (error) => {
    return {
        type: ACTIVATE_RESERVATION_FAILED,
        failedAt: Date.now(),
        error: {
            ...error,
            message: 'Det er ikke muligt at aktivere reservationen, da den er købt eller afbestilt.'
        }
    }
}

export const GET_RESERVATION_SUCCESS = 'GET_RESERVATION_SUCCESS'
export const GET_RESERVATION_REQUEST = 'GET_RESERVATION_REQUEST'
export const GET_RESERVATION_FAILED = 'GET_RESERVATION_FAILED'

export function getReservationInfo(reservationId, HPKey) {
    return dispatch => {
        dispatch(getReservationRequest());
        return ticketgate.getReservation(reservationId, HPKey).then(receipt => {
            return dispatch(getReservationSuccess(receipt));
        }, error => dispatch(getReservationFailed(error)));
    };
}

const getReservationRequest = () => {
    return {
        type: GET_RESERVATION_REQUEST,
        requestedAt: Date.now()
    }
}

const getReservationSuccess = (transaction) => {
    return {
        type: GET_RESERVATION_SUCCESS,
        suceededAt: Date.now(),
        transaction
    }
}

const getReservationFailed = (error) => {
    return {
        type: GET_RESERVATION_FAILED,
        failedAt: Date.now(),
        error
    }
}

export const GET_RECEIPT_REQUEST = 'GET_RECEIPT_REQUEST'
export const GET_RECEIPT_FAILED = 'GET_RECEIPT_FAILED'

export function getReceipt(phone, id) {
    return dispatch => {
        dispatch(getReceiptRequest());
        return api.fetchReceipt(phone, id).then(receiptHtml => {
            if (receiptHtml)
                document.getElementsByTagName("html")[0].innerHTML = receiptHtml
        }, error => dispatch(getReceiptFailed(error)));
    };
}

const getReceiptRequest = () => {
    return {
        type: GET_RECEIPT_REQUEST,
        requestedAt: Date.now()
    }
}

const getReceiptFailed = (error) => {
    return {
        type: GET_RECEIPT_FAILED,
        failedAt: Date.now(),
        error: {
            message: 'Hov, noget gik galt med visningen af kvitteringen. Tjek din mail og se om du får kvitteringen, der kan gå et par minutter.'
        }
    }
}