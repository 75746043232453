import React, { useEffect, useState } from 'react'
import './styles/program.scss'
import DatePickerContainer from '../datepicker/DatePickerContainer'
//import CONSTANTS from '../movielist-constants'
import xml2js from '../../lib/xml-to-js';
import DayPicker from 'react-day-picker';
import moment from 'moment'
import downarrow from './images/down-arrow-backup-2-svgrepo-com (1).svg'
import check from "./images/check-mark.svg";
import checkIcon from "./images/check-mark.svg";


export default function Region() {

   

    // choose region functions 
    const [isOpen, setIsOpen] = useState(false);
    const [chosenRegion, setChosenRegion] = useState('Vælge region');
    // const [checked, setChecked] = useState(false);
    const [jyllandCheck, setJyllandCheck] = useState(false);
    const [fynCheck, setFynCheck] = useState(false);
    const [zealandCheck, setZealandCheck] = useState(false);
    const [copenhagenCheck, setCopenhagenCheck] = useState(false);

    function handleRegionArrrow() {
        setIsOpen((current) => !current);
    }


    function handleCopenhagenCheck() {
        setCopenhagenCheck((current) => !current);
        setChosenRegion('Storkøbenhavn')
        setIsOpen(false)
    }
    function handleZealandCheck() {
        setZealandCheck((current) => !current);
        setChosenRegion('Sjælland')
        setIsOpen(false)

    }
    function handleFynCheck() {
        setFynCheck((current) => !current);
        setChosenRegion('Fyn & øerne')
        setIsOpen(false)

    }
    function handleJyllandCheck() {
        setJyllandCheck((current) => !current);
        setChosenRegion('Jylland')
        setIsOpen(false)

    }

   

    return (
       
              
            
                <div className="region">
                    <div style={{ padding: '0px 15px' }} >
                        <p className="region-title">Vælge region</p>
                        <div className="chooseRegion">
                            <div
                                style={{
                                    position: "relative",
                                    padding: "5px 8px",
                                    boxSizing: "border-box",
                                    height: "31px",
                                }}
                            >
                                <p style={{ margin: 0 }}>{chosenRegion}</p>
                                <span className="one"> 1</span>
                                <span
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        height: "31px",
                                        right: "35px",
                                        borderLeft: "0.1px solid #757575",
                                    }}
                                ></span>
                                <button
                                    onClick={handleRegionArrrow}
                                    style={{
                                        position: "absolute",
                                        bottom: "7px",
                                        right: "-4px",
                                        border: "none",
                                        outline: "none",
                                        color:'grey',
                                        background: "transparent",
                                        
                                    }}
                                >
                                    <p className={isOpen ? "rotated" : ""}>
                                        <img src={downarrow} alt="arrows" />
                                    </p>
                                </button>
                            </div>
                            {isOpen && (
                                <div
                                    style={{
                                        borderTop: "1px solid #757575",
                                        backgroundColor: "#3a3a3a",
                                        height: "128px", zIndex: 100
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            height: "31px",
                                            padding: "8px",
                                        }}
                            >
                                <p className='regionPointer' style={{ margin: 0, color: copenhagenCheck && chosenRegion === 'Storkøbenhavn' ? '#fff' : '' }}>Storkøbenhavn</p>
                                        <p
                                            className="checkCheckbox"
                                            style={{ position: "relative", top: "-14px" }}
                                            onClick={handleCopenhagenCheck}
                                        >
                                            {copenhagenCheck && chosenRegion === 'Storkøbenhavn' ? (
                                                <img
                                                    style={{ position: "absolute", top: "2px", left: "3px" }}
                                                    src={checkIcon}
                                                    alt=""
                                                />
                                            ) : (
                                                " "
                                            )}
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            height: "31px",
                                            padding: "8px",
                                        }}
                                    >
                                <p className='regionPointer' style={{ margin: 0, color: zealandCheck && chosenRegion === 'Sjælland' ? '#fff' : '' }}>Sjælland</p>
                                        <p
                                            className="checkCheckbox"
                                            style={{ position: "relative", top: "-14px" }}
                                            onClick={handleZealandCheck}
                                        >
                                            {zealandCheck && chosenRegion === 'Sjælland' ? (
                                                <img
                                                    style={{ position: "absolute", top: "2px", left: "3px" }}
                                                    src={checkIcon}
                                                    alt=""
                                                />
                                            ) : (
                                                " "
                                            )}
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            height: "31px",
                                            padding: "8px",
                                        }}
                                    >
                                <p className='regionPointer' style={{ margin: 0, color: fynCheck && chosenRegion === 'Fyn & øerne' ? '#fff' : '' }}>Fyn & øerne</p>
                                        <p
                                            className="checkCheckbox"
                                            style={{ position: "relative", top: "-14px" }}
                                            onClick={handleFynCheck}
                                        >
                                            {fynCheck && chosenRegion === 'Fyn & øerne' ? (
                                                <img
                                                    style={{ position: "absolute", top: "2px", left: "3px" }}
                                                    src={checkIcon}
                                                    alt=""
                                                />
                                            ) : (
                                                " "
                                            )}
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            height: "31px",
                                            padding: "8px",
                                        }}
                                    >
                                <p className='regionPointer' style={{ margin: 0, color: jyllandCheck && chosenRegion === 'Jylland' ? '#fff' : '' }}>Jylland</p>
                                        <p
                                            className="checkCheckbox"
                                            style={{ position: "relative", top: "-14px" }}
                                            onClick={handleJyllandCheck}
                                        >
                                            {jyllandCheck && chosenRegion === 'Jylland' ? (
                                                <img
                                                    style={{ position: "absolute", top: "2px", left: "3px" }}
                                                    src={check}
                                                    alt=""
                                                />
                                            ) : (
                                                " "
                                            )}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    
                </div>
               

           
        )
}