import './clubcard.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import * as userActions from '../../../actions/user-actions'
import Login from './Login'

class ClubCardContainer extends React.Component {

    constructor() {
        super();
        this.state = {
            showLoginOverlay: false,
            error: ''
        };

        this.toggleLoginOverlay = this.toggleLoginOverlay.bind(this);
        this.submitLogin = this.submitLogin.bind(this);
    }

    toggleLoginOverlay() {
        const show = this.state.showLoginOverlay;
        if (!show) this.setState({ showLoginOverlay: true });
        else this.setState({ showLoginOverlay: false, error: '' });
    }

    submitLogin(email, password) {
        const { actions } = this.props;
        this.setState({ error: '' });
        actions.clubCardLogin(email, password)
            .then((result) => { !result.error ? this.setState({ showLoginOverlay: false }) : this.setState({ error: result.error.message }) });
    }

    render() {
        const { clubCard, availableClubCard } = this.props;
        const pointsLeft = clubCard ? clubCard.remainingPoints : null;

        const loginTopBar = availableClubCard && (!clubCard || clubCard.id == 0) ? <div className="login"><h1>{availableClubCard.name}</h1>
            <input className="login-button next" type="button" onClick={this.toggleLoginOverlay} value="Login" /><span> {availableClubCard.loginText} </span> </div> : null;

        const loginOverlay = this.state.showLoginOverlay ?
            <Login title={availableClubCard.name}
                submit={this.submitLogin}
                close={this.toggleLoginOverlay}
                error={this.state.error}
                resetError={this.resetError} /> : null;

        const user = clubCard && clubCard.id != 0 ? <div className="user">
            <h1>{clubCard.customer}</h1>
            <h3>Dine point: {pointsLeft}</h3>
        </div> : null;

        return (
            <div className="ClubCard">
                {loginTopBar}
                {user}
                {loginOverlay}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {
        clubCard: state.order.clubCard,
        availableClubCard: state.organizer.configuration.clubCard
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(userActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClubCardContainer)