import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import CONSTANTS from '../movielist-constants'
import LOCALE from '../MovieListLocale'
import Multibio_MovieListItem from './Multibio_MovieListItem'
import { setItemClasses } from '../shared'

export default class Multibio_MovieList extends React.Component {

    generateMovieListItems(movies) {
        const { organizers, posterBanners, appLocale, movieClicked, showtimeClicked, columns, listType, showLocationOnShowtime, showAsBase, appHeightChanged, organizerIds, showExpired } = this.props;
        let sortedMovies = this.sortMovies(movies);
        let movieItems = [];
        let counter = 0;

        _.forEach(sortedMovies, (movie) => {
            let rowClasses = setItemClasses(counter, columns);
            const showtimesByOrgId = _.groupBy(movie.validShowtimes, 'organizerId');

            movieItems.push(<Multibio_MovieListItem key={movie.id}
                listType={listType}
                appLocale={appLocale}
                rowClasses={rowClasses}
                columns={columns}
                organizers={organizers}
                showAsBase={showAsBase}
                movie={movie}
                showtimesByOrgId={showtimesByOrgId}
                posterBanners={posterBanners}
                movieClicked={movieClicked}
                showtimeClicked={showtimeClicked}
                showLocationOnShowtime={showLocationOnShowtime}
                appHeightChanged={appHeightChanged}
                organizerIds={organizerIds}
                showExpired={showExpired}
            />);

            counter++;
        });

        return movieItems;
    }

    sortMovies(movies) {
        const { sortBy, showtimes, selectedDate, selectedMonth, listType, showBackInTime, showAsBase, showExpired } = this.props;

        let sortedMovies = [];
        _.forEach(movies, (movie) => {
            movie.validShowtimes = _.filter(showtimes, (showtime) => {
                if ((showtime.movieId == movie.id || (showAsBase && showtime.movieBaseId > 0 && showtime.movieBaseId == movie.baseId))) {
                    const isInFuture = moment(showtime.dateTime) > moment() || showBackInTime;
                    const datetime = moment(showtime.dateTime);
                    if (showtime.showInfo.enabled !== true) return false;
                    else {
                        if (listType === CONSTANTS.listTypes.special) {
                            const month = datetime.month();
                            const isInMonth = month === selectedMonth;
                            return (sortBy == CONSTANTS.sort.alphabet || sortBy == CONSTANTS.sort.time && isInFuture) && isInMonth;
                        }
                        else {
                            const formattedDate = datetime.format('YYYY-MM-DD');
                            const isOnDate = formattedDate === selectedDate;
                            return (sortBy == CONSTANTS.sort.alphabet || sortBy == CONSTANTS.sort.time && showExpired || sortBy == CONSTANTS.sort.time && isInFuture) &&
                                (listType !== CONSTANTS.listTypes.all ? isOnDate : true);
                        }
                    }
                }
            });

            if (movie.validShowtimes.length > 0) {
                movie.validShowtimes = _.sortBy(movie.validShowtimes, ['dateTime']);
                sortedMovies.push(movie);
            }
            else if (listType == CONSTANTS.listTypes.future) sortedMovies.push(movie);
        });

        if (sortBy === CONSTANTS.sort.alphabet) sortedMovies = _.sortBy(sortedMovies, ['name']);
        else if (sortBy === CONSTANTS.sort.time)
            sortedMovies.sort((a, b) => { return moment(a.validShowtimes[0].dateTime) - moment(b.validShowtimes[0].dateTime); });

        return sortedMovies;
    }

    render() {
        const { movies, appLocale, organizers } = this.props;
        const movieListItems = this.generateMovieListItems(movies);

        return (
            <div className={organizers.length > 1 ? 'MovieList multibio' : 'MovieList'}>
                {movieListItems.length > 0 ? movieListItems : <h3 className="no-events">{LOCALE[appLocale].noMoviesText}</h3>}
            </div>)
    }
}