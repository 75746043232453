import React from 'react'
import _ from 'lodash'

export default class SelectedTickets extends React.Component {

    extractSelectedTickets() {
        const { tickets } = this.props;
        if (tickets) {
            return _.map(tickets, function (ticket, index) {
                const seperator = index < tickets.length - 1 ? ', ' : null;
                return <span key={index}>{ticket.quantity} x {ticket.name}{seperator}</span>;
            });
        }
    }

    render() {
        const selectedTickets = this.extractSelectedTickets();
        return (
            <div className="SelectedTickets">
                {selectedTickets}
            </div>
        )
    }
}