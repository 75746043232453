import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { languageChanged } from '../../actions/app-actions'

import './languagepicker.scss';

class LanguagePicker extends React.Component {
    constructor() {
        super();
        this.state = { isOpen: false };
        this.togglePicker = this.togglePicker.bind(this);
        this.languageChanged = this.languageChanged.bind(this);
    }

    togglePicker() {
        this.state.isOpen ? this.setState({ isOpen: false }) : this.setState({ isOpen: true });
    }

    languageChanged(e) {
        const locale = e.currentTarget.id;
        const { actions } = this.props;
        this.togglePicker();
        actions.languageChanged(locale);
    }

    render() {
        const { isOpen } = this.state;
        const { appLocale, availableLocales } = this.props;
        const selected = _.find(availableLocales, (locale) => locale.id == appLocale);
        return (
            <div className={isOpen ? 'LanguagePicker open' : 'LanguagePicker'}>
                <div className="language selected" key={selected.id} id={selected.id} onClick={this.togglePicker}>
                    <img src={selected.flag} alt={selected.name} title={selected.name} />
                </div>

                {_.map(availableLocales, (locale) => {
                    const selected = appLocale == locale.id;
                    if (!selected)
                        return <div className="language" key={locale.id} id={locale.id} onClick={this.languageChanged}>
                            <img src={locale.flag} alt={locale.name} title={locale.name} />
                        </div>
                })}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        availableLocales: state.organizer.configuration.availableLocales,
        appLocale: state.app.locale
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ languageChanged }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePicker)