import './summary.scss'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { ROUTES } from '../../navigation/route-constants'
import CONSTANTS from '../orderflow-constants'
import { appHeightChanged } from '../../../actions/app-actions'
import Summary from './Summary'

const mapStateToProps = (state, ownProps) => {
    const { receipt, order } = state;
    const showReceiptData = receipt.transactionId != 0;

    const ticketPrice = showReceiptData ? receipt.prices.tickets : order.selected.totalTicketPrice;
    const tickets = showReceiptData ? receipt.tickets : (order.selected ? order.selected.tickets : null);

    const extraPurchasesActive = !state.order.canReserve || _.some(state.order.availableExtraPurchases, (extra) => { return extra.buyOnly === false });;
    const includeExtras = ownProps.currentPage !== ROUTES.ORDERFLOW.TICKETS && order.orderType !== CONSTANTS.orderTypes.reserve && extraPurchasesActive;
    const extraPurchasePrice = includeExtras ? (showReceiptData ? receipt.prices.extras : order.selected.totalExtraPurchasesPrice) : 0;

    const priceBeforeFees = ticketPrice + extraPurchasePrice;
    const showFees = ownProps.currentPage !== ROUTES.ORDERFLOW.TICKETS;
    const totalFees = showFees && priceBeforeFees > 0 ? (showReceiptData ? receipt.prices.fees : order.selected.totalFees) : 0;

    const totalPrice = showReceiptData ? receipt.prices.total : state.order.totalPrice;

    return {
        tickets,
        ticketPrice,
        extraPurchasePrice,
        totalFees,
        totalPrice,
        showFees,
        includeExtras,
        selected: state.order.selected,
        bottomBarAllwaysVisible: state.organizer.configuration.bottomBarAllwaysVisible,
        appLocale: state.app.locale,
        summaryExpanded: state.organizer.configuration.summaryExpanded
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { appHeightChanged }), dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Summary)