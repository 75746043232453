import React from 'react'
import _ from 'lodash'
import Transaction from './TransactionListItem'
import LOCALE from './TransactionsLocale'

export default class TransactionList extends React.Component {
    constructor() {
        super();
        this.getReceipt = this.getReceipt.bind(this);
    }

    getReceipt(e) {
        e.preventDefault();
        const target = e.target;
        const currentTarget = e.currentTarget;
        if (!currentTarget.className.includes('removed') && (target.tagName == 'IMG' || target.tagName == 'H1' || target.tagName == 'INPUT')) {
            const { transactions, toggleReceipt, appLocale } = this.props;
            const transaction = _.find(transactions, (trans) => trans.id == currentTarget.id);
            if (transaction) toggleReceipt(transaction);
            else this.setState({ error: LOCALE[appLocale].getReceiptError });
        }
    }

    generateListItems() {
        const { transactions, appLocale, organizerType } = this.props;
        return _.map(transactions, (transaction, i) => {
            let rowClasses = this.calculateRowPlacement(i, 'Transaction two-columns');
            if (transaction.removed == true) rowClasses += ' removed';
            return <Transaction key={i} rowClasses={rowClasses} transaction={transaction} getReceipt={this.getReceipt} appLocale={appLocale} organizerType={organizerType}/>
        });
    }

    calculateRowPlacement(counter, rowClasses) {
        if (counter > 1) {
            for (let i = 2; i <= 7; i++) {
                if (counter % i == 0) rowClasses += ' first-in-row' + i;
            }
        }
        return rowClasses;
    }

    render() {
        const list = this.generateListItems();

        return (
            <div className="TransactionsList MovieList">
                {list}
            </div>
        )
    }
}
