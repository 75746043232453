import {
    TRACK_CHECKOUT_STEP,
    TRACK_PURCHASE,
    TRACK_RESERVATION,
    TRACK_DELETE_RESERVATION
} from '../actions/ga-actions'

export default function googleAnalytics(
    state = {
        event: '',
        step: 0,
        dataLayer: {}
    }, action) {
    switch (action.type) {
        case TRACK_CHECKOUT_STEP:
            return {
                ...state,
                event: action.event,
                step: action.step,
                dataLayer: action.dataLayer
            }
        case TRACK_PURCHASE:
            return {
                ...state,
                event: action.event,
                dataLayer: action.dataLayer
            }
        case TRACK_RESERVATION:
            return {
                ...state,
                event: action.event,
                dataLayer: action.dataLayer
            }
        case TRACK_DELETE_RESERVATION:
            return {
                ...state,
                event: action.event,
                dataLayer: action.dataLayer
            }
        default:
            return state
    }
}