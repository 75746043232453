import moment from 'moment-timezone'
//Available moment locales
import 'moment/locale/da'
import 'moment/locale/fo'
//--
import _ from 'lodash'
import localStorageUtils from '../utilities/localstorage-utilities'

export const LANGUAGE_CHANGED = 'LANGUAGE_CHANGED'
export function languageChanged(newLocale) {
    return (dispatch, getState) => {
        const availableLocales = getState().organizer.configuration.availableLocales;
        const isAvailable = _.find(availableLocales, { id: newLocale });
        if (isAvailable) {
            moment.locale(newLocale);
            localStorageUtils.setItem('locale', newLocale);

            return dispatch({
                type: LANGUAGE_CHANGED,
                locale: newLocale
            });
        }
    }
}

export const SITETYPE_CHANGED = 'SITETYPE_CHANGED'
export const siteTypeChanged = (newSiteType) => {
    return {
        type: SITETYPE_CHANGED,
        siteType: newSiteType
    }
}

export const INIT_FROM_PARENT_SITE = 'INIT_FROM_PARENT_SITE'
export function initFromParentSite(origin) {
    return (dispatch, getState) => {
        return dispatch({
            type: INIT_FROM_PARENT_SITE,
            origin
        });
    };
}

export const APP_HEIGHT_CHANGED = 'APP_HEIGHT_CHANGED'
export function appHeightChanged(initIframeId, scrollToIframeTop = false, saveScroll = false, recoverScroll = false) {
    return (dispatch, getState) => {
        if (window.parent != window) {
            let { height, iframeId } = getState().app;
            if (!iframeId) iframeId = initIframeId;

            const app = document.querySelector('.App');
            let newPageHeight = Math.max(app.scrollHeight, app.offsetHeight, app.clientHeight);

            // Annoying but needed since .App height doesn't include the recept overlay
            const receipt = document.querySelector('.TransactionReceipt'); 
            newPageHeight = receipt ? Math.max(newPageHeight, receipt.scrollHeight, receipt.offsetHeight, receipt.clientHeight) : newPageHeight;
            //---

            if (iframeId !== null && (height != newPageHeight || saveScroll || recoverScroll)) {
                console.log('[CHILD] AppHeightChanged! Posting to parent: ');
                console.log({ iframeId, oldHeight: height, newHeight: newPageHeight, scrollToIframeTop, saveScroll, recoverScroll });

                window.parent.postMessage({
                    iframeId: iframeId,
                    iframeHeight: newPageHeight,
                    scrollToTop: scrollToIframeTop,
                    saveScroll: saveScroll,
                    recoverScroll: recoverScroll
                }, '*');

                return dispatch({
                    type: APP_HEIGHT_CHANGED,
                    iframeId,
                    appHeight: newPageHeight
                });
            }
        }
    }
}