import API_CONSTANTS from '../api/api-constants'

import {
    FETCH_ORGANIZER_REQUEST,
    FETCH_ORGANIZER_SUCCESS,
    FETCH_ORGANIZER_FAILED
} from '../actions/organizer-actions'

export default function organizer(
    state = {
        isFetching: false,
        id: 0,
        name: '',
        futureTemplateId: 0,
        configuration: API_CONSTANTS.defaultConfiguration,
        creditcards: [],
        error: null
    }, action) {
    switch (action.type) {
        case FETCH_ORGANIZER_REQUEST:
            return {
                ...state,
                isFetching: true,
                lastRequested: action.requestedAt,
                error: null
            }
        case FETCH_ORGANIZER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                lastUpdated: action.suceededAt,
                id: action.id,
                multibioIds: action.multibioIds,
                name: action.name,
                futureTemplateId: action.futureTemplateId,
                configuration: action.configuration,
                creditcards: action.creditcards,
                error: null
            }
        case FETCH_ORGANIZER_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }

        default:
            return state
    }
}