const authHeader = '<AuthHeader xmlns="http://flow.ebillet.dk/systemnative/">'
    + '<UserName>web</UserName>'
    + '<Password>web</Password>'
    + '<OriginNo>10</OriginNo>'
    + '</AuthHeader>';

export function getOrganizer(organizerId, siteType = 0, customerEmail) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Header>'
        + authHeader
        + '</soap12:Header>'
        + '<soap12:Body>'
        + '<GetOrganizer xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<Organizer>' + organizerId + '</Organizer>'
        + '<SiteType>' + siteType + '</SiteType>'
        + '<CustomerEmail>' + customerEmail + '</CustomerEmail>'
        + '</GetOrganizer>'
        + '</soap12:Body>'
        + '</soap12:Envelope>';
}

export function getAvailableTicketsAndConcession(organizerId, movieId, cardholder, discountCode, transactionId, queueId, siteType, ekstern) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Header>'
        + authHeader
        + '</soap12:Header>'
        + '<soap12:Body>'
        + '<GetAvailableTicketsAndConcession xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<TransactionID>' + transactionId + '</TransactionID>'
        + '<QueueID>' + queueId + '</QueueID>'
        + '<Organizer>' + organizerId + '</Organizer>'
        + '<Arrangement>' + movieId + '</Arrangement>'
        + '<Cardholder>' + cardholder + '</Cardholder>'
        + '<strDiscountCode>' + discountCode + '</strDiscountCode>'
        + '<SiteType>' + siteType + '</SiteType>'
        + '<ekstern>' + ekstern + '</ekstern>'
        + '</GetAvailableTicketsAndConcession>'
        + '</soap12:Body>'
        + '</soap12:Envelope>';
}



export function ticketsXml(organizerId, showtimeId, tickets) {
    return '<Message xmlns="">'
        + '<Show No="' + showtimeId + '" OrganizerNo="' + organizerId + '">'
        + '<Tickets>'
        + tickets
        + '</Tickets>'
        + '</Show>'
        + '</Message>';
}

export function blockSeats(transactionId, cardholder, hostaddress, tickets, vouchers, defaultTickets) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Header>'
        + authHeader
        + '</soap12:Header>'
        + '<soap12:Body>'
        + '<BlockSeats_v2 xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<TransactionID>' + transactionId + '</TransactionID>'
        + '<Cardholder>' + cardholder + '</Cardholder>'
        + '<HostAddress>' + hostaddress + '</HostAddress>'
        + '<Tickets>' + tickets + '</Tickets>'
        + '<strBarcodes>' + vouchers + '</strBarcodes>'
        + '<DefaultTickets>' + defaultTickets + '</DefaultTickets>'
        + '</BlockSeats_v2>'
        + '</soap12:Body>'
        + '</soap12:Envelope>';
}

export function seatsImage(transactionId) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Header>'
        + authHeader
        + '</soap12:Header>'
        + '<soap12:Body>'
        + '<SeatsImage xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<TransactionID>' + transactionId + '</TransactionID>'
        + '</SeatsImage>'
        + '</soap12:Body>'
        + '</soap12:Envelope>';
}



export function moveSeats(transactionId, x, y) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Header>'
        + authHeader
        + '</soap12:Header>'
        + '<soap12:Body>'
        + '<MoveSeats xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<TransactionID>' + transactionId + '</TransactionID>'
        + '<x>' + x + '</x>'
        + '<y>' + y + '</y>'
        + '</MoveSeats>'
        + '</soap12:Body>'
        + '</soap12:Envelope>';
}

export function getConcessions(organizerId, showtimeId, cardholderId) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Body>'
        + '<GetConcession xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<Organizer>' + organizerId + '</Organizer>'
        + '<Arrangement>' + showtimeId + '</Arrangement>'
        + '<Cardholder>' + cardholderId + '</Cardholder>'
        + '</GetConcession>'
        + '</soap12:Body>'
        + '</soap12:Envelope>';
}

export function concessionXml(organizerId, showtimeId, concessions) {
    return '<Message xmlns="">'
        + '<Show No="' + showtimeId + '" OrganizerNo="' + organizerId + '">'
        + '<Concessions>'
        + concessions
        + '</Concessions>'
        + '</Show>'
        + '</Message>';
}

export function addConcession(transactionId, cardholderId, quickuserId, concessions) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Header>'
        + authHeader
        + '</soap12:Header>'
        + '<soap12:Body>'
        + '<AddConcession xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<TransactionID>' + transactionId + '</TransactionID>'
        + '<Cardholder>' + cardholderId + '</Cardholder>'
        + '<QuickUser>' + quickuserId + '</QuickUser>'
        + '<Concessions>' + concessions + '</Concessions>'
        + '</AddConcession>'
        + '</soap12:Body>'
        + '</soap12:Envelope>'
}

export function voucherXml(organizerId, showtimeId, vouchers) {
    return '<Message xmlns="">'
        + '<Show No="' + showtimeId + '" OrganizerNo="' + organizerId + '">'
        + '<Vouchers>'
        + vouchers
        + '</Vouchers>'
        + '</Show>'
        + '</Message>';
}

export function addVouchers(transactionId, cardholderId, vouchers) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Header>'
        + authHeader
        + '</soap12:Header>'
        + '<soap12:Body>'
        + '<AddVoucher xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<TransactionID>' + transactionId + '</TransactionID>'
        + '<Cardholder>' + cardholderId + '</Cardholder>'
        + '<BonusVouchers>' + vouchers + '</BonusVouchers>'
        + '</AddVoucher>'
        + '</soap12:Body>'
        + '</soap12:Envelope>';
}

export function preBook(transactionId, userId, phone, name, password, email, cardholder, creditCardCode, quickUser, newsletters, zipCode, note) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Header>'
        + authHeader
        + '</soap12:Header>'
        + '<soap12:Body>'
        + '<PreBook xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<TransactionID>' + transactionId + '</TransactionID>'
        + '<UserID>' + userId + '</UserID>'
        + '<Phone>' + phone + '</Phone>'
        + '<Name>' + name + '</Name>'
        + '<Password>' + password + '</Password>'
        + '<EMail>' + email + '</EMail>'
        + '<CardHolder>' + cardholder + '</CardHolder>'
        + '<CreditCardCode>' + creditCardCode + '</CreditCardCode>'
        + '<QuickUser>' + quickUser + '</QuickUser>'
        + '<Newsletter>' + newsletters + '</Newsletter>'
        + '<bSendReceipt>true</bSendReceipt>'
        + '<Zipcode>' + zipCode + '</Zipcode>'
        + '<Note>' + note + '</Note>'
        + '</PreBook>'
        + '</soap12:Body>'
        + '</soap12:Envelope>';
}




export function reserve(transactionId, userId, phone, name, password, email, hostaddress, quickuser, newsletters, smsReminder, zipCode, note) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Header>'
        + authHeader
        + '</soap12:Header>'
        + '<soap12:Body>'
        + '<Reserve xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<TransactionID>' + transactionId + '</TransactionID>'
        + '<UserID>' + userId + '</UserID>'
        + '<Phone>' + phone + '</Phone>'
        + '<Name>' + name + '</Name>'
        + '<Password>' + password + '</Password>'
        + '<EMail>' + email + '</EMail>'
        + '<HostAddress>' + hostaddress + '</HostAddress>'
        + '<QuickUser>' + quickuser + '</QuickUser>'
        + '<Newsletter>' + newsletters + '</Newsletter>'
        + '<Sms>' + smsReminder + '</Sms>'
        + '<Zipcode>' + zipCode + '</Zipcode>'
        + '<Note>' + note + '</Note>'
        + '</Reserve>'
        + '</soap12:Body>'
        + '</soap12:Envelope>';
}

export function book(transactionId, reference, amount, invoiceId) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Header>'
        + authHeader
        + '</soap12:Header>'
        + '<soap12:Body>'
        + '<Book xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<TransactionID>' + transactionId + '</TransactionID>'
        + '<Reference>' + reference + '</Reference>'
        + '<Amount>' + amount + '</Amount>'
        + '<BuyCode>' + invoiceId + '</BuyCode>'
        + '</Book>'
        + '</soap12:Body>'
        + '</soap12:Envelope>';
}

export function activateReservation(reservationId, HPKey) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Header>'
        + authHeader
        + '</soap12:Header>'
        + '<soap12:Body>'
        + '<ActivateReservation xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<ReservationId>' + reservationId + '</ReservationId>'
        + '<Key>' + HPKey + '</Key>'
        + '</ActivateReservation>'
        + '</soap12:Body>'
        + '</soap12:Envelope>';
}

export function getReservation(reservationId, HPKey) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Header>'
        + authHeader
        + '</soap12:Header>'
        + '<soap12:Body>'
        + '<GetReservationInfo xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<ReservationId>' + reservationId + '</ReservationId>'
        + '<Key>' + HPKey + '</Key>'
        + '</GetReservationInfo>'
        + '</soap12:Body>'
        + '</soap12:Envelope>';
}

export function cancelReservation(reservationId, userId, phone, password, hostaddress, quickuser) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Header>'
        + authHeader
        + '</soap12:Header>'
        + '<soap12:Body>'
        + '<CancelReservation xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<ReservationNo>' + reservationId + '</ReservationNo>'
        + '<UserID>' + userId + '</UserID>'
        + '<Phone>' + phone + '</Phone>'
        + '<Password>' + password + '</Password>'
        + '<HostAddress>' + hostaddress + '</HostAddress>'
        + '<QuickUser>' + quickuser + '</QuickUser>'
        + '</CancelReservation>'
        + '</soap12:Body>'
        + '</soap12:Envelope>';
}

export function cancelTransaction(transactionId) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Header>'
        + authHeader
        + '</soap12:Header>'
        + '<soap12:Body>'
        + '<CancelTransaction xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<TransactionID>' + transactionId + '</TransactionID>'
        + '</CancelTransaction>'
        + '</soap12:Body>'
        + '</soap12:Envelope>';
}

export function getUser(email, password) {
    return '<?xml version="1.0" encoding="utf-8"?>'
        + '<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">'
        + '<soap12:Header>'
        + authHeader
        + '</soap12:Header>'
        + '<soap12:Body>'
        + '<GetUser xmlns="http://flow.ebillet.dk/systemnative/">'
        + '<EMail>' + email + '</EMail>'
        + '<Password>' + password + '</Password>'
        + '</GetUser>'
        + '</soap12:Body>'
        + '</soap12:Envelope>'
}