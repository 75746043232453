import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { FILTER_TEXTS } from '../../../api/api-constants'
import CONSTANTS from '../movielist-constants'
import LOCALE from '../MovieListLocale'
import ProgramListItem from './ProgramListItem'
import { setItemClasses } from '../shared'

export default class ProgramList extends React.Component {
    generateMovieListItems(movies) {
        const { posterBanners, appLocale, movieClicked, showtimeClicked, columns, showLocationOnShowtime, showAsBase, appHeightChanged, organizerIds, showExpired, showBackInTime, filterIds } = this.props;
        let sortedMovies = this.sortMovies(movies);
        let movieItems = [];
        let counter = 0;

        _.forEach(sortedMovies, (movie) => {
            let rowClasses = setItemClasses(counter, columns);

            movieItems.push(<ProgramListItem key={movie.id}
                appLocale={appLocale}
                rowClasses={rowClasses}
                columns={columns}
                showAsBase={showAsBase}
                movie={movie}
                posterBanners={posterBanners}
                movieClicked={movieClicked}
                showtimeClicked={showtimeClicked}
                showLocationOnShowtime={showLocationOnShowtime}
                organizerIds={organizerIds}
                appHeightChanged={appHeightChanged}
                showExpired={showExpired}
                showBackInTime={showBackInTime}
                filterIds={filterIds}
            />);

            counter++;
        });

        return movieItems;
    }

    noMoviesText = null;
    sortMovies(movies) {
        const { sortBy, showtimes, selectedDate, showBackInTime, showAsBase, showExpired, appLocale } = this.props;

        let sortedMovies = [];
        _.forEach(movies, (movie) => {
            movie.validShowtimes = _.filter(showtimes, (showtime) => {
                if ((showtime.movieId == movie.id || (showAsBase && showtime.movieBaseId > 0 && showtime.movieBaseId == movie.baseId))) {
                    const datetime = moment(showtime.dateTime);
                    const isInFuture = datetime > moment() || showBackInTime;

                    if (showtime.showInfo.enabled !== true) return false;

                    const formattedDate = datetime.format('YYYY-MM-DD');
                    const isOnDate = formattedDate === selectedDate;
                    if (this.noMoviesText == null && isOnDate) this.noMoviesText = LOCALE[appLocale].noMoreMoviesTodayText;

                    return (sortBy == CONSTANTS.sort.alphabet || sortBy == CONSTANTS.sort.time && showExpired || sortBy == CONSTANTS.sort.time && isInFuture) && isOnDate;
                }
            });

            if (movie.validShowtimes.length > 0) {
                movie.validShowtimes = _.sortBy(movie.validShowtimes, ['dateTime']);
                sortedMovies.push(movie);
            }
        });

        if (sortBy === CONSTANTS.sort.alphabet) sortedMovies = _.sortBy(sortedMovies, ['name']);
        else if (sortBy === CONSTANTS.sort.time)
            sortedMovies.sort((a, b) => { return moment(a.validShowtimes[0].dateTime) - moment(b.validShowtimes[0].dateTime); });

        return sortedMovies;
    }

    render() {
        const { movies, appLocale, filterIds } = this.props;
        const movieListItems = this.generateMovieListItems(movies);
        const noMoviesText = this.noMoviesText ? this.noMoviesText :
            (filterIds && filterIds.length == 1 ? LOCALE[appLocale].noMovieTypesTodayText.replace('INSERT_TYPE', FILTER_TEXTS[filterIds[0]]) : LOCALE[appLocale].noMoviesTodayText)

        return (
            <div className="MovieList">
                {movieListItems.length > 0 ? movieListItems : <h3 className="no-events">{noMoviesText}</h3>}
            </div>)
    }
}