import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import LOCALE from '../movielists/MovieListLocale'
import Showtime from '../showtime/Showtime'
import Poster from '../movie/Poster'

export default class CircusListItem extends React.Component {
    constructor() {
        super();
        this.state = { search: '' };
        this.searchInputChanged = this.searchInputChanged.bind(this);
    }

    searchInputChanged(e) {
        const value = e.target.value;
        this.setState({ search: value });
    }

    generateShowtimes() {
        const { organizer, event, showtimeClicked, appLocale } = this.props;
        return _.map(event.validShowtimes, (showtime) => {
            const search = this.state.search.toUpperCase();
            const location = _.find(organizer.locations, (location) => { return showtime.locationId == location.id });
            if (search) {
                const locationMatch = _.includes(location.name.toUpperCase(), search);
                const dateMatch = _.includes(moment(showtime.dateTime).format(LOCALE[appLocale].longDateTimeFormat).toUpperCase(), search)
                if (locationMatch || dateMatch) return this.generateShowtimeRow(showtime, appLocale, showtimeClicked, location);

            } else return this.generateShowtimeRow(showtime, appLocale, showtimeClicked, location);
        });
    }

    generateShowtimeRow(showtime, appLocale, showtimeClicked, location) {
        return <div key={showtime.id} className="showtime-row">
            <div className="primary-container">
                <div>
                    <div className="date-time">{_.capitalize(moment(showtime.dateTime).format(LOCALE[appLocale].longDateTimeFormat))}</div>
                    <div>{showtime.minPrice == showtime.maxPrice ? `${showtime.minPrice} kr.` : `${showtime.minPrice} kr. til ${showtime.maxPrice} kr.`}</div>
                </div>

                <div className="buy-tickets">
                    <Showtime key={showtime.id} showtime={showtime} text={LOCALE[appLocale].buyTickets} showtimeClicked={showtimeClicked} />
                </div>
            </div>

            <div className="info-container">
                <div className="location"><strong>{location.name}</strong> <span>&#65293;</span> {location.address.roadAndNumber}, {location.address.zip}, {location.address.city}</div>
                <div className="extra-info">
                    <strong>{showtime.type ? showtime.type.name : ''}</strong>
                    {showtime.info ? <label><span>&#65293;</span> {showtime.info}</label> : null}
                </div>
            </div>
        </div>;
    }

    render() {
        const { search } = this.state;
        const { event, appLocale } = this.props;
        const showtimes = this.generateShowtimes();
        const movieNameLocale = event.names ? (event.names[appLocale] ? event.names[appLocale] : event.names['da']) : event.name;
        const descriptionLocale = event.descriptions ? (event.descriptions[appLocale] ? event.descriptions[appLocale] : event.descriptions['da']) : event.description;

        return (
            <div className="CircusListItem one-column" id={event.id}>
                <div className="header">
                    {event.posters ? <Poster movie={event} /> : null}
                    <h1>{movieNameLocale}</h1>
                    <input type="text" className="search" placeholder={LOCALE[appLocale].searchCircus} value={search} onChange={this.searchInputChanged} />
                    <p className="description" dangerouslySetInnerHTML={{ __html: descriptionLocale.replace(/(?:\r\n|\r|\n)/g, '<br />') }} />
                </div>
                <div className="movie-info">
                    {showtimes}
                </div>
            </div>
        )
    }
}