import React from 'react'
import { connect } from 'react-redux'
import LOCALES from './OverlayLocales'

class Spinner extends React.Component {
    constructor() {
        super();
        this.state = { show: true };
    }

    componentDidMount() {
        const { show } = this.props;
        setTimeout(() => { this.setState({ show }); }, 0) // css transition workaround
    }

    render() {
        const { show, appLocale } = this.props;
        return (
            <div className={`Spinner overlay ${show ? ' fade-in' : ' fade-out'}`}>
                <p className="overlay-content">{LOCALES[appLocale].loading}</p>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        show: state.organizer.isFetching || state.movielist.isFetching || state.external.isFetching || state.order.isFetching ||
            state.customer.isFetching || state.receipt.isFetching || state.user.isFetching || state.ticketmaster.isFetching || state.cms.isFetching || ownProps.show || false,
        appLocale: state.app.locale
    }
}

export default connect(mapStateToProps)(Spinner)