import {
    EVENTLIST_REQUEST,
    EVENTLIST_SUCCESS,
    EVENTLIST_FAILED,
    SELECTED_EVENT_CHANGED
} from '../actions/ticketmaster-actions'

export default function ticketmaster(
    state = {
        isFetching: false,
        events: [],
        selectedEvent: {
            posters: {}
        },
        error: null
    }, action) {
    switch (action.type) {
        case EVENTLIST_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: null
            }
        case EVENTLIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                events: action.events
            }
        case EVENTLIST_FAILED:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case SELECTED_EVENT_CHANGED:
            return {
                ...state,
                selectedEvent: action.selectedEvent
            }
        default:
            return state
    }
}