export default {
    da: {
        longDateTimeFormat: 'dddd [d.] LL [kl.] LT',
        shortDateTimeFormat: 'ddd [d.] DD/MM [kl.] LT',
        noMoviesText: 'Der er ingen film',
        noMoviesThisWeekText: 'Der er ingen forestillinger i denne uge',
        noMoviesTodayText: 'Der er ingen forestillinger i dag',
        noMoreMoviesTodayText: 'Der er ikke flere forestillinger i dag',
        noMovieTypesTodayText: 'Der er ingen programlagte INSERT_TYPE forestillinger',
        noEventsText: 'Der er ingen arrangementer',
        readMore: 'Læs mere...',
        moreInfo: 'Mere info',
        moreShowtimes: 'Flere tider  ❯',
        readMoreShowtimes: 'Læs mere og se alle tider  ❯',
        buyTickets: 'Billetter',

        movieInfo: 'Filminfo',
        allShowtimes: 'Alle spilletider',
        specialEventInfo: 'Mere info',
        allEvents: 'Alle visninger',

        searchCircus: 'Søg dato eller by',
        today: 'I dag',
        shownFrom: 'Vises fra ',

    },

    en: {
        longDateTimeFormat: 'dddd LL [at] LT',
        shortDateTimeFormat: 'ddd, DD/MM [at] LT',
        noMoviesText: 'There are no movies',
        noMoviesThisWeekText: 'There are no movies this week',
        noMoviesTodayText: 'There are no showtimes today',
        noMoreMoviesTodayText: 'There are no more showtimes today',
        noMovieTypesTodayText: 'There are no planned INSERT_TYPE showtimes',
        noEventsText: 'There are no events',
        readMore: "Read more...",
        moreInfo: 'More info',
        moreShowtimes: "More showtimes  ❯",
        readMoreShowtimes: "Read more and all showtimes  ❯",
        buyTickets: 'Tickets',

        movieInfo: 'Movie info',
        allShowtimes: 'All showtimes',
        specialEventInfo: 'More info',
        allEvents: 'All shows',

        searchCircus: 'Search date or city',
        today: 'Today',
        shownFrom: 'Shown from '
    },

    fo: {
        longDateTimeFormat: 'dddd LL [d.] LT',
        shortDateTimeFormat: 'ddd, DD/MM [d.] LT',
        noMoviesText: 'Eingin filmur',
        noMoviesTodayText: 'Tað eru ongar spælitíðir í dag',
        noMoreMoviesTodayText: 'Tað eru ikki fleiri spælitíðir í dag',
        noMovieTypesTodayText: 'Tað eru ongar ætlaðar INSERT_TYPE spælitíðir',
        noEventsText: 'Tað er eingin sýning í dag',
        readMore: "Les meira...",
        moreInfo: 'Meira kunning',
        moreShowtimes: "Fleiri tíðir  ❯",
        readMoreShowtimes: "Les meira og síggj allar tíðir  ❯",
        buyTickets: 'Atgongumerki',

        movieInfo: 'Films kunning',
        allShowtimes: 'Allar spælitíðir',

        searchCircus: 'Leita eftir degi ella bý',
        today: 'Í dag',
        shownFrom: 'Vís frá '
    }

    // new: {
    //    longDateTimeFormat: 'dddd LL [INSERT HERE] LT',
    //    shortDateTimeFormat: 'ddd, DD/MM [INSERT HERE] LT',
    //    noMoviesText: '',
    //    noEventsText: '',
    //    readMore: "...",
    //    moreInfo: '',
    //    moreShowtimes: "  ❯",
    //    readMoreShowtimes: "  ❯",
    //    buyTickets: '',

    //    movieInfo: '',
    //    allShowtimes: '',

    //    searchCircus: '',
    //    today: '',
    //    shownFrom: ' '
    //}
}