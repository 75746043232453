import React from 'react'
import _ from 'lodash'
import LOCALE from './TransactionsLocale'

export default class TransactionLogin extends React.Component {
    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.submit(this.email.value, this.password.value);
    }

    render() {
        const { customer, error, appLocale } = this.props;

        return (
            <form className="Login" onSubmit={this.handleSubmit}>
                <div className="row">
                    <label>{LOCALE[appLocale].loginName}</label><input className="textbox" type="text" defaultValue={customer.email} ref={(email) => this.email = email} />
                </div>
                <div className="row">
                    <label>{LOCALE[appLocale].loginPassword}</label><input className="textbox" type="password" defaultValue={customer.password} ref={(password) => this.password = password} />
                </div>
                <div className="row">
                    <input type="submit" className="next" value={LOCALE[appLocale].findTickets} />
                </div>
                {error ? <div className="error">{LOCALE[appLocale].noUserTickets}</div> : null}
                <div className="forgot-password" dangerouslySetInnerHTML={{ __html: LOCALE[appLocale].forgotPassword }}></div>
            </form>
        )
    }
}