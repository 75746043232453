import './movie.scss'

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { getParams } from '../../utilities/location-utilities'
import { APP_MODES } from '../../api/api-constants'
import LOCALE from './MovieLocale'
import { ROUTES } from '../navigation/route-constants'
import { getMovieList, changeSelectedShowtime, selectedMovieChanged } from '../../actions/movielist-actions'
import Movie from './Movie'

import { appHeightChanged } from '../../actions/app-actions'

class MovieContainer extends React.Component {
    constructor() {
        super();
        this.movieClicked = this.movieClicked.bind(this);
        this.showtimeClicked = this.showtimeClicked.bind(this);
        this.navBack = this.navBack.bind(this);
        
       
    }
 

    componentDidMount() {
        const { actions, movies, movieId, organizerIds, iframeId } = this.props;
      
        if (movies.length == 0) actions.getMovieList(organizerIds).then(() => actions.selectedMovieChanged(movieId));

        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);
    }

    movieClicked(id) {
        const { organizerIds, actions, history } = this.props;
        actions.selectedMovieChanged(id).then(() => history.replace(`${ROUTES.MOVIE}/${id}?org=${organizerIds}`));
    }

    showtimeClicked(organizerId, showtimeId) {
        const { openOrderInNewWindow, actions, history, selectedMovie } = this.props;
        const showtime = _.find(selectedMovie.validShowtimes, (showtime) => showtime.id == showtimeId && showtime.organizerId == organizerId) ||
            _.find(selectedMovie.showtimesByOrgId[organizerId], (showtime) => showtime.id == showtimeId);

        if (showtime) {
            actions.changeSelectedShowtime(showtime).then(() => {
                const orderUrl = `${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${showtime.organizerId}`;
                if (openOrderInNewWindow) window.open(orderUrl, '_blank', 'width=1170,height=800,resizable=yes,scrollbars=yes');
                else history.push(orderUrl);
            })
        }
    }

    navBack() {
        const { organizerIds, history, backTo } = this.props;
        history.push(`${backTo}?org=${organizerIds}`);
    }

    generatBackbutton() {
        const { backTo, appLocale, backToText } = this.props;
        let backText = LOCALE[appLocale].backProgram;
        switch (backTo) {
            case ROUTES.PROGRAM:
                backText = LOCALE[appLocale].backProgram;
                break;
            case ROUTES.ALL_MOVIES:
                backText = LOCALE[appLocale].backAll;
                break;
            case ROUTES.ALL_FUTURE_MOVIES:
                backText = LOCALE[appLocale].backAllFuture;
                break;
            case ROUTES.FUTURE_MOVIES:
                backText = LOCALE[appLocale].backFuture;
                break;
        }

        if (backToText) backText = `❮  ${backToText[appLocale]}`;
        return <button className="back gray" onClick={this.navBack}>{backText}</button>;
    }

    render() {
        
        const { selectedMovie, configuration, pageHeader, appLocale, organizers, iframeId, showtimeTypes, showExpired, filterIds, movies } = this.props;
       
       
        const header = pageHeader ? <div className="page-header" dangerouslySetInnerHTML={{ __html: pageHeader }}></div> : null;
        const movie = !_.isEmpty(selectedMovie) ?
            <Movie appLocale={appLocale}
                organizers={organizers}
                movie={selectedMovie}
                movies={movies}
                movieClicked={this.movieClicked}
                showtimeClicked={this.showtimeClicked}
                posterBanners={configuration.posterBanners}
                iframeId={iframeId}
                showtimeTypes={showtimeTypes}
                showExpired={showExpired}
                filterIds={filterIds}
            /> : null;

        return (
            <div className={`MovieContainer ${organizers.length > 1 ? 'multibio' : ''}`}>
                {header}
                {movie}
                <div className="nav-buttons" style={{ marginBottom:'50px' }}>
                    {configuration.appMode !== APP_MODES.MOVIE ? this.generatBackbutton() : null}
                </div>
              
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let selectedMovie = state.movielist.selectedMovie;

    const movieId = ownProps.match.params.movieId;
    if (selectedMovie == null || movieId != null && selectedMovie.id != movieId)
        selectedMovie = _.find(state.movielist.movies, (movie) => { return movie.id == ownProps.match.params.movieId }) || {};

    selectedMovie.versions = [];
    if (selectedMovie.baseId) {
        selectedMovie.versions = _.filter(state.movielist.movies, (movie) => { return movie.baseId === selectedMovie.baseId });
        selectedMovie.base = _.find(state.movielist.movieBases, { 'id': selectedMovie.baseId });
    }
    const validShowtimesForMovie = _.filter(state.movielist.showtimes, (showtime) => {
        if (showtime.showInfo.enabled !== true) return false;
        return showtime.movieId == selectedMovie.id;
    });
    selectedMovie.showtimesByOrgId = validShowtimesForMovie ? _.groupBy(validShowtimesForMovie, 'organizerId') : null;

    const config = state.organizer.configuration;
    const backTo = state.movielist.backTo;
    const showExpired = _.includes(ownProps.location.search, 'showexpired=true') ||
        (backTo == ROUTES.ALL_MOVIES || backTo == ROUTES.MULTI_ALL_MOVIES) && config.movielists.all.showExpired ||
        (backTo == ROUTES.PROGRAM || backTo == ROUTES.MULTI_PROGRAM || backTo == ROUTES.PROGRAM_AND_ALL_FUTURE_MOVIES) && config.movielists.date.showExpired

    return {
        organizerIds: state.movielist.organizerIds || getParams().org,
        organizers: state.movielist.organizers,
        selectedMovie,
        configuration: config,
        movieId,
        movies: state.movielist.movies,
        backTo,
        pageHeader: config.pageHeaders.movie,
        appLocale: state.app.locale,
        openOrderInNewWindow: config.openOrderInNewWindow,
        iframeId: state.app.iframeId,
        showtimeTypes: state.movielist.showtimeTypes,
        showExpired,
        filterIds: state.movielist.filterIds,
        backToText: _.find(state.organizer.configuration.texts, (text) => { return text.id == 'film-info-back' })
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, { getMovieList, selectedMovieChanged, changeSelectedShowtime, appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MovieContainer)