import _ from 'lodash'
import api from '../api/billetten-api'

export function getEventList(organizerId) {
    return (dispatch) => {
        dispatch(eventListRequest());
        return api.fetchEvents(organizerId)
            .then(events => {
                return dispatch(eventListSuccess(events))
            }, error => dispatch(eventListFailed(error)));
    }
}

export const EVENTLIST_REQUEST = 'EVENTLIST_REQUEST'
const eventListRequest = () => {
    return { type: EVENTLIST_REQUEST }
}

export const EVENTLIST_SUCCESS = 'EVENTLIST_SUCCESS'
const eventListSuccess = (events) => {
    return {
        type: EVENTLIST_SUCCESS,
        events: _.sortBy(events, 'dateTime')
    }
}

export const EVENTLIST_FAILED = 'EVENTLIST_FAILED'
const eventListFailed = (error) => {
    return {
        type: EVENTLIST_FAILED,
        error: {
            ...error,
            message: 'Der var ingen data at hente. Prøv igen senere.'
        }
    }
}

export function changeSelectedEvent(id) {
    return (dispatch, getState) => {
        const events = getState().external.events;
        const event = _.find(events, (event) => { return event.id == id });
        return new Promise((resolve) => resolve(dispatch(selectedEventChanged(event))));
    }
}

export const SELECTED_EVENT_CHANGED = 'SELECTED_EVENT_CHANGED'
export const selectedEventChanged = (selectedEvent) => {
    return {
        type: SELECTED_EVENT_CHANGED,
        selectedEvent: selectedEvent
    }
}