import React from 'react'
import _ from 'lodash'
import LOCALE from '../OrderLocale'
import SeatModal from './SeatModal'


export default class SelectedSeats extends React.Component {

    constructor(props) {
        super(props);
       
    }

   
    extractRowsAndSeats() {
        const { hasNumberedSeats, selectedSeats, appLocale } = this.props;
     
        if (hasNumberedSeats) {
            return _.map(selectedSeats, (seatText, i) => {
                return <div key={i}>{seatText}</div>;
            });
        } else return LOCALE[appLocale].seatsUnNumbered;

    }

   

   
    render() {

        const { label, seatsArr, seatsInfoArr, organizerId,} = this.props;
     
       
        let selectedSeatArr = []
        
       seatsArr && seatsArr.map(seat => {   
            seatsInfoArr && seatsInfoArr.map(sir => {
                if (seat.areano === sir._no) {                     
                    selectedSeatArr.push(seat.seat)              
                }
            })        
        })
    
       
        const seatModal = <SeatModal seat_img={seatsInfoArr.length && seatsInfoArr[0].seatImageReal} organizerId={organizerId}
            seatsInfo={seatsInfoArr.length && seatsInfoArr[0].seatInfo} extractedSeats={selectedSeatArr} seatsInfoArr={seatsInfoArr} seatsArr={seatsArr} />;

        const rowsAndSeats = this.extractRowsAndSeats();
        return (
            <div className="SelectedSeats">
                <span>{label}</span>
                <div className="label">{rowsAndSeats}</div>

                {seatsInfoArr.length && (seatsInfoArr[0] && seatsInfoArr[0].seatImageReal || seatsInfoArr[1] && seatsInfoArr[1].seatImageReal || seatsInfoArr[0] && seatsInfoArr[0].seatInfo || seatsInfoArr[1] && seatsInfoArr[1].seatInfo) ? seatModal : ''}
            </div>
        )
    }
}