import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

class ShowtimeType extends React.Component {

    render() {
        const { type, includeName, showtimeTypes } = this.props;
        const name = includeName ? type.name : '';
        const index = showtimeTypes ? _.findIndex(showtimeTypes, (showType) => { return type.id == showType.id }) : null;

        return (
            <h4 className="ShowtimeType" >
                <span className="showtime-type-icon">{index + 1}</span> {name}
            </h4>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  
    return {
        showtimeTypes: state.movielist.showtimeTypes
    }
}

export default connect(mapStateToProps)(ShowtimeType)