export default {
    da: {
        backProgram: '❮  Program',
        backAll: '❮  Alle Film',
        backFuture: '❮  Kommende Film',
        backAllFuture: '❮  Fremtidige Film',

        originalTitle: 'Orig. titel: ',
        premiere: 'Premiere: ',
        length: 'Længde: ',
        age: 'Aldersgrænse: ',
        genre: 'Genre: ',
        actor: 'Skuespiller: ',
        actors: 'Skuespillere: ',
        director: 'Instruktør: ',
        directors: 'Instruktører: ',
        scriptwriter: 'Manuskript:',
        scriptwriters:'Manuskript:'
    },

    en: {
        backProgram: '❮  Program',
        backAll: '❮  All Movies',
        backFuture: '❮  Future Movies',

        originalTitle: 'Orig. title: ',
        premiere: 'Premiere: ',
        length: 'Length: ',
        age: 'Rated: ',
        genre: 'Genre: ',
        actor: 'Actor: ',
        actors: 'Actors: ',
        director: 'Director: ',
        directors: 'Directors: ',
        scriptwriter: 'Writer:',
        scriptwriters: 'Writers:'
    },

    fo: {
        backProgram: '❮  Skrá',
        backAll: '❮  Allir filmar',
        backFuture: '❮  Komandi filmar',

        originalTitle: 'Uppruna heiti: ',
        premiere: 'Frumsýning: ',
        length: 'Longd: ',
        age: 'Aldursmarn: ',
        genre: 'Slag: ',
        actor: 'Leikari: ',
        actors: 'Leikarar: ',
        director: 'Leikstjóri: ',
        directors: 'Leikstjórar: ',
        scriptwriter: 'Manuskript:',
        scriptwriters: 'Manuskript:'
    }

    //new: {
    //    backProgram: '❮  ',
    //    backAll: '❮  ',
    //    backFuture: '❮  ',

    //    originalTitle: ': ',
    //    premiere: ': ',
    //    length: ': ',
    //    age: ': ',
    //    genre: ': ',
    //    actor: '',
    //    actors: ': ',
    //    director: ': ',
    //    directors: ': '
    //}
}