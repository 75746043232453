import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { ROUTES } from '../../navigation/route-constants'
import LOCALE from '../ExternalLocale'

export default class MovieListItem extends React.Component {
    constructor(props) {
        super(props);
        this.eventClicked = this.eventClicked.bind(this);
    }

    eventClicked(e) {
        const { eventClicked } = this.props;
        const dataset = e.target.dataset;
        e.preventDefault();

        const eventId = e.currentTarget.id || dataset.movieId;
        if (e.target.tagName == 'IMG' || e.target.tagName == 'H1')
            eventClicked(eventId);
    }

    getShowtimeText() {
        const { event, appLocale } = this.props;
        if (event.isSoldOut) return LOCALE[appLocale].soldOut;
        else if (event.isCancelled) return LOCALE[appLocale].cancelled;
        else return LOCALE[appLocale].buyTickets;
    }

    render() {
        const { event, rowClasses, organizerId } = this.props;
        const showtimeText = this.getShowtimeText();
        const image = event.posters.medium || event.posters.small;

        return (
            <div className={rowClasses} id={event.id} onClick={this.eventClicked}>
                <div className="Poster"><img src={image} /></div>
                <a href={`${window.location.origin}${ROUTES.BILLETTEN_EVENT}/${event.id}?org=${organizerId}`}><h1>{event.name}</h1></a>

                <div className="show-info">
                    {event.dateTime ? _.capitalize(moment(event.dateTime).format('ddd [d.] DD/MM [kl.] LT')) : null}
                    <a className={event.isSoldOut || event.isCancelled ? 'Showtime disabled' : 'Showtime'} href={event.ticketsUrl} target="_blank"
                        onClick={() => window.open(event.ticketsUrl, '_blank', 'width=1170,height=800,resizable=yes,scrollbars=yes')}>
                        {showtimeText}
                    </a>
                </div>
            </div>
        )
    }
}