export default {
    da: {
        startOver: 'Start forfra',
        reservationSuccess: 'Reservation gennemført på',
        purchaseSuccess: 'Køb gennemført på',
        name: 'Navn',
        email: 'Email',
        print: 'Print',
        reserve: 'Reserver',
        buy: 'Køb',
        delete: 'Afbestil',
        deleteText: 'Er du sikker på at du vil afbestille din reservation?',
        deletedText: 'Din reservation er afbestilt',
        confirm: 'Ja',
        cancel: 'Nej',
        pickUp: 'Afhentes'
    },

    en: {
        startOver: 'Start over',
        reservationSuccess: 'Reservation completed on',
        purchaseSuccess: 'Purchase completed on',
        name: 'Name',
        email: 'Email',
        print: 'Print',
        reserve: 'Reserve',
        buy: 'Buy',
        delete: 'Cancel',
        deleteText: 'Are you sure you want to cancel your reservation?',
        deletedText: 'Your reservation is cancelled',
        confirm: 'Yes',
        cancel: 'No',
        pickUp: 'Pick up'
    },

    fo: {
        startOver: 'Byrja av nýggjum',
        reservationSuccess: 'Bílegging er gjøgnumført til',
        purchaseSuccess: 'Keyp er gjøgnumført til',
        name: 'Navn',
        email: 'Teldupostur',
        print: 'Printa',
        reserve: 'Bílegging',
        buy: 'Keyp',
        delete: 'Strika',
        deleteText: 'Er tú vís/vísur í at tú ynskir at strika tina bílegging?',
        deletedText: 'Tín bílegging er strika',
        confirm: 'Ja',
        cancel: 'Nei',
        pickUp: 'Heintast'
    }

    // new: {
    //    startOver: '',
    //    reservationSuccess: '',
    //    purchaseSuccess: '',
    //    name: '',
    //    email: '',
    //    print: '',
    //    reserve: '',
    //    buy: '',
    //    delete: '',
    //    deleteText: '',
    //    deletedText: '',
    //    confirm: '',
    //    cancel: '',
    //    pickUp: ''
    //}
}