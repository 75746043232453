import React from 'react'
import _ from 'lodash'
import QuantitySelector from './QuantitySelector'
import LOCALE from './ExtraPurchaseLocale'

const ExtraPurchaseItem = ({ extra, quantity, isFetching, quantityChanged, clubCard, canIncrementTotalPoints, appLocale }) => (
    <div className="ExtraPurchaseItem">
        <div className="image-container">
            <img src={extra.image} />
        </div>
        <div className="name-and-price">
            <h3>{extra.name}</h3>
            <label>{extra.price ? `${LOCALE[appLocale].price}: ${extra.price} ${LOCALE[appLocale].currency}` : `${_.capitalize(LOCALE[appLocale].points)} : ${extra.points}`}</label>
            <QuantitySelector id={extra.id}
                quantity={quantity || 0}
                maxQuantity={extra.buyMax || 20}
                quantityChanged={quantityChanged}
                isFetching={isFetching}
                extra={extra}
                clubCard={clubCard}
                canIncrementTotalPoints={canIncrementTotalPoints}
            />
            <div className="note" dangerouslySetInnerHTML={{ __html: extra.note }}></div>
        </div>
    </div>
)

export default ExtraPurchaseItem