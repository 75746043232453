import './order.scss'
import './multi.scss'
import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import moment from 'moment'
import CONSTANTS from '../orderflow-constants'
import { ROUTES } from '../../navigation/route-constants'

import LOCALE from './OrderLocale'
import Info from '../../overlays/Info'

import * as soapbuilder from '../../../api/soap-builder'
import xml2js from '../../../lib/xml-to-js'
import Ticket from './ticketselection/Ticket'
import SummaryMulti from './SummaryMulti';
import local from '../summary/SummaryLocale'

export default function SeatSelectionMulti({ configuration, appLocale, flowInfo, showtime }) {

  
    let infoPopups = [];
    if (flowInfo) infoPopups.push(<Info key="info-1" canClose={true} text={`<strong>${flowInfo[appLocale]}</strong><br /><br />`} buttons={<input type="button" className="next" value="OK" />} />);
    //if (showtime.info) infoPopups.push(<Info key="info-2" canClose={true} text={showtime.info} buttons={<input type="button" className="next" value="OK" />} />);
    const [errorMessage, setErrorMessage] = useState('')
  //  let ticketsTypes = [{}]
    const [ticketTypes, setTicketTypes] = useState([{}]);
    const [oneTicketType, setOneTicketType] = useState('')
    const [ticketTypeLength, setTicketTypeLength ] = useState('')
   // const [ticketType, setTicketType] = useState('')
   // const [availableTicketType, setAvailableTicketType] = useState('')
    const [showInsteadOfLocation, setShowInsteadOfLocation] = useState('')
    const [transId, setTransId] = useState('');
    const [seatText, setSeatText] = useState('')
    const [ticketCount, setTicketCount] = useState(2)
    const [ticketCount1, setTicketCount1] = useState(0)
    const [ticketCount2, setTicketCount2] = useState(0)
    const [ticketCount3, setTicketCount3] = useState(0)
    const [ticketCount4, setTicketCount4] = useState(0)
    const [ticketCount5, setTicketCount5] = useState(0)
    const [tc, setTc ] = useState('')
    const [imageSrc, setImageSrc] = useState('')
    const [priceInfo, setPriceInfo] = useState(false)
    const [xWidth, setXWidth] = useState('-1')
    const [yHeight, setYHeight] = useState('-1')
    const [ticketPrice, setTicketPrice] = useState('')
    const [totalTicketPrice, setTotalTicketPrice] = useState('')
    const [gebyr, setGebyr] = useState('')
    const [eventName, setEventName] = useState('')
    const [noShow, setNoShow] = useState(false)
    const [moveSeatsData, setMoveSeatsData] = useState('')
  //  const [show, setShow] = useState(false)
  //  const [firstTimeRUN, SetFirstTimeRun] = useState(false)
    const [errorCode, setErrorCode] = useState('')
    const [errorNo, setErrorNo] = useState('')
    let history = useHistory();
   
    const search = window.location.search;
    const paramss = new URLSearchParams(search);
    const org = paramss.get('org')
    const eventId = window.location.href.split('billetter/')[1].split('?')[0]

 //console.log(window)

    useEffect(() => {
       
        fetch(`${CONSTANTS.fetchUrl}/${CONSTANTS.ticketgatePath}/GetAvailableTicketsAndConcession_Multi?QueueID=0&TransactionID=0&Organizer=${org}&EventGroupNo=${eventId}&SiteType=0`, {
            method: 'GET',
            headers: {
                'UserName': 'web',
                'Password': 'web',
                'OriginNo': '10',
            },


        })
            .then(response => {              
                return response.text()
            })

            .then(result => {
                 const json = xml2js.parseString(result)
                 const data = json.message
             // console.log(data, 'dataaaaa')
              
                if (data && data.answer._result === false) { setErrorMessage(data.answer._niceMessage ? data.answer._niceMessage : data.answer._message) }
                if (data && data.answer._result === false) { setErrorCode(data.answer._code) }
                if (data && data.answer._result === false) { setErrorNo(data.answer._error) }
                setTc(data.ticketTypes.ticketType._refNo +'-'+ ticketCount)
                data && setTicketTypes(data.ticketTypes.ticketType)
                data && setOneTicketType(data.ticketTypes.ticketType._name)
                data && setTicketTypeLength(data.ticketTypes.ticketType.length)
                data && setShowInsteadOfLocation(data.arrangements._showInsteadOfLocation)
              //  console.log(data.ticketTypes.ticketType.length, 'ticket type length')
                data && setTransId(data.answer._transactionID)
                data && setEventName(data.arrangements.arrangement[0]._eventGroupName)
              //  console.log('trans_id', data.answer._transactionID)
              //  sessionStorage.setItem('event_name', data.arrangements.arrangement[0]._eventGroupName )
            })

    }, []);
  
    const getImageSrc = () => {
        if (transId) { 
        fetch(`${CONSTANTS.fetchUrl}/${CONSTANTS.ticketgatePath}/SeatsImage_Multi?TransactionID=${transId}`, {
            method: 'GET',
            headers: {
                'UserName': 'web',
                'Password': 'web',
                'OriginNo': '10',
            },
        })
            .then(res => {
                return res.text()
            })
            .then(result => {
                const json = xml2js.parseString(result)
                json && setImageSrc(json.base64Binary)
                //console.log(json.base64Binary, 'new src')
            })
        }
    }

    useEffect(() => {
        if (transId && xWidth && yHeight && ticketCount) {
            fetch(`${CONSTANTS.fetchUrl}/${CONSTANTS.ticketgatePath}/MoveSeats_Multi?TransactionID=${transId}&x=${xWidth}&y=${yHeight}
                   &strTicketTypes=${ticketTypes.length > 1 ? ticketTypes[0]._refNo + '-' + ticketCount + ',' + ticketTypes[1]._refNo + '-' + ticketCount1
                : ticketTypes.length > 2 ? ticketTypes[0]._refNo + '-' + ticketCount + ',' + ticketTypes[1]._refNo + '-' + ticketCount1 + ',' + ticketTypes[2]._refNo + '-' + ticketCount2
                : ticketTypes.length > 3 ? ticketTypes[0]._refNo + '-' + ticketCount + ',' + ticketTypes[1]._refNo + '-' + ticketCount1 + ',' + ticketTypes[2]._refNo + '-' + ticketCount2 + ',' + ticketTypes[3]._refNo + '-' + ticketCount3
                : ticketTypes.length > 4 ? ticketTypes[0]._refNo + '-' + ticketCount + ',' + ticketTypes[1]._refNo + '-' + ticketCount1 + ',' + ticketTypes[2]._refNo + '-' + ticketCount2 + ',' + ticketTypes[3]._refNo + '-' + ticketCount3 + ',' + ticketTypes[4]._refNo + '-' + ticketCount4
                : ticketTypes.length > 4 ? ticketTypes[0]._refNo + '-' + ticketCount + ',' + ticketTypes[1]._refNo + '-' + ticketCount1 + ',' + ticketTypes[2]._refNo + '-' + ticketCount2 + ',' + ticketTypes[3]._refNo + '-' + ticketCount3 + ',' + ticketTypes[4]._refNo + '-' + ticketCount4 + ',' + ticketTypes[5]._refNo + '-' + ticketCount5

                : ticketTypes._refNo + '-' + ticketCount}
                

`, {
            method: 'GET',
            headers: {
                'UserName': 'web',
                'Password': 'web',
                'OriginNo': '10',
            },

        })
            .then(response => {
                // console.log(response)
                return response.text()
            })
            .then(result => {
               // console.log(result)
                const json = xml2js.parseString(result)
                const data = json.message
              //  console.log(data, 'move seats')
                if (data && data.answer._result === true)  setMoveSeatsData(data)
                if (data && data.answer._result === false) { setErrorMessage(data.answer._niceMessage ? data.answer._niceMessage : data.answer._message) }
                if (data && data.answer._result === false) { setErrorCode(data.answer._code) }
                if (data && data.answer._result === false) { setErrorNo(data.answer._error) }

               
                data && setSeatText(data.transaction.seatText.line)
                data && setTicketPrice(data.transaction.amounts.tickets.split(',')[0])
                data && setGebyr(data.transaction.amounts.fees._total.split(',')[0])
                data && setTotalTicketPrice(data.transaction.amounts._total.split(',')[0])
               // data && setTicketCount(data.transaction.tickets)
                getImageSrc()
                

            })
        }
    }, [transId, xWidth, yHeight, ticketCount, ticketCount1, ticketCount2, ticketCount3, ticketCount4, ticketCount5])
   //console.log(transId, 'trans id')

   

  

    function navBack() {
     //   console.log('clicked')
        !noShow ? 
        fetch(`${CONSTANTS.fetchUrl}/${CONSTANTS.ticketgatePath}/CancelTransaction_Multi?TransactionID=${transId}`, {
            method: 'GET',
            headers: {
                'UserName': 'web',
                'Password': 'web',
                'OriginNo': '10',
            },
        })
            .then(res => {
                return res.text()
            })
            .then(result => {
                const json = xml2js.parseString(result)
                //console.log(json)
                history.push(`${ROUTES.FILMCLUB}?org=${org}`)

            })
            : 
        history.push(`${ROUTES.FILMCLUB}?org=${org}`)
    }

    function navNext() {
        //history.push(`${ROUTES.FILMCLUB_CUSTOMER}/${eventId}?org=${org}`);
        history.push({
            pathname: `/filmklub-Kunde/${eventId}?org=${org}`,
            state: {
                naam: 'mero',
                ticketCount:  ticketCount,
                ticketCount1: ticketCount1,
                ticketCount3: ticketCount3,
                ticketCount4: ticketCount4,
                ticketCount5: ticketCount5,
                ticketType: ticketTypeLength ? ticketTypes && ticketTypes[0]._name : ticketTypes && ticketTypes._name,
                ticketType1: ticketTypes[1] && ticketTypes[1]._name,
                ticketType2: ticketTypes[2] && ticketTypes[2]._name ,
                ticketType3: ticketTypes[3] && ticketTypes[3]._name,
                ticketType4: ticketTypes[4] && ticketTypes[4]._name,
                ticketType5: ticketTypes[5] && ticketTypes[5]._name,
                ticketPrice:  ticketPrice ,
                totalTicketPrice:  totalTicketPrice,
                gebyr: gebyr,
                transactionId: transId,
                eventName: eventName
                    }
        })
    }

    function togglePriceInfo() {
        setPriceInfo((current => !current))
    }

    function selectedSeatsChanged(e) {
        
        const target = e.target;
        const boundingClientRect = target.getBoundingClientRect();
       
        let x = (e.pageX - boundingClientRect.left) - window.pageXOffset;
        let y = (e.pageY - boundingClientRect.top) - window.pageYOffset;

        if (target.naturalWidth != target.width) {
            x = x * target.naturalWidth / target.width;
            y = y * target.naturalHeight / target.height;
           
        }
       
         setXWidth(parseInt(x)), setYHeight(parseInt(y))
      
    }

    const icon = priceInfo ? <span className="icon-chevrons-up"></span> : <span className="icon-chevrons-down"></span>;
   // console.log(ticketCount)
    function increment() {
        setTicketCount(ticketCount + 1)
    }
    function decrement() {
        setTicketCount(ticketCount - 1)
    }

    function increment1() {
        setTicketCount1(ticketCount1 + 1)
    }
    function decrement1() {
        setTicketCount1(ticketCount1 - 1)
    }

    function increment2() {
        setTicketCount2(ticketCount2 + 1)
    }
    function decrement2() {
        setTicketCount2(ticketCount2 - 1)
    }

    function increment3() {
        setTicketCount3(ticketCount3 + 1)
    }
    function decrement3() {
        setTicketCount3(ticketCount3 - 1)
    }

    function increment4() {
        setTicketCount4(ticketCount4 + 1)
    }
    function decrement5() {
        setTicketCount5(ticketCount5 - 1)
    }
    //  console.log(errorMessage)
    const decrementClasses = ticketCount > 0 ? 'decrement' : 'decrement deactivated';
    return (
        <>
          
      {errorMessage && <div style={{ backgroundColor: 'rgba(161, 161, 161, 0.95)', color: 'white', height: '100%', width: '100%', position: 'fixed', top: 0, left: 0, bottom: 0, padding: '30px' }}>

                <div style={{ backgroundColor: '#2e2e2e', textAlign: 'center', padding: '30px', width: '100%', margin: 'auto' }}>
                    <h3>{errorMessage}</h3>

                    <div style={{ textAlign: 'center' }}>
                        <button onClick={navBack} style={{ backgroundColor: 'green', color: 'white' }}>Filmklub</button>
                        </div>
                    <p style={{ float: 'right', fontSize: '12px' }}> Error code : ( {errorCode}, {errorNo} )</p>

                </div>
            </div> }
          
           
            <section style={{ textAlign:'center', height: '300px', backgroundColor: '#2e2e2e', color: 'white' , padding:'90px'}}>
                <h1>{eventName}</h1>
            </section>
            
            
        <main className="table-container" >
                <section className="AvailableTickets" style={{ textAlign: 'center' }}>

                    {ticketTypes.length > 1 ?
                       
                          <>
                         
                            {ticketTypes[0] &&
                                <div>
                                    <h3>{ticketTypes[0]._name}</h3>
                                    <div className="QuantitySelector">
                                       <div className='decrement' onClick={decrement}><span className="icon-circle-minus"></span></div>
                                       <div className="quantity" >{ticketCount}</div>
                                       <div className='increment' onClick={increment}><span className="icon-circle-plus"></span></div>
                                    </div>
                                </div>}

                            {ticketTypes[1] &&
                                <div>
                                    <h3>{ticketTypes[1]._name}</h3>
                                    <div className="QuantitySelector">
                                        <div className='decrement' onClick={decrement1}><span className="icon-circle-minus"></span></div>
                                        <div className="quantity" >{ticketCount1}</div>
                                        <div className='increment' onClick={increment1}><span className="icon-circle-plus"></span></div>
                                    </div>
                                </div>}
                            {ticketTypes[2] &&
                                <div>
                                    <h3>{ticketTypes[2]._name}</h3>
                                    <div className="QuantitySelector">
                                        <div className='decrement' onClick={decrement2}><span className="icon-circle-minus"></span></div>
                                        <div className="quantity" >{ticketCount2}</div>
                                        <div className='increment' onClick={increment2}><span className="icon-circle-plus"></span></div>
                                    </div>
                                </div>}
                            {ticketTypes[3] &&
                                <div>
                                    <h3>{ticketTypes[3]._name}</h3>
                                    <div className="QuantitySelector">
                                        <div className='decrement' onClick={decrement3}><span className="icon-circle-minus"></span></div>
                                        <div className="quantity" >{ticketCount3}</div>
                                        <div className='increment' onClick={increment3}><span className="icon-circle-plus"></span></div>
                                    </div>
                                </div>}
                            {ticketTypes[4] &&
                                <div>
                                    <h3>{ticketTypes[4]._name}</h3>
                                    <div className="QuantitySelector">
                                        <div className='decrement' onClick={decrement4}><span className="icon-circle-minus"></span></div>
                                        <div className="quantity" >{ticketCount4}</div>
                                        <div className='increment' onClick={increment4}><span className="icon-circle-plus"></span></div>
                                    </div>
                                </div>}
                            {ticketTypes[5] &&
                                <div>
                                    <h3>{ticketTypes[5]._name}</h3>
                                    <div className="QuantitySelector">
                                        <div className='decrement' onClick={decrement5}><span className="icon-circle-minus"></span></div>
                                        <div className="quantity" >{ticketCount5}</div>
                                        <div className='increment' onClick={increment5}><span className="icon-circle-plus"></span></div>
                                    </div>
                                </div>}
                    </> 
                  : (
                          <>
                             <h3>{ticketTypes._name}</h3>
                            <div className="QuantitySelector">
                                    <div className={decrementClasses} onClick={decrement}><span className="icon-circle-minus"></span></div>
                                  <div className="quantity" >{ticketCount}</div>
                                  <div className='increment' onClick={increment}><span className="icon-circle-plus"></span></div>
                            </div>
                          </>
                       )
                    }

            </section>

                {showInsteadOfLocation ? <h1 style={{ textAlign: 'center', minHeight: '400px', padding:'10px'}}> {showInsteadOfLocation}</h1> :
               <>
                  <section className="seatContainer" style={{ textAlign: 'center' , }}>
                  
                       <div className="seatSelection" style={{ marginTop: '20px' }}>
                       
                            <img style={{ maxWidth: '100%', minHeight: '380px', cursor: 'pointer', opacity: ticketCount === 0 ? 0.5 : '' }} src={`data:image/gif;base64,${imageSrc}`} onClick={(e) => selectedSeatsChanged(e)} />
                       </div>
                    
                        <div>
                           <br></br>
                           <br></br>
                           <b>  {LOCALE[appLocale].multiSeats} </b> <br></br>
                           <b>{seatText}</b>
                        </div>
                     
                </section>
                <br></br>

                <div className="SeatColors">
                    <> <span className="blue"></span> {LOCALE[appLocale].seatBlue}  </>
                    <> <span className="green"></span>{LOCALE[appLocale].seatGreen} </>
                    <> <span className="white"></span>{LOCALE[appLocale].seatWhite} </>
                    <> <span className="red"></span>  {LOCALE[appLocale].seatRed}   </>

                </div>
              </>
         }
            </main>
           

            <div className="nav-buttons">
                <div className="back-buttons">
                    <input type="button" className="back gray" onClick={navBack} value={LOCALE[appLocale].back} />
                </div>
                <div className="next-buttons">
                    <input type="button" id="buy" className="next green fade-in" onClick={navNext} value={LOCALE[appLocale].buy} />
                </div>
            </div>



             {moveSeatsData && ticketCount > 0 &&
                <SummaryMulti togglePriceInfo={togglePriceInfo} icon={icon} ticketPrice={ticketPrice} ticketTypeLength={ticketTypeLength} oneTicketType={oneTicketType}
                ticketCount={ticketCount} ticketCount1={ticketCount1} ticketCount2={ticketCount2} ticketCount3={ticketCount3} ticketCount4={ticketCount4} ticketCount5={ticketCount5}
                ticketType= {ticketTypes[0] && ticketTypes[0]._name}
                ticketType1={ticketTypes[1] && ticketTypes[1]._name}
                ticketType2={ticketTypes[2] && ticketTypes[2]._name}
                ticketType3={ticketTypes[3] && ticketTypes[3]._name} 
                ticketType4={ticketTypes[4] && ticketTypes[4]._name} 
                ticketType5={ticketTypes[5] && ticketTypes[5]._name}
                totalTicketPrice={totalTicketPrice} gebyr={gebyr} priceInfo={priceInfo} appLocale={appLocale} />} 

            </>
        )
}