import React, { useEffect, useState} from 'react'
import _ from 'lodash'


export default class VersionSelector extends React.Component {
    constructor() {
        super();
        this.movieClicked = this.movieClicked.bind(this);
    }

    movieClicked(e) {
         e.preventDefault();
        const movieId = e.currentTarget.id;
        this.props.movieClicked(movieId);
       // window.location.reload()
    }

    render() {
        const { movie } = this.props;
        const allVersions = _.map(movie.versions, (version, i) => {
            // const versionName = version.name.substr(version.base.name.length + 3); //name plus space, hyphen and space
            const classes = movie.id === version.id ? 'version selected' : 'version';
            //const name = versionName ? versionName : '2D';
            return <div key={i} className={classes} id={version.id} onClick={this.movieClicked}>{version.name}</div>
        });

        return (
            <div className="VersionSelector">
                {allVersions}
            </div>
        )
    }
}