import './order.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import moment from 'moment'

import { ROUTES } from '../../navigation/route-constants'
import { APP_MODES } from '../../../api/api-constants'
import * as orderActions from '../../../actions/order-actions'
import * as gaActions from '../../../actions/ga-actions'
import { appHeightChanged } from '../../../actions/app-actions'

import LOCALE from './OrderLocale'
import Info from '../../overlays/Info'

import SeatSelectionMulti from './SeatSelectionMulti'

class FilmClubOrderContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            canReserveSelected: true,
            canBuySelected: true,
            showExtraPurchasesReset: false,
            showTicketWarning: false,
            ticketWarningConfirmed: false,
           
        };
      
        this.navBack = this.navBack.bind(this)
        this.navNext = this.navNext.bind(this);
        this.setError = this.setError.bind(this);

      
    }

   
  
    componentDidMount() {
        const { actions, iframeId } = this.props;

        if (window.parent != window) actions.appHeightChanged(iframeId, true);
        else window.scrollTo(0, 0);


      
    }

    componentDidUpdate(prevProps) {
        const { order, actions, iframeId } = this.props;
        if (window.parent != window && order.availableTickets.length > 3)
            actions.appHeightChanged(iframeId, true);
    }

   

  

   
 
    

    setError(message) {
        this.setState({ error: message });
    }

    navBack() {
        const { history } = this.props;
        history.push(`${ROUTES.FILMCLUB}?org=${organizerId}`)
     
    }

    navNext() {
        const { history, organizerId } = this.props;
        history.push(`${ROUTES.FILMCLUB_CUSTOMER}/2?org=${organizerId}`);
    }


  

     
    render() {

        const { showtime, configuration, appLocale, flowInfo, warnIfOnlyThisInfo, noShow } = this.props;
     // console.log(showtime.info)
      // console.log(noShow)
       
        if (showtime.info) infoPopups.push(<Info key="info-2" canClose={true} text={showtime.info} buttons={<input type="button" className="next" value="OK" />} />);
       
        return (
            <div className="OrderContainer">
                {showtime.info && <Info key="info-2" canClose={true} text={showtime.info} buttons={<input type="button" className="next" value="OK" />} />}

                <div >

                    <SeatSelectionMulti appLocale={appLocale} configuration={configuration} flowInfo={flowInfo} showtime={showtime} />

                     
                </div>
              
                
               
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    //console.log(state, 'state')
    return {
        currentPage: ownProps.currentPage,
        organizerId: state.organizer.id,
        showtime: state.movielist.selectedShowtime,
        order: state.order,
        selected: state.order.selected,
        configuration: state.organizer.configuration,
        bioKlubDkAvailable: state.order.bioKlubDk.available,
        bioDkActive: state.order.selected.vouchers.active != '',
        backTo: state.movielist.backTo,
        initialFetchDone: state.order.initialFetchDone,
        appLocale: state.app.locale,
        discountEnabled: state.order.discountEnabled,
        discountActivated: state.order.discountActivated,
        clubCardId: state.user.clubCardId,
        canReserve: state.order.canReserve,
        showExtraPurchasesReset: state.order.showExtraPurchasesReset,
        iframeId: state.app.iframeId,
        flowInfo: _.find(state.organizer.configuration.texts, (text) => { return text.id == 'Flow_Popup' }),
        unNumberedText: _.find(state.organizer.configuration.texts, (text) => { return text.id == 'flow-unnumbered-text' }),
        seatSelectionInfo: _.filter(state.organizer.configuration.texts, (text) => { return text.id == 'Seat_Info_Reserved' || text.id == 'Seat_Info_Sold' || text.id == 'Seat_Info_Free' || text.id == 'Seat_Info_Selected' }),
        warnIfOnlyThisInfo: _.find(state.organizer.configuration.texts, (text) => { return text.id == 'WarnIfOnlyThis_Text' }),
        test: 'me'
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, orderActions, gaActions, {  appHeightChanged }), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilmClubOrderContainer)