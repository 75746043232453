import React from 'react'
import { baseUrl } from '../../api/cms-api'

export default class Commercial extends React.Component {
    constructor() {
        super();
        this.commercialClicked = this.commercialClicked.bind(this);
    }

    commercialClicked() {
        const { commercial, commercialClicked } = this.props;
        if (commercial.navigateTo) commercialClicked(commercial.navigateTo);
    }

    render() {
        const { commercial } = this.props;
        //TODO remove specific path on img...
        return (
            <div className="CommercialListItem" onClick={this.commercialClicked}>
                <div className="img-container">
                    <img src={commercial.image ? baseUrl + '/imageuploads/' + commercial.image : ''} />
                </div>
                <div className="text">
                    <h1>{commercial.title}</h1>
                    <p>{commercial.description}</p>
                </div>
            </div>
        );
    }
}