
import React from 'react'
import { ROUTES } from '../../navigation/route-constants'

export default class BuyOrCancel extends React.Component {
    constructor() {
        super()
        this.handleDelete = this.handleDelete.bind(this);
        this.handleBuy = this.handleBuy.bind(this)
    }

    handleBuy() {
        const { receipt, appLocale, actions } = this.props;
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const key = params.get('key')
        const rid = window.location.pathname.match(/\d/g).join("")
        window.location.href = window.location.origin + ROUTES.ORDERFLOW.CUSTOMER + '/' + receipt.movieId + '/' + receipt.showtimeId + '?org=' + receipt.organizerId + '&rid=' + rid+'&act=buy'+'&key='+key
        
    }

    handleDelete() {
        const { receipt, appLocale, actions } = this.props;
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const key = params.get('key')
        const rid = window.location.pathname.match(/\d/g).join("")
        
        window.location.href = window.location.origin+'/reservation'+'/'+rid+'?act=cancel'+'&key='+key
    }

 
    render() {
        const { receipt, appLocale, actions } = this.props;
      
       

        return (
            <div className="ReceiptContainer">

                <div>
                    <div className="Reservation">
                        <div className='Info overlay fade-in' >
                            <div className='overlay-content'>

                                <p>Vil du afbestille eller købe din reservation?</p>
                                <div className="buttons">
                                    <input type='button' style={{ color: 'white', backgroundColor: 'darkred', backgroundImage: 'none' }} value='afbestill' id='delete' onClick={this.handleDelete} />
                                    <input type='button' style={{ color: 'white', backgroundColor: 'green', backgroundImage: 'none' }} className='red' value='Køb' id='buy' onClick={this.handleBuy} />

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

