import {
    LANGUAGE_CHANGED,
    SITETYPE_CHANGED,
    APP_HEIGHT_CHANGED,
    INIT_FROM_PARENT_SITE
} from '../actions/app-actions'

export default function app(
    state = {
        isFetching: false,
        locale: 'da',
        height: 300,
        iframeId: null,
        origin: null,
        error: null
    }, action) {
    switch (action.type) {
        case LANGUAGE_CHANGED:
            return {
                ...state,
                locale: action.locale
            }
        case SITETYPE_CHANGED:
            return {
                ...state,
                siteType: action.siteType
            }
        case APP_HEIGHT_CHANGED:
            return {
                ...state,
                iframeId: action.iframeId,
                height: action.appHeight
            }
        case INIT_FROM_PARENT_SITE:
            return {
                ...state,
                origin: action.origin
            }
        default:
            return state
    }
}