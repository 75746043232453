import React from 'react'
import _ from 'lodash'


export default class Login extends React.Component {

    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    componentDidMount() {
        this.email.focus();
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.submit(this.email.value, this.password.value);
    }

    resetPassword() {
        const resetUrl = 'https://www.ebillet.dk/system/resetpassword.aspx';
        window.open(resetUrl, '_blank', 'width=400,height=400,resizable=yes,scrollbars=yes');
    }

    render() {
        const { title, close, error } = this.props;

        return (
            <div className="Login overlay fade-in">
                <div className="overlay-content">
                    <form onSubmit={this.handleSubmit} >
                        <h1>{title}</h1>
                        <div className="textbox">
                            <label>Email</label><input type="text" ref={(email) => this.email = email} />
                        </div>
                        <div className="textbox">
                            <label>Kode</label><input type="password" ref={(password) => this.password = password} />
                        </div>
                        <div className="reset-password" onClick={this.resetPassword}>Jeg har glemt min kode</div>
                        <div className="buttons">
                            {error ? <div className="error">{error}</div> : null}
                            <input type="button" className="red" onClick={close} value="Luk" />
                            <input type="submit" className="green" value="Login" />
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}