import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import moment from 'moment'
import { getParams } from '../../../utilities/location-utilities'
import CONSTANTS from '../movielist-constants'
import { ROUTES } from '../../navigation/route-constants'
import { manipulateTitles, setContainerClasses } from '../shared'
import { appHeightChanged } from '../../../actions/app-actions'
import * as movieListActions from '../../../actions/movielist-actions'
import * as orderActions from '../../../actions/order-actions'
import MonthNavigator from '../../datepicker/MonthNavigator'
import SpecialEventsList from './SpecialEventsList'

class SpecialEventsContainer extends React.Component {
    constructor() {
        super();
        this.movieClicked = this.movieClicked.bind(this);
        this.showtimeClicked = this.showtimeClicked.bind(this);
    }

    componentDidMount() {
        const { actions, movies, organizerIds, iframeId } = this.props;
        if (movies.length == 0) actions.getMovieList(organizerIds);

        if (window.parent != window) actions.appHeightChanged(iframeId, false, false, true);
        else window.scrollTo(0, sessionStorage.getItem("scroll-position"));
    }

    componentWillUnmount() {
        const { actions, iframeId } = this.props;
        if (window.parent != window) actions.appHeightChanged(iframeId, false, true, false);
        else sessionStorage.setItem("scroll-position", window.scrollY);
    }

    componentDidUpdate(prevProps) {
        this.manipulateTitles()
    }

    manipulateTitles() {
        const { columns } = this.props
        if (columns != CONSTANTS.columns.one) {
            const list = document.querySelector(`.MovieListContainer.special`)
            manipulateTitles(list, columns);
        }
    }

    movieClicked(id) {
        const { organizerIds, actions, history, movies } = this.props;
        const movie = _.find(movies, (movie) => { return movie.id == id });
        actions.selectedMovieChanged(movie.id, history.location.pathname).then(() => history.push(`${ROUTES.MOVIE}/${id}?org=${organizerIds}`));
    }

    showtimeClicked(organizerId, showtimeId) {
        const { openOrderInNewWindow, actions, history, showtimes } = this.props;
        const showtime = _.find(showtimes, (showtime) => { return showtime.id == showtimeId && showtime.organizerId == organizerId });
        if (showtime) {
            actions.changeSelectedShowtime(showtime, history.location.pathname).then(() => {
                const orderUrl = `${ROUTES.ORDERFLOW.TICKETS}/${showtime.movieId}/${showtime.id}?org=${showtime.organizerId}`;
                if (openOrderInNewWindow) window.open(orderUrl, '_blank', 'width=1170,height=800,resizable=yes,scrollbars=yes');
                else history.push(orderUrl);
            })
        }
    }

    render() {
        const { movies, columns, initialMonth, selectedMonth, monthsWithShowtimes, actions, posterBanners, appLocale, showtimes, sortBy, filterIds, headerText, availableLocales } = this.props;
        let classes = setContainerClasses(CONSTANTS.listTypes.special, columns);

        return (
            <div className={classes}>
                {headerText ? <h1 className="special-title" dangerouslySetInnerHTML={{ __html: headerText[appLocale] }}></h1> : null}
                <MonthNavigator
                    appLocale={appLocale}
                    initialMonth={initialMonth}
                    selectedMonth={selectedMonth}
                    monthsWithShowtimes={monthsWithShowtimes}
                    changeMonth={actions.changeMonth}
                    availableLocales={availableLocales}
                />

                <SpecialEventsList
                    appLocale={appLocale}
                    columns={columns}
                    movies={movies}
                    showtimes={showtimes}
                    sortBy={sortBy}
                    movieClicked={this.movieClicked}
                    showtimeClicked={this.showtimeClicked}
                    posterBanners={posterBanners}
                    selectedMonth={selectedMonth}
                    filterIds={filterIds}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const config = state.organizer.configuration;

    let monthsWithShowtimes = [];
    const movies = _.filter(state.movielist.movies, (movie) => movie.type == CONSTANTS.types.special);
    const showtimes = _.filter(state.movielist.showtimes, (showtime) => {
        if (_.find(movies, { 'id': showtime.movieId })) {
            monthsWithShowtimes = _.union(monthsWithShowtimes, [moment(showtime.dateTime).month()]);
            return true;
        }
    });


    return {
        organizerIds: state.movielist.organizerIds || getParams().org,
        movies,
        initialMonth: monthsWithShowtimes[0],
        selectedMonth: state.movielist.selectedMonth,
        monthsWithShowtimes,
        columns: config.movielists.special.columns,
        posterBanners: config.posterBanners,
        scrollPosition: state.movielist.scrollPosition,
        openOrderInNewWindow: config.openOrderInNewWindow,
        appLocale: state.app.locale,
        columns: config.movielists.special.columns,
        showtimes,
        sortBy: config.movielists.special.sort,
        iframeId: state.app.iframeId,
        filterIds: state.movielist.filterIds,
        headerText: _.find(config.texts, (text) => { return text.id == 'Arrangement_Headline' }),
        availableLocales: config.availableLocales,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(Object.assign({}, movieListActions, orderActions, { appHeightChanged }), dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpecialEventsContainer))